/* @flow */
import { pushEditorAssetAsync, pushReleaseAssetAsync, downloadFileAsync } from './common';
import type { pushEditorAssetType, pushReleaseAssetAsyncType, downloadAssetAsyncType } from './common';
import { Singleton as firebase } from '../Firebase';

export const pushCityEditorAssetAsync: pushEditorAssetType = async (cityId, filename, file: File, firebase) => {
  const scenarioStorage = firebase.cityEditorStorage(cityId);
  return pushEditorAssetAsync(scenarioStorage, filename, file);
};

export const pushCityReleaseAsset: pushReleaseAssetAsyncType = async (
  cityId,
  version,
  subfolder,
  filename,
  file,
  strContent,
  isPublic,
) => {
  console.log('Uploading release asset to firebase', cityId, version);
  const cityFolder = firebase.cityStorage(cityId);
  return pushReleaseAssetAsync(cityFolder, version, subfolder, filename, file, strContent, isPublic);
};

export const downloadCityAsset: downloadAssetAsyncType = async (cityId, filename, firebase) => {
  console.log('Download editor asset ', cityId, filename);
  const firebaseFile = firebase.cityEditorStorage(cityId).child(filename);
  const url = await firebaseFile.getDownloadURL();
  return downloadFileAsync(url);
};
