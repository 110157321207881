/* @flow */
import { createNamedReducer } from 'redux-named-reducers';
import { createTransform } from 'redux-persist';
import Scenario from 'src/data/Scenario';
import { Singleton } from './ScenarioLocaleManager';
import * as types from './types';

export type HeaderReducerState = Scenario;
export const HeaderReducerDefaultState = new Scenario({});

const reducer = (prevState: HeaderReducerState = HeaderReducerDefaultState, action: { type: string, payload: any }) => {
  let state;
  switch (action.type) {
    case types.HEADER_CREATE: {
      Singleton.restoreDefaults();
      state = new Scenario({ id: action.payload.id });
      break;
    }
    case types.HEADER_LOAD_CONTENT:
      Singleton.restoreDefaults();
      state = new Scenario({ id: action.payload.id });
      break;
    case types.HEADER_LOAD: {
      const { content } = action.payload;
      if (content) {
        Singleton.configure(content.defaultLocale, content.managedLocales);
      } else {
        Singleton.restoreDefaults();
      }
      state = new Scenario(content);
      break;
    }
    case types.HEADER_UPDATE: {
      const content = action.payload.content;
      if (content) {
        Singleton.configure(content.defaultLocale, content.managedLocales);
      } else {
        Singleton.restoreDefaults();
      }
      state = new Scenario(content);
      break;
    }
    case types.HEADER_UPDATE_LAST_VERSION:
      state = new Scenario(prevState);
      state.lastVersion = action.payload.version;
      break;
    case types.HEADER_CLEAN:
      Singleton.restoreDefaults();
      state = new Scenario({});
      break;
    default:
      state = prevState;
      break;
  }
  return state;
};

export const HeaderReducerKey = 'header';

export const HeaderTransform = createTransform(
  inboundState => inboundState,
  outboundState => new Scenario(outboundState),
  { whitelist: [HeaderReducerKey] },
);

export default createNamedReducer({
  moduleName: HeaderReducerKey,
  reducer,
});
