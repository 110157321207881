/* @flow */
import { AbstractPortFactory } from 'storm-react-diagrams';
import ItemPortModel from './ItemPortModel';

export default class ItemPortFactory extends AbstractPortFactory<ItemPortModel> {
  constructor() {
    super('item');
  }

  // eslint-disable-next-line class-methods-use-this,no-unused-vars
  getNewInstance(initialConfig?: any): ItemPortModel {
    return new ItemPortModel(true, 'unknown');
  }
}
