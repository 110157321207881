/* @flow */
import React from 'react';

import { connect } from 'react-redux';
import { DiscussionServiceHelper } from 'src/store/scenario/items';

import { LocalizedStringArray, AnswerOpened, Message } from 'src/data';
import { EventsServiceHelper, NotificationTypes } from 'src/store/events';
import errors from 'src/assets/errors';
import { withTranslation } from 'react-i18next';
import { compose } from 'redux';
import * as Globals from 'src/constants/globals';
import AddItemWidget from '../../../components/graph/AddItemWidget';

import './DiscussionGraphView.css';
import { InputBoolean, InputString, InputSelect } from '../../../../components/inputs';
import { generateId } from '../../../../../data/AtlObject';
import { ItemNodeTypes } from '../../../components/graph';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export type AnswerOpenedInputProps = {
  discussionId: string,
  messageId?: string,
  nodeId: string,
  answer: AnswerOpened,
  idPrefix: string,
  nextCustoms: string[],
  locale: string,
  updateAnswerOpened: DiscussionServiceHelper.updateAnswerType,
  parentMessage?: Message,
  scenarioId: string,
  addNotif: EventsServiceHelper.addNotifType,
  startEditing: () => any,
  isEditingItem: boolean,
  onAddBellow: (data: any, type: string) => any,
};

type State = {
  id?: string,
  isDefault: boolean,
  isInternationalized: boolean,
  idSuffix: string,
  isGoodAnswer: boolean,
  isValid: boolean,
  nextCustom?: string,
  hasChanges: boolean,
  contentsRecognized: string,
};

class AnswerOpenedInput extends React.PureComponent<AnswerOpenedInputProps, State> {
  static defaultProps = {
    idPrefix: '',
    canChangeId: true,
    contentTypes: [],
    nextCustoms: [],
    answer: {},
  };

  state = {
    id: undefined,
    isValid: false,
    nextCustom: undefined,
    idSuffix: '',
    isDefault: false,
    isInternationalized: false,
    contentsRecognized: '',
    isGoodAnswer: false,
    hasChanges: false,
  };

  componentDidMount() {
    this.setItemData(this.props);
  }

  componentDidUpdate(oldProps: AnswerOpenedInputProps, oldState: State) {
    if (oldProps.nodeId !== this.props.nodeId) {
      this.warnSaveIfNeeded(oldProps, oldState);
    }
    if (oldProps.messageId !== this.props.messageId || oldProps.nodeId !== this.props.nodeId) {
      this.setItemData(this.props);
    }
  }

  componentWillUnmount() {
    this.warnSaveIfNeeded();
  }

  warnSaveIfNeeded = (props?: AnswerOpenedInputProps = this.props, state?: State = this.state) => {
    if (this.state.hasChanges && this.props.scenarioId) {
      const updateData = this.getDataToSave(props, state);
      const { isValid } = state;
      if (isValid) {
        this.props.addNotif(NotificationTypes.WARN, 'W_UNSAVED_ITEM', undefined, 0, {
          title: this.props.t(['general.save', '']),
          callback: async () => {
            if (props.scenarioId) {
              this.updateWithData(updateData, false);
            } else {
              this.props.addNotif(NotificationTypes.ERROR, 'E_UNSAVED_ITEM', undefined, 0);
            }
          },
          closeOnCallback: true,
        });
      } else {
        this.props.addNotif(NotificationTypes.ERROR, 'E_UNSAVED_ITEM_INVALID', undefined, 0);
      }
    }
  };

  setItemData = (props: AnswerOpenedInputProps) => {
    const { answer, idPrefix, locale } = props;
    if (answer) {
      const {
        id, isDefault, contentsRecognized, isGoodAnswer, nextCustom,
      } = answer;
      const prefix = idPrefix;
      const idSuffix = id ? id.slice(prefix.length) : '';
      const val = contentsRecognized && contentsRecognized.valueForLocale(locale, true);
      console.log(contentsRecognized);
      const isInternationalized = !!contentsRecognized && Object.keys(contentsRecognized.values).length !== 1;
      const newState = {
        id: id || '',
        idSuffix: idSuffix || '',
        contentsRecognized: val && val.length ? JSON.stringify(val) : '',
        isDefault,
        isGoodAnswer,
        isInternationalized,
        nextCustom: nextCustom || '',
        hasChanges: false,
      };
      this.setState(newState);
      this.updateValidity(newState);
    }
  };

  onFieldFocus = () => {
    if (!this.props.isEditingItem) {
      this.props.startEditing();
    }
  };

  handleChange = (event) => {
    if (!this.props.isEditingItem) {
      this.props.startEditing();
    }
    const value = event.target.value;
    const fieldName = event.target.id;
    this.setState({ [fieldName]: value, hasChanges: true });
    const newVal = { ...this.state };
    newVal[fieldName] = value;
    this.updateValidity(newVal);
  };

  updateValidity = (newVal) => {
    const { contentsRecognized, isDefault } = newVal;
    const isValid = isDefault || (!!contentsRecognized && !!contentsRecognized.length);
    let isJsonValid = isDefault;
    try {
      if (!isDefault) {
        const json = JSON.parse(contentsRecognized);
        isJsonValid = Array.isArray(json);
      }
    } catch (error) {}
    this.setState({ isValid: isValid && isJsonValid });
  };

  getDataToSave = (props?: AnswerOpenedInputProps = this.props, state?: State = this.state) => {
    const {
      idSuffix, isDefault, contentsRecognized, isGoodAnswer, isInternationalized, nextCustom,
    } = state;
    const {
      updateAnswerOpened, answer, discussionId, messageId, locale, idPrefix,
    } = props;
    const suffixToUse = idSuffix || generateId();
    const id = idPrefix + suffixToUse;
    const newAnswer = new AnswerOpened(answer);
    const contentsRecognizedTrans = new LocalizedStringArray(
      answer.contentsRecognized.id,
      answer.contentsRecognized,
      isInternationalized,
    );

    if (!isInternationalized) {
      contentsRecognizedTrans.values = {
        en: contentsRecognizedTrans.values.en,
      };
    }

    if (contentsRecognized && (locale || !isInternationalized)) {
      const recogLanguage = isInternationalized ? locale : 'en';
      contentsRecognizedTrans.setValueForLocale(JSON.parse(contentsRecognized), recogLanguage);
      if (isInternationalized) {
        contentsRecognizedTrans.initAllLocalesIfRequired();
      }
    }
    newAnswer.id = id;
    newAnswer.isDefault = isDefault;
    newAnswer.contentsRecognized = contentsRecognizedTrans;
    newAnswer.isGoodAnswer = isGoodAnswer;
    newAnswer.nextCustom = nextCustom;
    return {
      newAnswer,
      updateAnswerOpened,
      discussionId,
      messageId,
      id,
    };
  };

  updateWithData = async (updateData, notifyUi: boolean = false) => {
    const {
      updateAnswerOpened, discussionId, messageId, id, newAnswer,
    } = updateData;
    if (updateAnswerOpened) {
      updateAnswerOpened(discussionId, messageId || id, newAnswer);
      if (notifyUi) {
        this.setState({ hasChanges: false });
      }
    }
  };

  updateAnswer = () => {
    const updateData = this.getDataToSave();
    this.updateWithData(updateData, true);
  };

  getErrors = () => {
    const { parentMessage, answer } = this.props;
    const res = [];
    if (parentMessage && answer) {
      if (!parentMessage.isAnswerOpenedAccessible(answer)) {
        res.push(errors.answer_default_order);
      }
    }
    return res;
  };

  addBellow = (dataStr: string) => {
    const data = JSON.parse(dataStr);
    this.props.onAddBellow(this.props.answer, data.type);
  };

  render() {
    const { nextCustoms, answer, t } = this.props;
    const {
      contentsRecognized,
      isDefault,
      isGoodAnswer,
      nextCustom,
      isValid,
      isInternationalized,
      idSuffix,
      hasChanges,
    } = this.state;
    const errorMessages = this.getErrors();

    const saveBtnClass = hasChanges ? 'btn-warning' : 'btn-outline-secondary';
    return (
      <div className="card bg-light screenBlock" style={{ height: '100%', overflow: 'hidden' }}>
        <div className="card-header">
          <h5 style={{ marginBottom: 0 }}>{t(['screens.discussionEdition.answerOpenedEdition.sectionTitle', ''])}</h5>
        </div>
        {errorMessages.length ? (
          <div>
            {errorMessages.map(mess => (
              <div className={`alert mb-0 ${mess.class}`} key={mess.key} role="alert">
                {mess.text}
              </div>
            ))}
          </div>
        ) : (
          undefined
        )}
        {answer && (
          <div className="pr-3 ml-3 mt-3 message-edit">
            <InputBoolean
              fieldName="isDefault"
              onFocus={this.onFieldFocus}
              value={isDefault}
              label={t(['screens.discussionEdition.answerOpenedEdition.isDefaultLabel', ''])}
              handleChange={this.handleChange}
            />
            <InputBoolean
              fieldName="isInternationalized"
              onFocus={this.onFieldFocus}
              value={isInternationalized}
              label={t(['screens.discussionEdition.answerOpenedEdition.isInternationalizedLabel', ''])}
              handleChange={this.handleChange}
            />
            <InputString
              onFocus={this.onFieldFocus}
              fieldName="contentsRecognized"
              value={contentsRecognized}
              label={t(['screens.discussionEdition.answerOpenedEdition.contentsRecognizedLabel', ''])}
              help={t(['screens.discussionEdition.answerOpenedEdition.contentsRecognizedHelp', ''])}
              handleChange={this.handleChange}
              hidden={isDefault}
              disabled={isDefault}
              multiline={true}
              helpInfos={[
                {
                  content: t(['helpStrings:scenario.discussion.input.answersOpened.contentsRecognized', ''], {
                    returnObjects: true,
                  }),
                  title: 'contentsRecognized',
                },
              ]}
            />
            <InputBoolean
              fieldName="isGoodAnswer"
              onFocus={this.onFieldFocus}
              value={isGoodAnswer}
              label={t(['screens.discussionEdition.answerOpenedEdition.isGoodPlaceholder', ''])}
              handleChange={this.handleChange}
            />
            <InputSelect
              fieldName="nextCustom"
              onFocus={this.onFieldFocus}
              value={nextCustom}
              values={nextCustoms}
              itemToId={it => it}
              itemToTitle={it => it}
              label={t(['screens.discussionEdition.answerOpenedEdition.nextCustomLabel', ''])}
              handleChange={this.handleChange}
              hidden={!!answer.nextMessageId}
            />
            <div
              className={`${saveBtnClass} save-btn interactive`}
              id="button-addon2"
              onClick={this.updateAnswer}
              disabled={!isValid}
            >
              <FontAwesomeIcon icon={['fad', 'save']} />
            </div>
            <div className="form-group">
              <label className="strong">{t(['screens.discussionEdition.answerOpenedEdition.fastNext', ''])}</label>
              <span className="fast-action">
                <AddItemWidget type={ItemNodeTypes.Message} draggable={false} onClick={this.addBellow} />
              </span>
            </div>
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { discussionId, messageId, nodeId } = ownProps;
  const discussion = discussionId && state.scenario.items[discussionId];
  let answerRedux;
  let messageRedux;
  if (discussion) {
    if (!messageId) {
      answerRedux = discussion.__detachedNodes.answersOpened.find(ans => ans.nodeId === nodeId);
    } else {
      messageRedux = messageId && discussion.messages[messageId];
      answerRedux = messageRedux && messageRedux.answersOpened.find(ans => ans.nodeId === nodeId);
    }
  }
  return {
    scenarioId: state.scenario.header.id,
    parentMessage: messageRedux,
    locale: state.preferences.editionLocale,
    nextCustoms: state.configuration.nextCustoms,
    answer: answerRedux,
  };
};

const mapDispatchToProps = {
  updateAnswerOpened: DiscussionServiceHelper.updateAnswerOpenedAsync,
  addNotif: EventsServiceHelper.addNotif,
};

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  withTranslation(['default', 'helpStrings']),
)(AnswerOpenedInput);
