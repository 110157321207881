/* eslint-disable import/prefer-default-export */
/* @flow */
/* global google */
export async function asyncForEach<T>(array: T[], callback: (item: T, index?: number, array: T[]) => Promise<void>) {
  if (array) {
    /* eslint-disable no-await-in-loop */
    /* eslint-disable callback-return */
    for (let index = 0; index < array.length; index += 1) {
      await callback(array[index], index, array);
    }
    /* eslint-enable no-await-in-loop */
    /* eslint-enable callback-return */
  }
}

export const uniq = (a) => {
  const seen = {};
  return a.filter((item) => {
    if (seen.hasOwnProperty(item)) {
      return false;
    }

    seen[item] = true;
    return true;
  });
};

export const getAltitude = (lat: number, lng: number) => new Promise((resolve, reject) => {
  // $FlowFixMe google
  const elevator = new google.maps.ElevationService();
  elevator.getElevationForLocations({ locations: [{ lat, lng }] }, (res, status) => {
    if (status === 'OK') {
      resolve([lat, lng, res[0].elevation]);
    } else {
      reject(status);
    }
  });
});

export const getPointsDistance = (
  left: { latitude: number, longitude: number },
  right: { latitude: number, longitude: number },
) => {
  if (left.latitude === right.latitude && left.longitude === right.longitude) {
    return 0;
  }
  const radlat1 = (Math.PI * left.latitude) / 180;
  const radlat2 = (Math.PI * right.latitude) / 180;
  const theta = left.longitude - right.longitude;
  const radtheta = (Math.PI * theta) / 180;
  let dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
  if (dist > 1) {
    dist = 1;
  }
  dist = Math.acos(dist);
  dist = (dist * 180) / Math.PI;
  dist = dist * 60 * 1.1515;

  return dist * 1.609344;
};

export const compareVersions = (v1: string, v2: string) => {
  if (typeof v1 !== 'string') return 0;
  if (typeof v2 !== 'string') return 0;
  const v1Parts = v1.split('.');
  const v2Parts = v2.split('.');
  const v1Numbers = [];
  const v2Numbers = [];
  const k = Math.min(v1Parts.length, v2Parts.length);
  for (let i = 0; i < k; i += 1) {
    v1Numbers[i] = parseInt(v1Parts[i], 10);
    v2Numbers[i] = parseInt(v2Parts[i], 10);
    if (v1Numbers[i] > v2Numbers[i]) return 1;
    if (v1Numbers[i] < v2Numbers[i]) return -1;
  }
  // eslint-disable-next-line no-nested-ternary
  return v1Parts.length === v2Parts.length ? 0 : v1Parts.length < v2Parts.length ? -1 : 1;
};
