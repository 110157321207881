/* @flow */
import Answer from './Answer';
import type { AppAnswer } from './Answer';
import LocalizedStringArray from '../LocalizedStringArray';
import LocalizedString from '../LocalizedString';
import MessageTriggeredItem from './MessageTriggeredItem';
import AnswerOpened from './AnswerOpened';
import type { AppOpenedAnswer } from './AnswerOpened';
import type { AppMessageTriggeredItem } from './MessageTriggeredItem';
import LocalizedFile from '../LocalizedFile';
import AtlGraphNode from '../AtlGraphNode';
import type { AppAtlObject } from '../AtlObject';

/* ***************************
  CLASS
*************************** */

export type AppMessage = AppAtlObject & {
  contentType: string,
  content: { [s: string]: string },
  answerMode: ?string,
  answers: Answer[],
  triggeredItems: MessageTriggeredItem[],
  nextMessageId: string,
};

export default class Message extends AtlGraphNode<AppMessage> {
  type: string;

  contentType: string;

  content: ?LocalizedString;

  contentImage: ?LocalizedFile;

  contentItemId: ?string;

  answerMode: ?string;

  answers: Answer[];

  answersOpened: AnswerOpened[];

  triggeredItems: MessageTriggeredItem[];

  nextMessageId: ?string;

  nextMode: ?string;

  // Contains all the data the editor does not manage yet
  meta: any;

  constructor(json: any) {
    super(json, json instanceof Message);
    const {
      id,
      contentType,
      content,
      contentImage,
      contentItemId,
      answerMode,
      answers,
      answersOpened,
      triggeredItems,
      nodeId,
      pos,
      type,
      nextMessageId,
      nextMode,
      meta,
      ...newMeta
    } = json;
    this.type = 'message';
    this.contentType = contentType;
    this.content = new LocalizedString(`${this.id}_content`, content);
    this.contentImage = new LocalizedFile(this.id, undefined, contentImage);
    this.contentItemId = contentItemId;
    this.answerMode = answerMode;
    this.nodeId = nodeId || this.id;
    this.nextMode = nextMode;
    this.nextMessageId = nextMessageId;
    if (json instanceof Message) {
      this.answers = [...answers];
      this.answersOpened = [...answersOpened];
      this.triggeredItems = [...triggeredItems];
      this.meta = { ...json.meta, ...newMeta };
    } else {
      this.answers = this.deserizalizeAnswers(answers);
      this.answersOpened = this.deserizalizeAnswersOpened(answersOpened);
      this.triggeredItems = this.deserizalizeTriggers(triggeredItems);
      this.meta = { ...json.meta, ...newMeta };
    }
  }

  deserizalizeAnswers(json: any) {
    const res = [];
    if (json) {
      json.forEach((value, index) => {
        const id = value.id || `answer_${index}`;
        res.push(new Answer({ parentMessageId: this.id, id, ...value }));
      });
    }
    return res;
  }

  deserizalizeAnswersOpened(json: any) {
    const res = [];
    if (json) {
      json.forEach((value, index) => {
        const id = value.id || `opened_answer_${index}`;
        res.push(new AnswerOpened({ parentMessageId: this.id, id, ...value }));
      });
    }
    return res;
  }

  deserizalizeTriggers(json: any) {
    const res = [];
    if (json) {
      json.forEach((value) => {
        res.push(new MessageTriggeredItem({ parentMessageId: this.id, ...value }));
      });
    }
    return res;
  }

  serializeInheritedFieldsForApp() {
    const res = {
      contentType: this.contentType,
      answerMode: this.answerMode,
      nextMessageId: this.nextMessageId,
      ...this.meta,
    };
    if (this.nextMode && !this.answers.length && !this.answersOpened.length) {
      res.nextMode = this.nextMode;
    }
    switch (this.contentType) {
      case 'Text':
        res.content = this.content && this.content.serializeForApp();
        break;
      case 'Image':
        if (this.contentImage) {
          res.contentImage = this.contentImage && this.contentImage.serializeForApp();
          res.contentImageName = this.contentImage && this.contentImage.getCompressedName();
        }
        break;
      case 'Item':
        res.contentItemId = this.contentItemId;
        break;
      default:
        break;
    }
    if (this.answers.length) {
      const resAnswers: AppAnswer[] = this.answers.map((ans: Answer) => ans.serializeForApp());
      res.answers = resAnswers;
    }
    if (this.answersOpened.length) {
      const resAnswers: AppOpenedAnswer[] = this.answersOpened.map((ans: AnswerOpened) => ans.serializeForApp());
      res.answersOpened = resAnswers;
    }
    if (this.triggeredItems.length) {
      const resTriggers: AppMessageTriggeredItem[] = this.triggeredItems.map((trig: MessageTriggeredItem) => trig.serializeForApp());
      res.triggeredItems = resTriggers;
    }
    return res;
  }

  serializeInheritedFields() {
    const res = {
      ...super.serializeInheritedFields(),
      type: this.type,
      contentType: this.contentType,
      answerMode: this.answerMode,
      nodeId: this.nodeId,
      pos: this.pos,
      nextMessageId: this.nextMessageId,
      nextMode: this.nextMode,
      ...this.meta,
    };
    switch (this.contentType) {
      case 'Text':
        res.content = this.content && this.content.serialize();
        break;
      case 'Image':
        if (this.contentImage) {
          res.contentImage = this.contentImage && this.contentImage.serialize();
        }
        break;
      case 'Item':
        res.contentItemId = this.contentItemId;
        break;
      default:
        break;
    }
    if (this.answers.length) {
      const resAnswers: any[] = this.answers.map((ans: Answer) => ans.serialize());
      res.answers = resAnswers;
    }
    if (this.answersOpened.length) {
      const resAnswersOp: any[] = this.answersOpened.map((ans: AnswerOpened) => ans.serialize());
      res.answersOpened = resAnswersOp;
    }
    if (this.triggeredItems.length) {
      const resTriggers: any[] = this.triggeredItems.map((trig: MessageTriggeredItem) => trig.serialize());
      res.triggeredItems = resTriggers;
    }
    return res;
  }

  cleanSubObjectsForFirebase(ser: any, forApp: boolean = false) {
    const res = { ...ser };
    if (this.answers.length) {
      const resAnswers: Answer[] = this.answers.map((ans: Answer) => ans.serializeForFirebase(forApp));
      res.answers = resAnswers;
    } else {
      delete res.answer;
    }
    if (this.answersOpened.length) {
      const resAnswersOp: AnswerOpened[] = this.answersOpened.map((ans: AnswerOpened) => ans.serializeForFirebase(forApp));
      res.answersOpened = resAnswersOp;
    } else {
      delete res.answersOpened;
    }
    if (this.triggeredItems.length) {
      const resTriggers: MessageTriggeredItem[] = this.triggeredItems.map((trig: MessageTriggeredItem) => trig.serializeForFirebase(forApp));
      res.triggeredItems = resTriggers;
    } else {
      delete res.triggeredItems;
    }
    return res;
  }

  getLocalizedStringsWithPath() {
    const res: { [path: string]: LocalizedString | LocalizedStringArray } = super.getLocalizedStringsWithPath();
    if (this.content && this.contentType !== 'Item') {
      res.content = this.content;
    }
    this.answers.forEach((answer, index) => {
      const ansStrings = answer.getLocalizedStringsWithPath();
      Object.keys(ansStrings).forEach((strPath) => {
        const newPath = `answers.${index}.${strPath}`;
        res[newPath] = ansStrings[strPath];
      });
    });
    this.answersOpened.forEach((answer, index) => {
      const ansStrings = answer.getLocalizedStringsWithPath();
      Object.keys(ansStrings).forEach((strPath) => {
        const newPath = `answersOpened.${index}.${strPath}`;
        res[newPath] = ansStrings[strPath];
      });
    });
    return res;
  }

  isAnswerOpenedAccessible(answerOpened: AnswerOpened) {
    let defaultCount = 0;
    let afterDefault = false;
    this.answersOpened.forEach((ans) => {
      if (ans.nodeId === answerOpened.nodeId) {
        afterDefault = defaultCount > 0;
      }
      if (ans.isDefault) {
        defaultCount += 1;
      }
    });
    return !afterDefault;
  }

  getLocalizedFilesWithPath() {
    const res = super.getLocalizedFilesWithPath();
    if (this.contentType === 'Image' && this.contentImage) {
      res.contentImage = this.contentImage;
    }
    return res;
  }
}
