/* @flow */
import * as React from 'react';
import { Switch, BrowserRouter, Route } from 'react-router-dom';

import { connect } from 'react-redux';
import { compose } from 'redux';
import * as Globals from 'src/constants/globals';
import { UserServiceHelper } from 'src/store/user';
import Loader, { MaintenanceLoader } from 'src/pages/components/loader';
import { InformationalCountdown } from 'src/pages/components';
import * as ROUTES from 'src/constants/routes';
import Firebase, { withFirebase } from 'src/services/Firebase';
import { withAuthentication } from 'src/services/Session';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { fas } from '@fortawesome/pro-solid-svg-icons';
import { fal } from '@fortawesome/pro-light-svg-icons';
import { far } from '@fortawesome/pro-regular-svg-icons';
import { fad } from '@fortawesome/pro-duotone-svg-icons';
import { hotjar } from 'react-hotjar';

import 'bootstrap/dist/js/bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'src/assets/styles/app.scss';

import logo from 'src/assets/logo ATLANTIDE.png';
import type { AtlEditorState } from 'src/store';
import type { ScenarioReducerState } from 'src/store/scenario';
import { ScenarioServiceHelper } from 'src/store/scenario';
import { changeLocale } from '../../assets/locales';
import LoginScreen from '../LoginScreen';
import SettingsScreen from '../settings/SettingsScreen';
import { NavBar } from '../components/NavBar';
import { ScenariosDashboardScreen, NewScenarioScreen } from '../scenario';
import { NPCSEditionScreen } from '../scenario/npcs';
import { DiscussionEditionScreen } from '../scenario/items/discussion';
import { ScenarioHeaderEditionScreen } from '../scenario/header';
import { ScenarioItemsEditionScreen } from '../scenario/items';
import ExportScenarioScreen from '../scenario/ExportScenarioScreen';
import ScenarioTranslationsScreen from '../scenario/translations/ScenarioTranslationsScreen';
import { AmsEditionScreen, AmsTranslationScreen, AmsExportScreen } from '../ams';
import { CitiesEditionScreen, CitiesExportScreen } from '../cities';
import { DashboardScreen } from '../components/dashboard';
import { AdminScreen } from '../admin';
import NotificationsView from '../components/notifications/NotificationsView';
import { StatisticsScreen } from '../statistics';

// FA INIT
library.add(fab, fas, fal, far, fad);
// check https://fontawesome.com/icons?d=gallery for icons !

type Props = {
  reduxState: ScenarioReducerState,
  setUser: UserServiceHelper.setUserType,
  updateOnFirebase: ScenarioServiceHelper.saveScenarioInFirebaseType,
  firebase: Firebase,
  isMaintaining: boolean,
  email?: string,
  maintenanceData?: { maintainers: string[], startTime: number },
  updateRequired: boolean,
  nextDeploymentTime: number,
  requiredEditorVersion: string,
  t: (key: string[]) => string,
};

type state = {
  isLoading: boolean,
};

class App extends React.Component<Props, state> {
  state = {
    isLoading: false,
  };

  listener: (authUser: any) => void;

  componentDidMount() {
    document.title = process.env.REACT_APP_ATL_EDITOR_NAME || 'Editor';

    changeLocale('fr');
    this.listener = this.props.firebase.auth.onAuthStateChanged((authUser) => {
      const { setUser } = this.props;
      if (setUser) {
        setUser(authUser);
      }
    });

    hotjar.initialize(1791696,6);
  }

  componentWillUnmount() {
    this.listener();
  }

  renderMaintenance = () => {};

  renderPages = () => {
    const { isMaintaining, updateRequired, nextDeploymentTime } = this.props;
    const { isLoading } = this.state;

    return (
      <div className="">
        <Switch className="pageContainer">
          <Route exact path={ROUTES.SIGN_IN} component={LoginScreen} />
          <div className="page active fill" logo={logo}>
            <NavBar logo={logo} updateOnFirebase={this.updateOnFirebase} />
            <Switch className="pageContainer">
              {/* Scenarios routes */}
              {Globals.hasEditor && (
                <Route exact path={ROUTES.SCENARIO_DASHBOARD} component={ScenariosDashboardScreen} />
              )}
              {Globals.hasEditor && <Route exact path={ROUTES.SCENARIO_NEW} component={NewScenarioScreen} />}
              {Globals.hasEditor && (
                <Route exact path={ROUTES.SCENARIO_EDITION} component={ScenarioItemsEditionScreen} />
              )}
              {Globals.hasEditor && <Route exact path={ROUTES.SCENARIO_NPCS} component={NPCSEditionScreen} />}
              {Globals.hasEditor && (
                <Route exact path={ROUTES.SCENARIO_HEADER} component={ScenarioHeaderEditionScreen} />
              )}
              {Globals.hasEditor && <Route exact path={ROUTES.SCENARIO_TRADS} component={ScenarioTranslationsScreen} />}
              {Globals.hasEditor && <Route exact path={ROUTES.SCENARIO_EXPORTS} component={ExportScenarioScreen} />}
              {Globals.hasEditor && (
                <Route exact path={ROUTES.SCENARIO_DISCUSSION_EDITION} component={DiscussionEditionScreen} />
              )}
              {/* AMS routes */}
              {Globals.hasEditor && <Route exact path={ROUTES.AMS_EDITION} component={AmsEditionScreen} />}
              {Globals.hasEditor && <Route exact path={ROUTES.AMS_TRADS} component={AmsTranslationScreen} />}
              {Globals.hasEditor && <Route exact path={ROUTES.AMS_EXPORTS} component={AmsExportScreen} />}
              {/* Cities routes */}
              {Globals.hasEditor && <Route exact path={ROUTES.CITIES_EDITION} component={CitiesEditionScreen} />}
              {Globals.hasEditor && <Route exact path={ROUTES.CITIES_EXPORTS} component={CitiesExportScreen} />}
              {/* Settings routes */}
              <Route exact path={ROUTES.SETTINGS} component={SettingsScreen} />
              {/* Admin routes */}
              <Route exact path={ROUTES.ADMIN} component={AdminScreen} />
              {/* Statistics routes */}
              <Route exact path={ROUTES.STATS} component={StatisticsScreen} />
              {/* Default route */}
              {Globals.hasEditor ? (
                <Route exact path={''} component={DashboardScreen} />
              ) : (
                <Route exact path={ROUTES.ADMIN} component={AdminScreen} />
              )}
            </Switch>
            <NotificationsView />
          </div>
        </Switch>
        {isLoading && <Loader />}
        {isMaintaining && !this.props.maintenanceData.maintainers.includes(this.props.email) && (
          <MaintenanceLoader
            maintainers={this.props.maintenanceData.maintainers}
            startTime={this.props.maintenanceData.startTime}
          />
        )}
        {updateRequired && <MaintenanceLoader requiredVersion={this.props.requiredEditorVersion} />}
        {!!nextDeploymentTime && (
          <InformationalCountdown
            endTime={nextDeploymentTime}
            messageKey={'general.updateTimerMessage'}
            titleKey={'general.updateTimerTitle'}
            tipKey={'general.updateTip'}
          />
        )}
      </div>
    );
  };

  updateOnFirebase = async () => {
    const { reduxState, updateOnFirebase, firebase } = this.props;
    this.setState({ isLoading: true });
    if (updateOnFirebase && reduxState) {
      await updateOnFirebase(reduxState.header.id, reduxState, firebase);
    }
    this.setState({ isLoading: false });
  };

  render() {
    return (
      <React.Suspense fallback={<div className="loader-container">...</div>}>
        <BrowserRouter>{this.renderPages()}</BrowserRouter>
      </React.Suspense>
    );
  }
}

const mapStateToProps = (state: AtlEditorState) => ({
  reduxState: state.scenario,
});

const mapDispatchToProps = {
  updateOnFirebase: ScenarioServiceHelper.exportScenarioInFirebase,
  setUser: UserServiceHelper.setUser,
};

export default compose(
  withAuthentication,
  withFirebase,
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
)(App);
