/* @flow */
import * as React from 'react';
import $ from 'jquery';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap';

import { withTranslation } from 'react-i18next';
import { compose } from 'redux';

export interface ConfirmModalProps {
  onMounted: ({ alert: (message: string, buttons: { text: string, onPress?: () => {} }[]) => any }) => any;
  title: string;
  t: (key: string[]) => String;
  id: string;
}

type State = {
  message: string,
  buttons: { text: string, onPress?: () => {} }[],
};

class ConfirmModal extends React.Component<ConfirmModalProps, State> {
  static defaultProps = {
    label: 'general.help',
  };

  state = {
    message: '',
    buttons: [],
  };

  componentDidMount = () => {
    if (this.props.onMounted) {
      this.props.onMounted({
        alert: (message, buttons) => this.alert(message, buttons),
      });
    }
  };

  alert = (message: string, buttons: { text: string, onPress?: () => {} }[]) => {
    this.setState({ message, buttons });

    $(`#QuestionModal${this.props.id}`).modal();
  };

  render = () => {
    const { title, id, t } = this.props;
    const { buttons, message } = this.state;
    const modalId = `QuestionModal${id}`;
    const headerId = `QuestionModalHeader${id}`;
    const contClass = 'ml-auto';
    const containerStyle = {};
    return (
      <div className={contClass} style={containerStyle}>
        <div className="modal fade" id={modalId} role="dialog" aria-labelledby={`#${headerId}`} aria-hidden="true">
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id={headerId}>
                  {t(['general.confimModalTitle', ''])}
                </h5>
                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <p>{message}</p>
                {buttons
                  && buttons.map(({ text, onPress }) => (
                    <button
                      type="button"
                      className="close ml-2"
                      data-dismiss="modal"
                      aria-label="Close"
                      key={`button_${id}_${text}`}
                      onClick={onPress}
                    >
                      <span aria-hidden="true">{text}</span>
                    </button>
                  ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };
}

export default compose(withTranslation('default'))(ConfirmModal);
