/* @flow */
import React from 'react';
import { CSVLink } from 'react-csv';
import csvtojson from 'csvtojson';

import 'bootstrap/dist/css/bootstrap.min.css';
import { withAuthorization, AuthenticatedCondition } from 'src/services/Session';
import { RoomMembersView } from 'src/pages/components';
import { Claims } from 'src/constants/roles';
import { withTranslation } from 'react-i18next';
import { compose } from 'redux';
import Loader from 'src/pages/components/loader';
import AtlObject from 'src/data/AtlObject';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FileTranslationView from './FileTranslationView';
import StringTranslationView from './StringTranslationView';

export type PartTranslationType = { [path: string]: any } | { [id: string]: { [path: string]: any } };
export type TranslationsType = { [partId: string]: PartTranslationType };
export type TranslationsScreenProps = {
  locale: string,
  roomMembers: any[],
  handleCsvLoaded: (json: any) => TranslationsType,
  applyTranslations: (translations: TranslationsType) => any,
  stringTranslationInfos: { id: string, part: string, item: AtlObject<any> }[],
  fileTranslationInfos: { id: string, part: string, item: AtlObject<any> }[],
  updateItem: (sectionId: string, itemId: string, item: AtlObject<any>) => Promise<any>,
  csvData: { header: string[], lines: string[][] },
  t: (key: string[]) => string,
  section: 'scenario' | 'ams' | 'cities',
};

type State = {
  showImages: boolean,
  showContent: boolean,
  translationCsv?: any,
  translationName?: string,
  isLoading: boolean,
  importedTranslations: TranslationsType,
};

class TranslationsScreen extends React.PureComponent<TranslationsScreenProps, State> {
  static defaultProps = {};

  reader: ?FileReader;

  state = {
    isValid: false,
    hasChanges: false,
    showImages: true,
    showContent: false,
    translationCsv: undefined,
    translationName: undefined,
    importedTranslations: {},
    isLoading: false,
  };

  handleBooleanChange = (event: any) => {
    this.setState({ [event.target.id]: !this.state[event.target.id] });
  };

  loadCsvFile = (event) => {
    const files = event.target.files;
    const first = files[0];
    if (first) {
      this.setState({ translationName: first.name });
      if (first) {
        this.reader = new FileReader();
        this.reader.onloadend = this.handleCsvLoaded;
        this.reader.readAsText(first);
      }
    }
  };

  handleCsvLoaded = async () => {
    const csv = this.reader && this.reader.result;
    const json = await csvtojson().fromString(csv);
    this.setState({
      translationCsv: json,
    });
    const res: TranslationsType = await this.props.handleCsvLoaded(json);
    this.setState({ importedTranslations: res });
  };

  resetStringTranslations = () => {
    this.setState({
      translationName: undefined,
      translationCsv: undefined,
      importedTranslations: {},
    });
  };

  applyStringTranslations = async () => {
    const { applyTranslations } = this.props;
    if (applyTranslations) {
      this.setState({ isLoading: true });
      try {
        this.props.applyTranslations(this.state.importedTranslations);
        this.resetStringTranslations();
      } catch (error) {
        console.log(error);
      }
      this.setState({ isLoading: false });
    }
  };

  hasTranslations = () => this.state.importedTranslations && Object.keys(this.state.importedTranslations).length;

  renderStringTranslations = () => {
    const {
      csvData, stringTranslationInfos, section, updateItem, t,
    } = this.props;
    const { importedTranslations } = this.state;
    return (
      <div className="tab-pane fade show active" id="strings" role="tabpanel" aria-labelledby="profile-tab">
        <div className="card bg-light screenBlock">
          <div className="card-header mb-0">
            <h5>{t(['screens.tradsScreen.importExport', ''])}</h5>
          </div>
          <div className="card-body mt-0 pt-2">
            <div className="row">
              <div className="col col-md-6">
                <h4>{t(['general.export', ''])}</h4>
                <div className="btn btn-primary mb-0 full-width" type="button">
                  <CSVLink data={csvData.lines} headers={csvData.header} style={{ color: 'white' }}>
                    {t(['general.export', ''])}
                  </CSVLink>
                </div>
              </div>
              <div className="col col-md-6">
                <h4>{t(['screens.tradsScreen.import', ''])}</h4>
                <div className="custom-file mt-0 pt-0">
                  <label className="custom-file-label" htmlFor="importCsv" aria-describedby="inputGroupFileAddon02">
                    {this.state.translationName || t(['screens.tradsScreen.import', ''])}
                  </label>
                </div>
                {!!this.hasTranslations() && (
                  <div className="btn btn-primary mb-2 full-width" type="button" onClick={this.applyStringTranslations}>
                    {t(['screens.tradsScreen.applyTranslations', '']).toUpperCase()}
                  </div>
                )}

                {!!this.hasTranslations() && (
                  <div className="btn delete mb-0 full-width" type="button" onClick={this.resetStringTranslations}>
                    {t(['screens.tradsScreen.resetTranslations', '']).toUpperCase()}
                  </div>
                )}
                <input
                  type="file"
                  className="custom-file-input mr-0 mb-O mt-0"
                  style={{ diplay: 'none', height: 0 }}
                  accept=".csv"
                  id="importCsv"
                  onChange={this.loadCsvFile}
                />
              </div>
            </div>
          </div>
        </div>
        {stringTranslationInfos.map(trad => (
          <StringTranslationView
            key={trad.id}
            part={trad.part}
            item={trad.item}
            newTranslation={importedTranslations[trad.part] && importedTranslations[trad.part][trad.id]}
            section={section}
            updateItem={updateItem}
          />
        ))}
      </div>
    );
  };

  renderFileTranslations = () => {
    const {
      fileTranslationInfos, section, updateItem, t,
    } = this.props;
    const { showImages, showContent } = this.state;
    return (
      <div className="tab-pane fade" id="files" role="tabpanel" aria-labelledby="home-tab">
        <button
          type="button"
          id="showImages"
          className="btn btn-primary btn-sm mb-1 mt-1 mr-2 hidden"
          data-toggle="button"
          aria-pressed="false"
          autoComplete="off"
          onClick={this.handleBooleanChange}
        >
          {t(['screens.tradsScreen.preview', ''])}
        </button>

        <button
          type="button"
          id="showContent"
          className="btn btn-primary btn-sm mb-1 mt-1 hidden"
          data-toggle="button"
          aria-pressed="false"
          autoComplete="off"
          onClick={this.handleBooleanChange}
        >
          {t(['screens.tradsScreen.fileContent', ''])}
        </button>

        {fileTranslationInfos.map(trad => (
          <FileTranslationView
            key={trad.id}
            part={trad.part}
            item={trad.item}
            showContent={showContent}
            showImages={showImages}
            section={section}
            updateItem={updateItem}
            sizeWarnLimit={trad.part === 'header' ? 500000 : 1000000}
            sizeErrorLimit={trad.part === 'header' ? 5000000 : 10000000}
          />
        ))}
      </div>
    );
  };

  render() {
    const { isLoading } = this.state;
    const { t } = this.props;
    return (
      <div className="pageContainer" id="translations">
        <RoomMembersView roomMembers={this.props.roomMembers} />
        <div className="container-fluid fill component-controller" style={{ overflow: 'scroll' }}>
          <div className="row mb-2">
            <div className="col">
              <ul className="nav nav-tabs" id="myTab" role="tablist">
                <li className="nav-item">
                  <a
                    className="nav-link active btn"
                    id="profile-tab"
                    data-toggle="tab"
                    href="#strings"
                    data-target="#strings"
                    role="tab"
                    aria-controls="profile"
                    aria-selected="true"
                  >
                    <FontAwesomeIcon icon={['far', 'quote-left']} />
                    {t(['screens.tradsScreen.strings', ''])}
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className="nav-link btn"
                    id="home-tab"
                    data-toggle="tab"
                    data-target="#files"
                    href={'#files'}
                    role="tab"
                    aria-controls="home"
                    aria-selected="false"
                  >
                    <FontAwesomeIcon icon={['fad', 'images']} />
                    {t(['screens.tradsScreen.files', ''])}
                  </a>
                </li>
              </ul>
            </div>
          </div>

          <div className="tab-content" id="myTabContent">
            {this.renderFileTranslations()}
            {this.renderStringTranslations()}
          </div>
        </div>
        {isLoading && <Loader />}
      </div>
    );
  }
}

export default compose(
  withAuthorization(AuthenticatedCondition, [Claims.Editor, Claims.Translator]),
  withTranslation('default'),
)(TranslationsScreen);
