/* @flow */
import AtlGraphNode from '../AtlGraphNode';

export const ENTRY_POINT_DEFAULT = 'Default';
export const ENTRY_POINT_COMPLETED = 'Completed';
export const ENTRY_POINT_PREFIX = 'InProgress%';

export default class DiscussionEntryPoint extends AtlGraphNode<any> {
  name: string;

  messageId: string;

  constructor(json: any) {
    super(json, json instanceof DiscussionEntryPoint);
    const { name, messageId, nodeId } = json;
    this.name = name;
    this.messageId = messageId;
    this.nodeId = nodeId || this.id;
  }

  // eslint-disable-next-line class-methods-use-this
  serializeForApp() {
    return undefined;
  }

  serializeInheritedFields() {
    return {
      ...super.serializeInheritedFields(),
      name: this.name,
      messageId: this.messageId,
    };
  }

  checkRelease(itemId: string): { level: string, item: string, message: string, details?: any }[] {
    const res = [];
    if (!this.messageId) {
      res.push({
        level: this.name === ENTRY_POINT_DEFAULT ? 'error' : 'warn',
        item: itemId,
        message: 'E_DISCUSSION_ENTRY_POINT_NO_MESSAGE',
        details: { entryPoint: this.name },
      });
    }
    return res;
  }
}
