/* @flow */
import React from 'react';
import { connect } from 'react-redux';

import 'bootstrap/dist/css/bootstrap.min.css';
import { withAuthorization, AuthenticatedCondition } from 'src/services/Session';
import { withUrlProperties } from 'src/pages/components/WithUrlProperties';
import { MapEditionWidget, AMSInput, RoomMembersView } from 'src/pages/components';
import { ItemTypes, BaseItem } from 'src/data';
import { Claims } from 'src/constants/roles';
import { withTranslation } from 'react-i18next';
import { compose } from 'redux';
import { withAmss } from './WithAms';
import AMSItem from '../../data/AMSItem';

export type AMSEditionScreenProps = {
  editionLocale: string,
  items: { [id: string]: BaseItem },
  roomMembers: any[],
  t: (key: string[]) => string,
};

type State = {
  currentItemId?: string,
  currentMarker?: number[],
  leftColumnClass: string,
  isLoading: boolean,
};

class AMSEditionScreen extends React.PureComponent<AMSEditionScreenProps, State> {
  static defaultProps = {};

  state = {
    currentItemId: undefined,
    currentMarker: undefined,
    leftColumnClass: 'col-8',
    isLoading: false,
  };

  componentWillMount() {}

  itemSelected = (id?: string) => {
    this.setState({
      currentItemId: id,
    });
  };

  markerChanged = (id, point) => {
    this.setState({ currentMarker: point });
  };

  onMarkerDragged = (id: string, lat: number, lng: number, alt: ?number) => {
    const pos = [lat, lng];
    if (alt !== undefined && alt !== null) {
      pos.push(alt);
    }
    this.setState({ currentItemId: id, currentMarker: pos });
  };

  markerAdded = (point) => {
    const pos = [point.lat(), point.lng()];
    this.setState({ currentMarker: pos, currentItemId: '' });
  };

  createNewAms = () => {
    this.setState({ currentMarker: [], currentItemId: '' });
  };

  // eslint-disable-next-line class-methods-use-this
  render() {
    const { items } = this.props;
    const { currentItemId, leftColumnClass, currentMarker } = this.state;
    return (
      <div className="pageContainer">
        <RoomMembersView roomMembers={this.props.roomMembers} />
        <div className="row fill mr-0">
          <div className={leftColumnClass} id="leftColumn">
            <div className="container-fluid fill background-primary" id="graphContainer">
              <MapEditionWidget
                currentItemId={currentItemId}
                itemClicked={this.itemSelected}
                markerChanged={this.markerChanged}
                onMarkerDragged={this.onMarkerDragged}
                markerAdded={this.markerAdded}
                itemsMap={items}
                markerFilter={it => it instanceof AMSItem && it.type === ItemTypes.AMS}
              />
            </div>
          </div>
          <div className="col" id="rightColumn">
            <div className="container-fluid no-margin" id="editionContainer">
              <button className="btn btn-primary mb-2" type="button" onClick={this.createNewAms}>
                {this.props.t(['general.new', ''])}
              </button>
              <AMSInput isAms itemId={currentItemId} marker={currentMarker} />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  editionLocale: state.preferences.editionLocale,
  items: state.ams,
});

const mapDispatchToProps = {};

export default withUrlProperties(
  compose(
    withAmss,
    withAuthorization(AuthenticatedCondition, [Claims.Moderator]),
    connect(
      mapStateToProps,
      mapDispatchToProps,
    ),
    withTranslation('default'),
  )(AMSEditionScreen),
  { ams: 'ams' },
);
