/* @flow */
import React from 'react';
import { connect } from 'react-redux';

import 'bootstrap/dist/css/bootstrap.min.css';
import { HeaderServiceHelper } from 'src/store/scenario/header';
import Firebase, { withFirebase, FirebaseHelper } from 'src/services/Firebase';
import { PreferencesServiceHelper } from 'src/store/preferences';
import { ItemsServiceHelper } from 'src/store/scenario/items';
import type { ItemsReducerState } from 'src/store/scenario/items';
import LocalizedFile from 'src/data/LocalizedFile';
import Loader from 'src/pages/components/loader';
import {
  POIItem, City, Scenario, ItemTypes, BaseItem,
} from 'src/data';
import { withAuthorization, AuthenticatedCondition } from 'src/services/Session';
import { Claims } from 'src/constants/roles';
import { withUrlProperties } from 'src/pages/components/WithUrlProperties';

import { withTranslation } from 'react-i18next';
import { compose } from 'redux';
import {
  InputString,
  InputBoolean,
  InputSteps,
  RoomMembersView,
  MapEditionWidget,
  InputSelect,
} from 'src/pages/components';
import InputLocalizedFile from 'src/pages/components/inputs/InputLocalizedFile';
import InputLocalizedFiles from 'src/pages/components/inputs/InputLocalizedFiles';
import { withCities } from '../../cities/WithCities';

export type ScenarioHeaderEditionScreenProps = {
  updateHeader: HeaderServiceHelper.updateHeaderType,
  updatePrefPosition: PreferencesServiceHelper.setLastCoordinateType,
  poiStart: POIItem,
  updateItem: ItemsServiceHelper.updateItemType,
  cities: City[],
  cityIds: string[],
  locale: string,
  scenario: Scenario,
  firebase: Firebase,
  items: ItemsReducerState,
  roomMembers: any[],
};

type State = {
  isValid?: boolean,
  isSchool: boolean,
  durationMin: number,
  durationMax: number,
  distanceMin: number,
  difficulty: number,
  cityKnowledge: boolean,
  hasChanges: boolean,
  isLoading?: boolean,
  filesToRemove: any[],
  isInit?: boolean,
  distanceMax: number,
  subtitle: string,
  pitch: string,
  missionText: string,
  startItemId: string,
  startPointName: string,
  startPointDescr: string,
  accessWheelchair: boolean,
  needCar: boolean,
  ageMin: number,
  cover?: LocalizedFile,
  hasLightCover: boolean,
  gameArea?: any,
  poiStartPos?: any,
  managedLocales: string,
  id: string,
  cityId: string,
  name: string,
  mapSelectedItem?: string,
};

class ScenarioHeaderEditionScreen extends React.PureComponent<ScenarioHeaderEditionScreenProps, State> {
  static defaultProps = {};

  reader = undefined;

  state = {
    isValid: false,
    isSchool: false,
    durationMin: 15,
    durationMax: 60,
    distanceMin: 0,
    difficulty: 1,
    cityKnowledge: false,
    hasChanges: false,
    filesToRemove: [],
    isLoading: false,
    isInit: false,
    distanceMax: 0,
    subtitle: '',
    pitch: '',
    missionText: '',
    startItemId: '',
    startPointName: '',
    startPointDescr: '',
    accessWheelchair: true,
    needCar: false,
    ageMin: 0,
    gameArea: [],
    managedLocales: '',
    id: '',
    cityId: '',
    name: '',
    cover: undefined,
    partners: undefined,
    hasLightCover: false,
    poiStartPos: undefined,
    mapSelectedItem: undefined,
  };

  componentDidMount() {
    this.setItemData(this.props);
  }

  componentDidUpdate(oldProps: ScenarioHeaderEditionScreenProps) {
    if (
      !this.state.isInit
      && (this.props.locale !== oldProps.locale || !oldProps.scenario || oldProps.scenario.id !== this.props.scenario.id)
    ) {
      this.setItemData(this.props);
    }
  }

  setItemData = (props: ScenarioHeaderEditionScreenProps) => {
    const { locale, scenario } = props;
    const {
      id,
      cityId,
      isSchool,
      name,
      cover,
      hasLightCover,
      partners,
      startItemId,
      difficulty,
      durationMax,
      durationMin,
      distanceMin,
      distanceMax,
      subtitle,
      pitch,
      missionText,
      startPointName,
      startPointDescr,
      accessWheelchair,
      needCar,
      ageMin,
      cityKnowledge,
      gameArea,
      managedLocales,
    } = scenario;
    const newState: State = {
      id: id || '',
      cityId: cityId || '',
      name: name && name.valueForLocale(locale),
      cover,
      hasLightCover,
      partners,
      isSchool,
      mapSelectedItem: startItemId,
      startItemId: startItemId || '',
      difficulty: difficulty || 1,
      durationMin: (durationMin && durationMin / 60) || 0,
      durationMax: (durationMax && durationMax / 60) || 0,
      distanceMin: (distanceMin && distanceMin / 1000) || 0,
      distanceMax: (distanceMax && distanceMax / 1000) || 0,
      subtitle: subtitle && subtitle.valueForLocale(locale),
      pitch: pitch && pitch.valueForLocale(locale),
      missionText: missionText && missionText.valueForLocale(locale),
      startPointName: startPointName && startPointName.valueForLocale(locale),
      startPointDescr: startPointDescr && startPointDescr.valueForLocale(locale),
      accessWheelchair,
      needCar,
      ageMin: ageMin || 0,
      cityKnowledge: cityKnowledge || false,
      gameArea,
      isInit: true,
      hasChanges: false,
      managedLocales: managedLocales ? JSON.stringify(managedLocales) : '["fr", "en"]',
      filesToRemove: [],
    };

    this.setState(newState);
    this.updateValidity(newState);
  };

  updateValidity(contentJson, loading) {
    this.setState({
      isValid: !loading && !!contentJson,
    });
  }

  handleChange = (event) => {
    const value = event.target.value;
    const fieldName = event.target.id;
    // $FlowFixMe Boolean is only used for bool fields
    this.setState({ [fieldName]: value, hasChanges: true });
    const newVal = { ...this.state };
    newVal[fieldName] = value;
    this.updateValidity(newVal);
    if (fieldName === 'cityId') {
      const { cities, updatePrefPosition } = this.props;
      const newCity = cities.find(cit => cit.id === value);
      if (newCity && updatePrefPosition) {
        updatePrefPosition(newCity.centerCoordinate.latitude, newCity.centerCoordinate.longitude, 12);
      }
    }
  };

  addFile = fieldName => () => {
    const field = [...this.state[fieldName]];
    const max = field.reduce((acc, cur) => (cur.index >= acc ? cur.index + 1 : acc), 0);
    field.push(new LocalizedFile(this.props.scenario.id, fieldName, { index: max }, true));
    this.setState({ [fieldName]: field, hasChanges: true });
  };

  removeFile = (fieldName, index) => {
    let field = [...this.state[fieldName]];
    const fileToRemove = field.find(it => it.index === index);
    field = field.filter(it => it.index !== index);
    this.setState({ [fieldName]: field, hasChanges: true });
    const itemFilesToRemove = fileToRemove.listStorageFiles();
    if (itemFilesToRemove.length) {
      let { filesToRemove } = this.state;
      if (filesToRemove) {
        filesToRemove = [...filesToRemove, ...itemFilesToRemove];
      } else {
        filesToRemove = itemFilesToRemove;
      }
      this.setState({ filesToRemove });
    }
  };

  updateHeader = async () => {
    this.setState({ isLoading: true });
    const {
      locale, scenario, updateHeader, firebase, items,
    } = this.props;
    const {
      id,
      cityId,
      isSchool,
      name,
      difficulty,
      durationMin,
      durationMax,
      distanceMin,
      distanceMax,
      subtitle,
      pitch,
      cover,
      hasLightCover,
      partners,
      missionText,
      startPointName,
      startPointDescr,
      accessWheelchair,
      needCar,
      ageMin,
      cityKnowledge,
      gameArea,
      poiStartPos,
      managedLocales,
    } = this.state;
    scenario.id = id;
    scenario.cityId = cityId;
    scenario.name.setValueForLocale(name, locale);
    if (cover) {
      scenario.cover = cover;
    }
    scenario.hasLightCover = hasLightCover;
    scenario.partners = partners;
    scenario.isSchool = isSchool;
    scenario.difficulty = parseInt(difficulty, 10);
    scenario.durationMin = parseInt(durationMin, 10) * 60;
    scenario.durationMax = parseInt(durationMax, 10) * 60;
    scenario.distanceMin = parseInt(distanceMin * 1000, 10);
    scenario.distanceMax = parseInt(distanceMax * 1000, 10);
    scenario.subtitle.setValueForLocale(subtitle, locale);
    scenario.pitch.setValueForLocale(pitch, locale);
    scenario.missionText.setValueForLocale(missionText, locale);
    if (!isSchool) {
      scenario.startPointName.setValueForLocale(startPointName, locale);
      scenario.startPointDescr.setValueForLocale(startPointDescr, locale);
    } else {
      scenario.startPointName.flush();
      scenario.startPointDescr.flush();
    }
    scenario.accessWheelchair = accessWheelchair;
    scenario.needCar = needCar;
    scenario.ageMin = ageMin;
    scenario.cityKnowledge = cityKnowledge;
    scenario.gameArea = gameArea;
    scenario.managedLocales = JSON.parse(managedLocales);

    const { filesToRemove } = this.state;

    if (filesToRemove && filesToRemove.length) {
      await FirebaseHelper.removeEditorFilesAsync(scenario.id, filesToRemove, 'scenario', firebase);
    }

    if (updateHeader) {
      try {
        await updateHeader(scenario, firebase, items);
      } catch (error) {
        console.log(error);
      }
      this.setState({ hasChanges: false });
    }

    if (poiStartPos) {
      const { poiStart, updateItem, firebase } = this.props;
      poiStart.coordinate = {
        latitude: poiStartPos[0],
        longitude: poiStartPos[1],
      };
      if (updateItem) {
        await updateItem(scenario.id, poiStart.id, poiStart, firebase);
      }
    }
    this.setState({ isLoading: false });
  };

  polygonChanged = (id, polygon) => {
    const { gameArea } = this.state;
    if (polygon && polygon.length) {
      gameArea.area = polygon;
    }
    this.setState({ gameArea, mapSelectedItem: gameArea.id });
  };

  markerChanged = () => {};

  itemSelected = (id) => {
    this.setState({ mapSelectedItem: id });
  };

  handleFileSelected = (fieldName: string, locale: string, file: File, index: ?number = undefined) => {
    const field = index === undefined ? this.state[fieldName] : [...this.state[fieldName]];
    let oldFile;
    if (index !== undefined) {
      oldFile = field.find(it => it.index === index);
    } else {
      oldFile = field;
    }
    const newFile = new LocalizedFile(this.props.scenario.id, fieldName, oldFile, true);
    const itemFilesToRemove = oldFile.listStorageFiles(locale);
    if (!newFile.hasLocale(locale)) {
      newFile.addLocale(locale);
    }
    newFile.files[locale].contentToUpload = file;
    newFile.files[locale].name = file.name;
    let newField;
    if (index !== undefined) {
      newField = field.map((it) => {
        if (it.index === index) {
          return newFile;
        }
        return it;
      });
    } else {
      newField = newFile;
    }
    this.setState({ [fieldName]: newField, hasChanges: true });
    if (itemFilesToRemove.length) {
      let { filesToRemove } = this.state;
      if (filesToRemove) {
        filesToRemove = [...filesToRemove, ...itemFilesToRemove];
      } else {
        filesToRemove = itemFilesToRemove;
      }
      this.setState({ filesToRemove });
    }
  };

  handleContentChange = (fieldName: string, value: string, index: ?number = undefined) => {
    const { locale } = this.props;
    const field = index === undefined ? this.state[fieldName] : [...this.state[fieldName]];
    let oldFile;
    if (index !== undefined) {
      oldFile = field.find(it => it.index === index);
    } else {
      oldFile = field;
    }
    const newFile = new LocalizedFile(this.props.scenario.id, fieldName, oldFile, true);
    newFile.content.values[locale] = value;
    let newField;
    if (index !== undefined) {
      newField = field.map((it) => {
        if (it.index === index) {
          return newFile;
        }
        return it;
      });
    } else {
      newField = newFile;
    }
    this.setState({ [fieldName]: newField, hasChanges: true });
  };

  addFileLocale = (fieldName: string, locale: string) => {
    const field = this.state[fieldName];
    const oldFile = field;
    const newFile = new LocalizedFile(this.props.scenario.id, fieldName, oldFile, true);
    newFile.addLocale(locale);
    const newField = newFile;
    this.setState({ [fieldName]: newField, hasChanges: true });
  };

  removeFileLocale = (fieldName: string, locale: string) => {
    const field = this.state[fieldName];
    const oldFile = field;
    const newFile = new LocalizedFile(this.props.scenario.id, fieldName, oldFile, true);
    const itemFilesToRemove = newFile.listStorageFiles(locale);
    newFile.removeLocale(locale);
    const newField = newFile;

    this.setState({ [fieldName]: newField, hasChanges: true });
    if (itemFilesToRemove.length) {
      let { filesToRemove } = this.state;
      if (filesToRemove) {
        filesToRemove = [...filesToRemove, ...itemFilesToRemove];
      } else {
        filesToRemove = itemFilesToRemove;
      }
      this.setState({ filesToRemove });
    }
  };

  render() {
    const {
      cities, locale, items, t,
    } = this.props;
    const {
      isValid,
      id,
      name,
      cover,
      hasLightCover,
      subtitle,
      pitch,
      missionText,

      isSchool,
      cityId,
      startItemId,
      gameArea,
      startPointName,
      startPointDescr,
      mapSelectedItem,

      managedLocales,
      durationMin,
      durationMax,
      distanceMin,
      distanceMax,
      difficulty,
      cityKnowledge,
      ageMin,
      accessWheelchair,
      needCar,
      partners,

      hasChanges,
      isLoading,
    } = this.state;

    const saveBtnClass = hasChanges ? 'btn-warning' : 'btn-primary float-right';
    return (
      <div className="pageContainer">
        <RoomMembersView roomMembers={this.props.roomMembers} />
        <div className="container-fluid fill component-controller" style={{ overflow: 'scroll' }}>
          <div className="card bg-light screenBlock mb-3">
            <div className="card-header">
              <h3>{t(['screens.headerEdition.identification.sectionTitle', ''])}</h3>
            </div>
            <div className="card-body">
              <InputString
                fieldName="id"
                value={id}
                label={t(['screens.headerEdition.identification.idLabel', ''])}
                help={t(['screens.headerEdition.identification.idHelp', ''])}
                handleChange={this.handleChange}
                disabled={true}
              />
              <InputString
                fieldName="name"
                value={name}
                label={t(['screens.headerEdition.identification.nameLabel', ''])}
                help={t(['screens.headerEdition.identification.nameHelp', ''])}
                handleChange={this.handleChange}
              />
              <InputLocalizedFile
                fieldName="cover"
                value={cover}
                label={t(['screens.headerEdition.identification.coverLabel', ''])}
                handleChange={this.handleChange}
                handleFileSelected={this.handleFileSelected}
                handleContentChange={this.handleContentChange}
                addFileLocale={this.addFileLocale}
                removeFileLocale={this.removeFileLocale}
                contentLocale={locale}
                sizeWarnLimit={500000}
                sizeErrorLimit={5000000}
              />
              <span className="margin-top-reducer" />
              <InputBoolean
                fieldName="hasLightCover"
                value={hasLightCover}
                label={t(['screens.headerEdition.identification.hasLightCoverLabel', ''])}
                help={t(['screens.headerEdition.identification.hasLightCoverHelp', ''])}
                handleChange={this.handleChange}
              />
              <InputString
                fieldName="subtitle"
                value={subtitle}
                label={t(['screens.headerEdition.identification.subtitleLabel', ''])}
                help={t(['screens.headerEdition.identification.subtitleHelp', ''])}
                handleChange={this.handleChange}
              />
              <InputString
                fieldName="pitch"
                value={pitch}
                label={t(['screens.headerEdition.identification.pitchLabel', ''])}
                help={t(['screens.headerEdition.identification.pitchHelp', ''])}
                multiline={true}
                handleChange={this.handleChange}
              />
              <InputString
                fieldName="missionText"
                value={missionText}
                label={t(['screens.headerEdition.identification.missionTextLabel', ''])}
                help={t(['screens.headerEdition.identification.missionTextHelp', ''])}
                multiline={true}
                handleChange={this.handleChange}
              />
              <button
                className={`btn btn-primary mb-3 ${saveBtnClass} float-right`}
                type="button"
                id="updateIdentity"
                onClick={this.updateHeader}
                disabled={!isValid}
              >
                {t(['general.save', ''])}
              </button>
            </div>
          </div>

          <div className="card bg-light screenBlock mb-3">
            <div className="card-header">
              <h3>{t(['screens.headerEdition.geography.sectionTitle', ''])}</h3>
            </div>
            <div className="card-body">
              <InputBoolean
                fieldName="isSchool"
                value={isSchool}
                label={t(['screens.headerEdition.geography.isSchoolLabel', ''])}
                help={t(['screens.headerEdition.tags.isSchoolHelp', ''])}
                handleChange={this.handleChange}
              />
              {!isSchool && (
                <div>
                  <InputSelect
                    fieldName="cityId"
                    value={cityId}
                    values={cities}
                    itemToId={it => it.id}
                    itemToTitle={it => `${it.name.valueForLocale(locale)} (${it.id})`}
                    label={t(['screens.headerEdition.geography.cityIdLabel', ''])}
                    help={t(['screens.headerEdition.geography.cityIdHelp', ''])}
                    handleChange={this.handleChange}
                  />
                  <InputString
                    fieldName="startPointName"
                    value={startPointName}
                    label={t(['screens.headerEdition.geography.startPointNameLabel', ''])}
                    help={t(['screens.headerEdition.geography.startPointNameHelp', ''])}
                    multiline={true}
                    handleChange={this.handleChange}
                  />
                  <InputString
                    fieldName="startPointDescr"
                    value={startPointDescr}
                    label={t(['screens.headerEdition.geography.startPointDescrLabel', ''])}
                    help={t(['screens.headerEdition.geography.startPointDescrHelp', ''])}
                    multiline={true}
                    handleChange={this.handleChange}
                  />
                  <MapEditionWidget
                    itemClicked={this.itemSelected}
                    restrictedPoiEdition={[startItemId]}
                    currentItemId={mapSelectedItem}
                    restrictedPolygonEdition={gameArea && [gameArea.id]}
                    extraEditablePolygons={[gameArea]}
                    markerChanged={this.markerChanged}
                    polygonChanged={this.polygonChanged}
                    itemsMap={items}
                    markerFilter={it => it instanceof BaseItem && it.type === ItemTypes.POI}
                    polygonFilter={it => it instanceof BaseItem && it.type === ItemTypes.GameArea}
                    showPolygonCoordinates={false}
                    maxHeight={500}
                  />
                  <small id={'poiStartUpdateHelp'} className="form-text text-muted">
                    {t(['screens.headerEdition.geography.poiStartUpdateHelp', ''])}
                  </small>
                </div>
              )}
              <button
                className={`btn mb-3 ${saveBtnClass} btn-primary float-right`}
                type="button"
                id="updateGeo"
                onClick={this.updateHeader}
                disabled={!isValid}
              >
                {t(['general.save', ''])}
              </button>
            </div>
          </div>

          <div className="card bg-light screenBlock mb-3">
            <div className="card-header">
              <h3>{t(['screens.headerEdition.tags.sectionTitle', ''])}</h3>
            </div>
            <div className="card-body">
              <InputString
                fieldName="managedLocales"
                value={managedLocales}
                label={t(['screens.headerEdition.identification.managedLocalesLabel', ''])}
                handleChange={this.handleChange}
              />
              <InputSteps
                fieldName="durationMin"
                value={durationMin}
                label={t(['screens.headerEdition.tags.durationMinLabel', ''])}
                help={t(['screens.headerEdition.tags.durationMinHelp', ''])}
                min={0}
                max={360}
                step={5}
                unit={t(['screens.headerEdition.tags.minits', ''])}
                handleChange={this.handleChange}
              />
              <InputSteps
                fieldName="durationMax"
                value={durationMax}
                label={t(['screens.headerEdition.tags.durationMaxLabel', ''])}
                help={t(['screens.headerEdition.tags.durationMaxHelp', ''])}
                min={0}
                max={360}
                step={5}
                unit={t(['screens.headerEdition.tags.minits', ''])}
                handleChange={this.handleChange}
              />
              <InputSteps
                fieldName="distanceMin"
                value={distanceMin}
                label={t(['screens.headerEdition.tags.distanceMinLabel', ''])}
                help={t(['screens.headerEdition.tags.distanceMinHelp', ''])}
                min={0}
                max={15}
                step={0.5}
                unit={t(['screens.headerEdition.tags.kilometers', ''])}
                handleChange={this.handleChange}
              />
              <InputSteps
                fieldName="distanceMax"
                value={distanceMax}
                label={t(['screens.headerEdition.tags.distanceMaxLabel', ''])}
                help={t(['screens.headerEdition.tags.distanceMaxHelp', ''])}
                min={0}
                max={15}
                step={0.5}
                unit={t(['screens.headerEdition.tags.kilometers', ''])}
                handleChange={this.handleChange}
              />
              <InputSteps
                fieldName="difficulty"
                value={difficulty}
                label={t(['screens.headerEdition.tags.difficultyLabel', ''])}
                help={t(['screens.headerEdition.tags.difficultyHelp', ''])}
                min={1}
                max={5}
                step={1}
                handleChange={this.handleChange}
                values={['facile', 'moyen', 'avancé', 'difficile', 'expert']}
              />
              <InputSteps
                fieldName="ageMin"
                value={ageMin}
                label={t(['screens.headerEdition.tags.ageMinLabel', ''])}
                help={t(['screens.headerEdition.tags.ageMinHelp', ''])}
                min={0}
                max={18}
                step={1}
                handleChange={this.handleChange}
              />
              <InputBoolean
                fieldName="cityKnowledge"
                value={cityKnowledge}
                label={t(['screens.headerEdition.tags.cityKnowledgeLabel', ''])}
                help={t(['screens.headerEdition.tags.cityKnowledgeHelp', ''])}
                handleChange={this.handleChange}
              />
              <InputBoolean
                fieldName="accessWheelchair"
                value={accessWheelchair}
                label={t(['screens.headerEdition.tags.accessWheelchairLabel', ''])}
                help={t(['screens.headerEdition.tags.accessWheelchairHelp', ''])}
                handleChange={this.handleChange}
              />
              <InputBoolean
                fieldName="needCar"
                value={needCar}
                label={t(['screens.headerEdition.tags.needCarLabel', ''])}
                help={t(['screens.headerEdition.tags.needCarHelp', ''])}
                handleChange={this.handleChange}
              />

              <span className="partners-container">
                <InputLocalizedFiles
                  fieldName="partners"
                  value={partners}
                  label={t(['screens.headerEdition.identification.partnersLabel', ''])}
                  handleChange={this.handleChange}
                  handleFileSelected={this.handleFileSelected}
                  handleContentChange={this.handleContentChange}
                  addFileLocale={this.addFileLocale}
                  removeFileLocale={this.removeFileLocale}
                  contentLocale={locale}
                  addFile={this.addFile('partners')}
                  removeFile={this.removeFile}
                  sizeWarnLimit={500000}
                  sizeErrorLimit={5000000}
                />
              </span>

              <div className="fresh-air" />

              <button
                className={`btn mb-3 ${saveBtnClass} btn-primary float-right`}
                type="button"
                id="updateTags"
                onClick={this.updateHeader}
                disabled={!isValid}
              >
                {t(['general.save', ''])}
              </button>
            </div>
          </div>

          <div className="card bg-light screenBlock mb-3 hidden">
            <div className="card-header">
              <h3>{t(['screens.headerEdition.startPoint.sectionTitle', ''])}</h3>
            </div>
            <div className="card-body">
              {t(['screens.headerEdition.startPoint.startItem', ''])}: {startItemId}
            </div>
          </div>
        </div>
        {isLoading && <Loader />}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  cityIds: state.configuration.availableCities.map(city => city.id),
  cities: state.configuration.availableCities,
  scenario: state.scenario.header,
  items: state.scenario.items,
  locale: state.preferences.editionLocale,
  poiStart: state.scenario.items[state.scenario.header.startItemId],
});

const mapDispatchToProps = {
  updateHeader: HeaderServiceHelper.updateHeader,
  updatePrefPosition: PreferencesServiceHelper.setLastCoordinate,
  updateItem: ItemsServiceHelper.updateItem,
};

export default compose(
  withCities,
  withUrlProperties,
  withFirebase,
  withAuthorization(AuthenticatedCondition, [Claims.Editor]),
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  withTranslation('default'),
)(ScenarioHeaderEditionScreen);
