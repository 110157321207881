/* @flow */
import * as types from './types';

export const setUser = (user: any) => ({
  type: types.USER_SET,
  payload: { user },
});

export const setLastEmail = (email: string) => ({
  type: types.LAST_EMAIL_SET,
  payload: { email },
});
