/* @flow */
import BaseItem, { ItemTypes } from './BaseItem';
import LocalizedString from './LocalizedString';
import LocalizedFile from './LocalizedFile';

/* ***************************
  CLASS
*************************** */

export default class ToolItem extends BaseItem {
  images: LocalizedFile[];

  name: LocalizedString;

  additionalInfo: ?LocalizedString;

  origin: ?LocalizedString;

  technicalName: string;

  constructor(json: any) {
    if (json instanceof ToolItem) {
      super(json, true);
    } else {
      super(json, false);
    }
    const {
      type, meta, imageNames, images, name, additionalInfo, origin, technicalName, ...newMeta
    } = json;
    this.images = [];
    if (images) {
      this.images = images.map(img => new LocalizedFile(this.id, undefined, img));
    }
    this.name = new LocalizedString(`${this.id}_name`, name);
    this.additionalInfo = new LocalizedString(`${this.id}_additionalInfo`, additionalInfo);
    this.origin = new LocalizedString(`${this.id}_origin`, origin);
    this.technicalName = technicalName;
    this.type = ItemTypes.Tool;
    this.setMeta({ ...json.meta, ...newMeta });
  }

  serializeInheritedFieldsForApp() {
    const res = super.serializeInheritedFieldsForApp();
    res.images = this.images.map(it => it.serializeForApp());
    res.name = this.name && this.name.serializeForApp();
    res.additionalInfo = this.additionalInfo && this.additionalInfo.serializeForApp();
    res.origin = this.origin && this.origin.serializeForApp();
    res.technicalName = this.technicalName;
    return res;
  }

  serializeInheritedFields() {
    const res = super.serializeInheritedFields();
    res.images = this.images.map(it => it.serialize());
    res.name = this.name && this.name.serializeForApp();
    res.additionalInfo = this.additionalInfo && this.additionalInfo.serializeForApp();
    res.origin = this.origin && this.origin.serializeForApp();
    res.technicalName = this.technicalName;
    return res;
  }

  getLocalizedStringsWithPath() {
    const res = super.getLocalizedStringsWithPath();
    res.name = this.name;
    if (this.additionalInfo) {
      res.additionalInfo = this.additionalInfo;
    }
    if (this.origin) {
      res.origin = this.origin;
    }
    return res;
  }

  getLocalizedFilesWithPath() {
    const res = super.getLocalizedFilesWithPath();
    this.images.forEach((img) => {
      res[`images.${img.index}`] = img;
    });
    return res;
  }
}
