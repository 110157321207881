/* @flow */
import type { ReduxDispatch } from 'redux';
import { saveAs } from 'file-saver';
import * as JSZip from 'jszip';
import * as xmlConvert from 'xml-js';
import { getNewItem } from 'src/store/scenario/items/ItemsReducer';
import { EventsServiceHelper, NotificationTypes } from 'src/store/events';
import { asyncForEach } from 'src/utils';

import BaseItem, { ItemTypes } from 'src/data/BaseItem';
import {
  AMSItem,
  AnecdoteItem,
  ArchiveItem,
  CheckpointItem,
  CustomItem,
  DocumentItem,
  FailureItem,
  GameAreaItem,
  OpenableItem,
  POIItem,
  SecondaryMissionItem,
  StartItem,
  SuccessItem,
  TimeTravelItem,
  TimerItem,
  ToolItem,
  Scenario,
} from 'src/data';
import TriggeredItem from 'src/data/TriggeredItem';
import * as Globals from 'src/constants/globals';
import Firebase, { FirebaseSingleton, FirebaseHelper } from 'src/services/Firebase';
import { DiscussionServiceHelper } from '.';
import * as actions from './actions';
import type { ItemsReducerState } from './ItemsReducer';
import type { TranslationItemsType } from '../ScenarioServiceHelper';
import LocalizedFile from '../../../data/LocalizedFile';
import AtlObject from '../../../data/AtlObject';

const logHelperCall = (title, args) => {
  if (Globals.__DEV__) {
    console.log(`################# ItemServiceHelper / ${title}`, args);
  }
};

// CLEANUP
// *********************
export type cleanupType = () => ReduxDispatch => void;
export const cleanup: cleanupType = () => (dispatch) => {
  logHelperCall('cleanup');
  dispatch(actions.cleanup());
};

export type createScenarioType = () => ReduxDispatch => void;
export const createScenario: createScenarioType = () => (dispatch) => {
  logHelperCall('createScenario', {});
  dispatch(actions.createScenario());
};

export type createItemType = (itemId?: string, item: BaseItem) => ReduxDispatch => void;
export const createItem: createItemType = (itemId, item) => (dispatch) => {
  logHelperCall('createItem', { itemId, item });
  dispatch(actions.createItem(itemId, item, false));
};

export type updateItemType = (
  scenarioId: string,
  itemId?: string,
  item: BaseItem,
  firebase: Firebase,
) => ReduxDispatch => Promise<void>;
export const updateItem: updateItemType = (scenarioId, itemId, item, firebase) => async (dispatch) => {
  logHelperCall('updateItem', { itemId, item });
  const shouldUploadFile = item instanceof BaseItem && item.hasFileToUpload();
  if (shouldUploadFile) {
    const version = await FirebaseHelper.getScenarioNextVersionAsync(scenarioId, firebase);
    const localizedFiles = item.getLocalizedFiles();
    if (localizedFiles && firebase) {
      await asyncForEach(localizedFiles, async (img) => {
        if (img instanceof LocalizedFile) {
          await asyncForEach(Object.keys(img.files), async (locale) => {
            const file = img.files[locale];
            if (file.contentToUpload) {
              const ext = file.contentToUpload.name.split('.').pop();
              const url = await FirebaseHelper.pushScenarioEditorAssetAsync(
                scenarioId,
                img.getStorageFileName(locale, version, ext),
                file.contentToUpload,
                firebase,
              );
              // eslint-disable-next-line no-param-reassign
              img.ext = ext;
              file.name = img.getStorageFileName(locale, version, ext);
              file.version = version;
              file.url = url;
              file.ext = ext;
              delete file.contentToUpload;
            }
          });
        }
      });
    }
  }
  dispatch(actions.updateItem(itemId, item));
  EventsServiceHelper.addNotif(NotificationTypes.SUCCESS, 'S_ITEM_PERSISTED')(dispatch);
};

type applyTranslationsType = (
  items: ItemsReducerState,
  translations: TranslationItemsType,
) => (dispatch: ReduxDispatch) => void;
export const applyTranslations: applyTranslationsType = (items, translations) => (dispatch) => {
  Object.keys(items).forEach((itemId) => {
    const itemTranslations = translations[itemId];
    const item = items[itemId];
    if (itemTranslations && item instanceof BaseItem) {
      item.applyTranslations(itemTranslations);
      dispatch(actions.updateItem(itemId, item));
    }
  });
};

export type nodeMovedType = (itemId?: string, nodeId: string, pos: { x: number, y: number }) => ReduxDispatch => void;
export const itemNodeMoved: nodeMovedType = (itemId, nodeId, pos) => (dispatch) => {
  logHelperCall('itemNodeMoved', { itemId, nodeId, pos });
  dispatch(actions.updateItemPosition(itemId, nodeId, pos));
};

export type removeItemType = (scenarioId: string, item: BaseItem, nodeId: string) => ReduxDispatch => Promise<void>;
export const removeItem: removeItemType = (scenarioId, item, nodeId) => async (dispatch) => {
  logHelperCall('removeItem', { scenarioId, item, nodeId });
  let filesToRemove = [];
  if (item) {
    item.getLocalizedFiles().forEach((it) => {
      if (it instanceof LocalizedFile) {
        filesToRemove = [...filesToRemove, ...it.listStorageFiles()];
      }
    });
  }
  if (filesToRemove && filesToRemove.length) {
    await FirebaseHelper.removeEditorFilesAsync(scenarioId, filesToRemove, 'scenario', FirebaseSingleton);
  }
  dispatch(actions.removeItem(item.id, nodeId));
};

// TRIGGERED ITEMS
// *********************

export type updateTriggeredItemType = (
  sourceItemId: string,
  targetItemId: string,
  triggerNodeId: string,
) => ReduxDispatch => void;
export const addTriggeredItem: updateTriggeredItemType = (sourceItemId, targetItemId, triggerNodeId) => (dispatch) => {
  logHelperCall('addTriggeredItem', {
    sourceItemId,
    targetItemId,
    triggerNodeId,
  });
  dispatch(actions.addTriggeredItem(sourceItemId, targetItemId, triggerNodeId));
};

export const removeTriggeredItem: updateTriggeredItemType = (sourceItemId, targetItemId, triggerNodeId) => (dispatch) => {
  logHelperCall('removeTriggeredItem', {
    sourceItemId,
    targetItemId,
    triggerNodeId,
  });
  dispatch(actions.removeTriggeredItem(sourceItemId, targetItemId, triggerNodeId));
};

export type triggerNodeMovedType = (
  parentId?: string,
  nodeId: string,
  pos: { x: number, y: number },
) => ReduxDispatch => void;
export const triggerNodeMoved: triggerNodeMovedType = (parentId, nodeId, pos) => (dispatch) => {
  logHelperCall('triggerNodeMoved', { parentId, nodeId, pos });
  dispatch(actions.updateTriggerPosition(parentId, nodeId, pos));
};

export type updateTriggeredItemDataType = (sourceItemId: string, trigger: TriggeredItem) => ReduxDispatch => void;
export const updateTriggeredItem: updateTriggeredItemDataType = (sourceItemId, trigger) => (dispatch) => {
  logHelperCall('updateTriggeredItem', {
    sourceItemId,
    trigger,
  });
  dispatch(actions.updateTriggeredItem(sourceItemId, trigger));
};

// IMPORT
// *********************

export type getItemAssetType = (
  scenarioId: string,
  item: AtlObject<any>,
  pathToFile: string,
  locales: string[],
) => Promise<void>;
export const getItemAsset: getItemAssetType = async (scenarioId, item, pathToFile, locales) => {
  logHelperCall('getItemAsset', {
    scenarioId,
    item,
    pathToFile,
    locales,
  });
  const newItem = getNewItem(item);
  let res;
  await asyncForEach(locales, async (locale) => {
    const localizedFile: LocalizedFile = newItem.getFile(pathToFile);
    if (localizedFile.files[locale] && !localizedFile.files[locale].url) {
      const currentFile = localizedFile.getFileForLocale(locale);
      if (currentFile) {
        const url = await FirebaseHelper.getScenarioEditorAssetUrlAsync(scenarioId, currentFile.storageName);
        const newFile = new LocalizedFile(localizedFile.itemId, localizedFile.fieldName, localizedFile);
        newFile.files[locale].url = url;
        newItem.setFile(pathToFile, newFile);
        res = newItem;
      }
    }
  });
  return res;
};

export type importItemType = (data: any) => ReduxDispatch => void;
export const importItem: importItemType = data => (dispatch) => {
  logHelperCall('importItem', { data });
  let res = data;
  switch (data.type) {
    case ItemTypes.AMS:
      res = new AMSItem(data);
      break;
    case ItemTypes.Anecdote:
      res = new AnecdoteItem(data);
      break;
    case ItemTypes.Archive:
      res = new ArchiveItem(data);
      break;
    case ItemTypes.Checkpoint:
      res = new CheckpointItem(data);
      break;
    case ItemTypes.Custom:
      res = new CustomItem(data);
      break;
    case ItemTypes.Document:
      res = new DocumentItem(data);
      break;
    case ItemTypes.Failure:
      res = new FailureItem(data);
      break;
    case ItemTypes.GameArea:
      res = new GameAreaItem(data);
      break;
    case ItemTypes.Openable:
      res = new OpenableItem(data);
      break;
    case ItemTypes.POI:
      res = new POIItem(data);
      break;
    case ItemTypes.SecondaryMission:
      res = new SecondaryMissionItem(data);
      break;
    case ItemTypes.Start:
      res = new StartItem(data);
      break;
    case ItemTypes.Success:
      res = new SuccessItem(data);
      break;
    case ItemTypes.TimeTravel:
      res = new TimeTravelItem(data);
      break;
    case ItemTypes.Timer:
      res = new TimerItem(data);
      break;
    case ItemTypes.Tool:
      res = new ToolItem(data);
      break;
    default:
      res = data;
      break;
  }
  if (res instanceof BaseItem) {
    dispatch(actions.createItem(res.id, res, true));
  }
};

const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));
export type importItemsType = (data: any) => ReduxDispatch => Promise<void>;
export const importItems: importItemsType = data => async (dispatch) => {
  logHelperCall('importItems', { data });
  cleanup()(dispatch);
  if (data) {
    const { __detachedNodes, ...items } = data;
    await asyncForEach(Object.keys(items), async (itemId) => {
      const item = { id: itemId, ...data[itemId] };
      switch (item.type) {
        case ItemTypes.Discussion:
          DiscussionServiceHelper.importDiscussion(item)(dispatch);
          break;
        default:
          importItem(item)(dispatch);
          break;
      }
      await sleep(5);
    });
    if (__detachedNodes && __detachedNodes.items) {
      __detachedNodes.items.forEach((item) => {
        switch (item.type) {
          case ItemTypes.Discussion:
            DiscussionServiceHelper.importDiscussion(item)(dispatch);
            break;
          default:
            importItem(item)(dispatch);
            break;
        }
      });
    }
    dispatch(actions.refreshAllTriggeredInfo());
  }
};

export type getAllItemAssetsType = (
  scenarioId: string,
  item: AtlObject<any>,
  locales: string[],
) => ReduxDispatch => Promise<void>;
export const getAllItemAssets: getAllItemAssetsType = (scenarioId, item, locales) => async (dispatch) => {
  logHelperCall('getUrlForImage', {
    scenarioId,
    item,
    locales,
  });
  const files = item.getLocalizedFilesWithPath();
  let updatedItem = item;
  let shouldDispatch = false;
  await asyncForEach(Object.keys(files), async (assetPath) => {
    const tempItem = await getItemAsset(scenarioId, updatedItem, assetPath, locales);
    if (tempItem) {
      shouldDispatch = true;
      updatedItem = tempItem;
    }
  });
  if (shouldDispatch && updatedItem instanceof BaseItem) {
    dispatch(actions.updateItem(item.id, updatedItem));
  }
};

// EXPORT
// *********************
export type exportItemsType = (state: ItemsReducerState) => any;

export const exportTranslations: exportItemsType = (state) => {
  logHelperCall('exportTranslation');
  let res = [];
  Object.values(state).forEach((item) => {
    if (item instanceof BaseItem) {
      if (item) {
        res = res.concat(item.getLocalizedStringToTranslateWithPath());
      }
    }
  });
  return res;
};

export const exportForApp: exportItemsType = (state) => {
  logHelperCall('exportForApp', { state });
  const res = {};
  Object.keys(state).forEach((itemId) => {
    const item = state[itemId];
    if (item instanceof BaseItem && item.id) {
      switch (item.type) {
        case ItemTypes.AMS:
        case ItemTypes.Anecdote:
        case ItemTypes.Archive:
        case ItemTypes.Checkpoint:
        case ItemTypes.Custom:
        case ItemTypes.Discussion:
        case ItemTypes.Document:
        case ItemTypes.Failure:
        case ItemTypes.GameArea:
        case ItemTypes.Openable:
        case ItemTypes.POI:
        case ItemTypes.SecondaryMission:
        case ItemTypes.Start:
        case ItemTypes.Success:
        case ItemTypes.TimeTravel:
        case ItemTypes.Timer:
        case ItemTypes.Tool:
          if (item instanceof BaseItem) {
            res[item.id] = item.serializeForApp();
          }
          break;
        default:
          console.log(item);
          break;
      }
    }
  });
  return res;
};

export const exportForEditor: exportItemsType = (state) => {
  logHelperCall('exportForEditor', { state });
  const res = {};
  Object.keys(state).forEach((itemId) => {
    const item = state[itemId];
    if (item instanceof BaseItem) {
      switch (item.type) {
        case ItemTypes.AMS:
        case ItemTypes.Anecdote:
        case ItemTypes.Archive:
        case ItemTypes.Checkpoint:
        case ItemTypes.Custom:
        case ItemTypes.Discussion:
        case ItemTypes.Document:
        case ItemTypes.Failure:
        case ItemTypes.GameArea:
        case ItemTypes.Openable:
        case ItemTypes.POI:
        case ItemTypes.SecondaryMission:
        case ItemTypes.Start:
        case ItemTypes.Success:
        case ItemTypes.TimeTravel:
        case ItemTypes.Timer:
        case ItemTypes.Tool:
          if (item instanceof BaseItem) {
            res[item.id] = item.serialize();
          }
          break;
        default:
          res[itemId] = item;
          break;
      }
    } else {
      res[itemId] = item;
    }
  });
  return res;
};

const exportGpxs: (state: ItemsReducerState) => { [id: string]: any } = (state) => {
  logHelperCall('exportGpx', { state });
  const res = {};
  Object.keys(state).forEach((itemId) => {
    const item = state[itemId];
    if (item instanceof BaseItem) {
      switch (item.type) {
        case ItemTypes.POI:
          if (item instanceof POIItem) {
            res[item.id] = item.serializeGpx();
          }
          break;
        default:
          break;
      }
    }
  });
  return res;
};

export type exportGpxsType = (items: ItemsReducerState, header: Scenario) => any;
const serializeGlobalGpx: exportGpxsType = (items, header) => {
  const json = {
    _declaration: {
      _attributes: {
        version: '1.0',
      },
    },
    metadata: {
      author: 'Atlantide editor',
      copyright: 'Atlantide',
      name: header.name.valueForLocale('fr', true),
      desc: header.pitch.valueForLocale('fr', true),
    },
    gpx: {
      _attributes: {
        version: '1.1',
        creator: 'Atlantide editor',
      },
      wpt: [],
    },
  };
  Object.keys(items).forEach((itemId) => {
    const item = items[itemId];
    if (item instanceof BaseItem) {
      switch (item.type) {
        case ItemTypes.POI:
          if (item instanceof POIItem) {
            json.gpx.wpt.push(item.getWptJson());
          }
          break;
        default:
          break;
      }
    }
  });
  const options = { compact: true, ignoreComment: true, spaces: 4 };
  const gpxContent = xmlConvert.json2xml(json, options);
  return gpxContent;
};

export const insertGpxsFolderToZip: (items: ItemsReducerState, zip: JSZip, header: Scenario) => void = (
  items,
  zip,
  header,
) => {
  const gpxs = exportGpxs(items);
  const gpxFold = zip.folder('gpx');
  const id = header.id || 'unnamedScenario';
  Object.keys(gpxs).forEach((key) => {
    const val = gpxs[key];
    gpxFold.file(`${id}-${key}.gpx`, val);
  });
  gpxFold.file(`${id}-all.gpx`, serializeGlobalGpx(items, header));
};

export const exportGpxsArchive: exportGpxsType = (state, header) => {
  const zip = new JSZip();
  insertGpxsFolderToZip(state, zip, header);
  const now = new Date();
  const year = now.getFullYear().toString(10);
  const month = (now.getMonth() + 1).toLocaleString('en', { minimumIntegerDigits: 2, useGrouping: false });
  const dayOfMonth = now.getDate().toLocaleString('en', { minimumIntegerDigits: 2, useGrouping: false });
  const hour = now.getHours().toLocaleString('en', { minimumIntegerDigits: 2, useGrouping: false });
  const mins = now.getMinutes().toLocaleString('en', { minimumIntegerDigits: 2, useGrouping: false });
  const secs = now.getSeconds().toLocaleString('en', { minimumIntegerDigits: 2, useGrouping: false });
  const id = header.id || 'unnamedScenario';
  const zipName = `${id}_gpxs_${year}${month}${dayOfMonth}_${hour}${mins}${secs}.zip`;
  zip.generateAsync({ type: 'blob' }).then((content) => {
    saveAs(content, zipName);
  });
};
