/* @flow */
import type { ReduxDispatch } from 'redux';
import * as Globals from 'src/constants/globals';
import * as actions from './actions';

const logHelperCall = (title, args) => {
  if (Globals.__DEV__) {
    console.log(`################# PreferencesServiceHelper / ${title}`, args);
  }
};

export type setUrlPropertiesType = (urlProperties: { [key: string]: string }) => ReduxDispatch => void;
export const setUrlProperties: setUrlPropertiesType = urlProperties => (dispatch) => {
  logHelperCall('setUrlProperties', { urlProperties });
  dispatch(actions.setUrlProperties(urlProperties));
};

export type cleanupUrlPropertiesType = () => ReduxDispatch => void;
export const cleanupUrlProperties: cleanupUrlPropertiesType = () => (dispatch) => {
  logHelperCall('cleanupUrlProperties', {});
  dispatch(actions.setUrlProperties({}));
};

export type setLastCoordinateType = (lat: number, lon: number, zoom: number) => ReduxDispatch => void;
export const setLastCoordinate: setLastCoordinateType = (lat, lon, zoom) => (dispatch) => {
  logHelperCall('setLastCoordinate', { lat, lon, zoom });
  dispatch(actions.setLastCoordinate(lat, lon, zoom));
};

export type updateGraphOffsetType = (graphId: string, x: number, y: number) => ReduxDispatch => void;
export const updateGraphOffset: updateGraphOffsetType = (graphId, x, y) => (dispatch) => {
  logHelperCall('updateGraphOffset', { graphId, x, y });
  dispatch(actions.updateGraphOffset(graphId, x, y));
};

export type updateGraphZoomType = (graphId: string, zoom: number) => ReduxDispatch => void;
export const updateGraphZoom: updateGraphZoomType = (graphId, zoom) => (dispatch) => {
  logHelperCall('updateGraphZoom', { graphId, zoom });
  dispatch(actions.updateGraphZoom(graphId, zoom));
};

export type setEditionLocaleType = (locale: string) => ReduxDispatch => void;
export const setEditionLocale: setEditionLocaleType = locale => (dispatch) => {
  dispatch(actions.setEditionLocale(locale));
};
