/* @flow */
import * as _ from 'lodash';
import { PortModel, DiagramEngine, LinkModel } from 'storm-react-diagrams';
import ItemLinkModel from './ItemLinkModel';

export default class ItemPortModel extends PortModel {
  in: boolean;

  label: string;

  links: { [id: string]: ItemLinkModel };

  constructor(isInput: boolean, name: string, label?: string, id?: string, max?: number) {
    super(name, 'item', id);
    this.in = isInput;
    if (max) {
      this.setMaximumLinks(max);
    }
    this.label = label || name;
  }

  deSerialize(object: any, engine: DiagramEngine) {
    super.deSerialize(object, engine);
    this.in = object.in;
    this.label = object.label;
  }

  canLinkToPort(port: PortModel, isBeforAdd: boolean): boolean {
    const max = this.getMaximumLinks();
    const mylinks = Object.values(this.links);
    let length = mylinks.length;
    if (isBeforAdd) {
      length -= 1;
    }
    if (max && max < length) {
      return false;
    }
    if (!port) {
      return true;
    }
    if (port && this.in === port.in) {
      return false;
    }

    const inPort = this.in ? this : port;
    const outPort = this.in ? port : this;
    const inParent = inPort.parent;
    const outParent = outPort.parent;

    if (
      !outParent.acceptOutLinks(inParent, outPort, isBeforAdd)
      || !inParent.acceptInLinks(outParent, inPort, isBeforAdd)
    ) {
      return false;
    }

    return true;
  }

  serialize() {
    return _.merge(super.serialize(), {
      in: this.in,
      label: this.label,
    });
  }

  link(port: PortModel, deleteLocked: boolean = false): LinkModel {
    const link = this.createLinkModel(deleteLocked);
    link.setSourcePort(this);
    link.setTargetPort(port);
    const outPort = link.getOutPort();
    const outNode = outPort && outPort.parent;
    const inPort = link.getInPort();
    const inNode = inPort && inPort.parent;
    if (outNode && inNode && inNode.inLinkAdded) {
      inNode.inLinkAdded(outNode.id, outNode.getModelId());
    }
    return link;
  }

  createLinkModel(deleteLocked: boolean): LinkModel {
    let link = super.createLinkModel();
    link = link || new ItemLinkModel();
    link.deleteLocked = deleteLocked;
    return link;
  }
}
