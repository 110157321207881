/* @flow */
import * as React from 'react';
import { withTranslation } from 'react-i18next';
import 'bootstrap/dist/css/bootstrap.min.css';
import './nodeStyle.css';
import $ from 'jquery';

export interface AddItemWidgetProps {
  type: string;
  label?: string;
  defaultContent?: any;
  disabled?: boolean;
  help?: string;
  draggable: boolean;
  t: (key: string[]) => string;
  onClick?: (data: any) => any;
}

export interface AddItemWidgetState {
  model: any;
}

class AddItemWidget extends React.Component<AddItemWidgetProps, AddItemWidgetState> {
  static defaultProps = {
    draggable: true,
  };

  constructor(props: AddItemWidgetProps) {
    super(props);
    const model = {
      type: this.props.type,
    };
    this.state = { model };
  }

  getId = () => {
    const res = `addItem${this.props.label || this.props.type}`;
    return res.replace(' ', '');
  };

  componentDidMount() {
    $('.trigger').tooltip({ position: 'center right', opacity: 0.7 });
  }

  showTootip = () => {
    $(`#${this.getId()}`).show(); // ('show');
  };

  hideTootip = () => {
    $(`#${this.getId()}`).tooltip('hide');
  };

  onClick = (event) => {
    event.preventDefault();
    this.props.onClick(JSON.stringify(this.props.defaultContent || this.state.model));
  };

  onDrag = (event) => {
    event.dataTransfer.setData('storm-diagram-node', JSON.stringify(this.props.defaultContent || this.state.model));
  };

  componentWillUnmount = () => {
    this.hideTootip();
  };

  render() {
    const { t } = this.props;
    const help = t(`helpStrings:scenario.itemTypes.${this.props.label || this.props.type}`, { returnObjects: true });
    return (
      <div
        className={'widget-button noselect'}
        draggable={!this.props.disabled && this.props.draggable}
        disabled={this.props.disabled}
        id={this.getId()}
        onDragStart={this.props.draggable && this.onDrag}
        data-toggle="tooltip"
        data-html={true}
        data-placement="top"
        title={
          help
            ? help.content
            : '' /* `<p><b>${this.props.label || this.props.type}</b><br/>${help ? help.content : ''}</p>` */
        }
        onMouseEnter={this.showTootip}
        onMouseLeave={this.hideTootip}
        onMouseOut={this.props.disabled && this.hideTootip}
        onClick={!this.props.draggable && this.onClick}
      >
        <span className={`${this.props.type}_graph_title icon`} />
        <label>{this.props.label || this.props.type}</label>
      </div>
    );
  }
}

export default withTranslation('helpStrings')(AddItemWidget);
