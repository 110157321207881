/* @flow */
import * as React from 'react';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap';
import type { ItemProgressionType } from 'src/data/BaseItem';
import type { screenPlayItemType } from 'src/store/configuration/ConfigurationReducer';

import HelpButton from '../HelpButton';
import InputNumber from './InputNumber';

export interface InputProgressProps {
  fieldName: string;
  value: ItemProgressionType;
  availableStates: screenPlayItemType;
  label: string;
  disabled?: boolean;
  hidden?: boolean;
  helpInfos: any;
  handleChange: any => any;
  separatorBefore?: boolean;
  onFocus?: () => any;
}

type state = {
  collapseButtonText: string,
  detailVisible: boolean,
};

export default class InputProgress extends React.Component<InputProgressProps, state> {
  static defaultProps = {
    disabled: false,
    availableStates: [],
    hidden: false,
    helpInfos: undefined,
    separatorBefore: false,
  };

  state = {
    collapseButtonText: '+',
    detailVisible: false,
  };

  handleNumberChange = (event: any) => {
    const { id } = event.target;
    const { value, handleChange, fieldName } = this.props;
    const newVal = { ...value };
    if (event.target.value && event.target.value.length) {
      newVal[id] = parseInt(event.target.value, 10);
    } else {
      delete newVal[id];
    }
    if (handleChange) {
      handleChange({ target: { id: fieldName, value: newVal } });
    }
  };

  onCollapseClick = () => {
    if (this.state.collapseButtonText === '+') {
      this.setState({
        collapseButtonText: '-',
        detailVisible: true,
      });
    } else {
      this.setState({
        collapseButtonText: '+',
        detailVisible: false,
      });
    }
  };

  render() {
    const {
      fieldName,
      value,
      availableStates,
      label,
      disabled,
      hidden,
      helpInfos,
      separatorBefore,
      onFocus,
    } = this.props;
    const { collapseButtonText, detailVisible } = this.state;
    return (
      <div className="form-group" key={fieldName} hidden={hidden}>
        {separatorBefore && <hr />}
        <label className="mb-0 strong" htmlFor={fieldName}>
          {`${label} `}
          {helpInfos
            && helpInfos.map((helpInfo, index) => (
              <HelpButton
                key={fieldName + index}
                helpStrings={helpInfo.content}
                id={fieldName}
                title={helpInfo.title}
                label={helpInfo.btnLabel}
                display={'inline'}
                className={'ml-auto btn btn-outline-warning ml-2'}
              />
            ))}

          <button type="button" className="btn btn-outline-info btn-sm ml-2" onClick={this.onCollapseClick}>
            {collapseButtonText}
          </button>
        </label>
        {detailVisible
          && availableStates
          && availableStates.map(val => (
            <InputNumber
              key={val.key}
              fieldName={val.key}
              disabled={disabled}
              inlineTitle
              value={(value && value[val.key]) || ''}
              label={val.title}
              onFocus={onFocus}
              handleChange={this.handleNumberChange}
            />
          ))}
        {!detailVisible && (
          <small id={`${fieldName}Help`} className="form-text text-muted">
            {JSON.stringify(value)}
          </small>
        )}
      </div>
    );
  }
}
