/* @flow */
import { Message, Answer, MessageTriggeredItem } from 'src/data/discussions';
import Discussion from 'src/data/discussions/Discussion';
import DiscussionEntryPoint from 'src/data/discussions/DiscussionEntryPoint';
import TriggeredItem from 'src/data/TriggeredItem';
import BaseItem from 'src/data/BaseItem';
import * as types from './types';

// Basic items
// ******************************

export const createScenario = () => ({
  type: types.SCENARIO_CREATE,
  payload: {},
});
export const createItem = (itemId?: string, item: BaseItem, isImport: boolean = false) => ({
  type: types.ITEMS_CREATE,
  payload: { itemId, item, isImport },
});

export const refreshAllTriggeredInfo = () => ({
  type: types.REFRESH_ALL_TRIGGERED_INFO,
  payload: {},
});

export const updateItem = (itemId?: string, item: BaseItem) => ({
  type: types.ITEMS_UPDATE,
  payload: { itemId, item },
});

export const updateItemPosition = (itemId?: string, nodeId: string, pos: { x: number, y: number }) => ({
  type: types.ITEMS_UPDATE_POSITION,
  payload: { itemId, nodeId, pos },
});

export const removeItem = (itemId?: string, nodeId: string) => ({
  type: types.ITEMS_REMOVE,
  payload: { itemId, nodeId },
});

// Triggered items
// ******************************
export const addTriggeredItem = (parentId: string, childId: string, triggerNodeId: string) => ({
  type: types.ITEMS_ADD_TRIGGERED_ITEM,
  payload: { parentId, childId, triggerNodeId },
});

export const updateTriggeredItem = (parentId: string, trigger: TriggeredItem) => ({
  type: types.ITEMS_UPDATE_TRIGGERED_ITEM,
  payload: { parentId, trigger },
});

export const removeTriggeredItem = (parentId: string, childId: string, triggerNodeId: string) => ({
  type: types.ITEMS_REMOVE_TRIGGERED_ITEM,
  payload: { parentId, childId, triggerNodeId },
});

export const updateTriggerPosition = (parentId?: string, nodeId: string, pos: { x: number, y: number }) => ({
  type: types.ITEMS_MOVE_TRIGGERED_ITEM,
  payload: { parentId, nodeId, pos },
});
// Discussions
// ******************************
export const addDiscussion = (discussionId: string, discussion: Discussion) => ({
  type: types.DISCUSSION_ADD,
  payload: { discussionId, discussion },
});

export const updateDiscussion = (discussionId: string, discussion: Discussion, isImport: boolean = false) => ({
  type: types.DISCUSSION_UPDATE,
  payload: { discussionId, discussion, isImport },
});

export const addEntryPoint = (discussionId: string, entryPoint: DiscussionEntryPoint) => ({
  type: types.DISCUSSION_ADD_ENTRY_POINT,
  payload: { discussionId, entryPoint },
});

export const updateEntryPoint = (discussionId: string, entryPoint: DiscussionEntryPoint) => ({
  type: types.DISCUSSION_UPDATE_ENTRY_POINT,
  payload: { discussionId, entryPoint },
});

export const moveEntryPoint = (discussionId: string, name: string, pos: { x: number, y: number }) => ({
  type: types.DISCUSSION_MOVE_ENTRY_POINT,
  payload: { discussionId, name, pos },
});

export const removeEntryPoint = (discussionId: string, name: string) => ({
  type: types.DISCUSSION_REMOVE_ENTRY_POINT,
  payload: { discussionId, name },
});

// Message
// ******************************
export const addMessage = (discussionId: string, messageId?: string, message: Message) => ({
  type: types.DISCUSSION_ADD_MESSAGE,
  payload: { discussionId, messageId, message },
});

export const updateMessage = (
  discussionId: string,
  messageId?: string,
  message: Message,
  undoDisabled: boolean = false,
) => ({
  type: types.DISCUSSION_UPDATE_MESSAGE,
  payload: {
    discussionId,
    messageId,
    message,
    undoDisabled,
  },
});

export const messageNodeMoved = (
  discussionId: string,
  messageId?: string,
  nodeId: string,
  pos: { x: number, y: number },
) => ({
  type: types.DISCUSSION_UPDATE_MESSAGE_POSITION,
  payload: {
    discussionId,
    messageId,
    nodeId,
    pos,
  },
});

export const removeMessage = (
  discussionId: string,
  messageId?: string,
  nodeId: string,
  undoDisabled: boolean = false,
) => ({
  type: types.DISCUSSION_REMOVE_MESSAGE,
  payload: {
    discussionId,
    messageId,
    nodeId,
    undoDisabled,
  },
});

// Answer
// ******************************
export const addAnswer = (discussionId: string, messageId?: string, answer: Answer) => ({
  type: types.DISCUSSION_ADD_ANSWER,
  payload: { discussionId, messageId, answer },
});

export const updateAnswer = (discussionId: string, messageId?: string, answer: Answer) => ({
  type: types.DISCUSSION_UPDATE_ANSWER,
  payload: { discussionId, messageId, answer },
});

export const answerNodeMoved = (
  discussionId: string,
  messageId?: string,
  nodeId: string,
  pos: { x: number, y: number },
) => ({
  type: types.DISCUSSION_UPDATE_ANSWER_POSITION,
  payload: {
    discussionId,
    messageId,
    nodeId,
    pos,
  },
});

export const removeAnswer = (discussionId: string, messageId?: string, nodeId: string) => ({
  type: types.DISCUSSION_REMOVE_ANSWER,
  payload: { discussionId, messageId, nodeId },
});

// AnswerOpened
// ******************************
export const addAnswerOpened = (discussionId: string, messageId?: string, answer: Answer) => ({
  type: types.DISCUSSION_ADD_ANSWER,
  payload: {
    discussionId,
    messageId,
    answer,
    isOpened: true,
  },
});

export const updateAnswerOpened = (discussionId: string, messageId?: string, answer: Answer) => ({
  type: types.DISCUSSION_UPDATE_ANSWER,
  payload: {
    discussionId,
    messageId,
    answer,
    isOpened: true,
  },
});

export const answerOpenedNodeMoved = (
  discussionId: string,
  messageId?: string,
  nodeId: string,
  pos: { x: number, y: number },
) => ({
  type: types.DISCUSSION_UPDATE_ANSWER_POSITION,
  payload: {
    discussionId,
    messageId,
    nodeId,
    pos,
    isOpened: true,
  },
});

export const removeAnswerOpened = (discussionId: string, messageId?: string, nodeId: string) => ({
  type: types.DISCUSSION_REMOVE_ANSWER,
  payload: {
    discussionId,
    messageId,
    nodeId,
    isOpened: true,
  },
});

// Message triggeredItem
// ******************************
export const addMessageTriggeredItem = (discussionId: string, messageId?: string, trigger: MessageTriggeredItem) => ({
  type: types.DISCUSSION_ADD_MESSAGE_TRIGGERED_ITEM,
  payload: { discussionId, messageId, trigger },
});

export const updateMessageTriggeredItem = (
  discussionId: string,
  messageId?: string,
  trigger: MessageTriggeredItem,
) => ({
  type: types.DISCUSSION_UPDATE_MESSAGE_TRIGGERED_ITEM,
  payload: { discussionId, messageId, trigger },
});

export const triggerNodeMoved = (
  discussionId: string,
  messageId?: string,
  nodeId: string,
  pos: { x: number, y: number },
) => ({
  type: types.DISCUSSION_UPDATE_MESSAGE_TRIGGER_POSITION,
  payload: {
    discussionId,
    messageId,
    nodeId,
    pos,
  },
});

export const removeMessageTriggeredItem = (discussionId: string, messageId?: string, nodeId: string) => ({
  type: types.DISCUSSION_REMOVE_MESSAGE_TRIGGERED_ITEM,
  payload: { discussionId, messageId, nodeId },
});

// Discussion Links
// ******************************
export const linkNextMessage = (
  discussionId: string,
  messageId: string,
  answerId?: string,
  nextMessageId: string,
  isOpened?: boolean = false,
) => ({
  type: types.DISCUSSION_LINK_NEXT_MESSAGE,
  payload: {
    discussionId,
    messageId,
    answerId,
    nextMessageId,
    isOpened,
  },
});
export const linkAnswer = (discussionId: string, messageId: string, answerNodeId: string) => ({
  type: types.DISCUSSION_LINK_MESSAGE_ANSWER,
  payload: { discussionId, messageId, answerNodeId },
});
export const linkAnswerOpened = (discussionId: string, messageId: string, answerNodeId: string) => ({
  type: types.DISCUSSION_LINK_MESSAGE_ANSWER,
  payload: {
    discussionId,
    messageId,
    answerNodeId,
    isOpened: true,
  },
});
export const linkMessageTriggeredItem = (discussionId: string, messageId: string, trigerNodeId: string) => ({
  type: types.DISCUSSION_LINK_MESSAGE_TRIGGERED_ITEM,
  payload: { discussionId, messageId, trigerNodeId },
});

export const unlinkNextMessage = (
  discussionId: string,
  messageId: string,
  answerNodeId?: string,
  nextMessageId: string,
  isOpened?: boolean = false,
) => ({
  type: types.DISCUSSION_UNLINK_NEXT_MESSAGE,
  payload: {
    discussionId,
    messageId,
    answerNodeId,
    nextMessageId,
    isOpened,
  },
});
export const unlinkAnswer = (discussionId: string, messageId: string, answerNodeId: string) => ({
  type: types.DISCUSSION_UNLINK_MESSAGE_ANSWER,
  payload: { discussionId, messageId, answerNodeId },
});
export const unlinkAnswerOpened = (discussionId: string, messageId: string, answerNodeId: string) => ({
  type: types.DISCUSSION_UNLINK_MESSAGE_ANSWER,
  payload: {
    discussionId,
    messageId,
    answerNodeId,
    isOpened: true,
  },
});
export const unlinkMessageTriggeredItem = (discussionId: string, messageId: string, trigerId: string) => ({
  type: types.DISCUSSION_UNLINK_MESSAGE_TRIGGERED_ITEM,
  payload: { discussionId, messageId, trigerId },
});

// Clean
// ******************************
export const cleanup = () => ({
  type: types.ITEMS_CLEAN,
  payload: {},
});
