/* @flow */
import React from 'react';
import { connect } from 'react-redux';

import 'bootstrap/dist/css/bootstrap.min.css';
import { withAuthorization, AuthenticatedCondition } from 'src/services/Session';
import { Redirect } from 'react-router-dom';
import * as ROUTES from 'src/constants/routes';

import { withTranslation } from 'react-i18next';
import { compose } from 'redux';
import { Claims } from 'src/constants/roles';
import DashboardButton from './components/DashboardButton';

export type DashboardScreenProps = {
  editionLocale: string,
  validClaims: string[],
  t: (key: string[]) => string,
};

type State = {};

class DashboardScreen extends React.PureComponent<DashboardScreenProps, State> {
  static defaultProps = {};

  state = {
    redirect: false,
  };

  componentDidMount() {
    this.setState({
      redirect: this.props.validClaims.includes(Claims.Moderator),
    });
  }

  // eslint-disable-next-line class-methods-use-this
  render() {
    const { t } = this.props;

    return (
      <div className="pageContainer" id="dashboard">
        {this.state.redirect && <Redirect to={ROUTES.SCENARIO_DASHBOARD} />}

        <div className="container-fluid fill component-controller row">
          <DashboardButton
            id="scenarios"
            img={require('../../../assets/images/scenario.png')}
            title={t(['screens.dashboard.scenarios', ''])}
            url={ROUTES.SCENARIO_DASHBOARD}
          />
          {!!this.props.validClaims.includes(Claims.Moderator) && (
            <DashboardButton
              id="ams"
              img={require('../../../assets/images/ams.png')}
              title={t(['screens.dashboard.ams', ''])}
              url={ROUTES.AMS_EDITION}
            />
          )}
          {!!this.props.validClaims.includes(Claims.Moderator) && (
            <DashboardButton
              id="cities"
              img={require('../../../assets/images/city.png')}
              title={t(['screens.dashboard.cities', ''])}
              url={ROUTES.CITIES_EDITION}
            />
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  editionLocale: state.preferences.editionLocale,
});

const mapDispatchToProps = {};

export default compose(
  withAuthorization(AuthenticatedCondition, Object.values(Claims)),
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  withTranslation('default'),
)(DashboardScreen);
