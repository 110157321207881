/* @flow */
import * as React from 'react';
import { connect } from 'react-redux';
import * as _ from 'lodash';
import { BaseWidgetProps, DiagramEngine } from 'storm-react-diagrams';
import { BaseItem } from 'src/data';
import { ItemsServiceHelper } from 'src/store/scenario/items';
import type { AtlEditorState } from 'src/store';
import ItemNodeWidget from '../ItemNodeWidget';

export interface BaseItemWidgetProps extends BaseWidgetProps {
  itemId: string;
  nodeId: string;
  outOfRedux: boolean;
  item: any;
  deleteTrigger: ItemsServiceHelper.updateTriggeredItemType;
  diagramEngine: DiagramEngine;
  itemTypeClass: string;
}

export interface ItemNodeState {
  hadNodeId?: string;
}

class TriggeredItemNodeWidget extends ItemNodeWidget<BaseItemWidgetProps, ItemNodeState> {
  state = {
    hadNodeId: undefined,
  };

  componentDidUpdate = (oldProps: TriggeredItemNodeWidget, oldState: ItemNodeState) => {
    const { triggerNodeId } = this.props;
    const { triggerNodeId: oldTrigger } = oldProps;
    if (triggerNodeId) {
      if (triggerNodeId !== oldTrigger) {
        this.setState({ hadNodeId: triggerNodeId });
      }
    } else {
      const { hadNodeId } = this.state;
      const { hadNodeId: oldHad } = oldState;
      if (hadNodeId && hadNodeId !== oldHad) {
        const { diagramEngine } = this.props;
        diagramEngine.diagramModel.removeNode(hadNodeId);
        const links = diagramEngine.getDiagramModel().links;
        Object.keys(links).forEach((linkId) => {
          const link = links[linkId];
          const source = link.sourcePort && link.sourcePort.parent;
          const target = link.targetPort && link.targetPort.parent;
          if ((source && source.nodeId === triggerNodeId) || (target && target.nodeId === triggerNodeId)) {
            diagramEngine.diagramModel.removeLink(linkId);
          }
        });
      }
    }
  };

  entityRemoved() {
    const {
      deleteTrigger, sourceItemId, targetItemId, triggerNodeId,
    } = this.props;
    if (deleteTrigger) {
      deleteTrigger(sourceItemId, targetItemId, triggerNodeId);
    }
  }

  parentChanged() {
    this.forceUpdate();
  }

  reachableChanged() {
    this.forceUpdate();
  }

  render() {
    return (
      <div
        {...this.getProps()}
        style={{
          background: this.props.color,
          borderColor: this.props.node.borderColor,
          color: '#494949',
        }}
      >
        <div className={`${this.bem('__title')} ${this.props.itemTypeClass}`}>
          <div className={this.bem('__name')}>{this.props.title}</div>
        </div>
        <div className={this.bem('__ports')}>
          <div className={this.bem('__in')}>{_.map(this.props.node.getInPorts(), this.generatePort.bind(this))}</div>
          <div className={this.bem('__out')}>{_.map(this.props.node.getOutPorts(), this.generatePort.bind(this))}</div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: AtlEditorState, ownProps: BaseItemWidgetProps) => {
  const { node } = ownProps;
  const { item, parent } = node;
  const { id } = parent;
  const parentNodeId = node.parent && node.parent.nodeId;
  let parentRedux: ?BaseItem;
  if (!id) {
    parentRedux = state.scenario.items.__detachedNodes.items.find(it => it.nodeId === parentNodeId);
    if (!parentRedux) {
      // $FlowFixMe : Type of object values result
      parentRedux = Object.values(state.scenario.items).find(it => it.nodeId === parentNodeId);
    }
    if (parentRedux && id !== parentRedux.id) {
      parent.id = parentRedux.id;
    }
  } else {
    parentRedux = state.scenario.items[id];
  }

  let itemRedux;
  if (parentRedux && parentRedux instanceof BaseItem) {
    itemRedux = parentRedux.triggeredItems.find(it => it.nodeId === item.nodeId);
  }

  if (itemRedux) {
    node.getInPorts()[0].label = itemRedux.condition || 'In';
    node.getOutPorts()[0].label = itemRedux.newTriggeredCondition || '(Added)';
  }

  return {
    nodeId: parent.nodeId,
    color: '#E8EDF3',
    sourceItemId: parentRedux && parentRedux.id,
    targetItemId: itemRedux && itemRedux.id,
    triggerNodeId: itemRedux && itemRedux.nodeId,
    item: itemRedux,
    title: 'Trigger',
  };
};

const mapDispatchToProps = {
  deleteItem: ItemsServiceHelper.removeItem,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(TriggeredItemNodeWidget);
