/* @flow */
import * as React from 'react';
import { PortWidget, BaseWidget, BaseWidgetProps } from 'storm-react-diagrams';
import ItemPortModel from './ItemPortModel';

export interface ItemPortWidgetProps extends BaseWidgetProps {
  model: ItemPortModel;
}

export interface ItemPortWidgetState {}

export default class ItemPortWidget extends BaseWidget<ItemPortWidgetProps, ItemPortWidgetState> {
  constructor(props: ItemPortWidgetProps) {
    super('srd-default-port', props);
  }

  getClassName() {
    return super.getClassName() + (this.props.model.in ? this.bem('--in') : this.bem('--out'));
  }

  render() {
    const port = <PortWidget node={this.props.model.getParent()} name={this.props.model.name} />;
    const label = <div className="name">{this.props.model.label}</div>;

    return (
      <div {...this.getProps()}>
        {this.props.model.in ? port : label}
        {this.props.model.in ? label : port}
      </div>
    );
  }
}
