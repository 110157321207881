/* @flow */
import * as React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

type Props = {
  title: string,
  url: string,
  imgClass: string,
  mode: string,
  id: string,
  icon: Array,
};

class SidebarButton extends React.Component<Props> {
  render() {
    const {
      title, url, imgClass, id, icon, mode
    } = this.props;

    const containerClass = (mode === "bar-button")?"nav-item":"dropdown-container"

    return (
      <li className={containerClass}>
        <Link to={url} id>
          <div>
            <FontAwesomeIcon icon={icon} />
            {title}
          </div>
        </Link>
      </li>
    );
  }
}

export default SidebarButton;
