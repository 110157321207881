/* @flow */
import * as React from 'react';
import { connect } from 'react-redux';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap';

import { withTranslation } from 'react-i18next';
import { compose } from 'redux';
import Firebase, { withFirebase } from 'src/services/Firebase';
import Loader from 'src/pages/components/loader';
import { Scenario, City } from 'src/data';
import { withAuthorization, AuthenticatedCondition } from 'src/services/Session';
import { Claims } from 'src/constants/roles';
import ScenarioButton from './ScenarioButton';
import { withCities } from '../../cities/WithCities';

import './scenarioList.css';

export interface Props {
  helpStrings: { title: string, content: string }[];
  title: string;
  id: string;
  className: string;
  display: string;
  label: string;
  firebase: Firebase;
  locale: string;
  selectedId?: string;
  validClaims: string[];
  userId: string;
  onSelect: string => any;
  cities: City[];
  cityIds: string[];
}

type state = {
  scenarios: Scenario[],
  matchingScenarios: Scenario[],
  loading: boolean,
  cityId?: string,
  searchString?: string,
};

class ScenarioList extends React.PureComponent<Props, state> {
  static defaultProps = {
    label: 'general.help',
  };

  state = {
    scenarios: [],
    matchingScenarios: [],
    cityId: undefined,
    searchString: '',
    loading: false,
  };

  componentDidMount() {
    this.setState({ loading: true });
    this.refreshScenarios(this.props);
  }

  componentDidUpdate = (oldProps: Props) => {
    if (oldProps.userId !== this.props.userId || this.props.validClaims.length !== oldProps.validClaims.length) {
      this.refreshScenarios(this.props);
    }
  };

  componentWillUnmount() {
    if (this.props.validClaims.includes(Claims.Moderator)) {
      this.props.firebase.scenarios().off();
    }
  }

  refreshScenarios = (props) => {
    if (props.validClaims.includes(Claims.Moderator)) {
      props.firebase.scenarios().on('value', (snapshot) => {
        const scenariosObj = snapshot.val();
        let scenarios = [];
        if (scenariosObj && Object.values(scenariosObj).length) {
          scenarios = Object.values(scenariosObj).map(scenar => new Scenario((scenar && scenar.header) || {}));
        }
        this.setState(
          {
            scenarios,
            loading: false,
          },
          this.updateScenarios,
        );
      });
    } else {
      this.refreshAuthorizedScenariosOnly(props.userId);
    }
  };

  refreshAuthorizedScenariosOnly = (userId: ?string) => {
    if (userId && userId.length) {
      this.props.firebase.listAuthorizedScenarios(userId).then((scenarios) => {
        this.setState({
          scenarios,
          loading: false,
        });
      });
    } else {
      this.setState({
        scenarios: [],
      });
    }
  };

  handleChange = (event) => {
    const value = event.target.value;
    const fieldName = event.target.id;
    // $FlowFixMe Boolean is only used for bool fields
    this.setState({ [fieldName]: value });
    this.updateScenarios(fieldName, value);
  };

  updateScenarios = (fieldName, value) => {
    let cityId = this.state.cityId;
    let searchString = this.state.searchString;
    if (fieldName) {
      if (fieldName === 'cityId') {
        cityId = value;
      }
      if (fieldName === 'searchString') {
        searchString = value;
      }
    }

    const matchingScenarios = this.state.scenarios.filter((it) => {
      if (cityId && it.cityId !== cityId) {
        return false;
      }
      if (searchString && searchString.length && !it.id.startsWith(searchString)) {
        return false;
      }
      return true;
    });
    this.setState({ matchingScenarios });
  };

  renderScenarioButton = (element) => {
    const { locale, selectedId, onSelect } = this.props;
    return (
      <ScenarioButton
        scenario={element}
        locale={locale}
        key={element.id}
        isSelected={element.id === selectedId}
        onSelect={onSelect}
      />
    );
  };

  renderSelectField = (fieldName, value, label, values, locale, forceLabel) => (
    <div className="form-group mb-3" key={fieldName} style={{paddingLeft:15,paddingRight:15}}>
      <label htmlFor={fieldName}>{forceLabel || label}</label>
      <select
        className="form-control"
        id={fieldName}
        onChange={this.handleChange}
        value={value}
        aria-describedby={`${fieldName}Help`}
        placeholder={''}
      >
        <option value={''}>{''}</option>
        {values
          && values.map(element => (
            <option key={element.id} value={element.id}>
              {element.name.valueForLocale(locale)}
            </option>
          ))}
      </select>
    </div>
  );

  render() {
    const { matchingScenarios, cityId } = this.state;
    const { cities, locale } = this.props;
    return (
      <div>
        {this.renderSelectField('cityId', cityId, 'cityId', cities, locale, '  ')}
        <div className="input-group mb-3">
          {matchingScenarios && matchingScenarios.map(element => this.renderScenarioButton(element))}
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  locale: state.preferences.editionLocale,
  cityIds: state.configuration.availableCities.map(city => city.id),
  cities: state.configuration.availableCities,
});

export default compose(
  withCities,
  withAuthorization(AuthenticatedCondition, Object.values(Claims)),
  withFirebase,
  connect(
    mapStateToProps,
    undefined,
  ),
  withTranslation('default'),
)(ScenarioList);
