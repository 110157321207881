/**
 * @flow
 * @format
 */
import React from 'react';
import styled from 'styled-components';
import { Doughnut } from 'react-chartjs-2';

type Props = {
  t: (key: string[]) => string,
  labels: string[],
  data: number[],
  title: string,
  yAxes: { id: string, stepSize: number }[],
  id: string,
  className?: String,
};

const colors = ['#011A48', '#006092', '#00AEBF', '#6cfbce', '#CFA616'];
const DoughnutGraph = (props: Props) => {
  const data = {
    labels: props.labels,
    datasets: [
      {
        data: props.data,
        backgroundColor: colors,
        hoverBackgroundColor: colors.map(it => `${it}cc`),
      },
    ],
  };

  const options = {
    title: { text: props.title },
    legend: {
      position: 'right',
    },
  };
  return (
    <Wrapper id={props.id} className={props.className}>
      <h4 style={{ fontSize: '1em', fontWeight: 200 }}>{props.title}</h4>
      <Doughnut data={data} options={options} />
    </Wrapper>
  );
};

DoughnutGraph.defaultProps = {
  className: '',
};
const Wrapper = styled.div`
  padding: 20px;
  display: inline-block;
  height: 100%;
`;

export default DoughnutGraph;
