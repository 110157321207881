/* @flow */

import * as _ from 'lodash';
import { DiagramEngine, BaseEvent } from 'storm-react-diagrams';

import { Answer } from 'src/data';
import { ItemNodeModel, ItemNodeTypes } from '../ItemNodeModel';
import ItemPortModel from '../ItemPortModel';
import type { ItemNodeModelListener } from '../ItemNodeModel';
import ItemLinkModel from '../ItemLinkModel';

const generateId = () => Math.random()
  .toString(36)
  .slice(-8);

/* eslint-disable no-use-before-define */
export interface AnswerNodeModelListener extends ItemNodeModelListener<AnswerNodeModel> {
  parentChanged?: (event: BaseEvent<AnswerNodeModel> & { nodeId: null | string, modelId: null | string }) => void;
}
/* eslint-enable no-use-before-define */

export default class AnswerNodeModel extends ItemNodeModel<AnswerNodeModelListener> {
  constructor(reachable: boolean = false, answer: any, discussionId: string) {
    super(ItemNodeTypes.Answer, answer.nodeId || generateId(), reachable);
    this.answer = answer;
    this.discussionId = discussionId;
  }

  getModelId() {
    return this.answer.id;
  }

  initPorts() {
    this.addPort(new ItemPortModel(true, `${this.id}_in`, 'In', undefined, 1));
    this.addPort(new ItemPortModel(false, `${this.id}_out`, 'Out', undefined, 1));
  }

  acceptInLinks(outNode: any) {
    if (!this.answer.nodeId) {
      console.log('NO ID', this);
      return false;
    }
    return outNode.type === ItemNodeTypes.Message;
  }

  inLinkAdded(nodeId: string, modelId: string) {
    this.messageId = modelId;
    const answer = new Answer(this.answer);
    answer.parentMessageId = modelId;
    this.answer = answer;
    this.iterateListeners((listener: AnswerNodeModelListener, event: BaseEvent) => {
      if (listener.parentChanged) {
        listener.parentChanged({ ...event, nodeId, modelId });
      }
    });
  }

  acceptOutLinks(inNode: any, port: any, isBeforeAdd: boolean) {
    // Only identified nodes can be linked
    if (!this.answer.id) {
      console.log('NO ID', this, this.answer);
      return false;
    }

    if (this.answer && this.answer.hasNextCustom()) {
      console.log('HAS NEXT CUSTOM', this);
      return false;
    }

    // Maximum 1 output per answer
    let links = Object.values(port.links);
    links = links.filter(link => link && link instanceof ItemLinkModel && link.targetPort);
    if (isBeforeAdd) {
      if (links.length >= 1) {
        console.log('TOO MANY LINKS', links);
        return false;
      }
    } else if (links.length >= 2) {
      console.log('TOO MANY LINKS', links);
      return false;
    }

    // Filter accepted types
    const inType = inNode.type;
    switch (inType) {
      case ItemNodeTypes.Message:
        return true;
      default:
        console.log('UNKNOWN ITEM', links);
        return false;
    }
  }

  updateAnswer(answer: any) {
    this.answer = answer;
  }

  deSerialize(object: any, engine: DiagramEngine) {
    super.deSerialize(object, engine);
  }

  serialize() {
    return _.merge(super.serialize(), {});
  }
}
