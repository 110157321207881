/* @flow */
import React from 'react';
import { connect } from 'react-redux';

import 'bootstrap/dist/css/bootstrap.min.css';
import { ConfigurationServiceHelper } from 'src/store/configuration';
import { withAuthorization, AuthenticatedCondition } from 'src/services/Session';
import { Claims } from 'src/constants/roles';
import { withUrlProperties } from 'src/pages/components/WithUrlProperties';

import { withTranslation } from 'react-i18next';
import { compose } from 'redux';
import Firebase, { withFirebase } from 'src/services/Firebase';
import type { City } from 'src/data';
import { Loader } from 'src/pages/components';
import { InputSelect } from 'src/pages/components/inputs';
import { withCities } from './WithCities';

export type CitiesExportScreenProps = {
  generateRelease: ConfigurationServiceHelper.generateCityReleaseType,
  firebase: Firebase,
  reduxState: City[],
  engineVersion: string,
  t: (key: string[]) => string,
  cityIds: string[],
};

type State = {
  isLoading: boolean,
  cityId?: string,
};

class CitiesExportScreen extends React.PureComponent<CitiesExportScreenProps, State> {
  static defaultProps = {};

  reader = undefined;

  state = {
    isLoading: false,
    cityId: undefined,
  };

  handleChange = (event) => {
    const value = event.target.value;
    const fieldName = event.target.id;
    this.setState({ [fieldName]: value });
    const newVal = { ...this.state };
    newVal[fieldName] = value;
  };

  generateRelease = () => {
    const {
      reduxState, generateRelease, firebase, engineVersion,
    } = this.props;
    const { cityId } = this.state;
    if (cityId) {
      this.setState({ isLoading: true });
      const cityToUpload = reduxState.find(city => city.id === cityId);
      if (generateRelease && cityToUpload) {
        generateRelease(cityToUpload, engineVersion, firebase)
          .then(() => {
            this.setState({ isLoading: false });
          })
          .catch((error) => {
            console.error(error);
            this.setState({ isLoading: false });
          });
      }
    }
  };

  render() {
    const { isLoading, cityId } = this.state;
    const { cityIds, t } = this.props;
    return (
      <div className="pageContainer">
        <div className="container-fluid fill component-controller" style={{ overflow: 'scroll' }}>
          <div className="card bg-light screenBlock mb-3">
            <div className="card-header">
              <h3>{t(['screens.export.beta.sectionTitle', ''])}</h3>
            </div>
            <div className="card-body">
              <small className="form-text text-muted  mb-3">{t(['screens.export.beta.desc', ''])}</small>
              <InputSelect
                fieldName="cityId"
                value={cityId}
                values={cityIds}
                itemToId={it => it}
                itemToTitle={it => it}
                label={t(['screens.export.beta.cityIdLabel', ''])}
                help={t(['screens.export.beta.cityIdHelp', ''])}
                handleChange={this.handleChange}
              />
              <br />
              <button
                className="btn btn-outline-secondary mb-3"
                type="button"
                onClick={this.generateRelease}
                disabled={!cityId}
              >
                {t(['general.export', ''])}
              </button>
            </div>
          </div>
        </div>
        {isLoading && <Loader />}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  reduxState: state.configuration.availableCities,
  engineVersion: state.configuration.engineVersion,
  cityIds: state.configuration.availableCities.map(city => city.id),
});

const mapDispatchToProps = {
  generateRelease: ConfigurationServiceHelper.generateCityRelease,
};

export default withUrlProperties(
  compose(
    withCities,
    withFirebase,
    withAuthorization(AuthenticatedCondition, [Claims.Moderator]),
    connect(
      mapStateToProps,
      mapDispatchToProps,
    ),
    withTranslation('default'),
  )(CitiesExportScreen),
  { cities: 'cities' },
);
