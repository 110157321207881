/* @flow */
import LocalizedString from './LocalizedString';
import LocalizedStringArray from './LocalizedStringArray';
import AtlObject from './AtlObject';
import type { AppAtlObject } from './AtlObject';

export type AppNPC = AppAtlObject & {
  name?: LocalizedString,
};

export default class NPC extends AtlObject<AppNPC> {
  name: LocalizedString;

  role: string;

  brief: ?string;

  linguisticTic: ?string;

  birthYear: ?number;

  deathYear: ?number;

  constructor(json: any) {
    super(json);
    this.name = new LocalizedString(`${this.id}_name`, json.name);
    this.role = json.role;
    this.brief = json.brief;
    this.linguisticTic = json.linguisticTic;
    this.birthYear = json.birthYear;
    this.deathYear = json.deathYear;
  }

  update(json: any) {
    if (json.name) {
      this.name = json.name;
    }
    if (json.role) {
      this.role = json.role;
    }
    if (json.brief) {
      this.brief = json.brief;
    }
    if (json.linguisticTic) {
      this.linguisticTic = json.linguisticTic;
    }
    if (json.birthYear) {
      this.birthYear = json.birthYear;
    }
    if (json.deathYear) {
      this.deathYear = json.deathYear;
    }
  }

  serializeInheritedFieldsForApp() {
    const res = {
      name: this.name ? this.name.serializeForApp() : undefined,
    };
    return res;
  }

  serializeInheritedFields() {
    const res = {
      name: this.name ? this.name.serialize() : undefined,
      role: this.role,
      brief: this.brief,
      linguisticTic: this.linguisticTic,
      birthYear: this.birthYear,
      deathYear: this.deathYear,
    };
    return res;
  }

  // eslint-disable-next-line class-methods-use-this
  getLocalizedStringsWithPath() {
    const res: { [path: string]: LocalizedString | LocalizedStringArray } = super.getLocalizedStringsWithPath();
    res.name = this.name;
    return res;
  }

  // eslint-disable-next-line class-methods-use-this
  getTranslationCsvIdPrefix() {
    return 'npcs';
  }
}
