/* @flow */

import * as React from 'react';
import * as _ from 'lodash';
import {
  DefaultPortLabel, DiagramEngine, BaseWidget, BaseWidgetProps, DefaultNodeModel,
} from 'storm-react-diagrams';
import ItemPortModel from './ItemPortModel';

export interface ItemNodeProps extends BaseWidgetProps {
  node: DefaultNodeModel;
  discussionId: string;
  messageId: string;
  answerId: string;
  triggerId: string;
  item: any;
  diagramEngine: DiagramEngine;
}

export interface ItemNodeState {}

class ItemNodeWidget<Props, State> extends BaseWidget<Props & ItemNodeProps, State & ItemNodeState> {
  isNodeMounted = true;

  constructor(props: ItemNodeProps) {
    super('srd-default-node', props);
    this.state = {};
    const node = props.node;
    node.addListener(this);
    this.state = {};
  }

  componentWillMount() {
    const node = this.props.node;
    this.isNodeMounted = false;
    node.removeListener(this);
  }

  reachableChanged() {
    if (this.isNodeMounted) {
      this.forceUpdate();
    }
  }

  // eslint-disable-next-line class-methods-use-this
  generatePort(port: ItemPortModel) {
    return <DefaultPortLabel model={port} key={port.id} />;
  }

  render() {
    return (
      <div
        {...this.getProps()}
        style={{
          background: this.props.node.color,
          borderColor: this.props.node.borderColor,
          top: this.props.node.y,
          left: this.props.node.x,
        }}
      >
        <div className={this.bem('__title')}>
          <div className={this.bem('__name')}>{this.props.node.name}</div>
        </div>
        <div className={this.bem('__ports')}>
          <div className={this.bem('__in')}>{_.map(this.props.node.getInPorts(), this.generatePort.bind(this))}</div>
          <div className={this.bem('__out')}>{_.map(this.props.node.getOutPorts(), this.generatePort.bind(this))}</div>
        </div>
      </div>
    );
  }
}

export default ItemNodeWidget;
