/* @flow */
import * as React from 'react';

import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import * as ROUTES from 'src/constants/routes';
import * as Globals from 'src/constants/globals';
import { Link } from 'react-router-dom';
import { withAuthorization, AuthenticatedCondition } from 'src/services/Session';
import { withTranslation } from 'react-i18next';
import { compose } from 'redux';
import { Claims } from 'src/constants/roles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Breadcrumb from '../NavBar/Breadcrumb';

import 'font-awesome/css/font-awesome.min.css';

import SidebarButton from './SidebarButton';
import './style/style-default.css';

type Props = {
  scenarioId?: string,
  validClaims: string[],
  ams?: string,
  cities?: string,
  logo: any,
};

class Sidebar extends React.Component<Props> {
  render() {
    const {
      scenarioId, ams, cities, t,
    } = this.props;

    return (
      <div id="left-part">
        <Link to={ROUTES.EDITOR_DASHBOARD} id="logo">
          <img src={this.props.logo} />
          <span className="version-number hidden">{this.props.version}</span>
        </Link>
        {
          // process.env.REACT_APP_ATL_EDITOR_NAME
        }
        <ul className="navbar-nav" id="home-nav">
          <li className="nav-item dropdown">
            <a
              className="nav-link dropdown-toggle icon-only"
              href="#"
              id="navbarDropdown"
              role="button"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <FontAwesomeIcon icon={['far', 'ellipsis-v']} />
            </a>
            <div className="dropdown-menu" aria-labelledby="navbarDropdown">
              <SidebarButton
                id="settings"
                imgClass="settings-icon"
                icon={['far', 'cog']}
                title={t(['sidebar.settings', ''])}
                url={ROUTES.SETTINGS}
              />
              {!!this.props.validClaims.includes(Claims.Admin) && (
                <SidebarButton
                  id="admin"
                  imgClass="admin-icon"
                  icon={['far', 'crown']}
                  title={t(['sidebar.admin', ''])}
                  url={ROUTES.ADMIN}
                />
              )}
              {!!this.props.validClaims.includes(Claims.Admin) && (
                <SidebarButton
                  id="stats"
                  imgClass="stats-icon"
                  icon={['far', 'chart-line']}
                  title={t(['sidebar.stats', ''])}
                  url={ROUTES.STATS}
                />
              )}
            </div>
          </li>

          {Globals.hasEditor && (
            <SidebarButton
              id="home"
              imgClass="home-icon"
              icon={['fad', 'home-lg']}
              title={t(['sidebar.home', ''])}
              mode="bar-button"
              url={ROUTES.EDITOR_DASHBOARD}
            />
          )}
        </ul>

        <FontAwesomeIcon icon={['far', 'horizontal-rule']} className="delimiter" />

        {Globals.hasEditor
          && (true || window.location.pathname === ROUTES.SCENARIO_DASHBOARD)
          && !scenarioId
          && !!this.props.validClaims.includes(Claims.Moderator) && (
            <ul className="navbar-nav" id="content-nav">
              <SidebarButton
                id="switch"
                imgClass="new-icon"
                icon={['fad', 'plus-circle']}
                title={t(['sidebar.newScenario', ''])}
                mode="bar-button"
                url={ROUTES.SCENARIO_NEW}
              />
            </ul>
        )}

        {scenarioId && Globals.hasEditor && (
          <ul className="navbar-nav" id="content-nav">
            <span className="sidebarSection hidden">{t(['sidebar.scenario', ''])}</span>
            {!!this.props.validClaims.includes(Claims.Editor) && (
              <SidebarButton
                id="editor"
                imgClass="editor-icon"
                title={t(['sidebar.edition', ''])}
                icon={['fad', 'pen']}
                mode="bar-button"
                url={ROUTES.SCENARIO_EDITION.replace(':scenarioId', scenarioId)}
              />
            )}
            {!!this.props.validClaims.includes(Claims.Editor) && (
              <SidebarButton
                id="scenario"
                imgClass="header-icon"
                title={t(['sidebar.headerEdition', ''])}
                icon={['fad', 'file-alt']}
                mode="bar-button"
                url={ROUTES.SCENARIO_HEADER.replace(':scenarioId', scenarioId)}
              />
            )}
            {!!this.props.validClaims.includes(Claims.Editor) && (
              <SidebarButton
                id="npcs"
                imgClass="npcs-icon"
                title={t(['sidebar.NPCSEdition', ''])}
                icon={['fad', 'user']}
                mode="bar-button"
                url={ROUTES.SCENARIO_NPCS.replace(':scenarioId', scenarioId)}
              />
            )}
            {(!!this.props.validClaims.includes(Claims.Translator)
              || !!this.props.validClaims.includes(Claims.Editor)) && (
              <SidebarButton
                id="trads"
                imgClass="trads-icon"
                title={t(['sidebar.translations', ''])}
                icon={['fad', 'language']}
                mode="bar-button"
                url={ROUTES.SCENARIO_TRADS.replace(':scenarioId', scenarioId)}
              />
            )}
            {!!(
              this.props.validClaims.includes(Claims.ConfirmedEditor)
              || this.props.validClaims.includes(Claims.Moderator)
            ) && (
              <SidebarButton
                id="export"
                imgClass="files-icon"
                title={t(['sidebar.exportModes', ''])}
                icon={['fad', 'file-export']}
                mode="bar-button"
                url={ROUTES.SCENARIO_EXPORTS.replace(':scenarioId', scenarioId)}
              />
            )}
          </ul>
        )}

        {!!ams && Globals.hasEditor && !!this.props.validClaims.includes(Claims.Moderator) && (
          <ul className="navbar-nav" id="content-nav">
            <span className="sidebarSection hidden">{t(['sidebar.ams', ''])}</span>
            <SidebarButton
              id="editor"
              imgClass="editor-icon"
              title={t(['sidebar.edition', ''])}
              icon={['fad', 'pen']}
              mode="bar-button"
              url={ROUTES.AMS_EDITION}
            />
            <SidebarButton
              id="trads"
              imgClass="trads-icon"
              title={t(['sidebar.translations', ''])}
              icon={['fad', 'language']}
              mode="bar-button"
              url={ROUTES.AMS_TRADS}
            />
            <SidebarButton
              id="export"
              imgClass="files-icon"
              title={t(['sidebar.exportModes', ''])}
              icon={['fad', 'file-export']}
              mode="bar-button"
              url={ROUTES.AMS_EXPORTS}
            />
          </ul>
        )}
        {!!cities && Globals.hasEditor && !!this.props.validClaims.includes(Claims.Moderator) && (
          <ul className="navbar-nav" id="content-nav">
            <span className="sidebarSection hidden">{t(['sidebar.cities', ''])}</span>
            <SidebarButton
              id="editor"
              imgClass="editor-icon"
              title={t(['sidebar.edition', ''])}
              icon={['fad', 'pen']}
              mode="bar-button"
              url={ROUTES.CITIES_EDITION}
            />
            <SidebarButton
              id="export"
              imgClass="files-icon"
              title={t(['sidebar.exportModes', ''])}
              icon={['fad', 'file-export']}
              mode="bar-button"
              url={ROUTES.CITIES_EXPORTS}
            />
          </ul>
        )}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  scenarioId: state.preferences.urlProperties.scenarioId,
  ams: state.preferences.urlProperties.ams,
  cities: state.preferences.urlProperties.cities,
});

export default compose(
  withAuthorization(undefined, Object.values(Claims), false),
  connect(mapStateToProps),
  withTranslation('default'),
)(withRouter(Sidebar));
