/* @flow */
import React from 'react';

import { connect } from 'react-redux';

import { EventsServiceHelper, NotificationTypes } from 'src/store/events';
import { FirebaseSingleton as firebase } from 'src/services/Firebase';
import { InputString, InputBoolean, Loader } from 'src/pages/components';
import { Scenario } from 'src/data';

import { withTranslation } from 'react-i18next';
import { compose } from 'redux';

type Props = {
  locale: string,
  notify: EventsServiceHelper.addNotifType,
};

type State = {
  userEmail?: string,
  recognizedEmail?: string,

  uid?: string,
  isAdmin: boolean,
  isModerator: boolean,
  isEditor: boolean,
  isTranslator: boolean,
  isConfirmedEditor: boolean,

  authorizedScenarios: string[],
  availableScenarios: Scenario[],

  isLoading: boolean,
  isValid: boolean,
};

class UserRightsEditionWidget extends React.PureComponent<Props, State> {
  static defaultProps = {
    scenarios: [],
  };

  state = {
    userEmail: undefined,
    recognizedEmail: undefined,

    uid: undefined,
    isAdmin: false,
    isModerator: false,
    isEditor: false,
    isTranslator: false,
    isConfirmedEditor: false,

    authorizedScenarios: [],
    availableScenarios: [],

    isLoading: false,
    isValid: false,
  };

  handleChange = (event) => {
    const value = event.target.value;
    const fieldName = event.target.id;
    // $FlowFixMe: Boolean can only be set for boolean fields
    const newState: $Shape<State> = { [fieldName]: value };
    if (fieldName === 'userEmail') {
      newState.recognizedEmail = undefined;
    }
    this.setState(newState, () => this.updateValidity(this.state));
  };

  getScenariosList = async () => {
    const snapshot = await firebase.scenarios().once('value');
    const scenariosObj = snapshot.val();
    let scenarios = [];
    if (scenariosObj && Object.values(scenariosObj).length) {
      scenarios = Object.values(scenariosObj).map(scenar => new Scenario((scenar && scenar.header) || {}));
    }
    return scenarios;
  };

  loadUser = async () => {
    const { userEmail } = this.state;
    if (userEmail) {
      try {
        this.setState({ isLoading: true });
        const user = await firebase.getUserByEmailAsync(userEmail);
        const scenarios = await this.getScenariosList();
        const userSnap = await firebase.userAuthorizedScenarios(user.uid).once('value');
        const authorizedScenarios = userSnap.exists() ? userSnap.val() : {};

        const customClaims = user.customClaims || {};

        this.setState(
          {
            recognizedEmail: userEmail,
            uid: user.uid,
            isAdmin: !!customClaims.admin,
            isModerator: !!customClaims.moderator,
            isEditor: !!customClaims.editor,
            isConfirmedEditor: !!customClaims.confirmedEditor,
            isTranslator: !!customClaims.translator,
            isLoading: false,
            authorizedScenarios: Object.keys(authorizedScenarios),
            availableScenarios: scenarios,
          },
          () => this.updateValidity(this.state),
        );
      } catch (error) {
        console.error('User does not exists', error);
        this.props.notify(NotificationTypes.ERROR, 'E_USER_NOT_FOUND', error.message);
        this.setState({ isLoading: false });
      }
    }
  };

  saveRights = async () => {
    const {
      recognizedEmail, isAdmin, isModerator, isEditor, isConfirmedEditor, isTranslator, uid,
    } = this.state;
    if (recognizedEmail && uid) {
      try {
        this.setState({ isLoading: true });
        const customClaims = {
          admin: isAdmin,
          moderator: isModerator,
          editor: isEditor,
          confirmedEditor: isConfirmedEditor,
          translator: isTranslator,
        };

        const authorizedScenarios = {};
        this.state.authorizedScenarios.forEach((it) => {
          authorizedScenarios[it] = true;
        });
        await firebase.setUserRightsAsync(recognizedEmail, uid, customClaims, authorizedScenarios);

        this.setState({ isLoading: false });
      } catch (error) {
        console.error('Cannot update rights', error);
        this.setState({ isLoading: false });
      }
    }
  };

  // eslint-disable-next-line no-unused-vars
  updateValidity = (newVal: State) => {
    const isValid: boolean = !!newVal.userEmail;
    this.setState({ isValid });
  };

  handleAuthorizedScenariosChange = (event) => {
    const options = event.target.options;
    const values = [];
    for (let i = 0, l = options.length; i < l; i += 1) {
      const option = options[i];
      if (option.selected) {
        values.push(option.value);
      }
    }
    this.setState({ authorizedScenarios: values });
  };

  renderRights = () => {
    const {
      uid,
      isAdmin,
      isModerator,
      isEditor,
      isConfirmedEditor,
      isTranslator,
      authorizedScenarios,
      availableScenarios,

      isValid,
    } = this.state;
    const { t } = this.props;

    return (
      <div className="list-group">
        <div className="card p-2 mb-2">
          <h3>{t(['screens.admin.users.userFoundTitle', ''])}</h3>
          <p>
            <strong>{t(['screens.admin.users.idPlaceholder', ''])}: </strong>
            {uid}
          </p>

          <h3>{t(['screens.admin.users.rightsPlaceholder', ''])}</h3>
          <InputBoolean
            fieldName="isAdmin"
            value={isAdmin}
            label={t(['screens.admin.users.isAdminLabel', ''])}
            help={t(['screens.admin.users.isAdminHelp', ''])}
            handleChange={this.handleChange}
          />
          <InputBoolean
            fieldName="isModerator"
            value={isModerator}
            label={t(['screens.admin.users.isModeratorLabel', ''])}
            help={t(['screens.admin.users.isModeratorHelp', ''])}
            handleChange={this.handleChange}
          />
          <InputBoolean
            fieldName="isEditor"
            value={isEditor}
            label={t(['screens.admin.users.isEditorLabel', ''])}
            help={t(['screens.admin.users.isEditorHelp', ''])}
            handleChange={this.handleChange}
          />
          <InputBoolean
            fieldName="isConfirmedEditor"
            value={isConfirmedEditor}
            label={t(['screens.admin.users.isConfirmedEditorLabel', ''])}
            help={t(['screens.admin.users.isConfirmedEditorHelp', ''])}
            handleChange={this.handleChange}
          />
          <InputBoolean
            fieldName="isTranslator"
            value={isTranslator}
            label={t(['screens.admin.users.isTranslatorLabel', ''])}
            help={t(['screens.admin.users.isTranslatorHelp', ''])}
            handleChange={this.handleChange}
          />
        </div>

        <h3>{t(['screens.admin.users.contentPlaceholder', ''])}</h3>
        {isAdmin || isModerator ? (
          <p>{t(['screens.admin.users.all', ''])}</p>
        ) : (
          <select
            className="form-control"
            id="authorizedScenarios"
            onChange={this.handleAuthorizedScenariosChange}
            value={authorizedScenarios}
            placeholder={''}
            multiple
          >
            {availableScenarios
              /* $FlowFixMe: Object.values */
              && Object.values(availableScenarios).map((element: Scenario) => (
                <option key={element.id} value={element.id}>
                  {element.id}
                </option>
              ))}
          </select>
        )}
        <button
          className="btn btn-outline-secondary mb-3"
          type="button"
          id="button-addon2"
          onClick={this.saveRights}
          disabled={!isValid}
        >
          {t(['general.save', ''])}
        </button>
      </div>
    );
  };

  render() {
    const { userEmail, recognizedEmail, isLoading } = this.state;
    const { t } = this.props;
    return (
      <div className="card bg-light screenBlock" style={{ overflow: 'scroll', height: '100%' }}>
        <div className="card-header">
          <h3>{t(['screens.admin.users.sectionTitle', ''])}</h3>
        </div>
        <div className="card-body p-2 pl-4">
          <div className="list-group pb-10">
            <InputString
              fieldName="userEmail"
              value={userEmail}
              label={t(['screens.admin.users.userEmailLabel', ''])}
              help={t(['screens.admin.users.userEmailHelp', ''])}
              multiline={true}
              handleChange={this.handleChange}
            />
            <button className="btn btn-outline-secondary mb-3" type="button" id="button-addon2" onClick={this.loadUser}>
              {t(['general.load', ''])}
            </button>
          </div>
          {recognizedEmail && this.renderRights()}
        </div>
        {isLoading && <Loader />}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  locale: state.preferences.editionLocale,
});

const mapDispatchToProps = {
  notify: EventsServiceHelper.addNotif,
};

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  withTranslation('default'),
)(UserRightsEditionWidget);
