/* @flow */

import * as _ from 'lodash';
import { DiagramEngine } from 'storm-react-diagrams';

import { Message } from 'src/data';
import { ItemNodeModel, ItemNodeTypes } from '../ItemNodeModel';
import ItemPortModel from '../ItemPortModel';
import type { ItemNodeModelListener } from '../ItemNodeModel';
import ItemLinkModel from '../ItemLinkModel';

/* eslint-disable no-use-before-define */
export interface MessageNodeModelListener extends ItemNodeModelListener<MessageNodeModel> {}
/* eslint-enable no-use-before-define */

const generateId = () => Math.random()
  .toString(36)
  .slice(-8);

export default class MessageNodeModel extends ItemNodeModel<MessageNodeModelListener> {
  constructor(reachable: boolean = false, message: Message, discussionId: string, messageId: string) {
    super(ItemNodeTypes.Message, (message && messageId) || generateId(), reachable);
    this.message = message;
    this.discussionId = discussionId;
  }

  updateMessage(message: Message) {
    this.message = message;
  }

  getModelId() {
    return this.message.id || this.message.nodeId;
  }

  getId() {
    return this.message.id || this.message.nodeId;
  }

  // eslint-disable-next-line no-unused-vars
  acceptInLinks(outNode: any, port: ItemPortModel, isBeforAdd: boolean) {
    if (!this.message.id) {
      console.log('REFUSED', this);
      return false;
    }
    const outType = outNode.type;
    switch (outType) {
      case ItemNodeTypes.Message:
      case ItemNodeTypes.Answer:
      case ItemNodeTypes.AnswerOpened:
      case ItemNodeTypes.EntryPoint:
        return true;
      default:
        return false;
    }
  }

  acceptOutLinks(inNode: any, port: any, isBeforeAdd: boolean) {
    // Only identified nodes can be linked
    if (!this.message.id) {
      return false;
    }

    const nextItems = Object.values(port.links);
    const nextMessages = nextItems.filter((link) => {
      if (link instanceof ItemLinkModel) {
        const inPort = link.getInPort();
        if (!inPort) {
          return false;
        }
        const parent = inPort.parent;
        return parent.type === ItemNodeTypes.Message;
      }
      return false;
    });
    const nextAnswers = nextItems.filter((link) => {
      if (link instanceof ItemLinkModel) {
        const inPort = link.getInPort();
        if (!inPort) {
          return false;
        }
        const parent = inPort.parent;
        return parent.type === ItemNodeTypes.Answer;
      }
      return false;
    });
    const nextAnswersOpened = nextItems.filter((link) => {
      if (link instanceof ItemLinkModel) {
        const inPort = link.getInPort();
        if (!inPort) {
          return false;
        }
        const parent = inPort.parent;
        return parent.type === ItemNodeTypes.AnswerOpened;
      }
      return false;
    });
    const maxNextMessages = isBeforeAdd ? 1 : 2; // In case the link is already added, it counts!

    // Filter accepted types
    const inType = inNode.type;
    switch (inType) {
      case ItemNodeTypes.Message:
        return nextAnswers.length === 0 && nextAnswersOpened.length === 0 && nextMessages.length < maxNextMessages;
      case ItemNodeTypes.Answer:
        return nextMessages.length === 0 && nextAnswersOpened.length === 0;
      case ItemNodeTypes.AnswerOpened:
        return nextMessages.length === 0 && nextAnswers.length === 0;
      case ItemNodeTypes.Trigger:
        return true;
      default:
        return false;
    }
  }

  initPorts() {
    this.addPort(new ItemPortModel(true, `${this.id}_in`, 'In'));
    this.addPort(new ItemPortModel(false, `${this.id}_out`, 'Out'));
  }

  deSerialize(object: any, engine: DiagramEngine) {
    super.deSerialize(object, engine);
  }

  serialize() {
    return _.merge(super.serialize(), {});
  }
}
