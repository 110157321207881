/* @flow */
import type { ReduxDispatch } from 'redux';
import * as Globals from 'src/constants/globals';
import * as actions from './actions';
import type { NotificationType } from './EventsReducer';
import { NotificationTypes } from './EventsReducer';

const logHelperCall = (title, args) => {
  if (Globals.__DEV__) {
    console.log(`################# EventsServiceHelper / ${title}`, args);
  }
};

export type addNotifType = (
  type: NotificationType,
  message: string,
  extra?: any,
  timeToStay?: number,
  action?: { title: string, callback: () => Promise<any>, closeOnCallback?: boolean },
) => ReduxDispatch => void;
export const addNotif: addNotifType = (type, message, extra, timeToStay, action) => (dispatch) => {
  logHelperCall('addNotif', {
    type,
    message,
    extra,
    timeToStay,
    action,
  });
  dispatch(actions.addNotif(type, message, extra, timeToStay, action));
  switch (type) {
    case NotificationTypes.ERROR: {
      console.error(`##AtlNotif: ${message}`, extra);
      break;
    }
    case NotificationTypes.WARN: {
      console.warn(`##AtlNotif: ${message}`, extra);
      break;
    }
    case NotificationTypes.SUCCESS: {
      console.log(`##AtlNotif: ${message}`, extra);
      break;
    }
    default: {
      console.debug(`##AtlNotif: ${message}`, extra);
      break;
    }
  }
};

export type closeNotifType = (id: string) => ReduxDispatch => void;
export const closeNotif: closeNotifType = id => (dispatch) => {
  logHelperCall('closeNotif', { id });
  dispatch(actions.closeNotif(id));
};

// CLEANUP
// *********************
export type cleanupType = () => ReduxDispatch => void;
export const cleanup: cleanupType = () => (dispatch) => {
  logHelperCall('cleanup');
  dispatch(actions.cleanupNotifs());
};
