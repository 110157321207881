/* @flow */
import * as React from 'react';

import { Loader } from 'src/pages/components';

type Props<T> = {
  renderItem: T => React.Element<any>,
  renderEdition: string => React.Element<any>,
  refresh: () => Promise<void>,
  items: T[],
};

type State = {
  selectedId?: string,
  isLoading: boolean,
};

class ListEditor<T: { id: string }> extends React.PureComponent<Props<T>, State> {
  static defaultProps = {
    items: [],
  };

  state = {
    newScenarioIds: [],
    selectedId: undefined,
    isLoading: false,
    isValid: false,
  };

  componentDidMount = () => {
    this.props.refresh();
  };

  handleChange = (event: any) => {
    const value = event.target.value;
    const fieldName = event.target.id;
    this.setState({ [fieldName]: value });
  };

  select = (element: T) => {
    this.setState({
      selectedId: element ? element.id : undefined,
    });
  };

  addScenario = () => {
    this.setState({
      selectedId: undefined,
    });
  };

  renderListButton = (element: T) => {
    const { renderItem } = this.props;
    const selectedId = this.state.selectedId || '??';
    let buttonClass = 'list-group-item list-group-item mb-3 list-group-item-action align-items-start';
    if (selectedId !== '??' && element.id === selectedId) {
      buttonClass += ' active';
    }
    return (
      <div className="" key={element.id}>
        <button id={element.id} className={buttonClass} onClick={() => this.select(element)}>
          {renderItem(element)}
        </button>
      </div>
    );
  };

  render() {
    const { renderEdition, items, newLabel } = this.props;
    const { selectedId, isLoading } = this.state;
    return (
      <div className="card bg-light fill screenBlock" style={{ overflow: 'hidden' }}>
        <div className="card-body d-flex p-2 pl-4 mb-2">
          <div className="row flex-fill">
            <div className="list-group fill col-4 pb-2" style={{ overflow: 'scroll' }}>
              <div className="addButton">
                <button
                  id="addButton"
                  className="list-group-item list-group-item mb-3 list-group-item-action align-items-start"
                  onClick={this.addScenario}
                >
                  <div className="d-flex justify-content-between">
                    <h5 className="mb-1">{newLabel}</h5>
                  </div>
                </button>
              </div>
              {/* $FlowFixMe: Object.values */}
              {items && Object.values(items).map(element => this.renderListButton(element))}
            </div>
            {selectedId && (
              <div className="list-group col-8 d-flex pb-4" style={{ overflow: 'scroll', height: '100%' }}>
                {renderEdition(selectedId)}
              </div>
            )}
          </div>
        </div>
        {isLoading && <Loader />}
      </div>
    );
  }
}

export default ListEditor;
