/* @flow */

/**
 * See catalog
 */
export const Visitor = 'visitor';
/**
 * See scenario details
 */
export const Moderator = 'moderator';
/**
 * Wording change in a given locale only
 */
export const Translator = 'translator';
/**
 * Edit scenarios
 */
export const Editor = 'editor';
/**
 * Release scenarios in dev mode
 */
export const ConfirmedEditor = 'confirmedEditor';
/**
 * Edit configurations
 */
export const Admin = 'admin';

export const Claims = {
  Visitor,
  Moderator,
  Translator,
  ConfirmedEditor,
  Editor,
  Admin,
};

export type ClaimType = $Values<typeof Claims>;
