/* @flow */
import AtlObject, { generateId } from './AtlObject';
import type { AppAtlObject } from './AtlObject';

export default class AtlGraphNode<TSerializeForApp: AppAtlObject> extends AtlObject<TSerializeForApp> {
  nodeId: string;

  pos: { x: number, y: number };

  constructor(json: any, isCopy: boolean) {
    super(json);
    const baseContent = json || {};
    const { nodeId, pos } = baseContent;
    this.nodeId = nodeId || generateId();
    if (isCopy) {
      this.pos = { ...pos };
    } else {
      this.pos = this.deserizalizePos(pos);
    }
  }

  // eslint-disable-next-line class-methods-use-this
  deserizalizePos(json: any) {
    if (json) {
      return json;
    }
    return { x: 100, y: 100 };
  }

  serializeInheritedFields() {
    const res = {
      pos: this.pos,
      nodeId: this.nodeId,
    };
    return res;
  }
}
