/* @flow */
import * as React from 'react';
import { BaseItem } from 'src/data';
import { DiagramEngine, AbstractNodeFactory } from 'storm-react-diagrams';
import { ItemNodeModel, ItemNodeTypes } from './ItemNodeModel';
import MessageNodeWidget from './message/MessageNodeWidget';
import TriggerNodeWidget from './messageTriggeredItem/TriggerNodeWidget';
import AnswerNodeWidget from './answer/AnswerNodeWidget';
import EntryPointWidget from './entryPoint/EntryPointWidget';
import BaseItemNodeWidget from './baseItem/BaseItemNodeWidget';
import AnswerOpenedNodeWidget from './answer/AnswerOpenedNodeWidget';
import TriggeredItemNodeWidget from './triggeredItem/TriggeredItemNodeWidget';
import BaseItemColorators from './baseItem/BaseItemColorations';

export default class DiscussionNodeFactory extends AbstractNodeFactory<ItemNodeModel<any>> {
  // eslint-disable-next-line class-methods-use-this
  constructor(type: string, colorator: BaseItem => string = BaseItemColorators.type.colorator) {
    super(type);
    this.baseItemColorator = colorator;
  }

  generateReactWidget(diagramEngine: DiagramEngine, node: ItemNodeModel<any>): React.Element<any> {
    if (node.type === ItemNodeTypes.Message) {
      return React.createElement(MessageNodeWidget, {
        node,
        diagramEngine,
      });
    }
    if (node.type === ItemNodeTypes.Answer) {
      return React.createElement(AnswerNodeWidget, {
        messageId: node.messageId,
        answer: node.answer,
        node,
        diagramEngine,
      });
    }
    if (node.type === ItemNodeTypes.AnswerOpened) {
      return React.createElement(AnswerOpenedNodeWidget, {
        messageId: node.messageId,
        answer: node.item,
        node,
        diagramEngine,
      });
    }
    if (node.type === ItemNodeTypes.Trigger) {
      return React.createElement(TriggerNodeWidget, {
        node,
        diagramEngine,
      });
    }
    if (node.type === ItemNodeTypes.BaseItem) {
      return React.createElement(BaseItemNodeWidget, {
        node,
        diagramEngine,
        colorator: this.baseItemColorator,
      });
    }
    if (node.type === 'TriggeredItem') {
      return React.createElement(TriggeredItemNodeWidget, {
        node,
        diagramEngine,
      });
    }
    return React.createElement(EntryPointWidget, {
      node,
      diagramEngine,
    });
  }
}
