/* @flow */
import BaseItem, { ItemTypes } from './BaseItem';
import LocalizedString from './LocalizedString';
import LocalizedStringArray from './LocalizedStringArray';

/* ***************************
  CLASS
*************************** */
export type AppAnecdote = {};

export default class AnectodeItem extends BaseItem {
  contentText: LocalizedString;

  constructor(json: any) {
    if (json instanceof AnectodeItem) {
      super(json, true);
    } else {
      super(json, false);
    }
    const {
      type, meta, contentText, ...newMeta
    } = json;
    this.type = ItemTypes.Anecdote;
    this.contentText = new LocalizedString(`${this.id}_contentText`, contentText);
    this.setMeta({ ...json.meta, ...newMeta });
  }

  serializeInheritedFieldsForApp() {
    const res = super.serializeInheritedFieldsForApp();
    res.contentText = this.contentText && this.contentText.serializeForApp();
    return res;
  }

  serializeInheritedFields() {
    const res = super.serializeInheritedFields();
    res.contentText = this.contentText && this.contentText.serialize();
    return res;
  }

  getLocalizedStringsWithPath() {
    const res: { [path: string]: LocalizedString | LocalizedStringArray } = super.getLocalizedStringsWithPath();
    res.contentText = this.contentText;
    return res;
  }
}
