/* @flow */
import * as React from 'react';
import { connect } from 'react-redux';
import { PreferencesServiceHelper } from 'src/store/preferences';
import { ScenarioServiceHelper } from 'src/store/scenario';
import Firebase, { withFirebase } from 'src/services/Firebase';
import { EventsServiceHelper, NotificationTypes } from 'src/store/events';
import { RoomServiceHelper } from 'src/store/room';
import { Loader } from 'src/pages/components';

const UrlParamsContext: Object = React.createContext(null);

export default UrlParamsContext;
const mapStateToProps = state => ({
  currentProperties: state.preferences.urlProperties,
  currentScenarioId: state.scenario.header.id,
  engineVersion: state.configuration.engineVersion,
  myEmail: state.user.user && state.user.user.email,
});

const mapDispatchToProps = {
  notify: EventsServiceHelper.addNotif,
  setUrlProperties: PreferencesServiceHelper.setUrlProperties,
  loadScenarioFromFirebase: ScenarioServiceHelper.loadScenarioAsync,
  setRoom: RoomServiceHelper.dispatchSetRoom,
};

export type WithPropertiesProps = {
  setRoom: RoomServiceHelper.dispatchSetRoomType,
  setUrlProperties: PreferencesServiceHelper.setUrlPropertiesType,
  loadScenarioFromFirebase: ScenarioServiceHelper.loadScenarioFromFirebaseType,
  match: { params: { [key: string]: string } },
  currentProperties: { params: { [key: string]: string } },
  notify: EventsServiceHelper.addNotifType,
  firebase: Firebase,
  currentScenarioId: string,
  engineVersion: number,
  myEmail?: string,
};

type State = {
  roomMembers: {
    sessionId: string,
    userId: String,
    email: string,
    sessionStartDate?: number,
    roomStartDate?: number,
  }[],
  isLoading: boolean,
};

const withUrlProperties = (Component: React.AbstractComponent<any>, params: ?{ [key: string]: string } = {}) => {
  class WithUrlProperties extends React.PureComponent<WithPropertiesProps, State> {
    state = {
      roomMembers: [],
      isLoading: false,
    };

    unsubscribe: any; // TODO Firebase type

    room: any; // TODO Firebase type

    roomListener: any; // TODO Firebase type

    componentWillMount() {
      const { match } = this.props;
      const allParams = { ...match.params, ...params };
      this.loadDataForProperties(allParams);

      try {
        this.props.firebase.updateSession();
      } catch (error) {
        console.log('Error refreshing session', error);
      }
    }

    loadDataForProperties = async (params: { [key: string]: string }) => {
      const { currentProperties } = this.props;
      const { setUrlProperties } = this.props;
      if (params.scenarioId) {
        /* $FlowFixMe */
        if (params.scenarioId !== currentProperties.scenarioId) {
          this.loadScenario(params.scenarioId);
        }
        this.notifyRoom(params.scenarioId);
      }
      if (params.ams) {
        this.notifyRoom('ams');
      }
      /* $FlowFixMe */
      if (params.city && !currentProperties.city) {
        this.notifyRoom('cities');
      }
      setUrlProperties(params);
    };

    loadScenario = async (scenarioId: string) => {
      const {
        loadScenarioFromFirebase, firebase, currentScenarioId, engineVersion,
      } = this.props;
      if (loadScenarioFromFirebase && scenarioId !== currentScenarioId) {
        this.setState({ isLoading: true });
        try {
          await loadScenarioFromFirebase(scenarioId, firebase, engineVersion);
        } catch (error) {
          console.error(error);
          setTimeout(() => {
            this.setState({ isLoading: false });
            window.location.reload();
          }, 1000);
        }
      }
    };

    snapshotToMember = (snapshot) => {
      const key = snapshot.key;
      const content = snapshot.val();
      let email;
      let roomStartDate;
      let sessionStartDate;
      let useAll = false;
      if (typeof content === 'string') {
        email = content;
      } else {
        email = content.email;
        roomStartDate = content.roomStartDate;
        sessionStartDate = content.sessionStartDate;
        useAll = true;
      }
      console.log(email);
      return {
        sessionId: key,
        userId: email,
        email,
        roomStartDate,
        sessionStartDate,
        meta: useAll && content,
      };
    };

    onMemberEntered = (snapshot) => {
      const id = snapshot.key;
      this.setState(
        prevState => ({
          roomMembers: [this.snapshotToMember(snapshot), ...prevState.roomMembers],
        }),
        () => {
          if (this.state.roomMembers.length > 1) {
            const otherEmails = this.state.roomMembers.map(it => it.email).filter(it => it !== this.props.myEmail);
            this.props.notify(NotificationTypes.WARN, 'W_CONCURENT_MODIFICATION', otherEmails.join(', '), 0);
          }
        },
      );
    };

    onMemberUpdated = (snapshot) => {
      const sessionId = snapshot.key;
      this.setState(prevState => ({
        roomMembers: [
          this.snapshotToMember(snapshot),
          ...prevState.roomMembers.filter(it => it.sessionId !== sessionId),
        ],
      }));
    };

    onMemberLeft = (snapshot) => {
      const sessionId = snapshot.key;
      this.setState(prevState => ({
        roomMembers: [...prevState.roomMembers.filter(it => it.sessionId !== sessionId)],
      }));
    };

    notifyRoom = async (roomId) => {
      this.unsubscribe = this.props.firebase.auth.onAuthStateChanged(async (authUser) => {
        if (roomId) {
          if (await this.props.firebase.enterRoom(roomId, authUser)) {
            this.room = this.props.firebase.room(roomId);
            this.props.setRoom(roomId);
          } else {
            this.room = undefined;
            this.props.setRoom(undefined);
          }
          if (this.room) {
            this.memberEntered = this.room.on('child_added', this.onMemberEntered);
            this.memberUpdated = this.room.on('child_changed', this.onMemberUpdated);
            this.memberLeft = this.room.on('child_removed', this.onMemberLeft);
          }
        } else {
          this.props.firebase.leaveRoom();
          this.props.setRoom(undefined);
        }
        this.unsubscribe();
        this.unsubscribe = undefined;
      });
    };

    componentWillUnmount() {
      if (this.unsubscribe) {
        this.unsubscribe();
      }
      if (this.memberEntered && this.room) {
        this.room.off('child_added', this.onMemberEntered);
        this.room.off('child_changed', this.onMemberUpdated);
        this.room.off('child_removed', this.onMemberLeft);
      }
      this.props.firebase.leaveRoom();
      this.props.setRoom(undefined);
    }

    render() {
      return (
        <UrlParamsContext.Provider value={this.props.match}>
          <Component {...this.props} roomMembers={this.state.roomMembers} />
          {this.state.isLoading && <Loader />}
        </UrlParamsContext.Provider>
      );
    }
  }

  return withFirebase(
    connect(
      mapStateToProps,
      mapDispatchToProps,
    )(WithUrlProperties),
  );
};

export { withUrlProperties };
