/* @flow */
import LocalizedString from '../LocalizedString';
import LocalizedStringArray from '../LocalizedStringArray';
import AtlGraphNode from '../AtlGraphNode';
import type { AppAtlObject } from '../AtlObject';
/* ***************************
  CLASS
*************************** */

export type AppAnswer = AppAtlObject & {
  isGoodAnswer: boolean,
  contentType: string,
  content: { [s: string]: string },
  nextMessageId: ?string,
};

export default class Answer extends AtlGraphNode<AppAnswer> {
  type: string;

  isGoodAnswer: boolean;

  contentType: string;

  content: LocalizedString;

  nextMessageId: string;

  nextCustom: ?string;

  parentMessageId: string;

  // Contains all the data the editor does not manage yet
  meta: any;

  constructor(json: any) {
    super(json, json instanceof Answer);
    const {
      id,
      type,
      isGoodAnswer,
      contentType,
      content,
      nextMessageId,
      nextCustom,
      nodeId,
      parentMessageId,
      pos,
      meta,
      ...newMeta
    } = json;
    this.type = 'answer';
    this.isGoodAnswer = isGoodAnswer !== '' ? isGoodAnswer : false;
    this.contentType = contentType;
    this.content = new LocalizedString(`${this.id}_content`, content);
    this.nextMessageId = nextMessageId;
    this.nextCustom = nextCustom;
    this.nodeId = nodeId && nodeId.length ? nodeId : `${parentMessageId}_${this.id}`;
    this.parentMessageId = parentMessageId;
    if (json instanceof Answer) {
      this.meta = { ...json.meta, ...newMeta };
    } else {
      this.meta = { ...json.meta, ...newMeta };
    }
  }

  serializeInheritedFieldsForApp() {
    const res = {
      isGoodAnswer: this.isGoodAnswer,
      contentType: this.contentType,
      content: this.content && this.content.serializeForApp(),
      nextMessageId: this.nextMessageId,
      ...this.meta,
    };
    if (this.nextCustom && this.nextCustom.length) {
      res.nextCustom = this.nextCustom;
    }
    return res;
  }

  serializeInheritedFields() {
    const res = {
      ...super.serializeInheritedFields(),
      type: this.type,
      isGoodAnswer: this.isGoodAnswer,
      contentType: this.contentType,
      content: this.content && this.content.serialize(),
      nextMessageId: this.nextMessageId,
      parentMessageId: this.parentMessageId,
      nextCustom: this.nextCustom,
      ...this.meta,
    };
    return res;
  }

  getLocalizedStringsWithPath() {
    const res: { [path: string]: LocalizedString | LocalizedStringArray } = { ...super.getLocalizedStringsWithPath() };
    res.content = this.content;
    return res;
  }

  hasNextCustom() {
    return !!this.nextCustom;
  }
}
