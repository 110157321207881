/* @flow */
import { ItemTypes, BaseItem } from 'src/data';

const itemProgressColorator = (item: BaseItem) => {
  let color = '#494949';
  if (item && item.progression && Object.keys(item.progression).length) {
    color = '#F5A623';
  }
  return color;
};

const itemMetricsColorator = (item: BaseItem) => {
  let color = '#494949';
  if (item && item.metricEvents && item.metricEvents.length) {
    color = '#F5A623';
  }
  return color;
};

const itemCluesColorator = (item: BaseItem) => {
  let color = '#494949';
  if (item && item.clues && !item.clues.isEmpty()) {
    color = '#F5A623';
  }
  return color;
};

const itemTypeColorator = (item: BaseItem) => {
  let color;
  switch (item.type) {
    case ItemTypes.Start:
      color = '#F5A623';
      break;
    case ItemTypes.Checkpoint:
    case ItemTypes.SecondaryMission:
      color = '#FC1472';
      break;
    case ItemTypes.Anecdote:
      color = '#66C77F';
      break;
    case ItemTypes.Archive:
      color = '#00A32B';
      break;
    case ItemTypes.Discussion:
      color = '#00E0CD';
      break;

    case ItemTypes.Custom:
      color = '#66004E';
      break;
    case ItemTypes.Document:
      color = '#990075';
      break;
    case ItemTypes.Openable:
      color = '#CC009C';
      break;
    case ItemTypes.Timer:
      color = '#FF00C4';
      break;
    case ItemTypes.Tool:
      color = '#ff00c2';
      break;

    case ItemTypes.Success:
      color = '#f8e71c';
      break;
    case ItemTypes.Failure:
      color = '#DFCF19';
      break;

    case ItemTypes.TimeTravel:
      color = '#0560F2';
      break;
    case ItemTypes.GameArea:
      color = '#044CC1';
      break;
    case ItemTypes.POI:
      color = '#001B48';
      break;
    case ItemTypes.AMS:
      color = '#001B48';
      break;

    default:
      color = 'red';
  }
  return color;
};

export default {
  type: {
    name: 'screens.scenarioEdition.structure.graphColorations.type',
    id: 'type',
    colorator: itemTypeColorator,
  },
  progress: {
    name: 'screens.scenarioEdition.structure.graphColorations.progress',
    id: 'progress',
    colorator: itemProgressColorator,
  },
  clues: {
    name: 'screens.scenarioEdition.structure.graphColorations.clues',
    id: 'clues',
    colorator: itemCluesColorator,
  },
  metrics: {
    name: 'screens.scenarioEdition.structure.graphColorations.metrics',
    id: 'metrics',
    colorator: itemMetricsColorator,
  },
};
