/* @flow */
import LocalizedString from './LocalizedString';
import LocalizedStringArray from './LocalizedStringArray';
import LocalizedFile from './LocalizedFile';
import type { StorageFileInfo } from './LocalizedFile';
import type { ObjectMap } from './Shortcuts';

/* ***************************
  CLASS
*************************** */

export type AppAtlObject = {
  id: string,
};

export type LocalizedItem = ?LocalizedString | ?LocalizedFile;

export const generateId = () => Math.random()
  .toString(36)
  .slice(-8);

export default class AtlObject<TSerializeForApp: AppAtlObject> {
  id: string;

  constructor(json: any) {
    const baseContent = json || {};
    const { id } = baseContent;
    this.id = id;
  }

  // eslint-disable-next-line class-methods-use-this,no-unused-vars
  serializeInheritedFieldsForApp(serData?: any): $Diff<TSerializeForApp, AppAtlObject> {
    /* $FlowFixMe: It's OK... */
    return {};
  }

  serializeForApp(serData?: any): TSerializeForApp {
    const res = {
      id: this.id,
      ...this.serializeInheritedFieldsForApp(serData),
    };
    Object.keys(res).forEach(key => res[key] === undefined && delete res[key]);
    return res;
  }

  // eslint-disable-next-line class-methods-use-this,no-unused-vars
  serializeInheritedFields(serData?: any) {
    return {};
  }

  serialize(serData?: any) {
    const res = {
      id: this.id,
      ...this.serializeInheritedFields(serData),
    };
    return res;
  }

  // eslint-disable-next-line class-methods-use-this,no-unused-vars
  cleanSubObjectsForFirebase(res: any, forApp: boolean) {
    return res;
  }

  serializeForFirebase(forApp: boolean = false) {
    let res = forApp ? this.serializeForApp() : this.serialize();
    res = this.cleanSubObjectsForFirebase(res, forApp);
    Object.keys(res).forEach(key => res[key] === undefined && delete res[key]);
    return res;
  }

  // eslint-disable-next-line class-methods-use-this
  getLocalizedStringsWithPath() {
    const res: { [path: string]: LocalizedString | LocalizedStringArray } = {};
    return res;
  }

  // eslint-disable-next-line class-methods-use-this
  getTranslationCsvIdPrefix() {
    return '';
  }

  getLocalizedStringToTranslateWithPath() {
    const localizedStrings = this.getLocalizedStringsWithPath();
    const res = [];
    Object.keys(localizedStrings).forEach((key) => {
      const value = localizedStrings[key];
      if (value && !value.isEmpty() && value.requireTranslations()) {
        res.push({ path: `${this.getTranslationCsvIdPrefix()}.${this.id}.${key}`, ...value.getCSVContent() });
      }
    });
    return res;
  }

  // eslint-disable-next-line class-methods-use-this
  applyTranslations(translationObj: { [path: string]: { [locale: string]: string } }) {
    Object.keys(translationObj).forEach((key) => {
      const pathComponents = key.split('.');
      let lastKey = pathComponents.shift();
      let parent = this;
      let exists = true;
      /* $FlowFixMe */
      let current = parent[lastKey];
      pathComponents.forEach((key) => {
        lastKey = key;
        parent = current;
        if (parent) {
          current = parent[lastKey];
        } else {
          exists = false;
        }
      });
      if (exists) {
        /* $FlowFixMe */
        parent[lastKey].applyTranslations(translationObj[key]);
      } else {
        console.warn(`Key not found '${key}'`);
      }
    });
  }

  finishTranslation(notFoundFields: any, unUsedTranslations: any) {
    const notFoundFieldsValues = Object.values(notFoundFields).filter(field => field !== undefined);
    if (notFoundFields && notFoundFieldsValues.length) {
      console.warn(`Some fields were not found in translations for ${this.id}`, notFoundFields);
    }
    if (unUsedTranslations && Object.keys(unUsedTranslations).length) {
      console.warn(`Some fields were not used during translations for ${this.id}`, unUsedTranslations);
    }
  }

  // eslint-disable-next-line no-unused-vars
  getFilesForLocale(locale: string): StorageFileInfo[] {
    const res: StorageFileInfo[] = [];
    const files = this.getLocalizedFiles();
    files.forEach((img) => {
      if (img instanceof LocalizedFile) {
        const file = img.getFileForLocale(locale);
        if (file) {
          res.push(file);
        }
      }
    });
    return res;
  }

  getFilesPerLocale(locales: string[]): ObjectMap<StorageFileInfo[]> {
    const res: ObjectMap<StorageFileInfo[]> = {};
    locales.forEach((locale) => {
      res[locale] = this.getFilesForLocale(locale);
    });
    return res;
  }

  // eslint-disable-next-line class-methods-use-this
  hasFileToUpload() {
    const files = this.getLocalizedFiles();
    if (!files.length) {
      return false;
    }
    return files.reduce((prev, cur) => prev || (cur instanceof LocalizedFile && cur.hasFileToUpload()));
  }

  // eslint-disable-next-line class-methods-use-this
  getLocalizedFiles() {
    const res: LocalizedFile[] = [];
    const classified = this.getLocalizedFilesWithPath();
    Object.keys(classified).forEach((key) => {
      res.push(classified[key]);
    });
    return res;
  }

  // eslint-disable-next-line class-methods-use-this
  getLocalizedFilesWithPath(): ObjectMap<LocalizedFile> {
    return {};
  }

  setString(pathToField: string, locale: string, content: string) {
    const pathComponents = pathToField.split('.');
    let lastKey = pathComponents.shift();
    let parent = this;
    /* $FlowFixMe */
    let current = parent[lastKey];
    pathComponents.forEach((key) => {
      lastKey = key;
      parent = current;
      current = parent[lastKey];
    });
    /* $FlowFixMe */
    parent[lastKey].setValueForLocale(content, locale);
  }

  setFile(pathToFile: string, file: LocalizedFile) {
    const pathComponents = pathToFile.split('.');
    let lastKey = pathComponents.shift();
    let parent = this;
    /* $FlowFixMe */
    let current = parent[lastKey];
    pathComponents.forEach((key) => {
      lastKey = key;
      parent = current;
      current = parent[lastKey];
    });
    /* $FlowFixMe */
    parent[lastKey] = file;
  }

  getFile(pathToFile: string) {
    const pathComponents = pathToFile.split('.');
    let lastKey = pathComponents.shift();
    let parent = this;
    /* $FlowFixMe */
    let current = parent[lastKey];
    pathComponents.forEach((key) => {
      lastKey = key;
      parent = current;
      current = parent[lastKey];
    });
    /* $FlowFixMe */
    return parent[lastKey];
  }
}
