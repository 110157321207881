/* @flow */
/* eslint-disable import/prefer-default-export */
import type { Store as ReduxStore } from 'redux';

import { asyncForEach, uniq } from 'src/utils';
import BaseItem from 'src/data/BaseItem';
import {
  Message, Discussion, Scenario, NPC,
} from 'src/data';
import { FirebaseSingleton } from 'src/services/Firebase';
import * as ScenarioServiceHelper from './ScenarioServiceHelper';
import * as itemActions from './items/types';
import * as headerActions from './header/types';
import * as npcsActions from './npcs/types';

const serializeElemForFirebase = (elem) => {
  let res;
  if (elem.serializeForFirebase) {
    res = elem.serializeForFirebase(false);
  } else if (Array.isArray(elem)) {
    res = elem.map(it => serializeElemForFirebase(it));
  } else {
    res = {};
    Object.keys(elem).forEach((key) => {
      res[key] = serializeElemForFirebase(elem[key]);
    });
  }
  return res;
};

const ChangeTypes = {
  add: 'Add',
  unknown: 'Unknown',
  remove: 'Remove',
  update: 'Update',
  translate: 'Translate',
  updateLinks: 'UpdateLinks',
};

type ChangeType = $Values<typeof ChangeTypes>;

const logChange = (scenarioId: string, version: string, section: string, itemId?: string, changeType: ChangeType) => {
  const changesRef = FirebaseSingleton.scenarioEditorChanges(scenarioId, version);
  const changelog = {
    date: new Date().getTime(),
    type: changeType,
    section,
    editor: FirebaseSingleton.email,
  };
  if (itemId) {
    changelog.itemId = itemId;
  }
  changesRef.push(changelog);
};

type Action = { type: string, payload: any };

export const scenarioFirebaseMidleware = (store: ReduxStore) => (next: Action => any) => (action: Action) => {
  let prevState; // Only calculate prev state if required
  switch (action.type) {
    case itemActions.ITEMS_REMOVE:
      prevState = store.getState();
      break;
    default:
      break;
  }
  const result = next(action);

  const itemIdsToUpload = [];
  let shouldUpdateHeader = false;
  const npcsToUpdate = [];

  if (action.payload && !action.payload.isImport) {
    switch (action.type) {
      case itemActions.ITEMS_CREATE:
      case itemActions.ITEMS_UPDATE: {
        if (action.payload.itemId) {
          itemIdsToUpload.push(action.payload.itemId);
        }
        break;
      }
      case itemActions.ITEMS_ADD_TRIGGERED_ITEM:
      case itemActions.ITEMS_UPDATE_TRIGGERED_ITEM:
      case itemActions.ITEMS_REMOVE_TRIGGERED_ITEM: {
        if (action.payload.parentId) {
          itemIdsToUpload.push(action.payload.parentId);
        }
        break;
      }
      case itemActions.DISCUSSION_ADD:
      case itemActions.DISCUSSION_UPDATE: {
        if (action.payload.discussionId) {
          itemIdsToUpload.push(action.payload.discussionId);
        }
        break;
      }
      case itemActions.DISCUSSION_ADD_ENTRY_POINT:
      case itemActions.DISCUSSION_UPDATE_ENTRY_POINT:
      case itemActions.DISCUSSION_REMOVE_ENTRY_POINT: {
        if (action.payload.discussionId) {
          itemIdsToUpload.push(`${action.payload.discussionId}/entryPoints`);
        }
        break;
      }
      case itemActions.DISCUSSION_ADD_MESSAGE:
      case itemActions.DISCUSSION_UPDATE_MESSAGE:
      case itemActions.DISCUSSION_ADD_ANSWER:
      case itemActions.DISCUSSION_UPDATE_ANSWER:
      case itemActions.DISCUSSION_REMOVE_ANSWER:
      case itemActions.DISCUSSION_ADD_MESSAGE_TRIGGERED_ITEM:
      case itemActions.DISCUSSION_UPDATE_MESSAGE_TRIGGERED_ITEM:
      case itemActions.DISCUSSION_LINK_MESSAGE_ANSWER:
      case itemActions.DISCUSSION_LINK_MESSAGE_TRIGGERED_ITEM:
      case itemActions.DISCUSSION_LINK_NEXT_MESSAGE:
      case itemActions.DISCUSSION_REMOVE_MESSAGE_TRIGGERED_ITEM:
      case itemActions.DISCUSSION_UNLINK_NEXT_MESSAGE:
      case itemActions.DISCUSSION_REMOVE_MESSAGE:
      case itemActions.DISCUSSION_UNLINK_MESSAGE_ANSWER:
      case itemActions.DISCUSSION_UNLINK_MESSAGE_TRIGGERED_ITEM: {
        if (action.payload.discussionId) {
          if (action.payload.messageId) {
            itemIdsToUpload.push(`${action.payload.discussionId}/messages/${action.payload.messageId}`);
          }
          itemIdsToUpload.push(`${action.payload.discussionId}/__detachedNodes`);
        }
        break;
      }
      case itemActions.ITEMS_REMOVE: {
        const { itemId } = action.payload;
        if (itemId) {
          itemIdsToUpload.push(itemId);
        }
        // $FlowFixMe : Variable is initialized in this case
        Object.values(prevState.scenario.items).forEach((item) => {
          if (item instanceof BaseItem) {
            if (item && item.triggeredItems) {
              item.triggeredItems.forEach((trigger) => {
                if (trigger.id === itemId) {
                  itemIdsToUpload.push(item.id);
                }
              });
            }
            if (item instanceof Discussion) {
              Object.values(item.messages).forEach((mess) => {
                if (mess instanceof Message) {
                  if (mess.contentItemId === itemId) {
                    itemIdsToUpload.push(`${item.id}/messages/${mess.id}`);
                  }
                }
                if (mess && mess instanceof Message && mess.triggeredItems) {
                  mess.triggeredItems.forEach((trigger) => {
                    if (trigger.id === itemId) {
                      itemIdsToUpload.push(`${item.id}/messages/${mess.id}`);
                    }
                  });
                }
              });
            }
          }
        });
        break;
      }
      case itemActions.ITEMS_UPDATE_POSITION:
      case itemActions.ITEMS_MOVE_TRIGGERED_ITEM:
      case itemActions.DISCUSSION_MOVE_ENTRY_POINT:
      case itemActions.DISCUSSION_UPDATE_MESSAGE_POSITION:
      case itemActions.DISCUSSION_UPDATE_ANSWER_POSITION:
      case itemActions.DISCUSSION_UPDATE_MESSAGE_TRIGGER_POSITION:
        // TODO
        break;

      case headerActions.HEADER_UPDATE:
        shouldUpdateHeader = true;
        break;
      case npcsActions.NPC_CREATE:
        if (action.payload.npc.id) {
          npcsToUpdate.push(action.payload.npc.id);
        }
        break;
      case npcsActions.NPC_UPDATE:
      case npcsActions.NPC_REMOVE:
        if (action.payload.id) {
          npcsToUpdate.push(action.payload.id);
        }
        break;
      default:
        break;
    }
  }

  if (shouldUpdateHeader) {
    const newState = store.getState();
    const scenarioId = newState.scenario.header.id;
    const version = newState.scenario.header.lastVersion;
    if (scenarioId) {
      const firebaseRef = FirebaseSingleton.scenarioEditorHeader(scenarioId);
      const firebaseHeader = ScenarioServiceHelper.serialiazeHeaderForFirebase(
        new Scenario(newState.scenario.header),
        newState.scenario.items,
        false,
      );
      logChange(scenarioId, version, 'header', undefined, ChangeTypes.update);
      firebaseRef.set(firebaseHeader);
    } else {
      console.error('COULD NOT SAVE DATA CAUSE NO SCENARIO LOADED');
    }
  }

  if (itemIdsToUpload.length) {
    const newState = store.getState();
    const filteredPaths = uniq(itemIdsToUpload);
    const scenarioId = newState.scenario.header.id;
    const version = newState.scenario.header.lastVersion;
    if (scenarioId) {
      asyncForEach(filteredPaths, async (pathToUpload) => {
        const parts = pathToUpload.split('/');
        let elem = newState.scenario.items;
        const itemId = parts[0];
        let changeType = ChangeTypes.unknown;
        parts.forEach((it) => {
          if (it.length) {
            elem = elem[it];
          }
        });
        const firebaseRef = FirebaseSingleton.scenarioEditorItemsData(scenarioId).child(pathToUpload);
        // console.log(`Updating editor data for ${pathToUpload}`, firebaseRef, elem);
        if (elem) {
          const serialized = serializeElemForFirebase(elem);
          firebaseRef.set(serialized);
        } else {
          firebaseRef.remove();
          changeType = ChangeTypes.remove;
        }
        logChange(scenarioId, version, 'items', itemId, changeType);
      });
    } else {
      console.error('COULD NOT SAVE DATA CAUSE NO SCENARIO LOADED');
    }
  }

  if (npcsToUpdate.length) {
    const newState = store.getState();
    const filteredPaths = uniq(npcsToUpdate);
    const scenarioId = newState.scenario.header.id;
    const version = newState.scenario.header.lastVersion;
    if (scenarioId) {
      asyncForEach(filteredPaths, async (npcId) => {
        const elem = newState.scenario.npcs.npcs.find(it => it.id === npcId);
        const firebaseRef = FirebaseSingleton.scenarioEditorNPCs(scenarioId).child(npcId);
        let changeType = action.type === npcsActions.NPC_CREATE ? ChangeTypes.add : ChangeTypes.update;
        if (elem) {
          const serialized = new NPC(elem).serializeForFirebase(false);
          firebaseRef.set(serialized);
        } else {
          firebaseRef.remove();
          changeType = ChangeTypes.remove;
        }
        logChange(scenarioId, version, 'npc', npcId, changeType);
      });
    } else {
      console.error('COULD NOT SAVE DATA CAUSE NO SCENARIO LOADED');
    }
  }

  return result;
};
