/* @flow */
import * as React from 'react';

import ReactJson from 'react-json-view';
import { connect } from 'react-redux';
import type { Notification } from 'src/store/events/EventsReducer';
import { EventsServiceHelper, NotificationTypes } from 'src/store/events';
import { withTranslation } from 'react-i18next';
import { compose } from 'redux';
import 'bootstrap/dist/css/bootstrap.min.css';

export type NavBarProps = {
  notification: Notification,
  closeNotification: EventsServiceHelper.closeNotifType,
  t: (key: string[]) => string,
};

export type state = {
  actionLaunched: boolean,
};

class NotificationView extends React.Component<NavBarProps, state> {
  state = {
    actionLaunched: false,
  };

  componentDidMount() {
    const { type, timeToStay } = this.props.notification;
    let tileTimeToStay = timeToStay;
    if (timeToStay === undefined) {
      switch (type) {
        case NotificationTypes.ERROR:
          break;
        case NotificationTypes.SUCCESS:
          tileTimeToStay = 2000;
          break;
        case NotificationTypes.DEBUG:
          tileTimeToStay = 2000;
          break;
        case NotificationTypes.WARN:
          tileTimeToStay = 2000;
          break;
        default:
          break;
      }
    }
    if (tileTimeToStay) {
      setTimeout(() => {
        this.close();
      }, tileTimeToStay);
    }
  }

  close = () => {
    const { closeNotification, notification } = this.props;
    if (notification.id) {
      closeNotification(notification.id);
    }
  };

  performAction = () => {
    const { action } = this.props.notification;
    this.setState({ actionLaunched: true });
    if (action) {
      if (action.callback) {
        try {
          action.callback();
        } catch (error) {
          console.error(error);
        }
      }
      if (action.closeOnCallback) {
        this.close();
      }
    }
  };

  render() {
    const { t } = this.props;
    const {
      type, id, message, extra, action,
    } = this.props.notification;

    const isExtraString = typeof extra === 'string';
    const { actionLaunched } = this.state;
    let alertType;
    switch (type) {
      case NotificationTypes.ERROR:
        alertType = 'alert-danger';
        break;
      case NotificationTypes.SUCCESS:
        alertType = 'alert-success';
        break;
      case NotificationTypes.DEBUG:
        alertType = 'alert-info';
        break;
      case NotificationTypes.WARN:
        alertType = 'alert-warning';
        break;
      default:
        alertType = 'alert-info';
        break;
    }
    return (
      <div id={id} className={`alert ${alertType}`} role="alert" style={{ zIndex: 100 }} onClick={this.close}>
        {t([`notifications.${message}`, ''])}
        <button type="button" className="close" aria-label="Close">
          {action && (
            <button
              className="btn btn-primary  btn-ation-pannel"
              type="button"
              onClick={this.performAction}
              disabled={actionLaunched}
            >
              {actionLaunched ? t(['general.launched', '']) : action.title}
            </button>
          )}
        </button>
        {extra && (
          <div className="atl-notification">
            <hr />
            {isExtraString ? (
              <small>{extra}</small>
            ) : (
              <ReactJson
                src={extra}
                name={'extra'}
                collapsed={0}
                displayDataTypes={false}
                collapseStringsAfterLength={50}
              />
            )}
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = () => ({});

const mapDispatchToProps = {
  closeNotification: EventsServiceHelper.closeNotif,
};

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  withTranslation('default'),
)(NotificationView);
