/* @flow */
import { createNamedReducer } from 'redux-named-reducers';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import * as types from './types';

export type UserRole = 'editor' | 'translator' | 'reviewer';
export type Language = 'fr' | 'en' | 'es' | 'de' | 'ro';
export type UserReducerState = {
  lastEmail: string,
  locale: Language,
  user?: any,
  roles?: UserRole[],
  languages: Language[],
};
export const UserReducerDefaultState: UserReducerState = {
  lastEmail: '',
  locale: 'fr',
  user: undefined,
  roles: [],
  languages: ['fr', 'en'],
};

const reducer = (state: UserReducerState = UserReducerDefaultState, action: { type: string, payload: any }) => {
  let newState;
  switch (action.type) {
    case types.USER_SET: {
      const { user } = action.payload;
      newState = { ...state };
      newState.user = user;
      break;
    }
    case types.LAST_EMAIL_SET: {
      const { email } = action.payload;
      newState = { ...state };
      newState.lastEmail = email;
      break;
    }
    default:
      newState = state;
      break;
  }
  return newState;
};

export const UserReducerKey = 'user';

const userPersistConfig = {
  key: UserReducerKey,
  storage,
  transforms: [],
};
export default createNamedReducer({
  moduleName: UserReducerKey,
  reducer: persistReducer(userPersistConfig, reducer),
});
