/* @flow */
import React from 'react';

import { connect } from 'react-redux';
import * as Globals from 'src/constants/globals';
import { NPCServiceHelper } from 'src/store/scenario/npcs';
import { NPC } from 'src/data';
import Loader from 'src/pages/components/loader';

import { withTranslation } from 'react-i18next';
import { compose } from 'redux';

export type NpcsEditionWidgetProps = {
  updateNPCAsync: NPCServiceHelper.updateNPCAsyncType,
  removeNPC: NPCServiceHelper.removeNPCType,
  scenarioId: string,
  locale: string,
  t: (key: string[]) => string,
  npcs: any,
};

type State = {
  npc?: NPC,
  role: string,
  linguisticTic: string,
  id: string,
  name: string,
  brief: string,
  birthYear?: number,
  deathYear?: number,
  isValid: boolean,
  isLoading: boolean,
};

class NpcsEditionWidget extends React.PureComponent<NpcsEditionWidgetProps, State> {
  static defaultProps = {
    scenarioId: 'unknown_',
    canChangeId: true,
    npcs: [],
  };

  state = {
    npc: undefined,
    isValid: false,
    name: '',
    role: '',
    linguisticTic: '',
    brief: '',
    id: '',
    birthYear: undefined,
    deathYear: undefined,
    isLoading: false,
  };

  handleChange = (event) => {
    const value = event.target.value;
    const fieldName = event.target.id;
    this.setState({ [fieldName]: value });
    const newVal = { ...this.state };
    newVal[fieldName] = value;
    this.updateValidity(newVal);
  };

  selectNpc = (data?: NPC) => {
    const { locale } = this.props;
    const npc = data || {};
    this.setState({
      npc: data,
      id: npc.id || '',
      name: (npc.name && npc.name.valueForLocale(locale)) || '',
      brief: npc.brief || '',
      role: npc.role || '',
      linguisticTic: npc.linguisticTic || '',
      birthYear: npc.birthYear || undefined,
      deathYear: npc.deathYear || undefined,
    });
    const newVal = { ...this.state };
    this.updateValidity(newVal);
  };

  saveNpc = async () => {
    const {
      id, name, role, linguisticTic, brief, npc, birthYear, deathYear,
    } = this.state;
    const { locale, updateNPCAsync, scenarioId } = this.props;
    const newNpc = new NPC(npc || {});
    newNpc.id = id;
    newNpc.role = role;
    newNpc.brief = brief;
    newNpc.linguisticTic = linguisticTic;
    newNpc.birthYear = birthYear;
    newNpc.deathYear = deathYear;
    newNpc.name.setValueForLocale(name, locale);
    this.setState({ isLoading: true });
    if (updateNPCAsync) {
      try {
        await updateNPCAsync(scenarioId, id, newNpc);
        this.selectNpc(newNpc);
      } catch (error) {
        console.log(error);
      }
    }
    this.setState({ isLoading: false });
  };

  removeNPC = async () => {
    const { id } = this.state;
    const { scenarioId, removeNPC } = this.props;
    this.setState({ isLoading: true });
    if (removeNPC) {
      try {
        await removeNPC(scenarioId, id);
        this.selectNpc();
      } catch (error) {
        console.log(error);
      }
    }
    this.setState({ isLoading: false });
  };

  // eslint-disable-next-line no-unused-vars
  updateValidity = (newVal: State) => {
    const isValid: boolean = !!newVal.id.match(Globals.idRegex) && newVal.name;
    this.setState({ isValid });
  };

  renderListButton = (element) => {
    const { npc } = this.state;
    const { locale } = this.props;
    const birth = element.birthYear ? element.birthYear : '??';
    const death = element.deathYear ? element.deathYear : '??';
    let buttonClass = 'list-group-item list-group-item mb-3 list-group-item-action align-items-start';
    if (element.id === npc) {
      buttonClass += ' active';
    }
    return (
      <div className="" key={element.id}>
        <button id={element.id} className={buttonClass} onClick={() => this.selectNpc(element)}>
          <div className="d-flex justify-content-between">
            <strong className="">{element.name.valueForLocale(locale)}</strong>
            <small className="text-muted hidden">{`${birth}/${death}`}</small>
          </div>
          <p className="hidden">{element.brief}</p>
          <small className="text-muted">{element.role}</small>
        </button>
      </div>
    );
  };

  render() {
    const { npcs, t } = this.props;
    const {
      isValid, npc, name, role, brief, id, linguisticTic, birthYear, deathYear, isLoading,
    } = this.state;
    return (
      <div className="card bg-light screenBlock npcs">
        <div className="card-header">
          <h3>{t(['screens.scenarioEdition.npcEdition.sectionTitle', ''])}</h3>
        </div>
        <div className="card-body">
          <div className="row">
            <div className="list-group col-4 npc-list">
              <div className="">
                <button id={'undef'} className={' btn btn-primary full-width mb-3'} onClick={this.selectNpc}>
                  NOUVEAU NPC
                </button>
              </div>
              {npcs && npcs.map(element => this.renderListButton(element))}
            </div>
            <div className="list-group col-8">
              <div className="input-group mb-3">
                <div className="input-group-prepend">
                  <span className="input-group-text" id="basic-addon1" />
                </div>
                <input
                  type="text"
                  className="form-control"
                  placeholder={t(['screens.scenarioEdition.npcEdition.idPlaceholder', ''])}
                  id="id"
                  onChange={this.handleChange}
                  value={id}
                  disabled={npc && npc.id}
                  aria-label="id"
                  aria-describedby="basic-addon1"
                />
              </div>
              <div className="input-group mb-3">
                <div className="input-group-prepend">
                  <span className="input-group-text" id="basic-addon1" />
                </div>
                <input
                  type="text"
                  className="form-control"
                  placeholder={t(['screens.scenarioEdition.npcEdition.namePlaceholder', ''])}
                  id="name"
                  onChange={this.handleChange}
                  value={name}
                  aria-label="name"
                  aria-describedby="basic-addon1"
                />
              </div>
              <div className="input-group mb-3 hidden">
                <div className="input-group-prepend">
                  <span className="input-group-text" id="basic-addon1" />
                </div>
                <input
                  type="text"
                  className="form-control"
                  placeholder={t(['screens.scenarioEdition.npcEdition.rolePlaceholder', ''])}
                  id="role"
                  onChange={this.handleChange}
                  value={role}
                  aria-label="role"
                  aria-describedby="basic-addon1"
                />
              </div>
              <div className="input-group mb-3">
                <div className="input-group-prepend">
                  <span className="input-group-text" id="basic-addon1" />
                </div>
                <input
                  type="text"
                  className="form-control"
                  placeholder={t(['screens.scenarioEdition.npcEdition.briefPlaceholder', ''])}
                  id="brief"
                  onChange={this.handleChange}
                  value={brief}
                  aria-label="brief"
                  aria-describedby="basic-addon1"
                />
              </div>
              <div className="input-group mb-3 hidden">
                <div className="input-group-prepend">
                  <span className="input-group-text" id="basic-addon1" />
                </div>
                <input
                  type="text"
                  className="form-control"
                  placeholder={t(['screens.scenarioEdition.npcEdition.ticsPlaceholder', ''])}
                  id="linguisticTic"
                  onChange={this.handleChange}
                  value={linguisticTic}
                  aria-label="linguisticTic"
                  aria-describedby="basic-addon1"
                />
              </div>
              <div className="input-group mb-3 hidden">
                <div className="input-group-prepend">
                  <span className="input-group-text" id="basic-addon1" />
                </div>
                <input
                  type="number"
                  className="form-control"
                  placeholder={t(['screens.scenarioEdition.npcEdition.birthPlaceholder', ''])}
                  id="birthYear"
                  onChange={this.handleChange}
                  value={birthYear}
                  aria-label="birthYear"
                  aria-describedby="basic-addon1"
                />
              </div>
              <div className="input-group mb-3 hidden">
                <div className="input-group-prepend">
                  <span className="input-group-text" id="basic-addon1" />
                </div>
                <input
                  type="number"
                  className="form-control"
                  placeholder={t(['screens.scenarioEdition.npcEdition.deathPlaceholder', ''])}
                  id="deathYear"
                  onChange={this.handleChange}
                  value={deathYear}
                  aria-label="deathYear"
                  aria-describedby="basic-addon1"
                />
              </div>
              {isValid ? (
                <button
                  className="btn btn-primary mb-3"
                  type="button"
                  id="button-addon2"
                  onClick={this.saveNpc}
                  disabled={!isValid}
                >
                  {t(['general.save', ''])}
                </button>
              ) : null}
              {npc && npc.id && isValid && (
                <button
                  className="btn btn-outline-secondary delete mb-3"
                  type="button"
                  id="button-addon2"
                  onClick={this.removeNPC}
                  disabled={!isValid}
                >
                  {t(['general.delete', ''])}
                </button>
              )}
            </div>
          </div>
        </div>
        {isLoading && <Loader />}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  npcs: state.scenario.npcs.npcs,
  scenarioId: state.scenario.header.id,
  prefix: `${state.scenario.header.id}_`,
  suffix: '_ams',
  locale: state.preferences.editionLocale,
});

const mapDispatchToProps = {
  updateNPCAsync: NPCServiceHelper.updateNPCAsync,
  removeNPC: NPCServiceHelper.removeNPC,
};

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  withTranslation('default'),
)(NpcsEditionWidget);
