/* @flow */
import React, { useState, useEffect } from 'react';

import { connect } from 'react-redux';
import moment from 'moment';

import {
  Loader, InputSelect, InputDate, withConfirm,
} from 'src/pages/components';
import { compose } from 'redux';
import { LocalizedString, City } from 'src/data';
import type { ScenarioVendingInfo } from 'src/data';
import Firebase, { withFirebase, FirebaseHelper } from 'src/services/Firebase';
import { ScenarioServiceHelper } from 'src/store/scenario';
import { withCities } from 'src/pages/cities/WithCities';
import { withTranslation } from 'react-i18next';

import MonitoringLineGraph from './MonitoringLineGraph';
import DoughnutGraph from './DoughnutGraph';

type Props = {
  firebase: Firebase,
  deployScenarioAsync: FirebaseHelper.deployScenarioAsyncType,
  checkScenarioVersionAsync: FirebaseHelper.checkScenarioVersionAsyncType,
  deployToInternalAsync: ScenarioServiceHelper.deployToInternalAsyncType,
  deployToInternalAsync: ScenarioServiceHelper.deployToInternalAsyncType,
  getScenarioSalesStatistics: FirebaseHelper.getScenarioSalesStatisticsType,
  getCitySalesStatistics: FirebaseHelper.getCitySalesStatisticsType,
  getScenarioSuccessStatistics: FirebaseHelper.getScenarioSuccessStatisticsType,
  locale: string,
  t: (key: string[]) => string,
  cities: City[],
};

type ScenarioInfo = {
  id: string,
  cityId: string,
  name: LocalizedString,
  subtitle: LocalizedString,
  vendingInfo: ScenarioVendingInfo,
  lastVersion: string,
  currentVersion?: string,
  alert: any,
};

const indexToColor = (index: number) => {
  switch (index) {
    case 0:
      return '#011A48';
    case 1:
      return '#006092';
    case 2:
      return '#00AEBF';
    case 3:
      return '#6cfbce';
    case 4:
      return '#CFA616';
    default:
      return '#000000';
  }
};

const periods = [
  {
    id: 'month',
    title: 'Mois',
  },
  { id: 'week', title: 'semaine' },
];

const units = [
  { id: 'mission', title: 'Scénario' },
  {
    id: 'city',
    title: 'Ville',
  },
];

const StatisticsTab = (props: Props) => {
  const [currentScenarios, setCurrentScenarios] = useState({});
  const [matchingScenarios, setMatchingScenarios] = useState({});
  const [scenarioId, setScenarioId] = useState(undefined);
  const [period, setPeriod] = useState(periods[0].id);
  const [unit, setUnit] = useState(units[0].id);
  const [startDate, setStartDate] = useState(undefined);
  const [endDate, setEndDate] = useState(undefined);
  const [cityId, setCityId] = useState(undefined);
  const [isLoading, setLoading] = useState(false);
  const [saleDataset, setSaleDataset] = useState([]);
  const [saleGlobalDataset, setSaleGlobalDataset] = useState([]);
  const [saleGlobalLabels, setSaleGlobalLabels] = useState([]);
  const [prevPeriodSaleDataset, setPrevPeriodSaleDataset] = useState([]);
  const [periodSaleDataset, setPeriodSaleDataset] = useState([]);
  const [successDataset, setSuccessDataset] = useState([]);
  const [totalSales, setTotalSales] = useState(0);
  const [periodTotalSales, setPeriodTotalSales] = useState(0);
  const [prevPeriodTotalSales, setPrevPeriodTotalSales] = useState(0);
  const [successGlobalDataset, setSuccessGlobalDataset] = useState([]);
  const [successGlobalLabels, setSuccessGlobalLabels] = useState([]);
  const [totalPlayed, setTotalPlayed] = useState(0);
  const [periodTotalPlayed, setPeriodTotalPlayed] = useState(0);
  const [prevPeriodTotalPlayed, setPrevPeriodTotalPlayed] = useState(0);
  const [prevPeriodSuccessDataset, setPrevPeriodSuccessDataset] = useState([]);
  const [periodSuccessDataset, setPeriodSuccessDataset] = useState([]);

  useEffect(() => {
    // eslint-disable-next-line no-use-before-define
    reloadScenariosAsync();
    const set = [
      {
        color: indexToColor(0),
        title: 'Super titre',
        data: [],
      },
    ];
    setSaleDataset(set);
  }, []);

  const refreshStatsAsync = async () => {
    // TODO : Update graph
    const { t } = props;
    if (scenarioId) {
      setLoading(true);
      const res = await props.getScenarioSalesStatistics(scenarioId, startDate, endDate, period);
      if (!res.error) {
        const set = [
          {
            color: indexToColor(0),
            title: 'Ventes globales',
            data: res.values.map(it => ({
              x: moment(it.date, 'YYYY-MM-DD')
                .toDate()
                .getTime(),
              y: it.count,
            })),
          },
          {
            color: indexToColor(1),
            title: 'Ventes plein tarif',
            data: res.values.map(it => ({
              x: moment(it.date, 'YYYY-MM-DD')
                .toDate()
                .getTime(),
              y: it.default_count,
            })),
          },
          {
            color: indexToColor(2),
            title: 'Ventes welcomes',
            data: res.values.map(it => ({
              x: moment(it.date, 'YYYY-MM-DD')
                .toDate()
                .getTime(),
              y: it.welcome_count,
            })),
          },
          {
            color: indexToColor(3),
            title: 'Ventes launch',
            data: res.values.map(it => ({
              x: moment(it.date, 'YYYY-MM-DD')
                .toDate()
                .getTime(),
              y: it.launch_count,
            })),
          },
        ];
        setSaleDataset(set);
        setSaleGlobalDataset([res.sum.default_sum, res.sum.welcome_sum, res.sum.launch_sum]);
        setPeriodSaleDataset([res.period.default_sum, res.period.welcome_sum, res.period.launch_sum]);
        setPeriodTotalSales(res.period.total);
        setPrevPeriodTotalSales(res.prevPeriod.total || 0);
        setPrevPeriodSaleDataset([
          res.prevPeriod.default_sum || 0,
          res.prevPeriod.welcome_sum || 0,
          res.prevPeriod.launch_sum || 0,
        ]);
        setTotalSales(res.sum.total);
        setSaleGlobalLabels(['Plein tarif', 'Welcome', 'Launch']);
        setTotalSales(res.sum.total);
      }

      const resSuccess = await props.getScenarioSuccessStatistics(scenarioId, startDate, endDate, period);
      if (!resSuccess.error) {
        const set = [
          {
            color: indexToColor(0),
            title: t(['screens.stats.playedGamesGraphTitle', '']),
            data: resSuccess.values.map(it => ({
              x: moment(it.date, 'YYYY-MM-DD')
                .toDate()
                .getTime(),
              y: it.launch_count,
            })),
          },
          {
            color: indexToColor(1),
            title: t(['screens.stats.winedGamesGraphTitle', '']),
            data: resSuccess.values.map(it => ({
              x: moment(it.date, 'YYYY-MM-DD')
                .toDate()
                .getTime(),
              y: it.success_count,
            })),
          },
          {
            color: indexToColor(2),
            title: t(['screens.stats.finishedGamesGraphTitle', '']),
            data: resSuccess.values.map(it => ({
              x: moment(it.date, 'YYYY-MM-DD')
                .toDate()
                .getTime(),
              y: it.end_count,
            })),
          },
          {
            color: indexToColor(3),
            title: t(['screens.stats.100percentGamesGraphTitle', '']),
            data: resSuccess.values.map(it => ({
              x: moment(it.date, 'YYYY-MM-DD')
                .toDate()
                .getTime(),
              y: it.story_completed_count,
            })),
          },
          {
            color: indexToColor(4),
            title: 'Gagnées sans clues',
            data: resSuccess.values.map(it => ({
              x: moment(it.date, 'YYYY-MM-DD')
                .toDate()
                .getTime(),
              y: it.completed_no_clues_count,
            })),
          },
        ];
        setSuccessDataset(set);

        setPeriodTotalPlayed(resSuccess.period.launch_sum);
        setPrevPeriodTotalPlayed(resSuccess.prevPeriod.launch_sum);
        setTotalPlayed(resSuccess.sum.launch_sum);
      }
      console.log('STATS FROM SCREEN', res);
      setLoading(false);
    }
  };

  useEffect(
    () => {
      const minDateMoment = moment().subtract(12, period);
      const maxDateMoment = moment();
      setStartDate(minDateMoment.toISOString());
      setEndDate(maxDateMoment.toISOString());
    },
    [period],
  );
  useEffect(
    () => {
      refreshStatsAsync();
    },
    [scenarioId, startDate, endDate],
  );

  const handleCityChange = (event) => {
    const value = event.target.value;
    // $FlowFixMe Boolean is only used for bool fields
    setCityId(value);
  };
  const handlePeriod = (event) => {
    const value = event.target.value;
    // $FlowFixMe Boolean is only used for bool fields
    setPeriod(value);
  };
  const handleUnit = (event) => {
    const value = event.target.value;
    // $FlowFixMe Boolean is only used for bool fields
    setUnit(value);
  };

  const handleStart = (event) => {
    const value = event.target.value;
    console.log(value);
    setStartDate(value);
  };

  const handleEnd = (event) => {
    const value = event.target.value;
    setEndDate(value);
  };
  const selectScenario = (scenario: { id: string }) => {
    const { id } = scenario;
    setScenarioId(id);
  };

  const reloadScenariosAsync = async () => {
    try {
      setLoading(true);
      const { currentScenarios } = await props.firebase.getScenariosToDeployAsync();
      setCurrentScenarios(currentScenarios);
    } catch (error) {
      console.warn('Cannot load scenarios', error);
    }
    setLoading(false);
  };

  const updateMatchingScenarios = () => {
    const matchingScenarios = {};
    if (currentScenarios) {
      Object.keys(currentScenarios).forEach((it: string) => {
        if (cityId) {
          // $FlowFixMe indexer
          if (currentScenarios[it].cityId === cityId) {
            matchingScenarios[it] = currentScenarios[it];
          }
        } else {
          // $FlowFixMe indexer
          matchingScenarios[it] = currentScenarios[it];
        }
      });
    }
    setMatchingScenarios(matchingScenarios);
  };

  useEffect(
    () => {
      updateMatchingScenarios();
    },
    [cityId, currentScenarios],
  );
  useEffect(() => {
    updateMatchingScenarios();
  }, []);

  const renderListButton = (element: ScenarioInfo) => {
    const { locale } = props;
    const cityId = element.cityId || '??';
    const scenarioIdVal = scenarioId || '??';
    let isActive = false;
    let buttonClass = 'list-group-item list-group-item mb-3 list-group-item-action align-items-start';
    if (scenarioIdVal !== '??' && element.id === scenarioIdVal) {
      buttonClass += ' active';
      isActive = true;
    }

    const { id, name, subtitle } = element;

    return (
      <div className="" key={id}>
        <button id={id} className={buttonClass} onClick={() => selectScenario(element)}>
          <div className="d-flex justify-content-between">
            <h5 className="mb-1">{name.valueForLocale(locale)}</h5>
            <small className="text-muted">{`${cityId}/${id}`}</small>
          </div>
          <div className="d-flex justify-content-between">
            <p className="mb-1">{subtitle.valueForLocale(locale)}</p>
          </div>
        </button>
      </div>
    );
  };

  const { locale, t } = props;
  return (
    <div id="statistics">
      <div className="filter">
        <div className="row ml-0 mr-0">
          <InputSelect
            className="col-4"
            fieldName={'unit'}
            value={unit}
            values={units}
            itemToId={it => it.id}
            itemToTitle={it => it.title}
            label={t(['screens.stats.unit', ''])}
            handleChange={handleUnit}
          />

          <InputSelect
            className="col-4"
            fieldName={'period'}
            value={period}
            values={periods}
            itemToId={it => it.id}
            itemToTitle={it => it.title}
            label={t(['screens.stats.period', ''])}
            handleChange={handlePeriod}
          />
          <InputDate
            style={{ minWidth: 300 }}
            fieldName="startDate"
            label={t(['screens.stats.startDate', ''])}
            value={startDate}
            handleChange={handleStart}
          />
          <InputDate
            fieldName="endDate"
            style={{ marginLeft: 40 }}
            label={t(['screens.stats.endDate', ''])}
            value={endDate}
            handleChange={handleEnd}
          />
        </div>
      </div>
      <div className="card bg-light  screenBlock fill" style={{ overflow: 'scroll' }}>
        <div className="card-body d-flex p-2 pl-4 fill">
          <div className="row fill" style={{ height: '100%', overflow: 'scroll', paddingBottom: 100 }}>
            <div className="list-group fill col-4 pb-2" style={{ overflow: 'scroll' }}>
              <InputSelect
                fieldName={'cityId'}
                value={cityId}
                values={props.cities}
                itemToId={it => it.id}
                itemToTitle={it => it.name.valueForLocale(locale)}
                label={t(['screens.stats.city', ''])}
                handleChange={handleCityChange}
              />
              {matchingScenarios
                /* $FlowFixMe: Object.values */
                && Object.values(matchingScenarios).map((element: ScenarioInfo) => renderListButton(element))}
            </div>
            <div className="col-8 row">
              <div className="col-6">
                <div className="big-stat">{t(['screens.stats.totalSales', ''])}</div>
                <div className="big-stat">{`${totalSales} ${t(['screens.stats.salesCount', ''])}`}</div>
                <div className="medium-stat">{`${periodTotalSales} ${t(['screens.stats.periodSales', ''])}`}</div>
                <div className="medium-stat">{`${prevPeriodTotalSales} ${t([
                  'screens.stats.previousPeriodSales',
                  '',
                ])}`}</div>
              </div>
              <div className="col-6">
                <DoughnutGraph
                  title={t(['screens.stats.salesPerPricing', ''])}
                  data={saleGlobalDataset}
                  labels={saleGlobalLabels}
                />
              </div>
              <div className="col-12 col-xxl-6">
                <MonitoringLineGraph
                  xUnit={period}
                  minDate={startDate}
                  maxDate={endDate}
                  id="salesGraph"
                  title={t(['screens.stats.periodSales', ''])}
                  datasets={saleDataset}
                />
              </div>
              <div className="col-6">
                <div className="big-stat">{t(['screens.stats.playedTitle', ''])}</div>
                <div className="big-stat">{`${totalPlayed} ${t(['screens.stats.playedTotal', ''])}`}</div>
                <div className="medium-stat">{`${periodTotalPlayed} ${t(['screens.stats.playedOverPeriod', ''])}`}</div>
                <div className="medium-stat">{`${prevPeriodTotalPlayed} ${t(
                  'screens.stats.playedOverPreviousPeriod',
                )}`}</div>
              </div>
              <div className="col-12 col-xxl-6">
                <MonitoringLineGraph
                  xUnit={period}
                  minDate={startDate}
                  maxDate={endDate}
                  id="successGraph"
                  title={t(['screens.stats.successsRateGraph', ''])}
                  datasets={successDataset}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      {isLoading && <Loader />}
    </div>
  );
};

const sortCities = (a: City, b: City) => a.id.localeCompare(b.id, 'fr');

const mapStateToProps = state => ({
  locale: state.preferences.editionLocale,
  cities: Object.values(state.configuration.availableCities).sort(sortCities),
});

const mapDispatchToProps = {
  deployScenarioAsync: FirebaseHelper.deployScenarioAsync,
  checkScenarioVersionAsync: FirebaseHelper.checkScenarioVersionAsync,
  deployToInternalAsync: ScenarioServiceHelper.deployToInternalAsync,
  getScenarioSalesStatistics: FirebaseHelper.getScenarioSalesStatistics,
  getCitySalesStatistics: FirebaseHelper.getCitySalesStatistics,
  getScenarioSuccessStatistics: FirebaseHelper.getScenarioSuccessStatistics,
};

export default compose(
  withCities,
  withConfirm,
  withFirebase,
  withTranslation('default'),
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
)(StatisticsTab);
