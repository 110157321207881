/* @flow */

import type { ReduxDispatch } from 'redux';
import * as Globals from 'src/constants/globals';
import { EventsServiceHelper, NotificationTypes } from 'src/store/events';
import { FirebaseSingleton } from 'src/services/Firebase';
import { asyncForEach } from 'src/utils';
import type { NPCReducerState } from './NPCReducer';
import type { TranslationItemsType } from '../ScenarioServiceHelper';
import * as actions from './actions';
import NPC from '../../../data/NPC';

const logHelperCall = (title, args) => {
  if (Globals.__DEV__) {
    console.log(`################# NpcServiceHelper / ${title}`, args);
  }
};

// CLEANUP
// *********************
export type cleanupType = () => ReduxDispatch => void;
export const cleanup: cleanupType = () => (dispatch) => {
  logHelperCall('cleanup');
  dispatch(actions.cleanup());
};

// INIT
// *********************
const updateFirebaseEditorData = (scenarioId: string, npcId: string, npc?: NPC) => async (dispatch) => {
  try {
    if (npc) {
      const firebaseContent = npc.serializeForFirebase(false);
      FirebaseSingleton.scenario(scenarioId)
        .child(`npcs/${npcId}`)
        .set(firebaseContent);
    } else {
      FirebaseSingleton.scenario(scenarioId)
        .child(`npcs/${npcId}`)
        .remove();
    }
    EventsServiceHelper.addNotif(NotificationTypes.SUCCESS, 'S_SCENARIO_NPC_SAVED_IN_DB', npcId)(dispatch);
  } catch (error) {
    EventsServiceHelper.addNotif(NotificationTypes.ERROR, 'E_SCNEARIO_NPC_SAVE_FAILED', error.message)(dispatch);
  }
};

export type createNPCType = (
  scenarioId: string,
  values: any,
  saveInFirebase?: boolean,
) => ReduxDispatch => Promise<void>;
export const createNPC: createNPCType = (scenarioId, values, saveInFirebase = false) => async (dispatch) => {
  logHelperCall('createNPC', { scenarioId, values });
  dispatch(actions.createNpc(values, !saveInFirebase));
  const npc = new NPC(values);
  // if (saveInFirebase) {
  //   await updateFirebaseEditorData(scenarioId, npc.id, npc)(dispatch);
  // }
};

export type updateNPCAsyncType = (scenarioId: string, id: string, npc: NPC) => ReduxDispatch => Promise<void>;
export const updateNPCAsync: updateNPCAsyncType = (scenarioId, id, npc) => async (dispatch) => {
  logHelperCall('updateNPCAsync', { scenarioId, id, npc });
  dispatch(actions.updateNPCAsync(id, npc));
  EventsServiceHelper.addNotif(NotificationTypes.SUCCESS, 'S_ITEM_PERSISTED')(dispatch);
  // await updateFirebaseEditorData(scenarioId, npc.id, npc)(dispatch);
};

type applyTranslationsType = (
  scenarioId: string,
  npcs: NPCReducerState,
  translations: TranslationItemsType,
) => (dispatch: ReduxDispatch) => Promise<void>;
export const applyTranslations: applyTranslationsType = (scenarioId, npcs, translations) => async (dispatch) => {
  logHelperCall('applyTranslations', { npcs, translations });
  await asyncForEach(npcs.npcs, async (npc) => {
    const npcTranslations = translations[npc.id];
    if (npcTranslations) {
      npc.applyTranslations(npcTranslations);
      await updateNPCAsync(scenarioId, npc.id, npc)(dispatch);
    }
  });
};

export type removeNPCType = (scenarioId: string, id: string) => ReduxDispatch => Promise<void>;
export const removeNPC: removeNPCType = (scenarioId, id) => async (dispatch) => {
  logHelperCall('removeNPC', id);
  dispatch(actions.removeNPC(id));
  // await updateFirebaseEditorData(scenarioId, id)(dispatch);
};

// IMPORT
// *********************
export type importNPCSType = (
  scenarioId: string,
  data: any,
  saveInFirebase?: boolean,
) => ReduxDispatch => Promise<void>;
export const importNPCS: importNPCSType = (scenarioId, data, saveInFirebase = false) => async (dispatch) => {
  logHelperCall('importNPCS', { data });
  cleanup()(dispatch);
  if (data) {
    await asyncForEach(Object.keys(data), async (npcId) => {
      const npc = { id: npcId, ...data[npcId] };
      await createNPC(scenarioId, npc, saveInFirebase)(dispatch);
    });
  }
};

// EXPORT
// *********************
export type exportNPCSType = (state: NPCReducerState) => any;
export const exportForApp: exportNPCSType = (state) => {
  logHelperCall('exportForApp', { state });
  const res = {};
  state.npcs.forEach((npc) => {
    res[npc.id] = npc.serializeForApp();
  });
  return res;
};

export const exportForEditor: exportNPCSType = (state) => {
  logHelperCall('exportForEditor', { state });
  const res = {};
  state.npcs.forEach((npc) => {
    res[npc.id] = npc.serialize();
  });
  return res;
};

export const exportTranslations: exportNPCSType = (state) => {
  logHelperCall('exportTranslations');
  let res = [];
  state.npcs.forEach((item) => {
    res = res.concat(item.getLocalizedStringToTranslateWithPath());
  });
  return res;
};
