/* @flow */
import { Scenario } from 'src/data';
import { getPointsDistance, asyncForEach } from 'src/utils';
import { CatalogTypes } from '../types';
import type { CatalogType } from '../types';
import { Singleton as firebase } from '../Firebase';
import {
  pushEditorAssetAsync,
  pushReleaseAssetAsync,
  downloadFileAsync,
  getEditorAssetUrlAsync,
  getNextVersionAsync,
} from './common';
import type {
  pushEditorAssetType,
  pushReleaseAssetAsyncType,
  downloadAssetAsyncType,
  getNextVersionAsyncType,
  getEditorAssetUrlAsyncType,
} from './common';

/* *********************
 * Scenario
 */

export const pushScenarioEditorAssetAsync: pushEditorAssetType = async (scenarioId, filename, file: File) => {
  const scenarioStorage = firebase.scenarioEditorStorage(scenarioId);
  return pushEditorAssetAsync(scenarioStorage, filename, file);
};

export const pushScenarioReleaseAsset: pushReleaseAssetAsyncType = async (
  scenarioId,
  version,
  subfolder,
  filename,
  file,
  strContent,
  isPublic,
) => {
  const scenarioFolder = firebase.scenarioStorage(scenarioId);
  return pushReleaseAssetAsync(scenarioFolder, version, subfolder, filename, file, strContent, isPublic);
};

export const getScenarioEditorAssetUrlAsync: getEditorAssetUrlAsyncType = async (scenarioId, filename) => getEditorAssetUrlAsync(firebase.scenarioEditorStorage(scenarioId), filename);

export const downloadScenarioAsset: downloadAssetAsyncType = async (scenarioId, filename) => {
  console.log('Download editor asset ', scenarioId, filename);
  const url = await getScenarioEditorAssetUrlAsync(scenarioId, filename);
  return downloadFileAsync(url);
};

export const getScenarioNextVersionAsync: getNextVersionAsyncType = scenarioId => getNextVersionAsync(firebase.scenarioData(scenarioId));

export const getAllScenariosAsync = async (catalog: CatalogType = CatalogTypes.prod) => {
  const scenariosSnapshot = await firebase.scenariosHeader(catalog).once('value');

  if (scenariosSnapshot.exists()) {
    const scenarios = {};
    scenariosSnapshot.forEach((scenarioSnapshot) => {
      scenarios[scenarioSnapshot.key] = new Scenario(scenarioSnapshot.val());
    });
    return scenarios;
  }

  return {};
};

export type updateScenarioSiblingsType = (
  scenarioId: string,
  startPoint: { latitude: number, longitude: number },
  radiusKm: number,
  catalogType: CatalogType,
  firebase: Firebase,
  dryRun: boolean,
  updateSiblings?: boolean,
  knownDistances?: { scenarioIds: string[], distance: number }[],
) => Promise<string[]>;
export const updateScenarioSibling: updateScenarioSiblingsType = async (
  scenarioId,
  startPoint,
  radiusKm = 100,
  catalogType,
  firebase,
  dryRun,
  updateSiblings = true,
  knownDistances = [],
) => {
  const allScenariosSnap = await firebase.scenariosHeader(catalogType).once('value');
  const allScenarios = allScenariosSnap.exists() ? allScenariosSnap.val() : {};

  const scenariosDistances: { scenarioIds: string[], distance: number }[] = [...knownDistances];
  Object.keys(allScenarios).forEach((otherId) => {
    const alreadyCalculated = scenariosDistances.find(
      it => (it.scenarioIds[0] === otherId && it.scenarioIds[1] === scenarioId)
        || (it.scenarioIds[0] === scenarioId && it.scenarioIds[1] === otherId),
    );
    if (!alreadyCalculated) {
      let dist;
      if (scenarioId === otherId) {
        dist = 0;
      } else {
        dist = getPointsDistance(allScenarios[scenarioId].startPoint, allScenarios[otherId].startPoint);
      }
      scenariosDistances.push({ scenarioIds: [scenarioId, otherId], distance: dist });
    }
  });

  let scenariosToUseIds = scenariosDistances
    .filter(it => it.scenarioIds.includes(scenarioId) && it.distance <= radiusKm)
    .sort((left, right) => left.distance - right.distance)
    .map(it => (it.scenarioIds[1] === scenarioId ? it.scenarioIds[0] : it.scenarioIds[1]));

  let lastScenarioId = scenariosToUseIds.shift();
  const orderedSiblings = [lastScenarioId];
  const filterFunc = it => it !== lastScenarioId;
  const reducer = (acc, cur) => (cur.distance < acc.distance ? cur : acc);
  while (scenariosToUseIds.length) {
    const distancesToLast: { scenarioId: string, distance: number }[] = [];
    // eslint-disable-next-line no-loop-func
    scenariosToUseIds.forEach((rightId) => {
      const alreadyCalculated = scenariosDistances.find(
        it => (it.scenarioIds[0] === rightId && it.scenarioIds[1] === lastScenarioId)
          || (it.scenarioIds[0] === lastScenarioId && it.scenarioIds[1] === rightId),
      );
      if (!alreadyCalculated) {
        let dist;
        if (lastScenarioId === rightId) {
          dist = 0;
        } else {
          dist = getPointsDistance(allScenarios[lastScenarioId].startPoint, allScenarios[rightId].startPoint);
        }
        scenariosDistances.push({ scenarioIds: [lastScenarioId, rightId], distance: dist });
        distancesToLast.push({ scenarioId: rightId, distance: dist });
      } else {
        distancesToLast.push({ scenarioId: rightId, distance: alreadyCalculated.distance });
      }
    });
    lastScenarioId = distancesToLast.reduce(reducer).scenarioId;
    orderedSiblings.push(lastScenarioId);
    scenariosToUseIds = scenariosToUseIds.filter(filterFunc);
  }

  if (!dryRun) {
    await firebase.scenarioSiblings(scenarioId, catalogType).set(orderedSiblings);
  }
  if (updateSiblings) {
    await asyncForEach(orderedSiblings, async (id) => {
      if (id !== scenarioId) {
        await updateScenarioSibling(
          id,
          allScenarios[id].startPoint,
          radiusKm,
          catalogType,
          firebase,
          dryRun,
          false,
          scenariosDistances,
        );
      }
    });
  }
  return orderedSiblings;
};

export const translateString = async (text: string, sourceLocale: string, destLocale: string) => {
  const res = await firebase.translateString(text, sourceLocale, destLocale);

  const translation = res.data[0];
  return translation ? translation.translatedText : '';
};
