/* @flow */
import BaseItem, { ItemTypes } from './BaseItem';

/* ***************************
  CLASS
*************************** */
export type AppCheckpoint = {};

export default class CheckpointItem extends BaseItem {
  constructor(json: any) {
    if (json instanceof CheckpointItem) {
      super(json, true);
    } else {
      super(json, false);
    }
    const { type, meta, ...newMeta } = json;
    this.type = ItemTypes.Checkpoint;
    this.setMeta({ ...json.meta, ...newMeta });
  }
}
