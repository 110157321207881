/* @flow */
import BaseItem, { ItemTypes } from './BaseItem';
import LocalizedString from './LocalizedString';
import LocalizedFile from './LocalizedFile';
import LocalizedStringArray from './LocalizedStringArray';
import type { ObjectMap } from './Shortcuts';

/* ***************************
  CLASS
*************************** */
export type AppDocument = {};

export type DocumentItemLock = {
  numberOfChar: number,
  inputType: string,
  solution: string,
};
export default class DocumentItem extends BaseItem {
  images: LocalizedFile[];

  lockedImage: LocalizedFile;

  name: LocalizedString;

  additionalInfo: ?LocalizedString;

  origin: ?LocalizedString;

  locks: DocumentItemLock[];

  timeOnError: number;

  toolId: string;

  constructor(json: any) {
    if (json instanceof DocumentItem) {
      super(json, true);
    } else {
      super(json, false);
    }
    const {
      type,
      meta,
      imageNames,
      images,
      lockedImage,
      name,
      additionalInfo,
      origin,
      locks,
      timeOnError,
      toolId,
      ...newMeta
    } = json;
    this.images = [];
    if (images) {
      this.images = images.map(img => new LocalizedFile(this.id, undefined, img));
    }
    this.lockedImage = new LocalizedFile(this.id, 'locked', lockedImage);
    this.name = new LocalizedString(`${this.id}_name`, name);
    this.additionalInfo = new LocalizedString(`${this.id}_additionalInfo`, additionalInfo);
    this.origin = new LocalizedString(`${this.id}_origin`, origin);
    this.locks = locks ? this.parseLocks(locks) : [];
    this.timeOnError = timeOnError;
    this.toolId = toolId;
    this.type = ItemTypes.Document;
    this.setMeta({ ...json.meta, ...newMeta });
    this.updateSelfUnlockedStates();
  }

  // eslint-disable-next-line class-methods-use-this
  parseLocks(locks: DocumentItemLock[]) {
    return locks.map((it: DocumentItemLock) => {
      const res: DocumentItemLock = { ...it };
      if (typeof it.numberOfChar === 'string') {
        res.numberOfChar = parseInt(it.numberOfChar, 10);
      }
      return res;
    });
  }

  updateSelfUnlockedStates() {
    const idsToRemove = Object.keys(this.unlockedValues).filter(key => key.startsWith(`${this.nodeId}_lock`));
    idsToRemove.forEach((key) => {
      delete this.unlockedValues[key];
    });
    if (this.locks && this.locks.length) {
      this.locks.forEach((lock, index) => {
        const key = `${this.nodeId}_lock${index}`;
        this.unlockedValues[key] = index;
      });
    }
  }

  serializeInheritedFieldsForApp() {
    const res = super.serializeInheritedFieldsForApp();
    res.images = this.images.map(it => it.serializeForApp());
    if (this.lockedImage && this.lockedImage.hasFile()) {
      res.lockedImage = this.lockedImage.serializeForApp();
    }
    res.name = this.name && this.name.serializeForApp();
    if (this.additionalInfo) {
      res.additionalInfo = this.additionalInfo && this.additionalInfo.serializeForApp();
    }
    if (this.origin) {
      res.origin = this.origin && this.origin.serializeForApp();
    }
    if (this.locks && this.locks.length) {
      res.locks = this.locks;
    }
    if (this.locks) {
      res.locks = this.locks;
    }
    if (this.timeOnError !== undefined) {
      res.timeOnError = this.timeOnError;
    }
    if (this.toolId) {
      res.toolId = this.toolId;
    }
    return res;
  }

  serializeInheritedFields() {
    const res = super.serializeInheritedFields();
    res.images = this.images.map(it => it.serialize());
    res.lockedImage = this.lockedImage && this.lockedImage.serialize();
    res.name = this.name && this.name.serialize();
    res.additionalInfo = this.additionalInfo && this.additionalInfo.serialize();
    res.origin = this.origin && this.origin.serialize();
    res.locks = this.locks;
    res.timeOnError = this.timeOnError;
    res.toolId = this.toolId;
    return res;
  }

  checkRelease(items: ObjectMap<BaseItem>, locales: string[]) {
    const res = super.checkRelease(items, locales);

    if (!this.images.length) {
      res.push({
        level: 'error',
        item: this.id,
        message: 'E_DOCUMENT_WITHOUT_IMAGE',
      });
    } else {
      console.log(this.images);
    }
    if (this.locks) {
      this.locks.forEach((lock) => {
        if (!lock.solution || !lock.solution.trim().length) {
          res.push({
            level: 'error',
            item: this.id,
            message: 'E_DOCUMENT_LOCK_SOLUTION',
            details: { type: 'empty', value: lock.solution },
          });
        }
      });
    }
    return res;
  }

  getLocalizedStringsWithPath() {
    const res: { [path: string]: LocalizedString | LocalizedStringArray } = super.getLocalizedStringsWithPath();
    res.name = this.name;
    if (this.additionalInfo) {
      res.additionalInfo = this.additionalInfo;
    }
    if (this.origin) {
      res.origin = this.origin;
    }
    return res;
  }

  getLocalizedFilesWithPath() {
    const res: { [path: string]: LocalizedFile } = super.getLocalizedFilesWithPath();
    this.images.forEach((img, index) => {
      res[`images.${index}`] = img;
    });
    if (this.lockedImage.hasFile()) {
      res.lockedImage = this.lockedImage;
    }
    return res;
  }
}
