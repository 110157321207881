/* eslint-disable import/prefer-default-export */
/* @flow */
import { EventsServiceHelper, NotificationTypes } from 'src/store/events';
import type { ReduxDispatch } from 'redux';
import type { ScenarioVendingInfo } from 'src/data';
import { generateId } from 'src/data/AtlObject';
import * as Globals from 'src/constants/globals';
import Firebase, { Singleton as firebase } from '../Firebase';

// Migrations

const _getAllUsersGroupedAsync = async (groupBy: number = 100) => {
  const usersSnapshot = await firebase.users().once('value');

  let currentCounter = 0;
  let tempArray = [];
  const userIds = [];
  usersSnapshot.forEach((userSnapshot) => {
    const userId = userSnapshot.key;
    if (currentCounter < groupBy) {
      tempArray.push(userId);
      currentCounter += 1;
    } else {
      userIds.push(tempArray);
      tempArray = [];
      currentCounter = 0;
    }
  });

  userIds.push(tempArray);

  return userIds;
};

export type migrateAllUsersAsyncType = (
  dryRun: boolean,
  removeOldData: boolean,
  progressChanged: ?(number) => any,
) => (dispatch: ReduxDispatch) => Promise<any>;

export const migrateAllUsersAsync: migrateAllUsersAsyncType = (
  dryRun = true,
  removeOldData = false,
  progressChanged,
) => async (dispatch) => {
  try {
    const users = await _getAllUsersGroupedAsync(firebase);
    if (users) {
      const total = users.length;

      let currentProgress = 0;

      const promises = users.map(userIds => firebase.migrateUsersAsync(userIds, dryRun, removeOldData).then(() => {
        currentProgress += 1;
        const percent = currentProgress / total;
        if (progressChanged) {
          progressChanged(percent);
        }

        if (percent !== 1) {
          EventsServiceHelper.addNotif(NotificationTypes.SUCCESS, 'S_USERS_MIGRATION_PROGRESS', `${percent * 100}%`)(
            dispatch,
          );
        }
      }));

      await Promise.all(promises);
      EventsServiceHelper.addNotif(NotificationTypes.SUCCESS, 'S_USERS_MIGRATED')(dispatch);
    }
  } catch (error) {
    console.error(error.details);
    EventsServiceHelper.addNotif(NotificationTypes.ERROR, 'E_USERS_MIGRATED', error.message, 0)(dispatch);
  }
};

// Code
export type Code = {
  id?: string,
  scenarioId: string,
  startDate?: Date,
  endDate?: Date,
  currentCount: number,
  maxNumber: number,
  _meta?: {
    info?: string,
  },
};

export type CodeConfiguration = {
  id: string,
  name: string,
  scenarioId: string,
  startDate?: Date,
  template: string,
  endDate?: Date,
  maxNumber: number,
  codeCount: number,
  _meta?: {
    info?: string,
  },
};

export type reloadCodeConfigurationsType = () => Promise<CodeConfiguration[]>;

export const reloadCodeConfigurations: reloadCodeConfigurationsType = async () => {
  console.log('Reload code configurations list');
  const snapshot = await firebase.codeConfigurations().once('value');
  const res = [];
  if (snapshot.exists()) {
    const val = snapshot.val();
    Object.keys(val).forEach((codeId) => {
      res.push({ index: codeId, ...val[codeId] });
    });
  }
  return res;
};

export type generateCodeConfigurationType = (
  template?: string,
  scenarioId: string,
  startDate: number,
  endDate: number,
  maxNumber: number,
  codeCount: number,
  info: string,
  codeType: string,
) => ReduxDispatch => Promise<void>;

export const generateCodeConfiguration: generateCodeConfigurationType = (
  template,
  scenarioId,
  startDate,
  endDate,
  maxNumber,
  codeCount,
  info,
  codeType,
) => async (dispatch) => {
  console.log('Generating new code configuration', startDate, endDate);
  const obj = {
    id: generateId(),
    name: info,
    template,
    scenarioId,
    startDate: startDate && startDate.getTime(),
    endDate: endDate && endDate.getTime(),
    maxNumber,
    info,
    codeType,
    codeCount,
  };
  Object.keys(obj).forEach(key => obj[key] === undefined && delete obj[key]);
  await firebase.codeConfigurations().push(obj);
  EventsServiceHelper.addNotif(NotificationTypes.SUCCESS, 'S_CODE_CONFIGURATION_CREATED', undefined, 0)(dispatch);
};

export type generateCodeType = (
  scenarioId: string,
  template: string,
  startDate?: Date,
  endDate?: Date,
  maxNumber?: number,
  codeCount: number,
  info?: string,
  codeType?: string,
  configurationId?: string,
) => ReduxDispatch => Promise<string[]>;
export const generateCode: generateCodeType = (
  scenarioId,
  template,
  startDate,
  endDate,
  maxNumber,
  codeCount,
  info,
  codeType,
  configurationId,
) => async (dispatch) => {
  console.log('Reload code list');
  let res;
  let codes;
  if (codeCount === 1) {
    res = await firebase.generateSecretCode(
      template,
      scenarioId,
      startDate ? startDate.getTime() : undefined,
      endDate ? endDate.getTime() : undefined,
      maxNumber,
      {
        info,
        codeType,
        fromEditor: true,
        configurationId,
      },
    );
    EventsServiceHelper.addNotif(NotificationTypes.SUCCESS, 'S_CODE_CREATED', res.data.code, 0)(dispatch);
    codes = [res.data.code];
  } else {
    res = await firebase.bulkGenerateSecretCodes(
      template,
      scenarioId,
      startDate ? startDate.getTime() : undefined,
      endDate ? endDate.getTime() : undefined,
      maxNumber,
      codeCount,
      {
        info,
        fromEditor: true,
        configurationId,
      },
    );
    EventsServiceHelper.addNotif(NotificationTypes.SUCCESS, 'S_CODE_CREATED', res.data.codes.join(', '), 0)(dispatch);
    codes = res.data.codes;
  }
  return codes;
};

export type sendSecretCodesLabelsType = (
  codes: Code[],
  configurationId: string,
  recipients: string[],
  expirationDate: number,
) => ReduxDispatch => Promise<void>;
export const sendSecretCodesLabels: sendSecretCodesLabelsType = (
  codes,
  configurationId,
  recipients,
  expirationDate,
) => async (dispatch) => {
  try {
    await firebase.sendSecretCodesLabels(codes, configurationId, recipients, expirationDate);
    EventsServiceHelper.addNotif(NotificationTypes.SUCCESS, 'S_CODE_SENT_VIA_EMAIL', recipients.join(', '), 0)(
      dispatch,
    );
  } catch (error) {
    EventsServiceHelper.addNotif(NotificationTypes.ERROR, 'E_CODE_SENT_FAILED', error.message)(dispatch);
  }
};

// CEs
export type CE = {
  id: string,
  domains: string[],
  discount?: number,
};
export type reloadCEsType = () => Promise<CE[]>;

export const reloadCEs: reloadCEsType = async () => {
  console.log('Reload CE list');
  const snapshot = await firebase.ces().once('value');
  const res = [];
  if (snapshot.exists()) {
    const val = snapshot.val();
    const { _domains, ...ces } = val;
    Object.keys(_domains).forEach((domain) => {
      const ce = ces[_domains[domain]];
      if (ce.domains) {
        ce.domains.push(domain);
      } else {
        ce.domains = [domain];
      }
    });
    Object.keys(ces).forEach((id) => {
      res.push({ id, ...ces[id] });
    });
  }
  return res;
};

export type updateCeType = (id: string, domains: string[], discount: number) => ReduxDispatch => Promise<void>;
export const updateCe: generateCodeType = (id, domains, discount) => async (dispatch) => {
  console.log('Updating CE', id, domains, discount);

  // TODO

  EventsServiceHelper.addNotif(NotificationTypes.SUCCESS, 'S_CE_UPDATE', id)(dispatch);
};

// Prod deployment

export type bulkDeployAmsAsyncType = (amsIds: string[], dryRun?: boolean) => ReduxDispatch => Promise<void>;
export const bulkDeployAmsAsync: bulkDeployAmsAsyncType = (amsIds, dryRun) => async (dispatch) => {
  console.log('Deploy AMSs ', amsIds, dryRun);
  try {
    const res = await firebase.deployAMSsAsync(amsIds, dryRun);
    const result = res.data;
    let hasFailedItem = false;
    const failedItems = [];
    Object.keys(result).forEach((amsId) => {
      if (result[amsId].succeeded) {
        EventsServiceHelper.addNotif(
          dryRun ? NotificationTypes.DEBUG : NotificationTypes.SUCCESS,
          'S_AMS_DEPLOYED',
          amsId,
        )(dispatch);
      } else {
        EventsServiceHelper.addNotif(NotificationTypes.ERROR, 'E_AMS_DEPLOY_FAILED', amsId)(dispatch);
        hasFailedItem = true;
        failedItems.push(amsId);
      }
    });
    if (hasFailedItem) {
      EventsServiceHelper.addNotif(NotificationTypes.ERROR, 'E_BULK_AMS_DEPLOY_FAILED', failedItems)(dispatch);
    } else {
      EventsServiceHelper.addNotif(
        dryRun ? NotificationTypes.DEBUG : NotificationTypes.SUCCESS,
        'S_BULK_AMS_DEPLOYED',
        amsIds,
        0,
      )(dispatch);
    }
  } catch (error) {
    EventsServiceHelper.addNotif(
      dryRun ? NotificationTypes.DEBUG : NotificationTypes.SUCCESS,
      'E_AMS_DEPLOY_FAILED',
      amsIds,
      0,
    )(dispatch);
  }
};

export type deployScenarioAsyncType = (
  scenarioId: string,
  vendingInfo: ScenarioVendingInfo,
  removeOldReleasesAccess: boolean,
  dryRun?: boolean,
) => ReduxDispatch => Promise<void>;
export const deployScenarioAsync: deployScenarioAsyncType = (
  scenarioId,
  vendingInfo,
  removeOldReleasesAccess,
  dryRun,
) => async (dispatch) => {
  console.log('Deploy scenario ', scenarioId, dryRun, vendingInfo);
  try {
    const res = await firebase.deployScenarioAsync(scenarioId, vendingInfo, removeOldReleasesAccess, dryRun);
    const { scenario } = res.data;
    EventsServiceHelper.addNotif(
      dryRun ? NotificationTypes.DEBUG : NotificationTypes.SUCCESS,
      'S_SCENARIO_DEPLOYED',
      scenarioId,
      0,
    )(dispatch);
    if (scenario && scenario.amsUpdatesNeeded) {
      const amsToDeployIds = Object.keys(scenario.amsUpdatesNeeded);
      if (amsToDeployIds.length) {
        let title = 'screens.admin.ams.deploy';
        if (dryRun) {
          title = 'screens.admin.ams.deployDry';
        } else if (!Globals.hasEditor) {
          title = 'screens.admin.ams.deployLive';
        }
        EventsServiceHelper.addNotif(NotificationTypes.WARN, 'W_AMS_UPDATE_NEEDED', amsToDeployIds, 0, {
          title,
          callback: () => bulkDeployAmsAsync(amsToDeployIds, dryRun)(dispatch),
        })(dispatch);
      }
    }
  } catch (error) {
    EventsServiceHelper.addNotif(
      NotificationTypes.ERROR,
      'S_SCENARIO_DEPLOYED',
      `${error.details.error} : for ${scenarioId}`,
      0,
    )(dispatch);
  }
};

export type checkScenarioVersionAsyncType = (
  scenarioId: string,
  versionToCheck: ?string,
) => ReduxDispatch => Promise<void>;
export const checkScenarioVersionAsync: checkScenarioVersionAsyncType = (
  scenarioId,
  versionToCheck,
) => async (dispatch) => {
  console.log('Check version of scenario ', scenarioId, versionToCheck);
  try {
    const res = await firebase.checkScenarioVersionAsync(scenarioId, versionToCheck);
    const { success, details } = res.data;
    if (success) {
      EventsServiceHelper.addNotif(NotificationTypes.SUCCESS, 'S_SCENARIO_CHECK_SUCCESS', details, 0)(dispatch);
    } else {
      EventsServiceHelper.addNotif(NotificationTypes.ERROR, 'E_SCENARIO_CHECK_FAILED', details, 0)(dispatch);
      console.error(details);
    }
  } catch (error) {
    EventsServiceHelper.addNotif(NotificationTypes.ERROR, 'E_SCENARIO_CHECK_FAILED', error.message, 0)(dispatch);
  }
};

export type dupplicateScenarioAsyncType = (
  scenarioId: string,
  sourceId: string,
  cityId: string,
) => ReduxDispatch => Promise<void>;
export const dupplicateScenarioAsync: dupplicateScenarioAsyncType = (
  scenarioId,
  sourceId,
  cityId,
) => async (dispatch) => {
  console.log(`Creating a copy of ${sourceId} with id ${scenarioId}`);
  try {
    await firebase.dupplicateScenarioAsync(scenarioId, sourceId, cityId);
    EventsServiceHelper.addNotif(NotificationTypes.SUCCESS, 'S_SCENARIO_DUPPLICATE_SUCCESS', scenarioId, 0)(dispatch);
  } catch (error) {
    EventsServiceHelper.addNotif(NotificationTypes.ERROR, 'E_SCENARIO_DUPPLICATE_FAILED', error.message, 0)(dispatch);
  }
};

