/* @flow */
import type { LocaleType } from 'src/store/scenario/header/ScenarioLocaleManager';
import LocalizedString from './LocalizedString';
import LocalizedFile from './LocalizedFile';
import GameAreaItem from './GameAreaItem';
import BaseItem from './BaseItem';
import POIItem from './POIItem';
import AtlObject from './AtlObject';
import type { ObjectMap } from './Shortcuts';

export type ScenarioVendingInfo = {
  comingSoon: boolean,
  externalSeller: boolean,
  visible: boolean,

  isFree: boolean,
  iapSku?: ?string,
  price: number,
  promoExpirationDate?: ?number,
};

export type ScenarioVersion = {
  scenarioVersion: string,
};

export type ScenarioEngineVersion = {
  [engineVersion: number]: ScenarioVersion,
};

// TODO: Change Generic Type
export default class Scenario extends AtlObject<any> {
  cityId: string;

  name: LocalizedString;

  startItemId: string;

  difficulty: number;

  durationMin: number;

  durationMax: number;

  distanceMin: number;

  distanceMax: number;

  subtitle: LocalizedString;

  pitch: LocalizedString;

  missionText: LocalizedString;

  startPointName: LocalizedString;

  startPointDescr: LocalizedString;

  accessWheelchair: boolean;

  cover: LocalizedFile;

  partners: LocalizedFile[];

  hasLightCover: boolean;

  needCar: boolean;

  ageMin: number;

  cityKnowledge: boolean;

  gameArea: GameAreaItem;

  lastVersion: string;

  versionPerEngine: ScenarioEngineVersion;

  managedLocales: LocaleType[];

  vendingInfo: ScenarioVendingInfo;

  isSchool: boolean;

  meta: any;

  constructor(json: any) {
    super(json);
    const {
      id,
      cityId,
      name,
      startItemId,
      coverFileName,
      contentFileName,
      zipFileName,
      difficulty,
      durationMax,
      durationMin,
      distanceMin,
      distanceMax,
      subtitle,
      pitch,
      cover,
      partners,
      hasLightCover,
      missionText,
      startPoint,
      startPointName,
      startPointDescr,
      accessWheelchair,
      needCar,
      ageMin,
      cityKnowledge,
      gameArea,
      meta,
      lastVersion,
      scenarioVersion,
      versionPerEngine,
      managedLocales,
      vendingInfo,
      isSchool,
      ...meta2
    } = json;
    const baseArea = gameArea || {};
    this.cityId = cityId || '';
    this.name = new LocalizedString(`${id}_name`, name);
    this.cover = new LocalizedFile(id, 'cover', cover, true);

    this.partners = [];
    if (partners) {
      this.partners = partners.map(img => new LocalizedFile(id, 'partners', img, true));
    }
    this.hasLightCover = hasLightCover || false;
    this.startItemId = startItemId || 'start_poi';
    this.difficulty = difficulty;
    this.durationMin = durationMin;
    this.durationMax = durationMax;
    this.distanceMin = distanceMin;
    this.distanceMax = distanceMax;
    this.subtitle = new LocalizedString(`${this.id}_subtitle`, subtitle);
    this.pitch = new LocalizedString(`${this.id}_pitch`, pitch);
    this.missionText = new LocalizedString(`${this.id}_missionText`, missionText);
    this.startPointName = new LocalizedString(`${this.id}_startPointName`, startPointName, !isSchool);
    this.startPointDescr = new LocalizedString(`${this.id}_startPointDescr`, startPointDescr, !isSchool);
    this.accessWheelchair = accessWheelchair !== '' ? accessWheelchair : false;
    this.needCar = needCar !== '' ? needCar : false;
    this.ageMin = ageMin;
    this.cityKnowledge = cityKnowledge !== '' ? cityKnowledge : false;
    this.gameArea = baseArea.area ? new GameAreaItem(baseArea) : new GameAreaItem({ id: 'gameZone', area: gameArea });
    this.lastVersion = lastVersion;
    this.versionPerEngine = versionPerEngine ? { ...versionPerEngine } : {};
    this.managedLocales = managedLocales ? [...managedLocales] : ['fr', 'en'];
    this.vendingInfo = vendingInfo ? { ...vendingInfo } : {};
    this.isSchool = isSchool !== '' ? isSchool : false;
    this.meta = { ...meta, ...meta2 };
    delete this.meta.iapSku;
    delete this.meta.comingSoon;
    delete this.meta.price;
  }

  updateSku() {
    this.vendingInfo.iapSku = `com.magnitudelabs.atlantide.${this.id}`;
  }

  serializeInheritedFieldsForApp(items?: ObjectMap<BaseItem>) {
    let startPoi: ?POIItem;
    if (items && items[this.startItemId] && items[this.startItemId] instanceof POIItem) {
      startPoi = items && items[this.startItemId];
    }

    // Recalculate vendingInfo (with isFree flag)
    const vendingInfo = { ...this.vendingInfo };
    if (vendingInfo.price === 0) {
      vendingInfo.iapSku = undefined;
    }

    const res = {
      cityId: this.cityId,
      name: this.name.serializeForApp(),
      startItemId: this.startItemId,
      cover: this.cover.serializeForApp(),
      hasLightCover: this.hasLightCover,
      partners: this.partners.map(it => it.serializeForApp()),
      difficulty: this.difficulty,
      durationMin: this.durationMin,
      durationMax: this.durationMax,
      distanceMin: this.distanceMin,
      distanceMax: this.distanceMax,
      subtitle: this.subtitle.serializeForApp(),
      pitch: this.pitch.serializeForApp(),
      missionText: this.missionText.serializeForApp(),
      startPoint: startPoi ? { ...startPoi.coordinate } : undefined,
      startPointName: this.startPointName.serializeForApp(),
      startPointDescr: this.startPointDescr.serializeForApp(),
      accessWheelchair: this.accessWheelchair,
      needCar: this.needCar,
      ageMin: this.ageMin,
      cityKnowledge: this.cityKnowledge,
      gameArea: this.gameArea && [...this.gameArea.area],
      lastVersion: this.lastVersion,
      versionPerEngine: { ...this.versionPerEngine },
      managedLocales: [...this.managedLocales],
      isSchool: this.isSchool || false,
      vendingInfo,
    };
    if (this.isSchool) {
      res.startPoint = {
        latitude: 0,
        longitude: 0,
      };
      res.cityId = 'sch';
      delete res.startPointName;
      delete res.startPointDescr;
    }
    return res;
  }

  serializeInheritedFields(items?: ObjectMap<BaseItem>) {
    const res = this.serializeInheritedFieldsForApp(items);
    res.cover = this.cover.serialize();
    res.partners = this.partners.map(it => it.serialize());
    return res;
  }

  getLocalizedStringsWithPath() {
    const res = super.getLocalizedStringsWithPath();
    res.name = this.name;
    res.subtitle = this.subtitle;
    res.pitch = this.pitch;
    res.missionText = this.missionText;
    res.startPointName = this.startPointName;
    res.startPointDescr = this.startPointDescr;
    return res;
  }

  getLocalizedFilesWithPath() {
    const res = {
      ...super.getLocalizedFilesWithPath(),
      cover: this.cover,
    };
    this.partners.forEach((img) => {
      res[`partners.${img.index}`] = img;
    });
    return res;
  }

  // eslint-disable-next-line class-methods-use-this
  getTranslationCsvIdPrefix() {
    return 'header';
  }
}
