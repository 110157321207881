/* @flow */
import React from 'react';
import { connect } from 'react-redux';

import 'bootstrap/dist/css/bootstrap.min.css';
import { withAuthorization, AuthenticatedCondition } from 'src/services/Session';
import { Claims } from 'src/constants/roles';
import { withUrlProperties } from 'src/pages/components/WithUrlProperties';
import { MapEditionWidget } from 'src/pages/components';
import { City } from 'src/data';
import { withCities } from './WithCities';
import CityInput from './CityInput';

export type CitiesEditionScreenProps = {
  editionLocale: string,
  cities: { [id: string]: City },
  mapAdaptedCities: any, // TODO type
  t: (key: string[]) => string,
};

type State = {
  currentCityId?: string,
  currentMarker?: number[],
  leftColumnClass: string,
  isLoading: boolean,
};

class CitiesEditionScreen extends React.PureComponent<CitiesEditionScreenProps, State> {
  static defaultProps = {};

  state = {
    currentCityId: undefined,
    currentMarker: undefined,
    leftColumnClass: 'col-8',
    isLoading: false,
  };

  componentWillMount() {}

  itemSelected = (id?: string) => {
    this.setState({
      currentCityId: id,
    });
  };

  markerChanged = (id, point) => {
    this.setState({ currentMarker: point });
  };

  onMarkerDragged = (id: string, lat: number, lng: number, alt: ?number) => {
    const pos = [lat, lng];
    if (alt !== undefined && alt !== null) {
      pos.push(alt);
    }
    this.setState({ currentCityId: id, currentMarker: pos });
  };

  markerAdded = (point) => {
    const pos = [point.lat(), point.lng()];
    this.setState({ currentMarker: pos, currentCityId: '' });
  };

  // eslint-disable-next-line class-methods-use-this
  render() {
    const { mapAdaptedCities } = this.props;
    const { currentCityId, leftColumnClass, currentMarker } = this.state;
    return (
      <div className="pageContainer">
        <div className="row fill mr-0">
          <div className={leftColumnClass} id="leftColumn">
            <div className="container-fluid fill background-primary" id="graphContainer">
              <MapEditionWidget
                currentItemId={currentCityId}
                itemClicked={this.itemSelected}
                markerChanged={this.markerChanged}
                onMarkerDragged={this.onMarkerDragged}
                markerAdded={this.markerAdded}
                itemsMap={mapAdaptedCities}
                markerFilter={() => true}
                prefZoom={5}
              />
            </div>
          </div>
          <div className="col" id="rightColumn">
            <div className="container-fluid no-margin" id="editionContainer">
              <CityInput cityId={currentCityId} marker={currentMarker} />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const mapCities = {};
  state.configuration.availableCities.forEach((city) => {
    mapCities[city.id] = {
      ...city,
      coordinate: city.centerCoordinate,
      distanceMinToTrigger: city.radius,
      type: 'CITY',
    };
  });
  return {
    editionLocale: state.preferences.editionLocale,
    cities: state.configuration.availableCities,
    mapAdaptedCities: mapCities,
  };
};

const mapDispatchToProps = {};

export default withUrlProperties(
  withCities(
    withAuthorization(AuthenticatedCondition, [Claims.Moderator])(
      connect(
        mapStateToProps,
        mapDispatchToProps,
      )(CitiesEditionScreen),
    ),
    true,
  ),
  { cities: 'cities' },
);
