/* @flow */
import * as React from 'react';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap';
import LocalizedString from 'src/data/LocalizedString';
import { withTranslation } from 'react-i18next';
import InputString from './InputString';

export type handleContentChangeType = any => any;
export interface InputFileProps {
  fieldName: string;
  value?: ?LocalizedString;
  label: string;
  help?: ?string;
  t: (key: string[]) => string;
  disabled?: boolean;
  hidden?: boolean;
  multiline?: boolean;
  handleLocalizedContentChange: handleContentChangeType;
  helpInfos: any;
  contentLocale: string;
  locale: string;
  labelSmall: boolean;
  onFocus?: () => any;
}

class InputLocalizedString extends React.Component<InputFileProps> {
  static defaultProps = {
    disabled: false,
    hidden: false,
    multiline: false,
    helpInfos: undefined,
    locale: 'en',
  };

  render() {
    const {
      fieldName,
      onFocus,
      value,
      label,
      help,
      disabled,
      multiline,
      hidden,
      t,
      helpInfos,
      contentLocale,
      handleLocalizedContentChange,
      labelSmall,
    } = this.props;
    return (
      <InputString
        fieldName={fieldName}
        onFocus={onFocus}
        value={value && value.values[contentLocale]}
        localized={true}
        label={label || t(['general.content', ''])}
        help={help}
        labelSmall={labelSmall}
        disabled={disabled}
        multiline={multiline}
        handleChange={handleLocalizedContentChange}
        hidden={hidden}
        helpInfos={helpInfos}
      />
    );
  }
}

export default withTranslation('default')(InputLocalizedString);
