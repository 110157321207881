/* @flow */
import React from 'react';
import { connect } from 'react-redux';

import 'bootstrap/dist/css/bootstrap.min.css';
import { ScenarioServiceHelper } from 'src/store/scenario';
import { ItemsServiceHelper } from 'src/store/scenario/items';
import { withAuthorization, AuthenticatedCondition } from 'src/services/Session';
import { Claims } from 'src/constants/roles';
import { withUrlProperties } from 'src/pages/components/WithUrlProperties';

import { withTranslation } from 'react-i18next';
import { compose } from 'redux';
import Firebase, { withFirebase } from 'src/services/Firebase';
import type { ScenarioReducerState } from 'src/store/scenario';
import { Loader, withConfirm } from 'src/pages/components';
import InputBoolean from '../components/inputs/InputBoolean';

export type ExportScenarioScreenProps = {
  exportGpxs: ItemsServiceHelper.exportGpxsType,
  exportAll: ScenarioServiceHelper.exportScenarioType,
  updateOnFirebase: ScenarioServiceHelper.saveScenarioInFirebaseType,
  createScenario: ScenarioServiceHelper.createScenarioType,
  firebase: Firebase,
  reduxState: ScenarioReducerState,
  generateRelease: ScenarioServiceHelper.generateReleaseType,
  engineVersion: number,
  scenarioId: string,
  scenarioVersion: string,
  t: (key: string[]) => string,
};

type State = {
  isLoading: boolean,
  changes: any[],
  editors: string[],
  itemIds: string[],
  sections: string[],
  removeAllPreviousReleases: boolean,
};

class ExportScenarioScreen extends React.PureComponent<ExportScenarioScreenProps, State> {
  static defaultProps = {};

  reader = undefined;

  state = {
    isLoading: false,
    changes: [],
    editors: [],
    itemIds: [],
    sections: [],
    removeAllPreviousReleases: false,
  };

  componentWillMount = () => {
    this.refreshChanges();
  };

  exportGpxs = () => {
    const { reduxState, exportGpxs } = this.props;
    if (exportGpxs && reduxState) {
      exportGpxs(reduxState.items, reduxState.header);
    }
  };

  updateOnFirebase = () => {
    const { reduxState, updateOnFirebase, firebase } = this.props;
    if (updateOnFirebase && reduxState) {
      updateOnFirebase(reduxState.header.id, reduxState, firebase);
    }
  };

  refreshChanges = async () => {
    const { scenarioId, scenarioVersion, firebase } = this.props;
    try {
      const {
        changes, editors, itemIds, sections,
      } = await ScenarioServiceHelper.listChangesAsync(
        scenarioId,
        [scenarioVersion],
        firebase,
      );
      this.setState({
        changes,
        editors,
        itemIds,
        sections,
      });
    } catch (error) {
      console.error('Could not load changelist', error);
    }
  };

  generateDryRelease = () => {
    this._generateRelease(true);
  };

  generateRelease = () => {
    this._generateRelease(false);
  };

  _generateRelease = (dryRun: boolean) => {
    const {
      reduxState, generateRelease, firebase, engineVersion,
    } = this.props;
    const { removeAllPreviousReleases } = this.state;
    const generate = () => {
      this.setState({ isLoading: true });
      generateRelease(reduxState.header.id, reduxState, engineVersion, removeAllPreviousReleases, firebase, dryRun)
        .then(() => {
          this.setState({ isLoading: false });
        })
        .catch(() => {
          this.setState({ isLoading: false });
        });
    };
    if (generateRelease && reduxState) {
      if (removeAllPreviousReleases) {
        const { t, alert } = this.props;
        alert(t(['screens.export.beta.removeAllPreviousReleasesHelp', '']), [
          { text: t(['general.confirm', '']), onPress: generate },
          { text: t(['general.cancel', '']), onPress: () => {} },
        ]);
      } else {
        generate();
      }
    }
  };

  exportAll = () => {
    const { reduxState, exportAll } = this.props;
    if (exportAll && reduxState) {
      exportAll(reduxState);
    }
  };

  handleChange = (event) => {
    const value = event.target.value;
    const fieldName = event.target.id;
    this.setState({ [fieldName]: value });
    const newVal = { ...this.state };
    newVal[fieldName] = value;
  };

  modaleCallbacks = undefined;

  modalMounted = (callbacks) => {
    this.modaleCallbacks = callbacks;
  };

  alert(message, buttons) {
    if (this.modaleCallbacks) {
      this.modaleCallbacks.alert(message, buttons);
    }
  }

  render() {
    const { isLoading, removeAllPreviousReleases } = this.state;
    const { t } = this.props;
    return (
      <div className="pageContainer">
        <div className="container-fluid fill component-controller" style={{ overflow: 'scroll' }}>
          <div className="card bg-light screenBlock mb-3">
            <div className="card-header">
              <h3>{t(['screens.export.gpx.sectionTitle', ''])}</h3>
            </div>
            <div className="card-body">
              <small className="form-text text-muted  mb-3">{t(['screens.export.gpx.desc', ''])}</small>
              <button className="btn btn-outline-secondary mb-3" type="button" onClick={this.exportGpxs}>
                {t(['general.export', ''])}
              </button>
            </div>
          </div>

          <div className="card bg-light screenBlock mb-3">
            <div className="card-header">
              <h3>{t(['screens.export.zip.sectionTitle', ''])}</h3>
            </div>
            <div className="card-body">
              <small className="form-text text-muted  mb-3">{t(['screens.export.zip.desc', ''])}</small>
              <button className="btn btn-outline-secondary mb-3" type="button" onClick={this.exportAll}>
                {t(['general.export', ''])}
              </button>
            </div>
          </div>

          <div className="card bg-light screenBlock mb-3">
            <div className="card-header">
              <h3>{t(['screens.export.beta.sectionTitle', ''])}</h3>
            </div>
            <div className="card-body">
              <small className="form-text text-muted  mb-3">{t(['screens.export.beta.desc', ''])}</small>
              <div>
                <h5>{t(['screens.export.beta.changeList', ''])}</h5>
                <h6>
                  {t(['screens.export.beta.editors', ''])} <small>{this.state.editors.join(', ')}</small>
                </h6>
                <h6>
                  {t(['screens.export.beta.sections', ''])} <small>{this.state.sections.join(', ')}</small>
                </h6>
                <h6>
                  {t(['screens.export.beta.items', ''])} <small>{this.state.itemIds.join(', ')}</small>
                </h6>
              </div>
              <InputBoolean
                fieldName="removeAllPreviousReleases"
                value={removeAllPreviousReleases}
                label={t(['screens.export.beta.removeAllPreviousReleasesLabel', ''])}
                help={t(['screens.export.beta.removeAllPreviousReleasesHelp', ''])}
                handleChange={this.handleChange}
              />
              <button className="btn btn-outline-secondary mb-3" type="button" onClick={this.generateRelease}>
                {t(['general.export', ''])}
              </button>
              <button className="btn btn-outline-secondary mb-3" type="button" onClick={this.generateDryRelease}>
                {t(['general.dryRun', ''])}
              </button>
            </div>
          </div>
        </div>
        {isLoading && <Loader />}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  scenarioId: state.scenario.header.id,
  scenarioVersion: state.scenario.header.lastVersion,
  reduxState: state.scenario,
  jsonRoot: 'Scénario',
  engineVersion: state.configuration.engineVersion,
});

const mapDispatchToProps = {
  exportGpxs: ItemsServiceHelper.exportGpxsArchive,
  exportAll: ScenarioServiceHelper.exportToZip,
  generateRelease: ScenarioServiceHelper.generateRelease,
};

export default compose(
  withUrlProperties,
  withConfirm,
  withFirebase,
  withAuthorization(AuthenticatedCondition, [Claims.EditorConfirmed, Claims.Moderator]),
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  withTranslation('default'),
)(ExportScenarioScreen);
