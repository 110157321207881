/* @flow */
import { createNamedReducer } from 'redux-named-reducers';
import { createTransform, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage/session';
import { AMSItem } from 'src/data';
import * as types from './types';

export type AMSReducerState = { [id: string]: AMSItem };
export const AMSReducerDefaultState = {};

const reducer = (state: AMSReducerState = AMSReducerDefaultState, action: { type: string, payload: any }) => {
  let newState: AMSReducerState;
  switch (action.type) {
    case types.AMS_CREATE:
    case types.AMS_UPDATE: {
      const { ams } = action.payload;
      newState = { ...state };
      const id = ams.id;
      const nextAms = new AMSItem(ams);
      newState[id] = nextAms;
      break;
    }
    case types.AMS_REMOVE: {
      const { id } = action.payload;
      newState = { ...state };
      delete newState[id];
      break;
    }
    case types.AMS_CLEANUP:
      newState = { ...AMSReducerDefaultState };
      break;
    default:
      newState = state;
      break;
  }
  return newState;
};

export const AMSReducerKey = 'ams';

const amsTransform = createTransform(
  inboundState => inboundState,
  (outboundState) => {
    const res = new AMSItem(outboundState);
    return res;
  },
  { blacklist: ['_persist'] },
);

const amsPersistConfig = {
  key: AMSReducerKey,
  storage,
  transforms: [amsTransform],
};

export default createNamedReducer({
  moduleName: AMSReducerKey,
  reducer: persistReducer(amsPersistConfig, reducer),
});
