/* @flow */
import * as React from 'react';

import HelpButton from '../HelpButton';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap';

export interface InputMultipleSelectProps {
  fieldName: string;
  value: string;
  values: string[];
  label: string;
  help?: ?string;
  disabled?: boolean;
  hidden?: boolean;
  separatorBefore?: boolean;
  handleChange: any => any;
  helpInfos: any;
  labelSmall: boolean;
  onFocus?: () => any;
}

export default class InputMultipleSelect extends React.Component<InputMultipleSelectProps> {
  static defaultProps = {
    disabled: false,
    hidden: false,
    helpInfos: undefined,
    labelSmall: false,
    separatorBefore: false,
  };

  onChange = () => {};

  render() {
    const {
      fieldName,
      value,
      values,
      label,
      help,
      disabled,
      hidden,
      helpInfos,
      separatorBefore,
      labelSmall,
    } = this.props;
    return (
      <div className="form-group" key={fieldName} hidden={hidden}>
        {separatorBefore && <hr />}
        {(label || helpInfos) && (
          <label htmlFor={fieldName}>
            {labelSmall ? <small>{label}</small> : label}{' '}
            {helpInfos
              && helpInfos.map((helpInfo, index) => (
                <HelpButton
                  key={fieldName + index}
                  helpStrings={helpInfo.content}
                  id={fieldName}
                  title={helpInfo.title}
                  label={helpInfo.btnLabel}
                  display={'inline'}
                  className={'ml-auto btn btn-outline-warning ml-2'}
                />
              ))}
          </label>
        )}
        <div className="form-check">
          {values.map(val => (
            <input
              key={val}
              className="form-check-input"
              type="checkbox"
              value={val}
              id={val}
              checked={value.includes(val)}
              onChange={this.onChange}
              disabled={disabled}
            >
              <label className="form-check-label" htmlFor="defaultCheck1">
                {val}
              </label>
            </input>
          ))}
        </div>
        {help && (
          <small id={`${fieldName}Help`} className="form-text text-muted">
            {help}
          </small>
        )}
      </div>
    );
  }
}
