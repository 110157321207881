/* @flow */
import React from 'react';
import { connect } from 'react-redux';

import './DiscussionGraphView.css';

import { withTranslation } from 'react-i18next';
import { compose } from 'redux';
import { DiscussionServiceHelper } from 'src/store/scenario/items';
import { Discussion, NPC } from 'src/data';
import InputString from 'src/pages/components/inputs/InputString';
import { EventsServiceHelper, NotificationTypes } from 'src/store/events';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { InputSelect } from '../../../../components/inputs';

export type DiscussionMetaInputProps = {
  nodeId: string,
  discussion: any,
  discussionId: string,
  triggeredItems: any,
  triggers: any,
  items: any,
  scenarioId: string,
  addNotif: EventsServiceHelper.addNotifType,
  updateMeta: DiscussionServiceHelper.updateDiscussionType,
  npcs: NPC[],
  locale: string,
  prefix: string,
  startEditing: () => any,
  isEditingItem: boolean,
};

type State = {
  id: string,
  isValid: boolean,
  hasChanges: boolean,
  npcId?: string,
  idSuffix: string,
};

class DiscussionMetaInput extends React.PureComponent<DiscussionMetaInputProps, State> {
  state = {
    id: '',
    idSuffix: '',
    isValid: false,
    hasChanges: false,
    npcId: undefined,
  };

  componentDidMount() {
    this.setItemData(this.props);
  }

  componentDidUpdate(oldProps) {
    if (oldProps.nodeId !== this.props.nodeId) {
      this.warnSaveIfNeeded();
    }
    if (!oldProps.discussion || (this.props.discussion && this.props.discussion.id !== oldProps.discussion.id)) {
      this.setItemData(this.props);
    }
  }

  componentWillUnmount() {
    this.warnSaveIfNeeded();
  }

  warnSaveIfNeeded = () => {
    if (this.state.hasChanges && this.props.scenarioId) {
      const updateData = this.getDataToSave();

      const { isValid } = this.state;
      if (isValid) {
        this.props.addNotif(NotificationTypes.WARN, 'W_UNSAVED_ITEM', undefined, 0, {
          title: this.props.t(['general.save', '']),
          callback: async () => {
            if (this.props.scenarioId) {
              this.updateWithData(updateData, false);
            } else {
              this.props.addNotif(NotificationTypes.ERROR, 'E_UNSAVED_ITEM', undefined, 0);
            }
          },
          closeOnCallback: true,
        });
      } else {
        this.props.addNotif(NotificationTypes.ERROR, 'E_UNSAVED_ITEM_INVALID', undefined, 0);
      }
    }
  };

  setItemData = (props: DiscussionMetaInputProps) => {
    const { prefix, discussion } = props;
    if (discussion) {
      const { id, npcId } = discussion;
      const idSuffix = id ? id.slice(prefix.length) : '';
      const newState = {
        id: id || '',
        idSuffix: idSuffix || '',
        npcId,
        hasChanges: false,
      };
      this.setState(newState);
      this.updateValidity(newState);
    }
  };

  updateValidity = (newVal) => {
    const { idSuffix, npcId } = newVal;
    const isValid = idSuffix && idSuffix.length && npcId;
    this.setState({ isValid: !!isValid });
  };

  onFieldFocus = () => {
    if (!this.props.isEditingItem) {
      this.props.startEditing();
    }
  };

  handleChange = (event) => {
    if (!this.props.isEditingItem) {
      this.props.startEditing();
    }
    const value = event.target.value;
    const fieldName = event.target.id;
    this.setState({ [fieldName]: value, hasChanges: true });
    const newVal: State = { ...this.state };
    newVal[fieldName] = value;
    this.updateValidity(newVal);
  };

  getDataToSave = () => {
    const { npcId, idSuffix } = this.state;
    const { prefix, discussion, updateMeta } = this.props;

    const newDiscussion = new Discussion(discussion);
    newDiscussion.id = prefix + idSuffix;
    if (npcId) {
      newDiscussion.npcId = npcId;
    }
    return { newDiscussion, updateMeta };
  };

  updateWithData = async (updateData, notifyUi: boolean = false) => {
    const { newDiscussion, updateMeta } = updateData;
    if (newDiscussion) {
      updateMeta(newDiscussion.id, newDiscussion);
      if (notifyUi) {
        this.setState({ hasChanges: false });
      }
    }
  };

  updateMeta = () => {
    const updateData = this.getDataToSave();
    this.updateWithData(updateData, true);
  };

  render() {
    const {
      idSuffix, npcId, isValid, hasChanges,
    } = this.state;
    const {
      npcs, discussion, locale, t,
    } = this.props;

    const saveBtnClass = hasChanges ? 'btn-warning' : 'btn-outline-secondary';
    return (
      <div className="meta-input">
        <div className="card-header hidden">
          <h5 style={{ marginBottom: 0 }}>{t(['screens.discussionEdition.metaEdition.sectionTitle', ''])}</h5>
        </div>
        <div className="">
          {/* }
          <InputString
            fieldName="idSuffix"
            onFocus={this.onFieldFocus}
            value={idSuffix}
            label={t(['screens.discussionEdition.metaEdition.idLabel', ''])}
            help={t(['screens.discussionEdition.metaEdition.idPlaceholder', ''])}
            handleChange={this.handleChange}
            disabled={discussion.id}
          />
          */}

          <InputSelect
            fieldName="npcId"
            onFocus={this.onFieldFocus}
            value={npcId}
            values={npcs}
            itemToId={it => it.id}
            itemToTitle={it => it.name.valueForLocale(locale, true)}
            label={'' /* t(['screens.discussionEdition.metaEdition.npcLabel', '']) */}
            handleChange={this.handleChange}
            hidden={false}
          />

          <div className="input-group-append save-btn-container-discussion">
            <div
              className={`btn ${saveBtnClass} save-btn interactive`}
              type="button"
              id="button-addon2"
              onClick={this.updateMeta}
              disabled={!isValid}
            >
              <FontAwesomeIcon icon={['fad', 'save']} />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { discussionId } = ownProps;
  return {
    discussion: state.scenario.items[discussionId],
    locale: state.preferences.editionLocale,
    prefix: '',
    scenarioId: state.scenario.header.id ? `${state.scenario.header.id}_` : '',
    npcs: [...state.scenario.npcs.npcs, ...state.configuration.globalNpcs],
  };
};

const mapDispatchToProps = {
  updateMeta: DiscussionServiceHelper.updateDiscussionAsync,
  addNotif: EventsServiceHelper.addNotif,
};

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  withTranslation('default'),
)(DiscussionMetaInput);
