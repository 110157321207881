/* @flow */
import { createNamedReducer } from 'redux-named-reducers';
// import { persistReducer } from 'redux-persist';
// import storage from 'redux-persist/lib/storage';
import { NPC, City } from 'src/data';
import type { ItemTypesType } from 'src/data/BaseItem';
import * as types from './types';
// import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';

export type MapStyle = string[];
export type screenPlayItemType = { key: string, title: string, content: string, metrics: string }[];
export type screenplayType = { [ItemTypesType]: screenPlayItemType };
export type ConfigurationReducerState = {
  mapStyles: string[],
  fontStyles: string[],
  availableCities: City[],
  triggers: string[],
  lockModes: string[],
  engineVersion: number,
  amsEngineVersion: number,
  globalNpcs: NPC[],
  screenplay: screenplayType,
};

export const ConfigurationReducerDefaultState = {
  mapStyles: ['t1400', 't1700', 't1800', 't1900', 't1940', 't2136', 't2136-withlabels'],
  fontStyles: ['t1700', 't1940', 't2018'],
  globalNpcs: [
    new NPC({
      id: 'lucas',
      name: { fr: 'Lucas', en: 'Lucas' },
      role: 'Agent de liaison',
      brief:
        "Peu d'information filtre à son sujet, personne ne l'a jamais rencontré ou n'à même entendue sa voie, seul son nom est connue. Enfin... s'il s'agit réèellement de son nom...",
    }),
  ],
  availableCities: [],
  triggers: ['Added', 'Discovered', 'FirstReach', 'Reached', 'Launched', 'Unlocked', 'Failed', 'Completed'],
  screenplay: {
    AMS: [],
    Anecdote: [
      {
        key: 'Added',
        title: 'Added',
        content: "L'Anecdote est affichée à l'écran dès lors qu'elle est ajoutée.",
        metrics: 'A',
      },
      {
        key: 'Completed',
        title: 'Completed',
        content: "Lorsque l'utilisateur ferme l'anecdote.\nAPRÈS l'animation de fermeture.",
        metrics: 'C',
      },
    ],
    Archive: [
      {
        key: 'Added',
        title: 'Added',
        content: "L'Archive est débloquée dans le profil du joueur. (En dehors de la mission)",
        metrics: 'A',
      },
    ],
    Checkpoint: [
      {
        key: 'Added',
        title: 'Added',
        content: 'Une sauvegarde est lancée.',
        metrics: 'A',
      },
    ],
    Custom: [
      {
        key: 'Added',
        title: 'Added',
        content: 'Ajout du Custom (disponible dans le reducer, avec l\'état "Added")',
        metrics: 'A',
      },
      {
        key: 'Discovered',
        title: 'Discovered',
        content: 'Change le state du custom à "Discovered"',
        metrics: 'D',
      },
      {
        key: 'Failed',
        title: 'Failed',
        content: 'Rien de spécial à part metrics + triggeredItems',
        metrics: 'F',
      },
      {
        key: 'Completed',
        title: 'Completed',
        content: 'Change le state du custom à "Completed"',
        metrics: 'C',
      },
    ],
    Discussion: [
      {
        key: 'Added',
        title: 'Added',
        content: "Discussion lancée\nOuverture automatique de la popup de Lucas OU de l'interrogatoire (Examination)",
        metrics: 'A',
      },
      {
        key: 'Unlocked',
        title: 'Unlocked',
        content: 'La discussion change d\'état, et passe en "InProgress" (avec une valeur d\'avancement)',
        metrics: 'U',
      },
      {
        key: 'Failed',
        title: 'Failed',
        content: 'Déclenché dès qu\'une réponse saisie n\'est pas marquée comme "bonne réponse"',
        metrics: 'F',
      },
      {
        key: 'Completed',
        title: 'Completed',
        content:
          "La discussion est terminée. L'utilisateur a déclenché la fermeture de l'écran.\nAVANT l'animation de fermeture.",
        metrics: 'C',
      },
    ],
    Document: [
      {
        key: 'Added',
        title: 'Added',
        content: "Document ajouté dans l'inventaire\nOU\nDocument restoré après une suppression (REMOVED)",
        metrics: 'A',
      },
      {
        key: 'Discovered',
        title: 'Discovered',
        content: 'Document ouvert pour la 1ère fois',
        metrics: 'D',
      },
      {
        key: 'Launched',
        title: 'Launched',
        content: 'A chaque ouverture du document',
        metrics: 'L',
      },
      {
        key: 'Unlocked',
        title: 'Unlocked',
        content:
          'Un des codes a été trouvé sur le document.\nSi tous les codes sont trouvés, passe le documentState en WaitingForCompletion',
        metrics: 'U',
      },
      {
        key: 'Failed',
        title: 'Failed',
        content: 'Une erreur a été saisie sur le document',
        metrics: 'F',
      },
      {
        key: 'Completed',
        title: 'Completed',
        content:
          "Tous les codes ont été trouvés.\nDéclenché depuis l'écran DocumentItem, quand le documentState est à WaitingForCompletion",
        metrics: 'C',
      },
      {
        key: 'Removed',
        title: 'Removed',
        content:
          "Le document est supprimé de l'inventaire du joueur. (Il reste dans la liste des documents dispo, mais n'est plus affiché)",
        metrics: 'REM',
      },
    ],
    Failure: [
      {
        key: 'Added',
        title: 'Added',
        content: "Déclenche l'écran d'échec du scénario automatiquement.",
        metrics: 'A',
      },
    ],
    GameArea: [
      {
        key: 'Added',
        title: 'Added',
        content: 'Restreint la zone de jeu',
        metrics: 'A',
      },
    ],
    Openable: [
      {
        key: 'Added',
        title: 'Added',
        content: 'Item déclenché.\nOuverture automatique de la popup CustomItem',
        metrics: 'A',
      },
      {
        key: 'Launched',
        title: 'Launched',
        content: 'La popup est ouverte',
        metrics: 'L',
      },
      {
        key: 'Completed',
        title: 'Completed',
        content: "L'utilisateur a validé l'action.\nAVANT l'animation de fermeture.",
        metrics: 'C',
      },
    ],
    POI: [
      {
        key: 'Added',
        title: 'Added',
        content: "POI disponible à l'utilisateur (pas forcément visible)",
        metrics: 'A',
      },
      {
        key: 'Discovered',
        title: 'Discovered',
        content: "le POI devient visible (soit de manière automatique, soit quand l'utilisateur s'en rapproche)",
        metrics: 'D',
      },
      {
        key: 'FirstReach',
        title: 'FirstReach',
        content: "L'utilisateur rentre dans le radius du POI pour la 1ère fois.",
        metrics: 'R1',
      },
      {
        key: 'Reached',
        title: 'Reached',
        content: "L'utilisateur rentre dans le radius du POI (Toutes les fois sauf la 1ère)",
        metrics: 'R',
      },
      {
        key: 'Launched',
        title: 'Launched',
        content: "L'utilisateur clique sur le POI",
        metrics: 'L',
      },
      {
        key: 'Unlocked',
        title: 'Unlocked',
        content: 'Permet de gérer un état temporaire du POI, pour gérer un affichage différent',
        metrics: 'U',
      },
      {
        key: 'Completed',
        title: 'Completed',
        content: 'Les actions sur le POI ont été terminées.\nPeut rester visible, ou passer en Archived.',
        metrics: 'C',
      },
    ],
    Start: [
      {
        key: 'Added',
        title: 'Added',
        content: 'Déclenche le début du scénario',
        metrics: 'A',
      },
    ],
    Success: [
      {
        key: 'Added',
        title: 'Added',
        content: 'Déclenche la fin et la validation du scénario',
        metrics: 'A',
      },
    ],
    SecondaryMission: [
      {
        key: 'Added',
        title: 'Added',
        content: 'Mission secondaire terminée',
        metrics: 'A',
      },
    ],
    Timer: [
      {
        key: 'Added',
        title: 'Added',
        content: 'Un timer est déclenché.',
        metrics: 'A',
      },
      {
        key: 'Failed',
        title: 'Failed',
        content: 'Fin du timer automatique',
        metrics: 'F',
      },
      {
        key: 'Completed',
        title: 'Completed',
        content: "Annulé prématurément (Signifie un succès généralement).\nReste affiché sur l'écran principal.",
        metrics: 'C',
      },
      {
        key: 'Removed',
        title: 'Removed',
        content: "Suppression de l'affichage du Timer sur l'écran principal",
        metrics: 'REM',
      },
    ],
    TimeTravel: [
      {
        key: 'Added',
        title: 'Added',
        content: 'Un time travel est demandé. Mise en attente du prochain "age"',
        metrics: 'A',
      },
      {
        key: 'Launched',
        title: 'Launched',
        content: "Le TimeTravel a démarré. Le screenshot a été effectué, et l'animation commence",
        metrics: 'L',
      },
      {
        key: 'Completed',
        title: 'Completed',
        content: 'Le time travel est terminé, mise à jour de redux',
        metrics: 'C',
      },
    ],
    Tool: [
      {
        key: 'Added',
        title: 'Added',
        content: "Outil ajouté à l'inventaire",
        metrics: 'A',
      },
      {
        key: 'Discovered',
        title: 'Discovered',
        content: 'Outil ouvert pour la 1ère fois',
        metrics: 'D',
      },
      {
        key: 'Launched',
        title: 'Launched',
        content: "A chaque ouverture de l'outil",
        metrics: 'L',
      },
    ],
  },
  lockModes: ['None', 'Blured', 'Image'],
  contentTypes: ['Text', 'Image', 'Item'],
  answerModes: ['Text', 'System'],
  nextModes: ['Continue', 'Retry', 'Exit', 'Complete'],
  nextCustoms: [
    'Exit',
    'Completed',
    'SocialConnectFB',
    'SocialConnectGoogle',
    'SocialRegisterFB',
    'SocialRegisterGoogle',
    'AskPermissionGeoloc',
    'AskPermissionCamera',
    'AskPermissionStorage',
    'GoSettings',
    'SaveUserName',
    'AcceptTOS',
    'ContinueScreenplay',
    'FinishScreenplay',
  ],
  engineVersion: 2,
  amsEngineVersion: 1,
};

const reducer = (prevState = ConfigurationReducerDefaultState, action) => {
  let state;
  switch (action.type) {
    case types.CITIES_CREATE:
    case types.CITIES_UPDATE: {
      const { city } = action.payload;
      if (prevState.availableCities === undefined) {
        state = { availableCities: [], ...prevState };
      } else {
        state = { ...prevState };
        state.availableCities = state.availableCities.filter(it => it.id !== city.id);
      }
      const nextCity = new City(city);
      state.availableCities = [...state.availableCities, nextCity];
      break;
    }
    case types.CITIES_REMOVE: {
      const { cityId } = action.payload;
      state = { ...prevState, availableCities: prevState.availableCities.filter(city => city.id !== cityId) };
      break;
    }
    case types.LOAD_SCREENPLAY: {
      const { screenplay } = action.payload;
      state = { ...prevState };
      state.screenplay = { ...screenplay };
      break;
    }
    default:
      state = prevState;
      break;
  }
  return state;
};

export const ConfigurationReducerKey = 'configuration';

// const configurationPersistConfig = {
//   key: ConfigurationReducerKey,
//   storage,
//   transforms: [],
//   stateReconciler: autoMergeLevel2
// };
// export default persistReducer(configurationPersistConfig, reducer);
export default createNamedReducer({
  moduleName: ConfigurationReducerKey,
  reducer,
});
