/* @flow */
import BaseItem, { ItemTypes } from './BaseItem';
import LocalizedString from './LocalizedString';

/* ***************************
  CLASS
*************************** */
export type AppTimeTravel = {};

export default class TimeTravelItem extends BaseItem {
  year: number;

  yearText: LocalizedString;

  transitionText: LocalizedString;

  mapStyleName: string;

  fontStyleName: string;

  constructor(json: any) {
    if (json instanceof TimeTravelItem) {
      super(json, true);
    } else {
      super(json, false);
    }
    const {
      type, meta, year, transitionText, mapStyleName, fontStyleName, yearText, ...newMeta
    } = json;
    this.type = ItemTypes.TimeTravel;
    this.setMeta({ ...json.meta, ...newMeta });
    this.year = year;
    this.yearText = new LocalizedString(`${this.id}_yearText`, yearText, false);
    this.transitionText = new LocalizedString(`${this.id}_transitionText`, transitionText, false);
    this.fontStyleName = fontStyleName;
    this.mapStyleName = mapStyleName;
  }

  serializeInheritedFieldsForApp() {
    const res = super.serializeInheritedFieldsForApp();
    res.year = this.year;
    res.transitionText = this.transitionText && this.transitionText.serializeForApp();
    res.fontStyleName = this.fontStyleName;
    res.mapStyleName = this.mapStyleName;
    if (this.yearText) {
      res.yearText = this.yearText.serializeForApp();
    }
    return res;
  }

  serializeInheritedFields() {
    const res = super.serializeInheritedFields();
    res.year = this.year;
    res.transitionText = this.transitionText && this.transitionText.serialize();
    res.fontStyleName = this.fontStyleName;
    res.mapStyleName = this.mapStyleName;
    if (this.yearText) {
      res.yearText = this.yearText.serialize();
    }
    return res;
  }

  getLocalizedStringsWithPath() {
    const res = super.getLocalizedStringsWithPath();
    res.transitionText = this.transitionText;
    res.yearText = this.yearText;
    return res;
  }
}
