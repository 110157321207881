/* @flow */
import * as React from 'react';

const FirebaseContext: Object = React.createContext(null);

// eslint-disable-next-line react/display-name
export function withFirebase<Props: {}>(
  Component: React.AbstractComponent<Props>,
): React.AbstractComponent<$Diff<Props, { firebase: any }>> {
  return function WrapperComponent(props: $Diff<Props, { firebase: any }>) {
    return (
      <FirebaseContext.Consumer>{firebase => <Component {...props} firebase={firebase} />}</FirebaseContext.Consumer>
    );
  };
}

export default FirebaseContext;
