/* @flow */
import * as React from 'react';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap';
import { uniq } from 'src/utils';

import HelpButton from '../HelpButton';

export interface InputPoiTypesProps {
  fieldName: string;
  value: { [string]: string };
  possiblePoiTypes: string[];
  availableStates: string[];
  itemUnlockedStates: number[];
  label: string;
  disabled?: boolean;
  hidden?: boolean;
  helpInfos: any;
  handleChange: any => any;
  separatorBefore?: boolean;
  onFocus?: () => any;
}

type state = {
  collapseButtonText: string,
  detailVisible: boolean,
  potentialPoiTypes: { title: string, stateId: string }[],
};

export default class InputPoiTypes extends React.Component<InputPoiTypesProps, state> {
  static defaultProps = {
    itemId: '',
    disabled: false,
    availableStates: ['Default', 'Visible', 'InProgress', 'Completed'],
    itemUnlockedStates: [],
    hidden: false,
    helpInfos: undefined,
    separatorBefore: false,
    possiblePoiTypes: ['Start', 'Objective', 'Major', 'Interactive', 'NPC', 'hidden'],
  };

  state = {
    collapseButtonText: '+',
    detailVisible: false,
    potentialPoiTypes: [],
  };

  componentDidMount = () => {
    this.updatepotentialPoiTypes(this.props);
  };

  componentDidUpdate = (oldProps: InputPoiTypesProps) => {
    if (
      this.props.availableStates !== oldProps.availableStates
      || this.props.itemUnlockedStates !== oldProps.itemUnlockedStates
    ) {
      this.updatepotentialPoiTypes(this.props);
    }
  };

  updatepotentialPoiTypes = (props: InputPoiTypesProps) => {
    const { availableStates, itemUnlockedStates } = props;
    const potentialPoiTypes = [];
    availableStates.forEach((it) => {
      if (it !== 'InProgress') {
        potentialPoiTypes.push({ title: it, stateId: it });
      } else if (itemUnlockedStates) {
        uniq(itemUnlockedStates).forEach((value) => {
          potentialPoiTypes.push({
            title: `${it} ${value}`,
            stateId: `InProgress${value + 1}`,
          });
        });
      }
    });
    this.setState({ potentialPoiTypes });
  };

  handleChange = (event: any) => {
    const { value, handleChange, fieldName } = this.props;
    const newVal = { ...value };
    delete newVal[event.target.id];
    if (event.target.value) {
      newVal[event.target.id] = event.target.value;
    }
    if (handleChange) {
      handleChange({ target: { id: fieldName, value: newVal } });
    }
  };

  onCollapseClick = () => {
    if (this.state.collapseButtonText === '+') {
      this.setState({
        collapseButtonText: '-',
        detailVisible: true,
      });
    } else {
      this.setState({
        collapseButtonText: '+',
        detailVisible: false,
      });
    }
  };

  hasPoiType = (stateId: string) => {
    const { value } = this.props;
    if (!value) {
      return undefined;
    }
    return value[stateId];
  };

  renderSelectField = (
    fieldName: string,
    value?: string,
    label: string,
    values: string[],
    help?: ?string = undefined,
    disabled: boolean = false,
  ) => (
    <div className="input-group" key={fieldName}>
      <label className="input-group-prepend mr-2" htmlFor={fieldName}>
        {label}
      </label>
      <select
        className="form-control"
        id={fieldName}
        onChange={this.handleChange}
        value={value}
        disabled={disabled}
        aria-describedby={`${fieldName}Help`}
        placeholder={''}
      >
        <option value={''}>{''}</option>
        {values
          && values.map(element => (
            <option key={element} value={element}>
              {element}
            </option>
          ))}
      </select>
      {help && (
        <small id={`${fieldName}Help`} className="form-text text-muted">
          {help}
        </small>
      )}
    </div>
  );

  render() {
    const {
      fieldName, value, label, disabled, hidden, helpInfos, separatorBefore,
    } = this.props;
    const { collapseButtonText, detailVisible, potentialPoiTypes } = this.state;
    return (
      <div className="form-group" key={fieldName} hidden={hidden}>
        {separatorBefore && <hr />}
        <label className="mb-0 strong" htmlFor={fieldName}>
          {`${label} `}
          {helpInfos
            && helpInfos.map((helpInfo, index) => (
              <HelpButton
                key={fieldName + index}
                helpStrings={helpInfo.content}
                id={fieldName}
                title={helpInfo.title}
                label={helpInfo.btnLabel}
                display={'inline'}
                className={'ml-auto btn btn-outline-warning ml-2'}
              />
            ))}

          <button type="button" className="btn btn-outline-info btn-sm ml-2" onClick={this.onCollapseClick}>
            {collapseButtonText}
          </button>
        </label>
        {detailVisible
          && potentialPoiTypes
          && potentialPoiTypes.map(val => this.renderSelectField(
            val.stateId,
            this.hasPoiType(val.stateId),
            val.title,
            this.props.possiblePoiTypes,
            undefined,
            disabled,
          ))}
        {!detailVisible && (
          <small id={`${fieldName}Help`} className="form-text text-muted">
            {value && JSON.stringify(value)}
          </small>
        )}
      </div>
    );
  }
}
