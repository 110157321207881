/* @flow */
import * as React from 'react';
import { connect } from 'react-redux';
import * as _ from 'lodash';
import { BaseWidgetProps, DiagramEngine } from 'storm-react-diagrams';
import { DiscussionServiceHelper } from 'src/store/scenario/items';
import ItemNodeWidget from '../ItemNodeWidget';

export interface AnswerNodeProps extends BaseWidgetProps {
  discussionId: string;
  messageId: string;
  triggerId: string;
  nodeId: string;
  item: any;
  outOfRedux: boolean;
  deleteTrigger: DiscussionServiceHelper.deleteTriggerType;
  diagramEngine: DiagramEngine;
}

export interface ItemNodeState {}

class TriggerNodeWidget extends ItemNodeWidget<AnswerNodeProps, ItemNodeState> {
  state = {};

  entityRemoved() {
    const {
      deleteTrigger, discussionId, messageId, nodeId,
    } = this.props;
    if (deleteTrigger) {
      deleteTrigger(discussionId, messageId, nodeId);
    }
  }

  componentDidUpdate(oldProps) {
    const hasParentId = this.props.hasParentId;
    const hasParent = this.props.hasParent;
    const hadParent = oldProps.hasParent;
    const { nodeId, diagramEngine } = oldProps;
    if (hasParentId && hadParent && !hasParent) {
      diagramEngine.diagramModel.removeNode(nodeId);
    }
  }

  render() {
    return (
      <div
        {...this.getProps()}
        style={{
          background: this.props.node.color,
          borderColor: this.props.node.borderColor,
        }}
      >
        <div className={`trigger_graph_title ${this.bem('__title')}`}>
          <div className={this.bem('__name')}>{this.props.title}</div>
        </div>
        <div className={this.bem('__ports')}>
          <div className={this.bem('__in')}>{_.map(this.props.node.getInPorts(), this.generatePort.bind(this))}</div>
          <div className={this.bem('__out')}>{_.map(this.props.node.getOutPorts(), this.generatePort.bind(this))}</div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { discussionId, trigger } = ownProps.node;
  const nodeId = trigger && trigger.nodeId;
  const messageId = trigger && trigger.parentMessageId;
  const discussion = discussionId && state.scenario.items[discussionId];
  const message = discussion && messageId && discussion.messages[messageId];
  let triggerRedux;
  if (discussion) {
    if (!messageId) {
      triggerRedux = discussion.__detachedNodes.triggers.find(trig => trig.nodeId === nodeId);
      if (trigger && triggerRedux && ownProps.node.trigger.parentMessageId !== triggerRedux.parentMessageId) {
        // eslint-disable-next-line no-param-reassign
        ownProps.node.trigger.parentMessageId = triggerRedux.parentMessageId;
      }
    } else {
      triggerRedux = message && trigger && message.triggeredItems.find(trig => trig.nodeId === nodeId);
    }
  }
  return {
    nodeId,
    messageId,
    discussionId,
    trigger: triggerRedux,
    title: triggerRedux && triggerRedux.id,
    hasParentId: !!messageId,
    hasParent: !!messageId && !!discussion.messages[messageId],
  };
};

const mapDispatchToProps = {
  deleteTrigger: DiscussionServiceHelper.deleteTriggerAsync,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(TriggerNodeWidget);
