/* @flow */
import inside from 'point-in-polygon';
import type { Scenario } from 'src/data';
import { asyncForEach } from 'src/utils';
import Firebase, { Singleton as firebase } from '../Firebase';
import type { CatalogType } from '../types';

import {
  pushEditorAssetAsync, pushReleaseAssetAsync, downloadFileAsync, getNextVersionAsync,
} from './common';
import type {
  pushEditorAssetType,
  pushReleaseAssetAsyncType,
  downloadAssetAsyncType,
  getNextVersionAsyncType,
} from './common';

/* *********************
 * AMS
 */

export const pushAmsEditorAssetAsync: pushEditorAssetType = async (amsId, filename, file: File, firebase) => {
  const scenarioStorage = firebase.amsEditorStorage(amsId);
  return pushEditorAssetAsync(scenarioStorage, filename, file);
};

export const pushAmsReleaseAsset: pushReleaseAssetAsyncType = async (
  amsId,
  version,
  subfolder,
  filename,
  file,
  strContent,
  isPublic,
) => {
  console.log('Uploading release asset to firebase', amsId, version);
  const amsFolder = firebase.amsStorage(amsId);
  return pushReleaseAssetAsync(amsFolder, version, subfolder, filename, file, strContent, isPublic);
};

export const downloaAmsAsset: downloadAssetAsyncType = async (amsId, filename, firebase) => {
  console.log('Download editor asset ', amsId, filename);
  const firebaseFile = firebase.amsEditorStorage(amsId).child(filename);
  const url = await firebaseFile.getDownloadURL();
  return downloadFileAsync(url);
};

export const getAMSNextVersionAsync: getNextVersionAsyncType = (amsId, firebase) => getNextVersionAsync(firebase.amsData(amsId));

/* *****************
 * AMS per scenario
 */
export type updateScenarioForAmsAsyncType = (
  ams: { id: string, coordinate: { latitude: number, longitude: number, altitude?: number } },
  version: string,
  catalogType: CatalogType,
  firebase: Firebase,
  dryRun: boolean,
) => Promise<string[]>;
export const updateScenarioForAmsAsync: updateScenarioForAmsAsyncType = async (
  ams,
  version,
  catalogType,
  firebase,
  dryRun,
) => {
  const scenariosIds = [];
  const amsPos = [ams.coordinate.latitude, ams.coordinate.longitude];
  // Search all the scenarios that starts near to the AMS
  const scenariosSnapshot = await firebase.scenariosHeader(catalogType).once('value');
  if (scenariosSnapshot.exists()) {
    const potentialScenarios = scenariosSnapshot.val();
    Object.keys(potentialScenarios).forEach((scenarioId) => {
      const polygon = potentialScenarios[scenarioId].gameArea;
      if (polygon && inside(amsPos, polygon)) {
        scenariosIds.push(scenarioId);
      }
    });
  }
  const updatedScenarios = [];
  await asyncForEach(scenariosIds, async (scenarioId) => {
    // set ams version
    const amsPerScenarioSnapshot = await firebase.amsPerScenario(scenarioId, catalogType).once('value');
    if (amsPerScenarioSnapshot.exists()) {
      updatedScenarios.push(scenarioId);
      if (!dryRun) {
        await firebase
          .amsPerScenario(scenarioId, catalogType)
          .child(`values/${ams.id}`)
          .set(version);
        // upgrade scenario ams global version
        const currentStr: string = (await firebase
          .amsPerScenario(scenarioId, catalogType)
          .child('version')
          .once('value')).val();
        const currentVal = Number.parseInt(currentStr.substr(1), 10);
        await firebase
          .amsPerScenario(scenarioId, catalogType)
          .child('version')
          .set(`v${currentVal + 1}`);
      }
    }
  });
  return updatedScenarios;
};

export type updateAmsForScenarioAsyncType = (
  scenario: Scenario,
  version: string,
  catalogType: CatalogType,
  firebase: Firebase,
  dryRun: boolean,
) => Promise<string[]>;
export const updateAmsForScenarioAsync: updateAmsForScenarioAsyncType = async (
  scenario,
  version,
  catalogType,
  firebase,
  dryRun,
) => {
  const polygon = scenario.gameArea;
  if (polygon) {
    const allAmsSnap = await firebase.amssIndex(catalogType).once('value');
    const allAms = allAmsSnap.exists() ? allAmsSnap.val() : {};
    const amsOfScenarioSnapshot = await firebase.amsPerScenario(scenario.id, catalogType).once('value');
    const amsOfScenario = amsOfScenarioSnapshot.exists() ? amsOfScenarioSnapshot.val() : { version: 'v0', values: {} };
    if (!amsOfScenario.values) {
      amsOfScenario.values = {};
    }
    const includedAmss = [];
    await asyncForEach(Object.keys(allAms), async (amsId) => {
      const ams = allAms[amsId];
      const amsPos = [ams.coordinate.latitude, ams.coordinate.longitude];
      if (inside(amsPos, polygon)) {
        amsOfScenario.values[amsId] = ams.version;
        includedAmss.push(amsId);
      } else if (amsOfScenario.values && amsOfScenario.values[amsId]) {
        delete amsOfScenario.values[amsId];
      }
    });
    const newVersion = Number.parseInt(amsOfScenario.version.substr(1), 10) + 1;
    amsOfScenario.version = `v${newVersion}`;

    if (!dryRun) {
      await firebase.amsPerScenario(scenario.id, catalogType).set(amsOfScenario);
    }
    return includedAmss;
  }
  return [];
};
