/* @flow */
import type { ReduxDispatch } from 'redux';
import Firebase from 'src/services/Firebase';
import * as Globals from 'src/constants/globals';
import * as actions from './actions';

const logHelperCall = (title, args) => {
  if (Globals.__DEV__) {
    console.log(`################# UserServiceHelper / ${title}`, args);
  }
};

export type loginType = (email: string, password: string, firebase: Firebase) => ReduxDispatch => Promise<void>;
export const loginAsync: loginType = (email, password, firebase) => async (dispatch) => {
  logHelperCall('loginAsync', { email, password });
  return firebase.doSignInWithEmailAndPassword(email, password).then(() => {
    dispatch(actions.setLastEmail(email));
  });
};

export type setUserType = (user: any) => ReduxDispatch => Promise<void>;
export const setUser: setUserType = user => async (dispatch) => {
  logHelperCall('setUser', { user });
  dispatch(actions.setUser(user));
};

export type logoutType = (firebase: Firebase) => ReduxDispatch => Promise<void>;
export const logoutAsync: logoutType = firebase => async () => {
  logHelperCall('logout', {});
  await firebase.doSignOut();
};
