/* @flow */
import * as xmlConvert from 'xml-js';
import BaseItem, { ItemTypes } from './BaseItem';
import type { ObjectMap } from './Shortcuts';

/* ***************************
  CLASS
*************************** */
export type AppPOI = {};

export const POIItemPOITypes = {
  Start: 'Start', // Start point of scenario
  Objective: 'Objective', // Main Objective for the scenario
  Major: 'Major', // An important point
  Interactive: 'Interactive', // A point with some interaction
  NPC: 'NPC', // A Point with an NPC
};

type POIItemPOITypesType = $Values<typeof POIItemPOITypes>;

export const POIItemProgressStates = {
  Hidden: 'Hidden',
  Visible: 'Visible',
  InProgress: 'InProgress',
  Completed: 'Completed',
  Archived: 'Archived',
};
export type POIItemProgressStatesType = $Values<typeof POIItemProgressStates>;

export default class POIItem extends BaseItem {
  coordinate: { latitude: number, longitude: number, altitude?: number };

  distanceMinToTrigger: number;

  visibleOnMap: boolean;

  autocompleteAfterLaunch: boolean;

  launchOnFirstReach: boolean;

  visibleAfterCompleted: boolean;

  poiTypes: ?ObjectMap<POIItemPOITypesType>;

  constructor(json: any) {
    if (json instanceof POIItem) {
      super(json, true);
    } else {
      super(json, false);
    }
    const {
      type,
      meta,
      coordinate,
      distanceMinToTrigger,
      visibleOnMap,
      autocompleteAfterLaunch,
      launchOnFirstReach,
      visibleAfterCompleted,
      poiType,
      poiTypes,
      ...newMeta
    } = json;
    this.type = ItemTypes.POI;
    this.coordinate = coordinate ? { ...coordinate } : { latitude: 0, longitude: 0 };
    this.distanceMinToTrigger = distanceMinToTrigger;
    this.visibleOnMap = visibleOnMap !== '' ? visibleOnMap : false;
    this.autocompleteAfterLaunch = autocompleteAfterLaunch !== '' ? autocompleteAfterLaunch : false;
    this.launchOnFirstReach = launchOnFirstReach !== '' ? launchOnFirstReach : false;
    this.visibleAfterCompleted = visibleAfterCompleted !== '' ? visibleAfterCompleted : false;
    this.poiTypes = { ...poiTypes };
    this.setMeta({ ...json.meta, ...newMeta });
  }

  serializeInheritedFieldsForApp() {
    const res = super.serializeInheritedFieldsForApp();
    res.type = ItemTypes.POI;
    res.coordinate = { ...this.coordinate };
    res.distanceMinToTrigger = this.distanceMinToTrigger;
    res.visibleOnMap = this.visibleOnMap;
    res.autocompleteAfterLaunch = this.autocompleteAfterLaunch;
    res.launchOnFirstReach = this.launchOnFirstReach;
    res.visibleAfterCompleted = this.visibleAfterCompleted;
    res.poiTypes = { ...this.poiTypes };
    return res;
  }

  serializeInheritedFields() {
    const res = super.serializeInheritedFields();
    res.type = ItemTypes.POI;
    res.coordinate = { ...this.coordinate };
    res.distanceMinToTrigger = this.distanceMinToTrigger;
    res.visibleOnMap = this.visibleOnMap;
    res.autocompleteAfterLaunch = this.autocompleteAfterLaunch;
    res.launchOnFirstReach = this.launchOnFirstReach;
    res.visibleAfterCompleted = this.visibleAfterCompleted;
    res.poiTypes = { ...this.poiTypes };
    return res;
  }

  checkRelease(items: ObjectMap<BaseItem>, locales: string[]) {
    const res = super.checkRelease(items, locales);

    if (this.poiTypes && !Object.keys(this.poiTypes).length) {
      res.push({
        level: 'warn',
        item: this.id,
        message: 'E_POI_NO_TYPE',
        details: { message: 'At least one POI type should be set' },
      });
    }
    return res;
  }

  getWptJson() {
    return {
      _attributes: {
        lat: this.coordinate.latitude,
        lon: this.coordinate.longitude,
      },
      ele: 0,
      name: this.id,
    };
  }

  serializeGpx() {
    const json = {
      _declaration: {
        _attributes: {
          version: '1.0',
        },
      },
      gpx: {
        metadata: {
          author: 'Atlantide editor',
          copyright: 'Atlantide',
        },
        _attributes: {
          version: '1.1',
          creator: 'Atlantide editor',
        },
        wpt: this.getWptJson(),
      },
    };
    const options = { compact: true, ignoreComment: true, spaces: 4 };
    const gpxContent = xmlConvert.json2xml(json, options);
    return gpxContent;
  }
}
