/* @flow */
import * as React from 'react';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap';

export interface InputNumberProps {
  style?: any;
  fieldName: string;
  value: number | string;
  label: string;
  help?: ?string;
  disabled: boolean;
  handleChange: any => any;
  hidden: boolean;
  separatorBefore?: boolean;
  inlineTitle?: boolean;
  onFocus?: () => any;
}

export default class InputNumber extends React.Component<InputNumberProps> {
  static defaultProps = {
    disabled: false,
    hidden: false,
    separatorBefore: false,
  };

  render() {
    const {
      fieldName,
      value,
      label,
      help,
      disabled,
      handleChange,
      hidden,
      separatorBefore,
      inlineTitle,
      onFocus,
      style,
    } = this.props;
    const divClass = inlineTitle ? 'input-group' : 'form-group';
    const labelClass = inlineTitle ? 'input-group-prepend mr-2' : '';
    return (
      <div className={divClass} key={fieldName} hidden={hidden} style={style}>
        {separatorBefore && <hr />}
        <label className={labelClass} htmlFor={fieldName}>
          {label}
        </label>
        <input
          type="number"
          className="form-control"
          id={fieldName}
          onChange={handleChange}
          value={value}
          disabled={disabled}
          aria-describedby={`${fieldName}Help`}
          placeholder={'...'}
          onFocus={onFocus}
        />
        {help && (
          <small id={`${fieldName}Help`} className="form-text text-muted">
            {help}
          </small>
        )}
      </div>
    );
  }
}
