/* @flow */
import React from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { compose } from 'redux';
import HelpButton from 'src/pages/components/HelpButton';
import { InputSelect } from 'src/pages/components';

import { BaseItem, ItemTypes, Discussion } from 'src/data';
import { getNewItem } from 'src/store/scenario/items/ItemsReducer';
import type { ItemTypesType } from 'src/data';
import { ItemsServiceHelper } from 'src/store/scenario/items';
import { DiagramModel } from 'storm-react-diagrams';
import { PreferencesServiceHelper } from 'src/store/preferences';
import GraphView from '../components/graph/GraphView';
import TriggeredItemNodeModel from '../components/graph/triggeredItem/TriggeredItemNodeModel';
import AddItemWidget from '../components/graph/AddItemWidget';
import { ItemNodeModel, BaseItemNodeModel, ItemLinkModel } from '../components/graph';
import BaseItemColorators from '../components/graph/baseItem/BaseItemColorations';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export type ScenarioGraphViewProps = {
  graphPrefs: any,
  zoom?: number,
  offsetX?: number,
  offsetY?: number,
  rootId: string,
  updatePrefZoom: PreferencesServiceHelper.updateGraphZoomType,
  updatePrefOffset: PreferencesServiceHelper.updateGraphOffsetType,
  items: { [s: string]: BaseItem },
  detachedItems: BaseItem[],
  itemSelected: (id: string, nodeId: string, itemType: ItemTypesType) => any,
  triggerSelected: (parentId: string, childId: string, nodeId: string) => any,
  itemDeleted: (id: string, nodeId: string) => any,
  addItem: ItemsServiceHelper.createItemType,
  updateItemPosition: ItemsServiceHelper.nodeMovedType,
  addTriggeredItem: ItemsServiceHelper.updateTriggeredItemType,
  updateTriggerPosition: ItemsServiceHelper.triggerNodeMovedType,
  removeTriggeredItem: ItemsServiceHelper.updateTriggeredItemType,
  hasDetachedNodes: boolean,
  hasStart: boolean,
  hasSuccess: boolean,
  scenarioId: string,
  selectedColorator: string,
  isEditingItem: boolean,
  forcedItemToFocus?: ItemNodeModel<any>,
};

type State = {};
class ScenarioGraphView extends React.PureComponent<ScenarioGraphViewProps, State> {
  static defaultProps = {};

  graphRef = undefined;

  state = {
    nodes: {},
    links: [],
    linksData: [],
    engine: undefined,
    errorMessage: undefined,
    selectedColorator: 'type',
  };

  constructor(props) {
    super(props);
    this.graphRef = React.createRef();
  }

  componentWillReceiveProps(newProps: ScenarioGraphViewProps) {
    if (newProps.scenarioId && newProps.scenarioId !== this.props.scenarioId) {
      if (this.graphRef && this.graphRef) {
        // this.graphRef.reload();
      }
    }
  }

  loadNode = (item: BaseItem) => {
    const { rootId } = this.props;
    const node = new BaseItemNodeModel(false, item, item && item.id === rootId);
    node.setPosition(item.pos.x, item.pos.y);
    return node;
  };

  loadGraphNodes = () => {
    const { items, detachedItems } = this.props;
    const nodes = {};
    if (items) {
      Object.values(items).forEach((element) => {
        if (element && element instanceof BaseItem && element.id) {
          const node = this.loadNode(element);
          nodes[element.nodeId] = node;
        }
      });
    }

    if (detachedItems) {
      detachedItems.forEach((element) => {
        if (element && element.nodeId) {
          const node = this.loadNode(element);
          nodes[element.nodeId] = node;
        }
      });
    }
    return nodes;
  };

  showColoratorSelection = () => {
    this.setState({ selectColoratorModal: true });
  };

  sleep = ms => new Promise(resolve => setTimeout(resolve, ms));

  addItemLink = async (
    source: ItemNodeModel<any>,
    target: ItemNodeModel<any>,
    link: ItemLinkModel,
    model: DiagramModel,
  ) => {
    if (source && target) {
      const { addTriggeredItem } = this.props;
      if (source.type !== 'TriggeredItem' && target.type !== 'TriggeredItem') {
        const triggerPos = {
          x: (source.x + target.x) / 2.0,
          y: (source.y + target.y) / 2.0,
        };
        const triggerNode = new TriggeredItemNodeModel(
          true,
          source.item,
          { id: target.item.id, pos: triggerPos },
          false,
        );
        triggerNode.setPosition(triggerPos.x, triggerPos.y);
        model.addAll(triggerNode);
        model.removeLink(link);
        await this.sleep(100); // Dirty but the only way i found to ensure the
        // node size is computed before linking
        const inLink = source.getOutPorts()[0].link(triggerNode.getInPorts()[0], true);
        const outLink = triggerNode.getOutPorts()[0].link(target.getInPorts()[0], true);

        inLink.setColor('#005aed');
        outLink.setColor('#003791');
        model.addLink(inLink);
        model.addLink(outLink);

        if (addTriggeredItem) {
          addTriggeredItem(source.item.id, target.item.id, triggerNode.id);
        }
      }
    }
  };

  removeItemLink = () => {};

  loadGraphLinks = (nodes, model) => {
    const { items } = this.props;
    const links = [];
    const linksData = [];

    if (items) {
      Object.values(items).forEach((source) => {
        if (source && source instanceof BaseItem && source.id) {
          const sourceNode = nodes[source.nodeId];
          if (source.triggeredItems) {
            source.triggeredItems.forEach((trigger) => {
              const triggerNodeId = trigger.nodeId;
              const targetId = trigger.id;
              const triggerNode = new TriggeredItemNodeModel(true, source, trigger, false);
              triggerNode.setPosition(trigger.pos.x, trigger.pos.y);
              model.addAll(triggerNode);
              const targetNodeId = items[targetId] && items[targetId].nodeId;
              if (targetNodeId) {
                const targetNode = nodes[targetNodeId];
                const sourcePort = sourceNode.getOutPorts()[0];
                const triggerIn = triggerNode.getInPorts()[0];
                const triggerOut = triggerNode.getOutPorts()[0];
                const targetPort = targetNode.getInPorts()[0];
                const link1 = sourcePort.link(triggerIn, true);
                const link2 = triggerOut.link(targetPort, true);
                link1.setColor('#005aed');
                link2.setColor('#003791');
                linksData.push({
                  out: source.nodeId,
                  in: triggerNodeId,
                  link: link1,
                });
                links.push(link1);
                linksData.push({
                  out: triggerNodeId,
                  in: targetNodeId,
                  link: link2,
                });
                links.push(link2);
              }
            });
          }
          if (source instanceof Discussion) {
            const messTriggeredItems = source.getMessageTriggeredItems();
            messTriggeredItems.forEach((target) => {
              const targetId = target.id;
              const targetNodeId = items[targetId] && items[targetId].nodeId;
              if (targetNodeId) {
                const targetNode = nodes[targetNodeId];
                const sourcePort = sourceNode.getOutPorts()[0];
                const targetPort = targetNode.getInPorts()[0];
                const link = sourcePort.link(targetPort, true);
                link.setColor('red');
                link.messageNodeId = target.nodeId;
                linksData.push({
                  out: source.nodeId,
                  in: targetNodeId,
                  link,
                });
                links.push(link);
              }
            });
          }
        }
      });
    }
    return { links, linksData };
  };

  coloratorToId = colorator => colorator.id;

  coloratorToLabel = colorator => this.props.t(colorator.name);

  renderDraggableWidgets = () => (
    <>
      <div
        className="widget-container"
        role="toolbar"
        aria-label="Draggable new items"
        style={{
          padding: 2,
          flexWrap: 'wrap',
          marginBottom: 10,
          borderRadius: 0,
        }}
      >
        <span className="category">{this.props.t("graph.common")}</span>
        <AddItemWidget
          type={ItemTypes.Start}
          disabled={this.props.hasStart}
          defaultContent={{
            type: ItemTypes.Start,
            id: 'start',
            nodeId: 'start',
          }}
        />
        <AddItemWidget
          type={ItemTypes.POI}
          defaultContent={{
            type: ItemTypes.POI,
            poiTypes: {
              Default: 'Interactive',
            },
          }}
        />
        <AddItemWidget type={ItemTypes.Document} />
        <AddItemWidget type={ItemTypes.Discussion} />
        <AddItemWidget type={ItemTypes.Openable} />
        <AddItemWidget type={ItemTypes.Anecdote} />

        <span className="category">{this.props.t("graph.gamesystem")}</span>
        <AddItemWidget
          type={ItemTypes.Success}
          disabled={this.props.hasSuccess}
          defaultContent={{
            type: ItemTypes.Success,
            id: 'success',
            nodeId: 'success',
          }}
        />
        <AddItemWidget type={ItemTypes.Checkpoint} />
        <AddItemWidget type={ItemTypes.Failure} />

        <span className="category">{this.props.t("graph.advanced")}</span>
        <AddItemWidget type={ItemTypes.Timer} />
        <AddItemWidget type={ItemTypes.GameArea} />

        <span className="category">{this.props.t("graph.atlantide")}</span>
        <AddItemWidget type={ItemTypes.TimeTravel} />
        <AddItemWidget type={ItemTypes.Archive} />
        <AddItemWidget type={ItemTypes.Custom} />
        <AddItemWidget type={ItemTypes.Tool} />
        <AddItemWidget type={ItemTypes.SecondaryMission} />


        <span className="category">{this.props.t("graph.colors")}</span>
        <InputSelect
          fieldName="selectedColorator"
          value={this.state.selectedColorator}
          values={Object.values(BaseItemColorators)}
          itemToId={this.coloratorToId}
          itemToTitle={this.coloratorToLabel}
          handleChange={this.selectColorator}
        />
      </div>

      <div className="floating-bar-top">
      </div>

      <div className="floating-bar">
        <div className={'zoom-button'} onClick={this.zoomToFit}>
          <FontAwesomeIcon icon={['fad', 'expand-alt']}/>
        </div>

        <HelpButton
          helpStrings={this.props.t(['helpStrings:scenario.dashboard.graph', ''], { returnObjects: true })}
          id="scenarioGraph"
          title="Graph help"
          containerClassName="help"
        />
      </div>
    </>
  );

  createDraggedNodeData = (draggedData: any, pos: any) => {
    const json = { ...draggedData, pos };
    return getNewItem(json);
  };

  createNodeModelFromItem = (item: BaseItem) => this.loadNode(item);

  addNodeToRedux = (item: BaseItem) => {
    const { addItem, itemSelected } = this.props;
    const { id, nodeId, type } = item;
    if (addItem) {
      addItem(id, item);
    }
    if (itemSelected) {
      itemSelected(id, nodeId, type);
    }
  };

  itemNodeMoved = (node: ItemNodeModel<any>, pos: { x: number, y: number }) => {
    const { updateItemPosition, updateTriggerPosition } = this.props;
    const { id, nodeId } = node.item;
    if (node.type === 'TriggeredItem') {
      if (updateTriggerPosition) {
        updateTriggerPosition(node.parent.id, nodeId, pos);
      }
    } else if (updateItemPosition) {
      updateItemPosition(id, nodeId, pos);
    }
  };

  nodeSelected = (node: ItemNodeModel<any>) => {
    const { id, nodeId, type } = node.item;
    const { itemSelected, triggerSelected } = this.props;
    if (node.type === 'TriggeredItem') {
      if (triggerSelected) {
        triggerSelected(node.parent.id, id, nodeId);
      }
    } else if (itemSelected) {
      itemSelected(id, nodeId, type);
    }
  };

  linkSelected = () => {};

  nodeRemoved = (node: ItemNodeModel<any>) => {
    const { id, nodeId } = node.item;
    const { itemDeleted, removeTriggeredItem } = this.props;
    if (node.type === 'TriggeredItem') {
      if (removeTriggeredItem) {
        removeTriggeredItem(node.parent.id, id, nodeId);
      }
    } else if (itemDeleted) {
      itemDeleted(id, nodeId);
    }
  };

  zoomUpdated = (evt) => {
    const { zoom } = evt;
    const { updatePrefZoom } = this.props;
    if (updatePrefZoom) {
      updatePrefZoom('scenario', zoom);
    }
  };

  offsetUpdated = (evt) => {
    const { offsetX, offsetY } = evt;
    const { updatePrefOffset } = this.props;
    if (updatePrefOffset) {
      updatePrefOffset('scenario', offsetX, offsetY);
    }
  };

  idForRoute = (route) => {
    const inPort = route.getInPort();
    const inParent = inPort && inPort.parent;
    const inType = inParent && inParent.type;
    const outPort = route.getOutPort();
    const outParent = outPort && outPort.parent;
    const outType = outParent && outParent.type;
    if (inParent && outParent) {
      if (outType === 'TriggeredItem') {
        return outParent.item.nodeId;
      }
      if (inType === 'TriggeredItem') {
        return inParent.item.nodeId;
      }
      // MessageTriggeredItem
      return route.messageNodeId;
    }
    return undefined;
  };

  isRouteReachable = (route, usedRoutes) => {
    const { items } = this.props;
    const inPort = route.getInPort();
    const inParent = inPort && inPort.parent;
    const inType = inParent && inParent.type;
    const outPort = route.getOutPort();
    const outParent = outPort && outPort.parent;
    const outType = outParent && outParent.type;
    if (inParent && outParent) {
      if (outType === 'TriggeredItem') {
        // Triggered item output
        const triggerSrcRedux = items[outParent.parent.id];
        if (triggerSrcRedux) {
          const triggerRedux = triggerSrcRedux.triggeredItems.find(trig => trig.nodeId === outParent.item.nodeId);
          if (triggerRedux) {
            const res = triggerSrcRedux.canReach(triggerRedux.condition, triggerRedux.conditionValue, usedRoutes);
            if (!res) {
              console.debug(
                'Trigger not accessible',
                triggerSrcRedux,
                triggerRedux.condition,
                triggerRedux.conditionValue,
              );
            }
            return res;
          }
          console.debug('Trigger not in redux', outParent.item.nodeId, triggerSrcRedux, outParent, triggerRedux);
        } else {
          console.debug('Input not in redux', inParent.item.id);
        }
      } else if (inType === 'TriggeredItem') {
        // Triggered item input
        const triggerSrcRedux = items[outParent.item.id];
        if (triggerSrcRedux) {
          const triggerRedux = triggerSrcRedux.triggeredItems.find(trig => trig.nodeId === inParent.item.nodeId);
          if (triggerRedux) {
            const res = triggerSrcRedux.canReach(triggerRedux.condition, triggerRedux.conditionValue, usedRoutes);
            if (!res) {
              console.debug(
                'Trigger not accessible',
                triggerSrcRedux,
                triggerRedux.condition,
                triggerRedux.conditionValue,
              );
            }
            return res;
          }
          console.debug('Trigger not in redux', triggerSrcRedux, inParent.item.nodeId);
        } else {
          console.debug('Input not in redux', inParent.item.id);
        }
      } else {
        // MessageTriggeredItem
        // TODO : Check if the messageTriggeredItem is reachable.
        return true;
      }
    }
    return false;
  };

  zoomToFit = () => {
    // $FlowFixMe unknown ref type
    if (this.graphRef && this.graphRef.zoomToFit) {
      this.graphRef.zoomToFit();
    }
  };

  selectColorator = (event: { target: { value: string } }) => {
    // $FlowFixMe unknown ref type
    const coloratorId = event.target.value;
    if (coloratorId) {
      const colorator = BaseItemColorators[coloratorId].colorator;
      this.setState({ selectedColorator: coloratorId });
      if (this.graphRef && this.graphRef.setColorator) {
        this.graphRef.setColorator(colorator);
      }
    }
  };

  render() {
    const { graphPrefs } = this.props;
    return (
      <div className="" id="graphContainer">
        <GraphView
          onRef={(ref) => {
            this.graphRef = ref;
          }}
          nodeSelected={this.nodeSelected}
          linkSelected={this.linkSelected}
          nodeRemoved={this.nodeRemoved}
          loadGraphNodes={this.loadGraphNodes}
          loadGraphLinks={this.loadGraphLinks}
          addItemLink={this.addItemLink}
          removeItemLink={this.removeItemLink}
          draggableWidgets={this.renderDraggableWidgets()}
          createDraggedNodeData={this.createDraggedNodeData}
          createNodeModelFromItem={this.createNodeModelFromItem}
          addNodeToRedux={this.addNodeToRedux}
          itemNodeMoved={this.itemNodeMoved}
          offsetUpdated={this.offsetUpdated}
          zoomUpdated={this.zoomUpdated}
          preferences={graphPrefs}
          isRouteReachable={this.isRouteReachable}
          idForRoute={this.idForRoute}
          canDelete={!this.props.isEditingItem}
          forcedItemToFocus={this.props.forcedItemToFocus}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const itemsRedux = state.scenario.items;
  const { __detachedNodes, ...others } = itemsRedux;
  const detachedItems = __detachedNodes && __detachedNodes.items;
  return {
    scenarioId: state.scenario.header.id,
    items: others,
    rootId: state.scenario.header.startItemId,
    detachedItems,
    hasDetachedNodes: detachedItems && detachedItems.length !== 0,
    graphPrefs: state.preferences.graphPreferences.scenario || {},
    hasStart: itemsRedux.start,
    hasSuccess: itemsRedux.success,
  };
};

const mapDispatchToProps = {
  updatePrefOffset: PreferencesServiceHelper.updateGraphOffset,
  updatePrefZoom: PreferencesServiceHelper.updateGraphZoom,
  addItem: ItemsServiceHelper.createItem,
  updateItemPosition: ItemsServiceHelper.itemNodeMoved,
  updateTriggerPosition: ItemsServiceHelper.triggerNodeMoved,
  addTriggeredItem: ItemsServiceHelper.addTriggeredItem,
  removeTriggeredItem: ItemsServiceHelper.removeTriggeredItem,
};

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  withTranslation(['default', 'helpStrings']),
)(ScenarioGraphView);
