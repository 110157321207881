/* @flow */

import firebase from 'firebase/app';
import 'firebase/database';
import { Reference, Database } from '@firebase/database';
import 'firebase/storage';
import 'firebase/auth';
import 'firebase/functions';
import type { ClaimType } from 'src/constants/roles';
import { Claims } from 'src/constants/roles';

import type { ScenarioVendingInfo, ObjectMap } from 'src/data';
import { LocalizedString, Scenario } from 'src/data';
import { asyncForEach } from 'src/utils';

import { CatalogTypes } from './types';
import type { CatalogType } from './types';

type Code = {
  id?: string,
  scenarioId: string,
  startDate?: Date,
  endDate?: Date,
  currentCount: number,
  maxNumber: number,
  _meta?: {
    info?: string,
  },
};

class Firebase {
  db: Database;

  auth: any;

  storage: any;

  functions: any;

  conn: Reference;

  currentRoom: Reference;

  roomId: ?string;

  userTokenRef: Reference;

  userTokenCallback: ?() => void;

  email: String;

  sessionStartDate: number;

  constructor() {
    const config = {
      apiKey: process.env.REACT_APP_API_KEY,
      authDomain: process.env.REACT_APP_AUTH_DOMAIN,
      databaseURL: process.env.REACT_APP_DATABASE_URL,
      projectId: process.env.REACT_APP_PROJECT_ID,
      storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
    };
    const app = firebase.initializeApp(config, 'current');

    this.db = app.database();
    this.auth = app.auth();
    this.storage = app.storage();
    this.functions = app.functions();

    this.auth.onIdTokenChanged(async (user) => {
      if (this.userTokenCallback) {
        this.userTokenRef.off('value', this.userTokenCallback);
        this.userTokenCallback = undefined;
        this.userTokenRef = undefined;
      }
      if (this.roomId) {
        const roomId = this.roomId;
        await this.leaveRoom();
        await this.enterRoom(roomId, user);
      }
      if (user) {
        // User is signed in or token was refreshed.
        this.email = user.email;
        if (
          !(await this.hasClaims(user, [
            Claims.Admin,
            Claims.Editor,
            Claims.confirmedEditor,
            Claims.Translator,
            Claims.Moderator,
          ]))
        ) {
          this.doSignOut();
          console.log('Insuficient rights to use this platform');
        }
        this.userTokenCallback = () => {
          user.getIdToken(true);
        };
        this.userTokenRef = this.userToken(user);
        this.userTokenRef.on('value', this.userTokenCallback);
        this.notifyConnected(user);
      } else {
        this.doSignOut();
      }
    });
  }

  // **** Authentication ****

  /*
   * Returns the claims that matched
   */
  hasClaims = async (user: any, claims: ClaimType[]) => {
    const res = [];
    const rights = user && (await user.getIdTokenResult());
    claims.forEach((claim) => {
      if (rights && rights.claims[claim]) {
        res.push(claim);
      }
    });
    return res;
  };

  updateSession = async () => {
    if (this.conn) {
      this.conn.transaction((currentData) => {
        if (currentData) {
          return { ...currentData, sessionRefreshDate: new Date().getTime() };
        }
      });
    }
  };

  notifyConnected = async (user: any) => {
    const connectedDevices = this.connectedDevices();
    if (this.conn) {
      this.conn.onDisconnect().cancel();
      this.conn.remove();
    }
    this.conn = connectedDevices.push();
    this.sessionStartDate = new Date().getTime();
    this.conn.set({
      email: user.email,
      sessionStartDate: this.sessionStartDate,
    });
    this.conn.onDisconnect().remove();
  };

  enterRoom = async (roomId: string, user: any) => {
    // console.log('coucou', roomId, user)
    if (this.currentRoom) {
      this.currentRoom.onDisconnect().cancel();
      this.currentRoom.remove();
    }
    const myRoom = this.room(roomId);
    this.currentRoom = myRoom.push();
    this.currentRoom.onDisconnect().remove();
    const data = {
      email: user.email,
      roomStartDate: new Date().getTime(),
      sessionStartDate: this.sessionStartDate || new Date().getTime(),
    };
    this.currentRoom.set(data);
    this.roomId = roomId;
    return this.currentRoom;
  };

  leaveRoom = async () => {
    if (this.currentRoom) {
      this.currentRoom.onDisconnect().cancel();
      this.currentRoom.remove();
      this.currentRoom = undefined;
    }
    this.roomId = undefined;
  };

  doSignInWithEmailAndPassword = async (email: string, password: string) => {
    const user = await this.auth.signInWithEmailAndPassword(email, password);
    return user;
  };

  doSignOut = () => {
    this.auth.signOut();
    if (this.userTokenCallback) {
      this.userTokenRef.off('value', this.userTokenCallback);
      this.userTokenCallback = undefined;
      this.userTokenRef = undefined;
    }
  };

  // *** User API ***

  userToken = (user: any): Reference => this.db.ref(`usersToken/${user.uid}/refreshTime`);

  connectedDevices = (): Reference => this.db.ref('editor/connections/users');

  rooms = (): Reference => this.db.ref('editor/connections/rooms');

  room = (roomId: string): Reference => this.rooms().child(roomId);

  user = (uid: string): Reference => this.db.ref(`users/${uid}`);

  users = (): Reference => this.db.ref('users');

  // **** Scenarios *****

  // Editor databases
  listAuthorizedScenarios = async (uid: string) => {
    const idsSnap = await this.userAuthorizedScenarios(uid).once('value');
    const idsObj = idsSnap.exists() ? idsSnap.val() : {};
    const scenarios = [];
    await asyncForEach(Object.keys(idsObj), async (id) => {
      const scenarioSnap = await this.scenario(id).once('value');
      scenarios.push(new Scenario(scenarioSnap.val().header));
    });
    return scenarios;
  };

  scenarios = (filter: string): Reference => {
    if (!filter) {
      return this.db.ref('editor/scenarios').orderByKey();
    }
    return this.db.ref('editor/scenarios').orderByChild(filter);
  };

  scenario = (scenarioId: string): Reference => this.db.ref(`editor/scenarios/${scenarioId}`);

  scenarioEditorHeader = (scenarioId: string): Reference => this.scenario(scenarioId).child('header');

  scenarioEditorItemsData = (scenarioId: string): Reference => this.scenario(scenarioId).child('itemsData');

  scenarioEditorChanges = (scenarioId: string, version: string): Reference => this.scenario(scenarioId).child(`changes/${version}`);

  scenarioEditorNPCs = (scenarioId: string): Reference => this.scenario(scenarioId).child('npcs');

  // Editor storage
  scenarioEditorStorage = (scenarioId: string): Reference => this.storage.ref(`editor/scenarios/${scenarioId}`);

  // App databases
  schoolScenarios = (catalog: CatalogType = CatalogTypes.prod): Reference => this.db.ref('releases/scenarios/schoolList').child(`catalog/${catalog}`);

  scenariosHeader = (catalog: CatalogType = CatalogTypes.prod): Reference => this.db.ref('releases/scenarios').child(`catalog/${catalog}`);

  scenarioHeader = (scenarioId: string, catalog: CatalogType = CatalogTypes.prod): Reference => this.scenariosHeader(catalog).child(`${scenarioId}`);

  scenarioVendingInfo = (scenarioId: string, catalog: CatalogType = CatalogTypes.prod) => this.scenarioHeader(scenarioId, catalog).child('vendingInfo');

  scenariosData = (): Reference => this.db.ref('releases/scenarios').child('data');

  scenarioData = (scenarioId: string): Reference => this.scenariosData().child(`${scenarioId}`);

  scenarioDataVersion = (scenarioId: string, version: string): Reference => this.scenarioData(scenarioId).child(`${version}`);

  scenariosSiblings = (catalogType: CatalogType = CatalogTypes.prod): Reference => this.db.ref(`releases/orderedScenarioSiblings/catalog/${catalogType}`);

  scenarioSiblings = (scenarioId: string, catalogType: CatalogType): Reference => this.scenariosSiblings(catalogType).child(`${scenarioId}`);

  // App storage
  scenarioStorage = (scenarioId: string): Reference => this.storage.ref(`releases/scenarios/${scenarioId}`);

  // ***** AMS *****

  amss = (): Reference => this.db.ref('editor/ams').orderByKey();

  ams = (amsId: string): Reference => this.db.ref(`editor/ams/${amsId}`);

  amsEditorStorage = (amsId: string): Reference => this.storage.ref(`editor/ams/${amsId}`);

  amssData = (): Reference => this.db.ref('releases/ams/data');

  amsData = (amsId: string): Reference => this.amssData().child(`${amsId}`);

  amsDataVersion = (amsId: string, version: string): Reference => this.amsData(amsId).child(version);

  amssIndex = (catalogType: CatalogType = CatalogTypes.prod): Reference => this.db.ref(`releases/ams/catalog/${catalogType}`);

  amsIndex = (amsId: string, catalogType: CatalogType = CatalogTypes.prod): Reference => this.amssIndex(catalogType).child(`${amsId}`);

  amsStorage = (amsId: string): Reference => this.storage.ref(`releases/ams/${amsId}`);

  // ****** AMS per scenario ******

  amsPerScenarios = (catalogType: CatalogType = CatalogTypes.prod): Reference => this.db.ref(`releases/amsPerScenario/catalog/${catalogType}`);

  amsPerScenario = (scenarioId: string, catalogType: CatalogType): Reference => this.amsPerScenarios(catalogType).child(`${scenarioId}`);

  // ***** Cities *****

  cities = (): Reference => this.db.ref('editor/cities');

  prodCities = (): Reference => this.db.ref('releases/cities/catalog/prod');

  city = (cityId: string): Reference => this.db.ref(`editor/cities/${cityId}`);

  cityRelease = (cityId: string, catalogType: CatalogType = CatalogTypes.prod): Reference => this.db.ref(`releases/cities/catalog/${catalogType}/${cityId}`);

  cityEditorStorage = (cityId: string): Reference => this.storage.ref(`editor/cities/${cityId}`);

  cityStorage = (cityId: string): Reference => this.storage.ref(`releases/cities/${cityId}`);

  // **** Admin ****

  // User
  getUserByEmailAsync = async (email: string) => {
    const result = await this.functions.httpsCallable('getUserByEmail')({ email });
    if (result.data) {
      return result.data.user;
    }
    throw new Error('Cannot get User');
  };

  userAuthorizedScenarios = (uid: string): Reference => this.db.ref(`users/${uid}/authorizedScenarios`);

  setUserRightsAsync = async (
    email: string,
    uid: string,
    claims: { admin: boolean, moderator: boolean, editor: boolean, confirmedEditor: boolean, translator: boolean },
    authorizedScenarios: ObjectMap<boolean>,
  ) => {
    await this.functions.httpsCallable('setCustomClaims')({ emails: [email], claims });
    await this.userAuthorizedScenarios(uid).set(authorizedScenarios);
  };

  migrateUsersAsync = async (
    userIds: string[],
    dryRun: boolean,
    removeOldData: boolean,
    catalog: CatalogType = CatalogTypes.prod,
  ) => {
    const result = await this.functions.httpsCallable('migrations-usersData')({
      userIds,
      catalog,
      dryRun,
      removeOldData,
    });
    if (result.data) {
      return result.data;
    }
    throw new Error('Cannot migrate Users');
  };

  migrateCEsAsync = async (dryRun: boolean, removeOldData: boolean) => {
    const result = await this.functions.httpsCallable('migrations-ceSkus')({ dryRun, removeOldData });
    if (result.data) {
      return result.data;
    }
    throw new Error('Cannot migrate CEs');
  };

  migrateCodesAsync = async (dryRun: boolean, removeOldData: boolean) => {
    const result = await this.functions.httpsCallable('migrations-codeSkus')({ dryRun, removeOldData });
    if (result.data) {
      return result.data;
    }
    throw new Error('Cannot migrate Codes');
  };

  // Catalog

  getPreconfiguredProducts = async () => {
    const allProductsSnap = await this.db.ref('products/items').once('value');
    const allProducts = allProductsSnap.val();
    const products = [];
    Object.keys(allProducts).forEach((it) => {
      const product = allProducts[it];
      if (product.expendable) {
        products.push({ ...product, id: it });
      }
    });
    return products;
  };

  getScenariosToDeployAsync = async () => {
    const result = await this.functions.httpsCallable('getScenariosToDeploy')({});

    const newScenarios = result.data.new;
    Object.keys(newScenarios).forEach((scenarioId) => {
      const scenarioInfo = newScenarios[scenarioId];

      scenarioInfo.name = new LocalizedString('name', scenarioInfo.name);
      scenarioInfo.subtitle = new LocalizedString('subtitle', scenarioInfo.subtitle);
    });
    const currentScenarios = result.data.current;
    Object.keys(currentScenarios).forEach((scenarioId) => {
      const scenarioInfo = currentScenarios[scenarioId];

      scenarioInfo.name = new LocalizedString('name', scenarioInfo.name);
      scenarioInfo.subtitle = new LocalizedString('subtitle', scenarioInfo.subtitle);
    });

    return { newScenarios, currentScenarios };
  };

  deployScenarioAsync = (
    scenarioId: string,
    vendingInfo: ScenarioVendingInfo,
    removeOldReleasesAccess: boolean,
    dryRun?: boolean,
  ) => this.functions.httpsCallable('deployScenario')({
    scenarioId,
    vendingInfo,
    removeOldReleasesAccess,
    dryRun,
  });

  checkScenarioVersionAsync = (scenarioId: string, versionToCheck?: string) => this.functions.httpsCallable('checkScenarioProdIntegrity')({ scenarioId, versionToCheck });

  updateVendingInfoAsync = (scenarioId: string, vendingInfo: ScenarioVendingInfo) => this.functions.httpsCallable('updateVendingInfo')({ scenarioId, vendingInfo, dryRun: false });

  dupplicateScenarioAsync = (destScenarioId: string, sourceScenarioId: string, destCityId: string) => this.functions.httpsCallable('dupplicateScenario')({
    sourceScenarioId,
    destScenarioId,
    destCityId,
    dryRun: false,
  });

  // AMS
  getAMSsToDeployAsync = async () => {
    const result = await this.functions.httpsCallable('getAMSsToDeploy')({});

    return { newAMSs: result.data.new, currentAMSs: result.data.current };
  };

  deployAMSsAsync = (amsIds: string[], dryRun?: boolean) => this.functions.httpsCallable('deployAMSs')({ amsIds, dryRun });

  // Codes
  codes = () => this.db.ref('codes');

  codeConfigurations = () => this.db.ref('codesConfigurations');

  generateSecretCode = (
    template: string,
    scenarioId: string,
    startDate?: number,
    endDate?: number,
    maxNumber?: number,
    metadata?: any,
  ) => this.functions.httpsCallable('generateSecretCode')({
    template,
    scenarioId,
    startDate,
    endDate,
    maxNumber,
    metadata,
  });

  bulkGenerateSecretCodes = (
    template: string,
    scenarioId: string,
    startDate?: number,
    endDate?: number,
    maxNumber?: number,
    codeCount: Number,
    metadata?: any,
  ) => this.functions.httpsCallable('bulkGenerateSecretCodes')({
    template,
    scenarioId,
    startDate,
    endDate,
    maxNumber,
    codeCount,
    metadata,
  });

  sendSecretCodesLabels = (codes: Code[], configurationId: string, recipients: string[], expirationDate: number) => this.functions.httpsCallable('sendSecretCodesLabels')({
    codes,
    configurationId,
    recipients,
    expirationDate,
  });

  // CE
  ces = () => this.db.ref('CE');

  // **** Configuration ****

  editorConfig = (): Reference => this.db.ref('editor/config');

  editorMaintenance = (): Reference => this.db.ref('editor/maintenance');

  editorDeployedVersion = (): Reference => this.db.ref('editor/deployedVersion');

  applicationVersions = (): Reference => this.db.ref('releases/appVersions');

  codeTypes = (): Reference => this.editorConfig().child('codeTypes');

  nextCustoms = (): Reference => this.editorConfig().child('nextCustoms');

  itemStatesMapping = (): Reference => this.editorConfig().child('itemStatesMapping');

  mapStyles = (): Reference => this.editorConfig().child('mapStyles');

  fontStyles = (): Reference => this.editorConfig().child('fontStyles');

  assetsBackgroundStorage = (): Reference => this.storage.ref('editor/imageEditor');

  assetBackgroundStorage = (assetId: string): Reference => this.assetsBackgroundStorage().child(assetId);

  imageUrl = (imagePath: string) => this.storage.ref(imagePath);

  // Statistics
  getScenarioSalesStatistics = (scenarioId: string, startDate?: string, endDate?: string, period?: string) => {
    const param = { scenarioId };
    if (startDate) {
      param.startDate = startDate;
    }
    if (endDate) {
      param.endDate = endDate;
    }
    if (period) {
      param.period = period;
    }
    return this.functions.httpsCallable('getScenarioSalesStatistics')(param);
  };

  getScenarioSuccessStatistics = (scenarioId: string, startDate?: string, endDate?: string, period?: string) => this.functions.httpsCallable('getScenarioSuccessStatistics')({
    scenarioId,
    startDate: startDate || undefined,
    endDate: endDate || undefined,
    period: period || undefined,
  });

  getCitySalesStatistics = (cityId: string, startDate?: string, endDate?: string, period?: string) => this.functions.httpsCallable('getCitySalesStatistics')({
    cityId,
    startDate,
    endDate,
    period,
  });

  translateString = (text: string, sourceLocale: string, destLocale: string) => this.functions.httpsCallable('translateString')({
    text,
    sourceLocale,
    destLocale,
  });
}

export default Firebase;

const Singleton: Firebase = new Firebase();

export { Singleton };
