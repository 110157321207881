/* eslint-disable no-param-reassign */
/* @flow */
import React from 'react';

import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  BaseItem, ItemTypes, POIItemPOITypes, LocalizedStringArray, AMSItem,
} from 'src/data';
import type { ItemProgressionType, MetricEvent } from 'src/data/BaseItem';
import type { screenPlayItemType } from 'src/store/configuration/ConfigurationReducer';
import { ItemsServiceHelper } from 'src/store/scenario/items';

import { EventsServiceHelper, NotificationTypes } from 'src/store/events';
import * as Routes from 'src/constants/routes';
import { withTranslation } from 'react-i18next';
import { compose } from 'redux';
import LocalizedFile from 'src/data/LocalizedFile';
import Firebase, { withFirebase, FirebaseHelper } from 'src/services/Firebase';
import Loader from 'src/pages/components/loader';
import InputLocalizedFiles from 'src/pages/components/inputs/InputLocalizedFiles';
import InputLocalizedFile from 'src/pages/components/inputs/InputLocalizedFile';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  InputBoolean,
  InputString,
  InputSelect,
  InputNumber,
  InputProgress,
  InputMetrics,
  InputPoiTypes,
  InputLocks,
} from 'src/pages/components/inputs';
import { asyncForEach, getAltitude } from 'src/utils';
import * as Globals from 'src/constants/globals';
import { getNewAtlObject } from 'src/store/scenario/items/ItemsReducer';
import { AmsServiceHelper } from '../../store/ams';

type State = {
  id?: string,
  idSuffix: string,
  contentType: string,
  content?: string,
  isGoodAnswer: boolean,
  isValid: boolean,
  nextCustom?: string,
  clues?: string,
  metricEvents: MetricEvent[],
  progression: ItemProgressionType,
  filesToRemove: string[],
  hasChanges: boolean,
  isIdValid: boolean,
  isLoading: boolean,
  idName: string,
};

export type AnswerInputProps = {
  itemId?: string,
  disabled?: boolean,
  itemStates: screenPlayItemType,
  itemUnlockedValues: number[],
  nodeId: string,
  item: BaseItem,
  title: string,
  idPrefix: (state: any) => string,
  idSuffix: string,
  locale: string,
  isCreate?: boolean,
  createItem: AmsServiceHelper.createAmsType,
  updateItem: ItemsServiceHelper.updateItemType,
  deleteItem: AmsServiceHelper.removeAmsType,
  fieldsDescription: any,
  idRequiredFieldsDescription: any,
  marker: number[],
  polygon: number[][],
  t: (key: string[]) => String,
  scenarioId: string,
  firebase: Firebase,
  existingItemIds: string[],
  hideBaseItemFields?: boolean,
  hideClues?: boolean,
  addNotif: EventsServiceHelper.addNotifType,
  isAms?: boolean,
  idKey?: string,
  isEditingItem: boolean,
  startEditing: () => any,
  defaultCoordinate: number[],
};

class DefaultItemInput extends React.PureComponent<AnswerInputProps, State> {
  static defaultProps = {
    idPrefix: undefined,
    idSuffix: '',
    canChangeId: true,
    idKey: 'nodeId',
  };

  state = {
    id: undefined,
    idName: '',
    contentType: '',
    metricEvents: [],
    progression: {},
    isGoodAnswer: false,
    isValid: false,
    hasChanges: false,
    clues: undefined,
    filesToRemove: [],
    isIdValid: false,
    isLoading: false,
    idSuffix: '',
    idPrefix: '',
  };

  componentDidMount() {
    this.setItemData(this.props, false, false);
  }

  componentDidUpdate = async (oldProps: AnswerInputProps, oldState: State) => {
    const { idKey } = this.props;
    // $FlowFixMe indexer
    if (oldProps.item && (!this.props.item || this.props.item[idKey] !== oldProps.item[idKey])) {
      await this.warnSaveIfNeeded(oldProps, oldState);
    } else {
      // console.log(oldProps.item, this.props.item)
    }
    if (this.props.nodeId !== oldProps.nodeId) {
      this.setItemData(this.props, false, false);
    } else {
      if (oldProps.marker !== this.props.marker) {
        this.setItemData(this.props, true, false);
      }
      if (oldProps.polygon !== this.props.polygon) {
        this.setItemData(this.props, false, true);
      }
    }
  };

  componentWillUnmount() {
    this.warnSaveIfNeeded();
  }

  warnSaveIfNeeded = async (props?: AnswerInputProps = this.props, state?: State = this.state) => {
    if (this.state.hasChanges && (props.scenarioId || props.isAms)) {
      const updateData = await this.getDataToSave(props, state);
      const { isValid } = state;
      const { t } = props;
      if (isValid) {
        this.props.addNotif(NotificationTypes.WARN, 'W_UNSAVED_ITEM', undefined, 0, {
          title: t(['general.save', '']),
          callback: async () => {
            if (props.scenarioId || props.isAms) {
              this.updateWithData(updateData, false);
            } else {
              this.props.addNotif(NotificationTypes.ERROR, 'E_UNSAVED_ITEM', undefined, 0);
            }
          },
          closeOnCallback: true,
        });
      } else {
        this.props.addNotif(NotificationTypes.ERROR, 'E_UNSAVED_ITEM_INVALID', undefined, 0);
      }
    }
  };

  loadFields = (item, description, newState, locale, marker, polygon, markerOnly: boolean, polygonOnly: boolean) => {
    const doAll = !markerOnly && !polygonOnly;
    description.forEach((desc) => {
      switch (desc.type) {
        case 'localizedString': {
          if (doAll) {
            // $FlowFixMe indexer
            const value = item[desc.name];
            const translated = value && value.valueForLocale(locale);
            newState[desc.name] = translated;
          } else {
            newState[desc.name] = this.state[desc.name];
          }
          break;
        }
        case 'latLngAlt':
        case 'coordinate': {
          if (doAll || markerOnly) {
            let textValue;
            if (marker) {
              textValue = JSON.stringify(marker);
            } else {
              // $FlowFixMe
              const value = item[desc.name];
              const altitudeStr = value.altitude ? `, ${value.altitude}` : '';
              textValue = value && `[${value.latitude}, ${value.longitude}${altitudeStr}]`;
            }
            newState[desc.name] = textValue;
          } else {
            newState[desc.name] = this.state[desc.name];
          }
          break;
        }
        case 'GameArea': {
          if (doAll || polygonOnly) {
            let textValue;
            if (polygon) {
              textValue = JSON.stringify(polygon, undefined, 4);
            } else {
              // $FlowFixMe indexer
              const value = item[desc.name];
              textValue = value && `[\n\t${value.map(it => `[${it[0]}, ${it[1]}]`).join(',\n\t')}\n]`;
            }
            newState[desc.name] = textValue;
          } else {
            newState[desc.name] = this.state[desc.name];
          }
          break;
        }
        case 'DocumentItemLock':
        case 'poiTypes':
          if (doAll) {
            // $FlowFixMe indexer
            newState[desc.name] = item[desc.name];
          } else {
            newState[desc.name] = this.state[desc.name];
          }
          break;
        case 'json': {
          if (doAll) {
            // $FlowFixMe indexer
            const value = item[desc.name];
            newState[desc.name] = JSON.stringify(value, undefined, 4);
          } else {
            newState[desc.name] = this.state[desc.name];
          }
          break;
        }
        case 'number':
          if (doAll) {
            // $FlowFixMe indexer
            newState[desc.name] = `${item[desc.name]}`;
            // $FlowFixMe indexer
            if (item[desc.name] === undefined) {
              newState[desc.name] = '';
            }
          } else {
            newState[desc.name] = this.state[desc.name];
          }
          break;
        default:
          if (doAll) {
            // $FlowFixMe indexer
            newState[desc.name] = item[desc.name] || '';
          } else {
            newState[desc.name] = this.state[desc.name];
          }
          break;
      }
    });
  };

  setItemData = (props: AnswerInputProps, markerOnly: boolean, polygonOnly: boolean) => {
    const {
      locale, item, fieldsDescription, idRequiredFieldsDescription, idPrefix, idSuffix, marker, polygon,
    } = props;
    const id = item && item.id;
    let idName;
    const idPrefixVal = idPrefix ? idPrefix(item || this.state) : '';
    if (idSuffix && idSuffix.length) {
      idName = id ? id.slice(idPrefixVal.length, -1 * idSuffix.length) : '';
    } else {
      idName = id ? id.slice(idPrefixVal.length) : '';
    }
    const val = item && item.clues && item.clues.valueForLocale(locale, true);
    const newState = {
      idName: idName || '',
      clues: val && val.length ? JSON.stringify(val) : '[]',
      progression: item && item.progression,
      metricEvents: item && item.metricEvents,
      hasChanges: false,
      filesToRemove: [],
    };
    if (item) {
      this.loadFields(item, idRequiredFieldsDescription, newState, locale, marker, polygon, markerOnly, polygonOnly);
      this.loadFields(item, fieldsDescription, newState, locale, marker, polygon, markerOnly, polygonOnly);
    }
    this.setState(newState);
    this.updateValidity(newState);
  };

  handleFileChange = (event) => {
    if (!this.props.isEditingItem) {
      this.props.startEditing();
    }
    const files = event.target.files;
    // $FlowFixMe Object.values
    const fileNames = Object.values(files).map(file => file.name);
    this.handleChange({ target: { value: fileNames, id: event.target.id } });
  };

  onFieldFocus = () => {
    if (!this.props.isEditingItem) {
      this.props.startEditing();
    }
  };

  handleChange = (event) => {
    if (!this.props.isEditingItem) {
      this.props.startEditing();
    }
    const value = event.target.value;
    const fieldName = event.target.id;
    this.setState({ [fieldName]: value, hasChanges: true });
    const newVal = { ...this.state };
    newVal[fieldName] = value;
    this.updateValidity(newVal);
  };

  updateValidity = (newVal) => {
    console.debug(newVal);

    const { idPrefix, idSuffix, existingItemIds } = this.props;
    const { idName } = newVal;
    const idPrefixVal = idPrefix ? idPrefix(this.state) : '';
    const itemId = idPrefixVal + idName + idSuffix;
    const isIdValid = idName.length && itemId.match(Globals.idRegex) && !existingItemIds.includes(itemId);

    const isValid = (!!this.props.item && !!this.props.item.id) || !!isIdValid;
    this.setState({ isIdValid: !!isIdValid, isValid });
  };

  deleteItem = async () => {
    const { deleteItem, item, firebase } = this.props;
    if (item) {
      deleteItem(item.id, firebase);
    }
  };

  getFieldsDataToSave = async (fields, newItem, state, locale) => {
    await asyncForEach(fields, async (desc) => {
      const stateVal = state[desc.name];
      switch (desc.type) {
        case 'localizedString': {
          if (stateVal) {
            // $FlowFixMe indexer
            newItem[desc.name].setValueForLocale(stateVal, locale);
          } else {
            // $FlowFixMe indexer
            newItem[desc.name].flush();
          }
          break;
        }
        case 'coordinate': {
          const json = JSON.parse(stateVal);
          // $FlowFixMe indexer
          newItem[desc.name] = json && {
            latitude: json[0],
            longitude: json[1],
          };
          if (json[2] !== undefined) {
            // $FlowFixMe indexer
            newItem[desc.name].altitude = json[2];
          }
          break;
        }
        case 'latLngAlt': {
          if (!stateVal) {
            break;
          }
          const json = JSON.parse(stateVal);
          let value = json;
          if (value.length < 3) {
            try {
              value = await getAltitude(value[0], value[1]);
            } catch (error) {
              // TODO NOTIF
            }
          }
          // $FlowFixMe indexer
          newItem[desc.name] = value && {
            latitude: value[0],
            longitude: value[1],
            altitude: value[2],
          };
          break;
        }
        case 'GameArea': {
          const json = JSON.parse(stateVal);
          // $FlowFixMe indexer
          newItem[desc.name] = json;
          break;
        }
        case 'number': {
          // $FlowFixMe indexer
          newItem[desc.name] = stateVal && stateVal.length ? parseInt(stateVal, 10) : undefined;
          break;
        }
        case 'DocumentItemLock':
        case 'poiTypes': {
          // $FlowFixMe indexer
          newItem[desc.name] = stateVal;
          break;
        }
        case 'json': {
          const json = JSON.parse(state[desc.name]);
          // $FlowFixMe indexer
          newItem[desc.name] = json;
          break;
        }
        case 'boolean': {
          // $FlowFixMe indexer
          newItem[desc.name] = stateVal !== '' ? stateVal : false;
          break;
        }
        default:
          // $FlowFixMe indexer
          newItem[desc.name] = stateVal;
          break;
      }
    });
    return newItem;
  };

  getDataToSave = async (props?: AnswerInputProps = this.props, state?: State = this.state) => {
    const {
      locale,
      item,
      fieldsDescription,
      idRequiredFieldsDescription,
      idPrefix,
      idSuffix,
      scenarioId,
      isCreate,
      updateItem,
      createItem,
    } = props;

    if (!item) {
      return undefined;
    }

    let newItem = getNewAtlObject(item);
    const idPrefixVal = idPrefix ? idPrefix(state) : '';
    newItem.id = idPrefixVal + state.idName + idSuffix;

    if (newItem instanceof BaseItem) {
      newItem.clues = new LocalizedStringArray(`${item.id}_clues`, item.clues, false);
      const parsedClues = JSON.parse(state.clues || '[]');
      newItem.clues.setValueForLocale(parsedClues, locale);
      newItem.clues.initAllLocalesIfRequired();
      newItem.metricEvents = state.metricEvents;
      newItem.progression = state.progression;
    }

    newItem = await this.getFieldsDataToSave(idRequiredFieldsDescription, newItem, state, locale);
    newItem = await this.getFieldsDataToSave(fieldsDescription, newItem, state, locale);
    const { filesToRemove } = state;
    return {
      newItem,
      filesToRemove: [...filesToRemove],
      scenarioId,
      isCreate,
      updateItem,
      createItem,
    };
  };

  updateWithData = async (updateData, notifyUi: boolean = false) => {
    const { firebase } = this.props;
    if (updateData) {
      const {
        filesToRemove, newItem, scenarioId, isCreate, updateItem, createItem,
      } = updateData;
      if (notifyUi) {
        this.setState({ isLoading: true });
      }
      if (filesToRemove && filesToRemove.length) {
        await FirebaseHelper.removeEditorFilesAsync(scenarioId, filesToRemove, 'scenario', firebase);
      }
      if (updateItem) {
        if (scenarioId && newItem instanceof BaseItem) {
          await updateItem(scenarioId, newItem.id, newItem, firebase);
        } else if (isCreate) {
          await createItem(newItem, true, firebase);
        } else {
          // $FlowFixMe TODO: See how to do it better
          await updateItem(newItem.id, newItem, firebase);
        }
        if (notifyUi) {
          this.setState({ hasChanges: false, isLoading: false });
        }
      }
    }
  };

  updateItem = async () => {
    const updateData = await this.getDataToSave();
    this.updateWithData(updateData, true);
  };

  renderFileField = (fieldName, value, label, help = undefined, disabled = false, multiple = false) => (
    <div className="form-group" key={fieldName}>
      <label htmlFor={fieldName}>{label}</label>
      <div className="custom-file">
        <input
          type="file"
          className="form-control custom-file-input"
          id={fieldName}
          onChange={this.handleFileChange}
          disabled={disabled}
          aria-describedby={`${fieldName}Help`}
          placeholder={'...'}
          multiple={multiple}
          disabled={this.props.disabled}
        />
        <label className="custom-file-label" style={{ overflow: 'hidden', wordWrap: '...' }} htmlFor={fieldName}>
          {value && value.join(', ')}
        </label>
      </div>
      {help && (
        <small id={`${fieldName}Help`} className="form-text text-muted">
          {help}
        </small>
      )}
    </div>
  );

  fileSuffix = (fieldName) => {
    const { fieldsDescription } = this.props;
    const desc = fieldsDescription.find(it => it.name === fieldName);
    return desc && desc.suffix;
  };

  addFile = (fieldName) => {
    const field = [...this.state[fieldName]];
    const max = field.reduce((acc, cur) => (cur.index >= acc ? cur.index + 1 : acc), 0);
    const suffix = this.fileSuffix(fieldName);
    field.push(new LocalizedFile(this.props.item.id, suffix, { index: max }));
    this.setState({ [fieldName]: field, hasChanges: true });
  };

  removeFile = (fieldName, index) => {
    let field = [...this.state[fieldName]];
    const fileToRemove = field.find(it => it.index === index);
    field = field.filter(it => it.index !== index);
    this.setState({ [fieldName]: field, hasChanges: true });
    const itemFilesToRemove = fileToRemove.listStorageFiles();
    if (itemFilesToRemove.length) {
      let { filesToRemove } = this.state;
      if (filesToRemove) {
        filesToRemove = [...filesToRemove, ...itemFilesToRemove];
      } else {
        filesToRemove = itemFilesToRemove;
      }
      this.setState({ filesToRemove });
    }
  };

  handleFileSelected = (fieldName: string, locale: string, file: File, index: ?number = undefined) => {
    const field = index === undefined ? this.state[fieldName] : [...this.state[fieldName]];
    let oldFile;
    if (index !== undefined) {
      oldFile = field.find(it => it.index === index);
    } else {
      oldFile = field;
    }
    const suffix = this.fileSuffix(fieldName);
    const newFile = new LocalizedFile(this.props.item.id, suffix, oldFile);
    const itemFilesToRemove = oldFile.listStorageFiles(locale);
    if (!newFile.hasLocale(locale)) {
      newFile.addLocale(locale);
    }
    newFile.files[locale].contentToUpload = file;
    newFile.files[locale].name = file.name;
    let newField;
    if (index !== undefined) {
      newField = field.map((it) => {
        if (it.index === index) {
          return newFile;
        }
        return it;
      });
    } else {
      newField = newFile;
    }
    this.setState({ [fieldName]: newField, hasChanges: true });
    if (itemFilesToRemove.length) {
      let { filesToRemove } = this.state;
      if (filesToRemove) {
        filesToRemove = [...filesToRemove, ...itemFilesToRemove];
      } else {
        filesToRemove = itemFilesToRemove;
      }
      this.setState({ filesToRemove });
    }
  };

  handleContentChange = (fieldName: string, value: string, index: ?number = undefined) => {
    const { locale } = this.props;
    const field = index === undefined ? this.state[fieldName] : [...this.state[fieldName]];
    let oldFile;
    if (index !== undefined) {
      oldFile = field.find(it => it.index === index);
    } else {
      oldFile = field;
    }
    const suffix = this.fileSuffix(fieldName);
    const newFile = new LocalizedFile(this.props.item.id, suffix, oldFile);
    newFile.content.values[locale] = value;
    let newField;
    if (index !== undefined) {
      newField = field.map((it) => {
        if (it.index === index) {
          return newFile;
        }
        return it;
      });
    } else {
      newField = newFile;
    }
    this.setState({ [fieldName]: newField, hasChanges: true });
  };

  addFileLocale = (fieldName: string, locale: string, index = undefined) => {
    const field = index === undefined ? this.state[fieldName] : [...this.state[fieldName]];
    let oldFile;
    if (index !== undefined) {
      oldFile = field.find(it => it.index === index);
    } else {
      oldFile = field;
    }
    const suffix = this.fileSuffix(fieldName);
    const newFile = new LocalizedFile(this.props.item.id, suffix, oldFile);
    newFile.addLocale(locale);
    let newField;
    if (index !== undefined) {
      newField = field.map((it) => {
        if (it.index === index) {
          return newFile;
        }
        return it;
      });
    } else {
      newField = newFile;
    }
    this.setState({ [fieldName]: newField, hasChanges: true });
  };

  removeFileLocale = (fieldName: string, locale: string, index = undefined) => {
    const field = index === undefined ? this.state[fieldName] : [...this.state[fieldName]];
    let oldFile;
    if (index !== undefined) {
      oldFile = field.find(it => it.index === index);
    } else {
      oldFile = field;
    }
    const suffix = this.fileSuffix(fieldName);
    const newFile = new LocalizedFile(this.props.item.id, suffix, oldFile);
    const itemFilesToRemove = newFile.listStorageFiles(locale);
    newFile.removeLocale(locale);
    let newField;
    if (index !== undefined) {
      newField = field.map((it) => {
        if (it.index === index) {
          return newFile;
        }
        return it;
      });
    } else {
      newField = newFile;
    }

    this.setState({ [fieldName]: newField, hasChanges: true });
    if (itemFilesToRemove.length) {
      let { filesToRemove } = this.state;
      if (filesToRemove) {
        filesToRemove = [...filesToRemove, ...itemFilesToRemove];
      } else {
        filesToRemove = itemFilesToRemove;
      }
      this.setState({ filesToRemove });
    }
  };

  renderCommonFields = () => [
    // eslint-disable-next-line react/jsx-key
    <InputString
      fieldName="clues"
      onFocus={this.onFieldFocus}
      value={this.state.clues}
      label={this.props.t(['screens.scenarioEdition.baseItemEdition.cluesLabel', ''])}
      help={this.props.t(['screens.scenarioEdition.baseItemEdition.cluesHelp', ''])}
      handleChange={this.handleChange}
      multiline={true}
      hidden={this.props.hideClues}
      disabled={this.props.disabled}
    />,
    // eslint-disable-next-line react/jsx-key
    <InputMetrics
      fieldName="metricEvents"
      onFocus={this.onFieldFocus}
      value={this.state.metricEvents}
      itemUnlockedStates={this.props.itemUnlockedValues}
      label={this.props.t(['screens.scenarioEdition.baseItemEdition.metricEventsLabel', ''])}
      availableStates={this.props.itemStates}
      help={this.props.t(['screens.scenarioEdition.baseItemEdition.progressionHelp', ''])}
      handleChange={this.handleChange}
      hidden={this.props.hideBaseItemFields}
      disabled={this.props.disabled}
    />,
    // eslint-disable-next-line react/jsx-key
    <InputProgress
      fieldName="progression"
      onFocus={this.onFieldFocus}
      value={this.state.progression}
      label={this.props.t(['screens.scenarioEdition.baseItemEdition.progressionLabel', ''])}
      availableStates={this.props.itemStates}
      help={this.props.t(['screens.scenarioEdition.baseItemEdition.progressionHelp', ''])}
      handleChange={this.handleChange}
      hidden={this.props.hideBaseItemFields}
      disabled={this.props.disabled}
    />,
  ];

  renderFields = (item, fieldsDescription, locale) => fieldsDescription.map((descr) => {
    const {
      type, name, value, values, disabled, multiline, multiple, separatorBefore, hidden,
    } = descr;
    const { t } = this.props;
    const forceMultiline = type === 'coordinate' || type === 'latLngAlt' || type === 'GameArea' || type === 'json';
    const label = t([`screens.scenarioEdition.baseItemEdition.${name}Label`, '']);
    const help = t([`screens.scenarioEdition.baseItemEdition.${name}Help`, '']);
    switch (type) {
      case 'string':
      case 'localizedString':
      case 'coordinate':
      case 'latLngAlt':
      case 'GameArea':
      case 'json':
        return (
            <InputString
              key={name}
              onFocus={this.onFieldFocus}
              separatorBefore={separatorBefore}
              fieldName={name}
              value={this.state[name]}
              label={label}
              help={help}
              disabled={disabled || this.props.disabled}
              handleChange={this.handleChange}
              multiline={multiline || forceMultiline}
              helpStrings={t(`helpStrings:scenario.dashboard.input.${type}`, { returnObjects: true })}
              hidden={hidden && hidden(this.state)}
            />
        );

      case 'DocumentItemLock':
        return (
            <InputLocks
              key={name}
              onFocus={this.onFieldFocus}
              separatorBefore={separatorBefore}
              fieldName={name}
              value={this.state[name]}
              label={label}
              help={help}
              disabled={disabled || this.props.disabled}
              handleChange={this.handleChange}
              helpStrings={t(`helpStrings:scenario.dashboard.input.${type}`, { returnObjects: true })}
              hidden={hidden && hidden(this.state)}
            />
        );
      case 'poiTypes':
        return (
            <InputPoiTypes
              key={name}
              onFocus={this.onFieldFocus}
              separatorBefore={separatorBefore}
              fieldName={name}
              value={this.state[name]}
              itemUnlockedStates={this.props.itemUnlockedValues}
              label={label}
              help={help}
              disabled={disabled || this.props.disabled}
              handleChange={this.handleChange}
              helpStrings={t(`helpStrings:scenario.dashboard.input.${type}`, { returnObjects: true })}
              hidden={hidden && hidden(this.state)}
            />
        );
      case 'number':
        return (
            <InputNumber
              key={name}
              onFocus={this.onFieldFocus}
              separatorBefore={separatorBefore}
              fieldName={name}
              value={this.state[name]}
              label={label}
              help={help}
              disabled={disabled || this.props.disabled}
              handleChange={this.handleChange}
              hidden={hidden && hidden(this.state)}
              disabled={this.props.disabled}
            />
        );
      case 'boolean':
        return (
            <InputBoolean
              key={name}
              onFocus={this.onFieldFocus}
              separatorBefore={separatorBefore}
              fieldName={name}
              value={this.state[name]}
              label={label}
              help={help}
              disabled={disabled || this.props.disabled}
              handleChange={this.handleChange}
              hidden={hidden && hidden(this.state)}
              disabled={this.props.disabled}
            />
        );
      case 'files':
        return this.renderFileField(name, this.state[name], label, help, disabled, multiple);
      case 'LocalizedFile':
        return (
            <InputLocalizedFile
              key={name}
              onFocus={this.onFieldFocus}
              separatorBefore={separatorBefore}
              fieldName={name}
              value={this.state[name]}
              label={label}
              help={help}
              disabled={disabled || (!item || !item.id) || this.props.disabled}
              handleFileSelected={this.handleFileSelected}
              handleContentChange={this.handleContentChange}
              addFileLocale={this.addFileLocale}
              removeFileLocale={this.removeFileLocale}
              contentLocale={locale}
              hidden={hidden && hidden(this.state)}
              disabled={this.props.disabled}
            />
        );
      case 'LocalizedFiles':
        return (
            <InputLocalizedFiles
              key={name}
              onFocus={this.onFieldFocus}
              separatorBefore={separatorBefore}
              fieldName={name}
              value={this.state[name]}
              label={label}
              help={help}
              disabled={disabled || (!item || !item.id) || this.props.disabled}
              addFile={() => this.addFile(name)}
              removeFile={this.removeFile}
              handleFileSelected={this.handleFileSelected}
              handleContentChange={this.handleContentChange}
              addFileLocale={this.addFileLocale}
              removeFileLocale={this.removeFileLocale}
              contentLocale={locale}
              hidden={hidden && hidden(this.state)}
            />
        );
      case 'enum':
        return (
            <InputSelect
              fieldName={name}
              onFocus={this.onFieldFocus}
              value={this.state[name]}
              values={values}
              itemToId={it => it}
              itemToTitle={it => it}
              label={label}
              help={help}
              disabled={disabled || this.props.disabled}
              handleChange={this.handleChange}
            />
        );
      case 'link':
        return (
            <Link key={value} to={value}>
              <button className="btn btn-primary full-width mb-3" type="button" id={name} disabled={disabled}>
                {name}
              </button>
            </Link>
        );
      default:
        return <div key={name}>{`${name}: ${this.state[name]}(${type})`}</div>;
    }
  });

  render() {
    const {
      title,
      item,
      fieldsDescription,
      idRequiredFieldsDescription,
      locale,
      idPrefix,
      idSuffix,
      deleteItem,
      t,
    } = this.props;
    const {
      idName, isValid, hasChanges, isIdValid, isLoading,
    } = this.state;

    const idPrefixVal = idPrefix ? idPrefix(this.state) : '';
    let saveBtnClass = hasChanges ? 'btn-warning' : 'btn-secondary';
    if (!isValid) {
      saveBtnClass = 'btn-danger';
    }
    return (
      <div className="card bg-light screenBlock" style={{ height: '100%', overflow: 'hidden' }}>
        <div className="card-header">
          <h3>{title}</h3>
        </div>
        {item && (
          <div className="pr-3 ml-3 mt-3" style={{ height: '100%', overflow: 'scroll', paddingBottom: 60 }}>
            {idRequiredFieldsDescription && this.renderFields(item, idRequiredFieldsDescription, locale)}
            <InputString
              fieldName="idName"
              value={idName}
              onFocus={this.onFieldFocus}
              label={t(['screens.scenarioEdition.baseItemEdition.idLabel', ''])}
              help={t(['screens.scenarioEdition.baseItemEdition.idPlaceholder', ''])}
              handleChange={this.handleChange}
              suffix={idSuffix}
              prefix={idPrefixVal}
              disabled={(!!item && !!item.id) || this.props.disabled}
              helpInfos={t(['helpStrings:scenario.dashboard.input.id', ''], { returnObjects: true })}
            />
            {(!item || !item.id) && idName && !isIdValid && (
              <label style={{ color: 'red', fontSize: 12 }}>{t(['general.idInvalid', ''])}</label>
            )}
            {item && item.id && this.renderFields(item, fieldsDescription, locale)}
            {item && item.id && this.renderCommonFields()}
            <div
              className={`mb-3 ${saveBtnClass} save-btn interactive`}
              id="updateitem"
              onClick={this.updateItem}
              disabled={!isValid || this.props.disabled}
            >
              <FontAwesomeIcon icon={['fad', 'save']} />
            </div>
            {deleteItem && (
              <button
                className={'btn mb-3 ml-2 btn-outline-danger'}
                type="button"
                id="updateitem"
                onClick={this.deleteItem}
                disabled={!isValid || this.props.disabled}
              >
                <FontAwesomeIcon icon={['fad', 'times']} />
              </button>
            )}
          </div>
        )}
        {isLoading && <Loader />}
      </div>
    );
  }
}

const fieldsDescriptionFromType = (type, item, items, state, scenarioId) => {
  switch (type) {
    case ItemTypes.Anecdote:
      return [
        {
          type: 'localizedString',
          name: 'contentText',
          // $FlowFixMe mixed
          value: item.contentText,
          multiline: true,
          disabled: false,
          separatorBefore: true,
        },
      ];
    case ItemTypes.Archive:
      return [
        {
          type: 'LocalizedFiles',
          multiple: true,
          name: 'images',
          suffix: undefined,
          // $FlowFixMe mixed
          value: item.images,
          disabled: false,
          separatorBefore: true,
        },
        {
          type: 'localizedString',
          name: 'name',
          // $FlowFixMe mixed
          value: item.name,
          disabled: false,
          separatorBefore: true,
        },
        {
          type: 'localizedString',
          name: 'subtitle',
          // $FlowFixMe mixed
          value: item.subtitle,
          disabled: false,
        },
        {
          type: 'localizedString',
          name: 'contentText',
          // $FlowFixMe mixed
          value: item.contentText,
          multiline: true,
          disabled: false,
        },
        {
          type: 'localizedString',
          name: 'annotations',
          // $FlowFixMe mixed
          value: item.annotations,
          disabled: false,
        },
      ];
    case ItemTypes.Checkpoint:
      return [];
    case ItemTypes.Custom:
      return [
        {
          type: 'string',
          name: 'customContent',
          // $FlowFixMe mixed
          value: item.customContent,
          disabled: false,
          separatorBefore: true,
        },
        {
          type: 'json',
          name: 'additionalInfo',
          // $FlowFixMe mixed
          value: item.additionalInfo,
          multiline: true,
          disabled: false,
        },
      ];
    case ItemTypes.Document:
      return [
        {
          type: 'LocalizedFiles',
          multiple: true,
          name: 'images',
          suffix: undefined,
          // $FlowFixMe mixed
          value: item.images,
          disabled: false,
          separatorBefore: true,
        },
        {
          type: 'localizedString',
          name: 'name',
          // $FlowFixMe mixed
          value: item.name,
          disabled: false,
          separatorBefore: true,
        },
        {
          type: 'localizedString',
          name: 'additionalInfo',
          // $FlowFixMe mixed
          value: item.additionalInfo,
          multiline: true,
          disabled: false,
        },
        {
          type: 'localizedString',
          name: 'origin',
          // $FlowFixMe mixed
          value: item.origin,
          disabled: false,
        },
        {
          type: 'DocumentItemLock',
          name: 'locks',
          // $FlowFixMe mixed
          value: item.locks,
          disabled: false,
          separatorBefore: true,
        },
        {
          type: 'LocalizedFile',
          multiple: true,
          name: 'lockedImage',
          suffix: 'locked',
          // $FlowFixMe mixed
          value: item.lockedImage,
          // $FlowFixMe
          hidden: (state: State) => !state.locks || !state.locks.length,
        },
        {
          type: 'number',
          name: 'timeOnError',
          // $FlowFixMe mixed
          value: item.timeOnError,
          disabled: false,
          separatorBefore: true,
          // $FlowFixMe
          hidden: (state: State) => !state.locks || !state.locks.length,
        },
      ];
    case ItemTypes.Discussion:
      return [
        {
          type: 'link',
          name: 'edit',
          value: Routes.SCENARIO_DISCUSSION_EDITION.replace(':scenarioId', scenarioId).replace(
            ':discussionId',
            // $FlowFixMe mixed
            item.id,
          ),
          // $FlowFixMe mixed
          disabled: !item.id,
        },
      ];
    case ItemTypes.Failure:
      return [
        {
          type: 'localizedString',
          name: 'message',
          // $FlowFixMe mixed
          value: item.message,
          multiline: true,
          disabled: false,
          separatorBefore: true,
        },
        {
          type: 'boolean',
          name: 'canRetry',
          // $FlowFixMe mixed
          value: item.canRetry,
          disabled: false,
          separatorBefore: true,
        },
        {
          type: 'boolean',
          name: 'canContinue',
          // $FlowFixMe mixed
          value: item.canContinue,
          disabled: false,
        },
      ];
    case ItemTypes.GameArea:
      return [
        {
          type: 'GameArea',
          name: 'area',
          // $FlowFixMe mixed
          value: item.area,
          disabled: false,
          separatorBefore: true,
        },
        {
          type: 'localizedString',
          name: 'title',
          // $FlowFixMe mixed
          value: item.title,
          disabled: false,
          separatorBefore: true,
        },
        {
          type: 'localizedString',
          name: 'message',
          // $FlowFixMe mixed
          value: item.message,
          multiline: true,
          disabled: false,
        },
      ];
    case ItemTypes.Openable:
      return [
        {
          type: 'localizedString',
          name: 'description',
          // $FlowFixMe mixed
          value: item.description,
          multiline: true,
          disabled: false,
          separatorBefore: true,
        },
        {
          type: 'localizedString',
          name: 'validateText',
          // $FlowFixMe mixed
          value: item.validateText,
          disabled: false,
        },
      ];

    case ItemTypes.POI:
      return [
        {
          type: 'latLngAlt',
          name: 'coordinate',
          // $FlowFixMe mixed
          value: item.coordinate,
          disabled: false,
          separatorBefore: true,
        },
        {
          type: 'number',
          name: 'distanceMinToTrigger',
          // $FlowFixMe mixed
          value: item.distanceMinToTrigger,
          disabled: false,
        },
        {
          type: 'boolean',
          name: 'visibleOnMap',
          // $FlowFixMe mixed
          value: item.visibleOnMap,
          disabled: false,
          separatorBefore: true,
        },
        {
          type: 'boolean',
          name: 'autocompleteAfterLaunch',
          // $FlowFixMe mixed
          value: item.autocompleteAfterLaunch,
          disabled: false,
        },
        {
          type: 'boolean',
          name: 'launchOnFirstReach',
          // $FlowFixMe mixed
          value: item.launchOnFirstReach,
          disabled: false,
        },
        {
          type: 'boolean',
          name: 'visibleAfterCompleted',
          // $FlowFixMe mixed
          value: item.visibleAfterCompleted,
          disabled: false,
        },
        {
          type: 'poiTypes',
          name: 'poiTypes',
          // $FlowFixMe mixed
          value: item.poiTypes,
          values: Object.values(POIItemPOITypes),
          disabled: false,
        },
      ];
    case ItemTypes.SecondaryMission:
      return [];
    case ItemTypes.Start:
      return [];
    case ItemTypes.Success:
      return [];
    case ItemTypes.Timer:
      return [
        {
          type: 'number',
          name: 'duration',
          // $FlowFixMe mixed
          value: item.duration,
          disabled: false,
          separatorBefore: true,
        },
      ];
    case ItemTypes.TimeTravel:
      return [
        {
          type: 'number',
          name: 'year',
          // $FlowFixMe mixed
          value: item.year,
          disabled: false,
          separatorBefore: true,
        },
        {
          type: 'localizedString',
          name: 'yearText',
          // $FlowFixMe mixed
          value: item.yearText,
          disabled: false,
        },
        {
          type: 'localizedString',
          name: 'transitionText',
          // $FlowFixMe mixed
          value: item.transitionText,
          disabled: false,
        },
        {
          type: 'enum',
          name: 'mapStyleName',
          // $FlowFixMe mixed
          value: item.mapStyleName,
          values: state.configuration.mapStyles,
          disabled: false,
        },
        {
          type: 'enum',
          name: 'fontStyleName',
          // $FlowFixMe mixed
          value: item.fontStyleName,
          values: state.configuration.fontStyles,
          disabled: false,
        },
      ];
    case ItemTypes.Tool:
      return [
        {
          type: 'LocalizedFiles',
          multiple: true,
          name: 'images',
          suffix: undefined,
          // $FlowFixMe mixed
          value: item.images,
          disabled: false,
          separatorBefore: true,
        },
        {
          type: 'localizedString',
          name: 'name',
          // $FlowFixMe mixed
          value: item.name,
          disabled: false,
          separatorBefore: true,
        },
        {
          type: 'localizedString',
          name: 'additionalInfo',
          // $FlowFixMe mixed
          value: item.additionalInfo,
          multiline: true,
          disabled: false,
        },
        {
          type: 'localizedString',
          name: 'origin',
          // $FlowFixMe mixed
          value: item.origin,
          disabled: false,
        },
        {
          type: 'string',
          name: 'technicalName',
          // $FlowFixMe mixed
          value: item.technicalName,
          disabled: false,
        },
      ];
    default:
      return [];
  }
};

const mapStateToProps = (state, ownProps) => {
  const { itemId, nodeId, t } = ownProps;
  const items = state.scenario.items;
  let itemRedux;
  let description = [];
  if (!itemId) {
    itemRedux = items.__detachedNodes.items.find(it => it.nodeId === nodeId);
    if (!itemRedux) {
      // $FlowFixMe Object.values
      itemRedux = Object.values(items).find(it => it.nodeId === nodeId);
    }
  } else {
    itemRedux = items[itemId];
  }
  const scenarioId = state.scenario.header.id;
  const title = itemRedux && t(['screens.scenarioEdition.baseItemEdition.sectionTitlePrefix', '']) + itemRedux.type;
  let idSuffix = '';
  if (itemRedux) {
    description = fieldsDescriptionFromType(itemRedux.type, itemRedux, items, state, scenarioId);
    switch (itemRedux.type) {
      case ItemTypes.Anecdote:
        idSuffix = '_anecdote';
        break;
      case ItemTypes.Archive:
        idSuffix = '_archive';
        break;
      case ItemTypes.Checkpoint:
        idSuffix = '_checkpt';
        break;
      case ItemTypes.Custom:
        idSuffix = '_custom';
        break;
      case ItemTypes.Discussion:
        idSuffix = '_disc';
        break;
      case ItemTypes.Document:
        idSuffix = '_doc';
        break;
      case ItemTypes.Failure:
        idSuffix = '_failure';
        break;
      case ItemTypes.Openable:
        idSuffix = '_openable';
        break;
      case ItemTypes.GameArea:
        idSuffix = '_zone';
        break;
      case ItemTypes.POI:
        idSuffix = '_poi';
        break;
      case ItemTypes.SecondaryMission:
        idSuffix = '_secondary';
        break;
      case ItemTypes.TimeTravel:
        idSuffix = '_tt';
        break;
      case ItemTypes.Timer:
        idSuffix = '_timer';
        break;
      case ItemTypes.Tool:
        idSuffix = '_tool';
        break;
      default:
        break;
    }
  }
  // $FlowFixMe Object.values
  const itemIds = Object.values(items).map(it => it.id);
  const itemStates = itemRedux && itemRedux.type && state.configuration.screenplay[itemRedux.type];
  return {
    idSuffix,
    scenarioId: state.scenario.header.id,
    item: itemRedux,
    title,
    locale: state.preferences.editionLocale,
    fieldsDescription: description,
    existingItemIds: itemIds,
    itemStates,
    itemUnlockedValues: itemRedux && itemRedux.unlockedValues && Object.values(itemRedux.unlockedValues),
    hideClues: !itemRedux || itemRedux.type !== ItemTypes.Document,
    idRequiredFieldsDescription: [],
  };
};

const mapDispatchToProps = {
  updateItem: ItemsServiceHelper.updateItem,
  addNotif: EventsServiceHelper.addNotif,
};

export default compose(
  withFirebase,
  withTranslation(['default', 'helpStrings']),
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
)(DefaultItemInput);

const mapStateToAMSProps = (state, ownProps) => {
  const { itemId, t } = ownProps;
  const items = state.ams;
  let description = [];
  let idRequiredFieldsDescription = [];
  let itemRedux = items[itemId];
  const title = itemRedux && t(['screens.scenarioEdition.baseItemEdition.sectionTitlePrefix', '']) + itemRedux.type;
  let idSuffix = '';
  let isCreate = false;
  if (!itemRedux && !!ownProps.marker) {
    itemRedux = new AMSItem({ type: ItemTypes.AMS });
    isCreate = true;
  }
  const cityIds = state.configuration.availableCities.map(city => city.id);
  if (itemRedux) {
    description = [
      {
        type: 'latLngAlt',
        name: 'coordinate',
        value: itemRedux.coordinate,
        disabled: false,
        separatorBefore: true,
      },
      {
        type: 'number',
        name: 'height',
        value: itemRedux.height,
        disabled: false,
      },
      {
        type: 'number',
        name: 'startYear',
        value: itemRedux.startYear,
        disabled: false,
        separatorBefore: true,
      },
      {
        type: 'localizedString',
        name: 'name',
        value: itemRedux.name,
        disabled: false,
      },
      {
        type: 'localizedString',
        name: 'subtitle',
        value: itemRedux.subtitle,
        disabled: false,
      },
      {
        type: 'localizedString',
        name: 'contentText',
        value: itemRedux.contentText,
        disabled: false,
        multiline: true,
      },
      {
        type: 'localizedString',
        name: 'annotations',
        value: itemRedux.annotations,
        disabled: false,
      },
    ];
    idRequiredFieldsDescription = [
      {
        type: 'enum',
        name: 'cityId',
        value: itemRedux.cityId,
        values: cityIds,
        disabled: !!itemRedux.id,
        emptyPlaceholder: 'Out of city',
      },
    ];
    idSuffix = '_ams';
  }
  // $FlowFixMe Object.values
  const itemIds = Object.values(items).map(it => it.id);
  return {
    idSuffix,
    idPrefix: viewState => (viewState.cityId ? `${viewState.cityId}_` : '_'),
    item: itemRedux,
    isCreate,
    title,
    locale: state.preferences.editionLocale,
    fieldsDescription: description,
    idRequiredFieldsDescription,
    existingItemIds: itemIds,
    hideBaseItemFields: true,
    hideClues: true,
    itemsStates: [],
    idKey: 'id',
    isAms: true,
  };
};

const mapDispatchToAMSProps = {
  updateItem: AmsServiceHelper.updateAms,
  createItem: AmsServiceHelper.createAms,
  deleteItem: AmsServiceHelper.removeAms,
  addNotif: EventsServiceHelper.addNotif,
};

const AMSInput = compose(
  withFirebase,
  withTranslation(['default', 'helpStrings']),
  connect(
    mapStateToAMSProps,
    mapDispatchToAMSProps,
  ),
)(DefaultItemInput);

export { AMSInput };
