/* @flow */
import { Singleton, LocaleDefaults } from 'src/store/scenario/header/ScenarioLocaleManager';
import type { ObjectMap } from './Shortcuts';

export const cleanupString = (value?: string = '') => {
  const quoteReplacer = (correspondance, p1) => `“${p1}”`;
  let res = value.trim();
  res = res.replace(/[ ]*\\n[ ]*/g, '\n');
  res = res.replace(/"([^"]*)"/, quoteReplacer);
  return res;
};

export const getValueForLocale = () => {};
export default class LocalizedString {
  id: string;

  defaultLocale: string;

  values: ObjectMap<string>; // locale/value

  constructor(id: string, values: any = {}, translate: boolean = true) {
    const { defaultLocale, defaultValues } = Singleton.localizedStringParams(translate);
    this.id = id;
    let valuesToUse = values;
    if (values.values) {
      valuesToUse = values.values;
    }
    const cleanedValues = {};
    Object.keys(valuesToUse).forEach(locale => (cleanedValues[locale] = cleanupString(valuesToUse[locale])));
    this.defaultLocale = defaultLocale;
    this.values = { ...defaultValues, ...cleanedValues };
  }

  addLocale(locale: string, value?: string) {
    this.values[locale] = cleanupString(value) || LocaleDefaults[locale];
  }

  flush() {
    Object.keys(this.values).forEach((locale) => {
      this.values[locale] = '';
    });
  }

  removeLocale(locale: string) {
    delete this.values[locale];
  }

  hasLocale(locale: string) {
    return this.values[locale];
  }

  valueForLocale(locale: string, defaultIfTrue: boolean = false): string {
    const val = this.values[locale];
    if (val || !defaultIfTrue) {
      return val;
    }
    return this.values[this.defaultLocale];
  }

  setValueForLocale(value: string, locale: string) {
    this.values[locale] = cleanupString(value);
  }

  isEmpty() {
    return (
      !Object.keys(this.values).length
      /* $FlowFixMe: Object.values */
      || Object.values(this.values).reduce((acc, cur: string) => acc || !cur || cur.length, false) === 0
    );
  }

  requireTranslations() {
    return Object.keys(this.values).length > 1;
  }

  serializeForApp() {
    const res = { ...this.values };
    return res;
  }

  serialize() {
    const res = { ...this.values };
    return res;
  }

  checkRelease(locales: string[]): { level: string, item: string, message: string, details?: any }[] {
    const res = [];
    // Check that the content is available in all locales
    if (!this.hasLocale('default')) {
      locales.forEach((loc) => {
        if (loc !== 'default' && !this.hasLocale(loc)) {
          res.push({
            level: 'warn',
            item: this.id,
            message: 'E_STRING_MISSING_IN_LOCALE',
            details: { message: `No translation for ${this.id} in ${loc}` },
          });
        }
      });
    }
    return res;
  }

  applyTranslations(translationObj: { [locale: string]: string }) {
    const cleanedTranslations = {};
    Object.keys(translationObj).forEach(
      locale => (cleanedTranslations[locale] = cleanupString(translationObj[locale])),
    );
    if (translationObj) {
      this.values = { ...this.values, ...cleanedTranslations };
    }
  }

  getCSVContent() {
    return { ...this.values };
  }
}
