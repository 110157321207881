/* @flow */
import * as React from 'react';

import { connect } from 'react-redux';
import $ from 'jquery';
import Flag from 'react-world-flags';
import 'bootstrap/dist/css/bootstrap.min.css';

// eslint-disable-next-line import/no-extraneous-dependencies
import { object } from 'prop-types'; // TODO

import packageJson from 'src/../package.json';
import Firebase, { withFirebase } from 'src/services/Firebase';
import { ScenarioServiceHelper } from 'src/store/scenario';
import type { ScenarioReducerState } from 'src/store/scenario';
import { HeaderServiceHelper } from 'src/store/scenario/header';
import { PreferencesServiceHelper } from 'src/store/preferences';
import type { AtlEditorState } from 'src/store';
import { withTranslation } from 'react-i18next';
import { compose } from 'redux';
import * as ROUTES from 'src/constants/routes';
import { AmsServiceHelper } from 'src/store/ams';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import TutorialModal from '../tutorial/TutorialModal';

import '../Sidebar/style/style-default.css';
import '../../App/App.css';
import { Sidebar } from '../Sidebar';
import Breadcrumb from './Breadcrumb';
import { RoomMembersView } from 'src/pages/components';

export type NavBarProps = {
  updateOnFirebase: () => Promise<void>,
  cleanupScenarios: ScenarioServiceHelper.cleanupType,
  cleanupAms: AmsServiceHelper.cleanupType,
  cleanupUrlProperties: PreferencesServiceHelper.cleanupUrlPropertiesType,
  firebase: Firebase,
  scenarioId?: string,
  ams?: string,
  cities?: string,
  t: (key: string[]) => string,
  logo: any,
  showSaveBtn: boolean,
  editionLocale: string,
};

export type state = {
  jsonType: string,
  redirect?: string,
};

class NavBar extends React.Component<NavBarProps, state> {
  static contextTypes = {
    router: object,
  };

  state = {
    jsonType: 'none',
    redirect: undefined,
  };

  toogleExportType = (event) => {
    const newValue = event.target.id;
    this.setState({ jsonType: newValue });
  };

  closeContent = async (forceClose: boolean = false) => {
    const {
      scenarioId, ams, cleanupScenarios, cleanupAms, cleanupUrlProperties,
    } = this.props;
    let shouldRedirect = true;
    if (ams) {
      await cleanupAms();
    }
    if (scenarioId) {
      if (forceClose) {
        await cleanupScenarios();
      } else {
        shouldRedirect = false;
        $('#saveModal').modal();
      }
    }
    if (shouldRedirect) {
      await cleanupUrlProperties();
      this.context.router.history.push(ROUTES.EDITOR_DASHBOARD);
    }
  };

  forceCloseContent = async () => this.closeContent(true);

  saveAndClose = async () => {
    const { updateOnFirebase } = this.props;
    await updateOnFirebase();
    await this.closeContent(true);
  };

  renderSaveModal = () => (
    <div id="saveModal" className="modal fade" tabIndex="-1" role="dialog">
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">{this.props.t(['navbar.saveModalTitle', ''])}</h5>
            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <p>{this.props.t(['navbar.saveModalMessage', ''])}</p>
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-secondary" data-dismiss="modal">
              {this.props.t(['general.cancel', ''])}
            </button>
            <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={this.forceCloseContent}>
              {this.props.t(['general.close', ''])}
            </button>
            <button type="button" className="btn btn-primary" data-dismiss="modal" onClick={this.saveAndClose}>
              {this.props.t(['general.save', ''])}
            </button>
          </div>
        </div>
      </div>
    </div>
  );

  render() {
    const {
      showSaveBtn, logo, updateOnFirebase, scenarioId, cities, ams, t,
    } = this.props;
    // const { redirect } = this.state;
    // if (redirect) {
    //   return <Redirect to={redirect} push />;
    // }
    return (
      <div id="mainnavbar">
        <nav className="navbar navbar-expand" id="main">
          <Sidebar logo={logo} version={packageJson.version} />

          <div id="right-part">
            <ul className="navbar-nav" id="handle-buttons">
              <TutorialModal />

              {scenarioId && <FontAwesomeIcon icon={['far', 'horizontal-rule']} className="delimiter" />}

              {scenarioId && showSaveBtn && (
                <li className="nav-item" onClick={updateOnFirebase} id="saveOnFirebase">
                  <a>
                    <div>
                      <FontAwesomeIcon icon={['fad', 'save']} />
                      {t(['navbar.saveOnFirebase', ''])}
                    </div>
                  </a>
                </li>
              )}

              {(!!scenarioId || !!ams || !!cities) && (
                <li className="nav-item" onClick={() => this.closeContent(false)}>
                  <a>
                    <div>
                      <FontAwesomeIcon icon={['fad', 'times']} />
                      {t(['general.close', ''])}
                    </div>
                  </a>
                </li>
              )}
            </ul>
          </div>

          {/* }
          <div className="collapse navbar-collapse">
            <Breadcrumb />

            <div className="d-flex flex-fill flex-row-reverse">
            </div>
          </div>
          */}
        </nav>
        {(!!scenarioId || !!ams || !!cities) && (
          <div id="underbar">
            <div>
              <span className="text-light">{t(['navbar.editableLanguage', '']).toUpperCase()}</span>
              <Flag code={this.props.editionLocale} height="16" className="ml-2 mr-2" />
            </div>

            {scenarioId && <Breadcrumb />}
          </div>
        )}
        {this.renderSaveModal()}
      </div>
    );
  }
}

const mapStateToProps = (state: AtlEditorState) => ({
  scenarioId: state.scenario.header && state.scenario.header.id,
  showSaveBtn: state.preferences.urlProperties.scenarioId,
  editionLocale: state.preferences.editionLocale === 'en' ? 'GB' : state.preferences.editionLocale,
  ams: state.preferences.urlProperties.ams,
  cities: state.preferences.urlProperties.cities,
});

const mapDispatchToProps = {
  cleanupScenarios: ScenarioServiceHelper.cleanup,
  cleanupAms: AmsServiceHelper.cleanup,
  cleanupUrlProperties: PreferencesServiceHelper.cleanupUrlProperties,
};

export default compose(
  withFirebase,
  withTranslation('default'),
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
)(NavBar);
