/* @flow */
import React from 'react';

import { connect } from 'react-redux';

import Firebase, { withFirebase } from 'src/services/Firebase';
import { UserServiceHelper } from 'src/store/user';
import type { AtlEditorState } from 'src/store';

import { withTranslation } from 'react-i18next';
import { compose } from 'redux';

export type UserViewProps = {
  logout: UserServiceHelper.logoutType,
  firebase: Firebase,
  email: string,
  t: (key: string[]) => string,
};

type State = {};

class UserView extends React.PureComponent<UserViewProps, State> {
  static defaultProps = {};

  state = {};

  logout = () => {
    const { firebase, logout } = this.props;
    if (logout) {
      logout(firebase);
    }
  };

  render() {
    const { email, t } = this.props;

    return (
      <div className="card bg-light screenBlock">
        <div className="card-header">
          <h5 style={{ marginBottom: 0 }}>{t(['screens.settings.user.sectionTitle', ''])}</h5>
        </div>
        <div className="mr-3 ml-3 mt-3">{email}</div>
        <div className="mr-3 ml-3 mt-3">
          <button className={'btn mb-3 btn-primary'} type="button" id="button-addon2" onClick={this.logout}>
            {t(['screens.settings.user.logout', ''])}
          </button>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: AtlEditorState) => ({
  email: state.user.user && state.user.user.email,
});

const mapDispatchToProps = dispatch => ({
  logout: firebase => UserServiceHelper.logoutAsync(firebase)(dispatch),
});

export default compose(
  withFirebase,
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  withTranslation('default'),
)(UserView);
