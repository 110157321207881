/* @flow */
import React from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router';

import 'bootstrap/dist/css/bootstrap.min.css';
import * as Globals from 'src/constants/globals';
import { ScenarioServiceHelper } from 'src/store/scenario';
import Loader from 'src/pages/components/loader';
import { withAuthorization, AuthenticatedCondition } from 'src/services/Session';
import { Claims } from 'src/constants/roles';
import { withCities } from 'src/pages/cities/WithCities';

import type { ObjectMap, City } from 'src/data';
import { withTranslation } from 'react-i18next';
import { compose } from 'redux';
import Firebase, { withFirebase, FirebaseHelper } from 'src/services/Firebase';
import * as Routes from 'src/constants/routes';
import { InputString, InputSelect } from '../components/inputs';

export type NewScenarioScreenProps = {
  importScenario: ScenarioServiceHelper.importScenarioDataType,
  resetScenario: ScenarioServiceHelper.cleanupType,
  createScenario: ScenarioServiceHelper.createScenarioType,
  dupplicateScenarioAsync: FirebaseHelper.dupplicateScenarioAsyncType,
  firebase: Firebase,
  engineVersion: number,
  cities: City[],
  locale: string,
};

type State = {
  isValid: boolean,
  headerJson?: any,
  contentJson?: any,
  headerName?: string,
  contentName?: string,
  createNew: boolean,
  createScenarioId: string,
  scenarioId?: string,
  cityId?: string,
  sourceId?: string,
  loading: boolean,
  imported: boolean,
  hasChanges: boolean,
};

class NewScenarioScreen extends React.PureComponent<NewScenarioScreenProps, State> {
  static defaultProps = {};

  reader: ?FileReader = undefined;

  state = {
    isValid: false,
    headerJson: undefined,
    contentJson: undefined,
    contentName: undefined,
    headerName: undefined,
    createNew: false,
    imported: false,
    createScenarioId: '',
    cityId: '',
    sourceId: '',
    scenarioId: undefined,
    loading: false,
    hasChanges: false,
  };

  createScenario = () => {
    const { resetScenario, createScenario, firebase } = this.props;
    const { createScenarioId } = this.state;
    if (resetScenario) {
      resetScenario();
    }
    if (createScenario && createScenarioId) {
      createScenario(createScenarioId, firebase)
        .then(() => {
          this.setState({ createNew: true, scenarioId: createScenarioId });
        })
        .catch((error) => {
          console.error(error);
        });
    }
  };

  dupplicateScenario = async () => {
    this.setState({ loading: true });
    const { createScenarioId, sourceId, cityId } = this.state;
    try {
      await this.props.dupplicateScenarioAsync(createScenarioId, sourceId, cityId);
      // TODO Open scenario
      this.setState({ loading: false });
    } catch (error) {
      console.warn('Cannot dupplicate scenario', error);
      this.setState({ loading: false });
    }
  };

  updateValidity(contentJson, loading) {
    this.setState({
      isValid: !loading && !!contentJson,
    });
  }

  handleChange = (event) => {
    const value = event.target.value;
    const fieldName = event.target.id;
    this.setState({ [fieldName]: value, hasChanges: true });
    const newVal = { ...this.state };
    newVal[fieldName] = value;
    this.updateValidity(newVal);
  };

  loadScenarioFile = (event) => {
    this.updateValidity(undefined, true);
    const files = event.target.files;
    const first = files[0];
    this.setState({ contentName: first.name });
    if (first) {
      this.reader = new FileReader();
      this.reader.onloadend = this.handleScenarioLoaded;
      this.reader.readAsText(first);
    }
  };

  handleScenarioLoaded = () => {
    if (this.reader) {
      // $FlowFixMe: Result is string when using readAsText
      const content: string = this.reader.result;
      const json = content && JSON.parse(content);
      this.setState({
        contentJson: json,
      });
      this.updateValidity(json, false);
    }
  };

  loadHeaderFile = (event) => {
    this.updateValidity(undefined, true);
    const files = event.target.files;
    const first = files[0];
    this.setState({ headerName: first.name });
    if (first) {
      this.reader = new FileReader();
      this.reader.onloadend = this.handleHeaderLoaded;
      this.reader.readAsText(first);
    }
  };

  handleHeaderLoaded = () => {
    if (this.reader) {
      // $FlowFixMe: Result is string when using readAsText
      const content: string = this.reader.result;
      const json = JSON.parse(content);
      this.setState({
        headerJson: json,
      });
      this.updateValidity(this.state.contentJson, false);
    }
  };

  startImport = async () => {
    const { contentJson, headerJson } = this.state;
    const { resetScenario, importScenario, engineVersion } = this.props;
    this.setState({ loading: true });
    if (resetScenario) {
      resetScenario();
    }
    let scenarioId = 'unnamed';
    if (importScenario) {
      try {
        scenarioId = await importScenario(contentJson, headerJson, false, engineVersion);
        this.setState({ imported: true, scenarioId, loading: false });
      } catch (error) {
        console.log(error);
        this.setState({ imported: false, loading: false });
      }
    }
  };

  render() {
    const {
      isValid, createNew, scenarioId, imported, loading, createScenarioId, sourceId, cityId,
    } = this.state;
    if (createNew && scenarioId) {
      return <Redirect to={Routes.SCENARIO_HEADER.replace(':scenarioId', scenarioId)} />;
    }
    if (imported && scenarioId) {
      return <Redirect to={Routes.SCENARIO_EDITION.replace(':scenarioId', scenarioId)} />;
    }
    const isCreateIdValid = createScenarioId && createScenarioId.length > 2 && createScenarioId.match(Globals.idRegex);
    const isDupplicateValid = createScenarioId.length > 2
      && createScenarioId.match(Globals.idRegex)
      && sourceId.length > 2
      && cityId.length > 0;
    const { t } = this.props;
    return (
      <div className="pageContainer">
        <div className="container-fluid fill component-controller" style={{ overflow: 'scroll' }}>
          <div className="card bg-light screenBlock mb-3">
            <div className="card-header">
              <h3>{t(['screens.newScenario.create.sectionTitle', ''])}</h3>
            </div>
            <div className="card-body">
              <InputString
                fieldName="createScenarioId"
                value={createScenarioId}
                label={t(['screens.newScenario.create.idLabel', ''])}
                handleChange={this.handleChange}
                help={t(['screens.newScenario.create.idHelp', ''])}
              />

              <button
                className={`btn mb-3 ${isCreateIdValid ? 'btn-warning' : 'btn-outline-secondary'}`}
                type="button"
                id="button-addon2"
                onClick={this.createScenario}
                disabled={!isCreateIdValid}
              >
                {t(['general.create', ''])}
              </button>
              <hr />
              <InputString
                fieldName="sourceId"
                value={sourceId}
                label={t(['screens.newScenario.create.sourceIdLabel', ''])}
                handleChange={this.handleChange}
                help={t(['screens.newScenario.create.sourceIdHelp', ''])}
              />
              <InputSelect
                fieldName={'cityId'}
                value={cityId}
                values={this.props.cities}
                itemToId={it => it.id}
                itemToTitle={it => it.name.valueForLocale(this.props.locale)}
                label={'Ville'}
                handleChange={this.handleChange}
              />

              <button
                className={`btn mb-3 ${isDupplicateValid ? 'btn-warning' : 'btn-outline-secondary'}`}
                type="button"
                id="button-addon3"
                onClick={this.dupplicateScenario}
                disabled={!isDupplicateValid}
              >
                {t(['general.dupplicate', ''])}
              </button>
            </div>
          </div>

          <div className="card bg-light screenBlock mb-3">
            <div className="card-header">
              <h3>{t(['screens.newScenario.import.sectionTitle', ''])}</h3>
            </div>
            <div className="card-body">
              <div className="custom-file mb-3">
                <input
                  type="file"
                  className="custom-file-input mb-3"
                  accept=".json"
                  id="importScenario"
                  onChange={this.loadScenarioFile}
                />
                <label className="custom-file-label" htmlFor="importScenario" aria-describedby="inputGroupFileAddon02">
                  {this.state.contentName || t(['screens.newScenario.import.importContentJson', ''])}
                </label>
              </div>
              <div className="custom-file mb-3">
                <fieldset>
                  <input
                    type="file"
                    className="custom-file-input mb-3"
                    accept=".json"
                    id="importHeader"
                    onChange={this.loadHeaderFile}
                  />
                  <label className="custom-file-label" htmlFor="importHeader" aria-describedby="inputGroupFileAddon02">
                    {this.state.headerName || t(['screens.newScenario.import.importHeader', ''])}
                  </label>
                </fieldset>
              </div>
              <button
                className="btn btn-outline-secondary mb-3"
                type="button"
                id="button-addon2"
                onClick={this.startImport}
                disabled={!isValid}
              >
                {t(['general.import', ''])}
              </button>
            </div>
          </div>
        </div>
        {loading && <Loader />}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  engineVersion: state.configuration.engineVersion,
  locale: state.preferences.editionLocale,
  cities: Object.values(state.configuration.availableCities),
});

const mapDispatchToProps = {
  resetScenario: ScenarioServiceHelper.cleanup,
  createScenario: ScenarioServiceHelper.createScenario,
  importScenario: ScenarioServiceHelper.importScenarioData,
  dupplicateScenarioAsync: FirebaseHelper.dupplicateScenarioAsync,
};

export default compose(
  withFirebase,
  withAuthorization(AuthenticatedCondition, [Claims.Moderator]),
  withCities,
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  withTranslation('default'),
)(NewScenarioScreen);
