/* @flow */
import React from 'react';
import { connect } from 'react-redux';
import { withAuthorization, AuthenticatedCondition } from 'src/services/Session';
import { RoomMembersView } from 'src/pages/components';
import { Claims } from 'src/constants/roles';
import { withUrlProperties } from 'src/pages/components/WithUrlProperties';
import { NpcsEditionWidget } from './components';

import 'bootstrap/dist/css/bootstrap.min.css';

export type NPCSEditionScreenProps = {
  roomMembers: any[],
};

type State = {
  editionMode: 'npcs' | 'gameZone',
};

class NPCSEditionScreen extends React.PureComponent<NPCSEditionScreenProps, State> {
  static defaultProps = {};

  state = {
    editionMode: 'npcs',
  };

  // eslint-disable-next-line class-methods-use-this
  componentDidMount() {}

  // eslint-disable-next-line class-methods-use-this
  render() {
    return (
      <div className="pageContainer">
        <div className="container-fluid fill component-controller">
          <RoomMembersView roomMembers={this.props.roomMembers} />
          <NpcsEditionWidget />
        </div>
      </div>
    );
  }
}

// eslint-disable-next-line no-unused-vars
const mapStateToProps = (state, ownProps) => ({});

const mapDispatchToProps = {};

export default withUrlProperties(
  withAuthorization(AuthenticatedCondition, [Claims.Editor])(
    connect(
      mapStateToProps,
      mapDispatchToProps,
    )(NPCSEditionScreen),
  ),
);
