/* @flow */
import BaseItem, { ItemTypes } from './BaseItem';

/* ***************************
  CLASS
*************************** */
export type AppTimer = {};

export default class TimerItem extends BaseItem {
  duration: number;

  constructor(json: any) {
    if (json instanceof TimerItem) {
      super(json, true);
    } else {
      super(json, false);
    }
    const {
      type, meta, duration, ...newMeta
    } = json;
    this.type = ItemTypes.Timer;
    this.duration = duration;
    this.setMeta({ ...json.meta, ...newMeta });
  }

  serializeInheritedFieldsForApp() {
    const res = super.serializeInheritedFieldsForApp();
    res.duration = this.duration;
    return res;
  }

  serializeInheritedFields() {
    const res = super.serializeInheritedFields();
    res.duration = this.duration;
    return res;
  }
}
