/* @flow */

import * as _ from 'lodash';
import { DiagramEngine, BaseEvent } from 'storm-react-diagrams';
import { MessageTriggeredItem } from 'src/data';
import { ItemNodeModel, ItemNodeTypes } from '../ItemNodeModel';
import ItemPortModel from '../ItemPortModel';
import type { ItemNodeModelListener } from '../ItemNodeModel';

const generateId = () => Math.random()
  .toString(36)
  .slice(-8);

export interface TriggerNodeModelListener /* eslint-disable no-use-before-define */
  extends ItemNodeModelListener<TriggerNodeModel> {
  parentChanged?: (event: BaseEvent<TriggerNodeModel> & { nodeId: null | string, modelId: null | string }) => void;
}
/* eslint-enable no-use-before-define */

export default class TriggerNodeModel extends ItemNodeModel<TriggerNodeModelListener> {
  constructor(reachable: boolean = false, trigger: any, discussionId: string) {
    super(ItemNodeTypes.Trigger, (trigger && trigger.nodeId) || generateId(), reachable);
    this.trigger = trigger;
    this.discussionId = discussionId;
  }

  initPorts() {
    this.addPort(new ItemPortModel(true, `${this.id}_in`, 'In', undefined, 1));
  }

  updateTrigger(trigger: any) {
    this.trigger = trigger;
  }

  acceptInLinks(outNode: any) {
    if (!this.trigger.nodeId) {
      return false;
    }
    return outNode.type === ItemNodeTypes.Message;
  }

  inLinkAdded(nodeId: string, modelId: string) {
    this.messageId = modelId;
    const trigger = new MessageTriggeredItem(this.trigger);
    trigger.parentMessageId = modelId;
    this.trigger = trigger;
    this.iterateListeners((listener: TriggerNodeModelListener, event: BaseEvent) => {
      if (listener.parentChanged) {
        listener.parentChanged({ ...event, nodeId, modelId });
      }
    });
  }

  deSerialize(object: any, engine: DiagramEngine) {
    super.deSerialize(object, engine);
  }

  serialize() {
    return _.merge(super.serialize(), {});
  }
}
