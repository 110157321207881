/* @flow */
import * as React from 'react';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap';
import { withTranslation } from 'react-i18next';
import Countdown from 'react-countdown-now';

export interface LoaderProps {
  endTime?: number;
  titleKey?: string;
  messageKey?: string;
  t: (key: string[]) => string;
  tipKey?: string;
}

class InformationalCountdown extends React.Component<LoaderProps> {
  // eslint-disable-next-line class-methods-use-this
  state = {
    isDragging: false,
    originalX: 150,
    originalY: 100,

    translateX: 150,
    translateY: 100,

    lastTranslateX: 150,
    lastTranslateY: 100,
  };

  componentWillUnmount() {
    window.removeEventListener('mousemove', this.handleMouseMove);
    window.removeEventListener('mouseup', this.handleMouseUp);
  }

  handleMouseDown = ({ clientX, clientY }) => {
    window.addEventListener('mousemove', this.handleMouseMove);
    window.addEventListener('mouseup', this.handleMouseUp);

    if (this.props.onDragStart) {
      this.props.onDragStart();
    }

    this.setState({
      originalX: clientX,
      originalY: clientY,
      isDragging: true,
    });
  };

  handleMouseMove = ({ clientX, clientY }) => {
    const { isDragging } = this.state;
    const { onDrag } = this.props;

    if (!isDragging) {
      return;
    }

    this.setState(
      prevState => ({
        translateX: clientX - prevState.originalX + prevState.lastTranslateX,
        translateY: clientY - prevState.originalY + prevState.lastTranslateY,
      }),
      () => {
        if (onDrag) {
          onDrag({
            translateX: this.state.translateX,
            translateY: this.state.translateY,
          });
        }
      },
    );
  };

  handleMouseUp = () => {
    window.removeEventListener('mousemove', this.handleMouseMove);
    window.removeEventListener('mouseup', this.handleMouseUp);

    this.setState(
      {
        originalX: 0,
        originalY: 0,
        lastTranslateX: this.state.translateX,
        lastTranslateY: this.state.translateY,

        isDragging: false,
      },
      () => {
        if (this.props.onDragEnd) {
          this.props.onDragEnd();
        }
      },
    );
  };

  render() {
    const {
      translateX, translateY, isDragging, t,
    } = this.state;

    return (
      <div
        style={{
          transform: `translate(${translateX}px, ${translateY}px)`,
        }}
        onMouseDown={this.handleMouseDown}
        x={translateX}
        y={translateY}
        isDragging={isDragging}
      >
        <div id="draggable-area-id" className="countdownContainer">
          <div className="title">{t(this.props.titleKey)}</div>
          <div className="message">{t(this.props.messageKey)}</div>
          <div className="counter">
            <Countdown date={this.props.endTime} />
          </div>
          <div className="message">{t(this.props.tipKey)}</div>
        </div>
      </div>
    );
  }
}

export default withTranslation('default')(InformationalCountdown);
