/* @flow */
import * as React from 'react';

import HelpButton from '../HelpButton';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap';

export interface InputStringProps {
  style?: any;
  fieldName: string;
  value?: ?string;
  label?: string;
  help?: ?string;
  disabled?: boolean;
  hidden?: boolean;
  multiline?: boolean;
  separatorBefore?: boolean;
  handleChange?: any => any;
  helpInfos: any;
  labelSmall: boolean;
  prefix?: ?string;
  suffix?: ?string;
  inlineTitle?: boolean;
  spellCheck?: boolean;
  onFocus?: () => any;
  onClick?: () => any;
}

export default class InputString extends React.Component<InputStringProps> {
  static defaultProps = {
    disabled: false,
    hidden: false,
    multiline: false,
    helpInfos: undefined,
    labelSmall: false,
    separatorBefore: false,
    spellCheck: true,
  };

  render() {
    const {
      style,
      fieldName,
      value,
      label,
      help,
      disabled,
      multiline,
      handleChange,
      hidden,
      helpInfos,
      separatorBefore,
      labelSmall,
      prefix,
      suffix,
      spellCheck,
      inlineTitle,
      onFocus,
      onClick,
    } = this.props;
    const divClass = inlineTitle ? 'input-group' : 'form-group';
    const labelClass = inlineTitle ? 'input-group-prepend mr-2' : 'strong';
    if (multiline) {
      return (
        <div className={divClass} key={fieldName} hidden={hidden} style={style} onClick={() => { if(onClick) { onClick() }} }>
          {separatorBefore && <hr />}
          {(label || helpInfos) && (
            <label className={labelClass} htmlFor={fieldName}>
              {labelSmall ? <small>{label}</small> : label}{' '}
              {helpInfos
                && helpInfos.map((helpInfo, index) => (
                  <HelpButton
                    key={fieldName + index}
                    helpStrings={helpInfo.content}
                    id={fieldName}
                    title={helpInfo.title}
                    label={helpInfo.btnLabel}
                    display={'inline'}
                    className={'ml-auto btn btn-outline-warning ml-2'}
                  />
                ))}
            </label>
          )}
          <textarea
            type="text"
            className="form-control"
            id={fieldName}
            onChange={handleChange}
            value={value}
            disabled={disabled}
            aria-describedby={`${fieldName}Help`}
            placeholder={''}
            rows="3"
            spellCheck={spellCheck}
            onFocus={onFocus}
          />
          {help && (
            <small id={`${fieldName}Help`} className="form-text text-muted">
              {help}
            </small>
          )}
        </div>
      );
    }
    return (
      <div className={divClass} key={fieldName} hidden={hidden} style={style} onClick={onClick}>
        {(label || helpInfos) && (
          <label className={labelClass} htmlFor={fieldName}>
            {`${label} `}
            {helpInfos
              && helpInfos.map((helpInfo, index) => (
                <HelpButton
                  key={fieldName + index}
                  helpStrings={helpInfo.content}
                  id={fieldName}
                  title={helpInfo.title}
                  label={helpInfo.btnLabel}
                  display={'inline'}
                  className={'ml-auto btn btn-outline-warning ml-2'}
                />
              ))}
          </label>
        )}
        <div className={`${inlineTitle ? 'input-group' : ''}`}>
          {prefix && prefix.length && (
            <div className="input-group-prepend prefix">
              <span className="input-group-text" id="basic-addon1">
                {prefix}
              </span>
            </div>
          )}
          <input
            type="text"
            className="form-control"
            id={fieldName}
            onChange={handleChange}
            value={value}
            disabled={disabled}
            aria-describedby={`${fieldName}Help`}
            placeholder={''}
            spellCheck={spellCheck}
            onFocus={onFocus}
          />
          {suffix && suffix.length && (
            <div className="input-group-prepend suffix">
              <span className="input-group-text" id="basic-addon1">
                {suffix}
              </span>
            </div>
          )}
        </div>
        {help && (
          <small id={`${fieldName}Help`} className="form-text text-muted">
            {help}
          </small>
        )}
      </div>
    );
  }
}
