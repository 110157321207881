/* @flow */
import AtlGraphNode from './AtlGraphNode';
import type { ObjectMap } from './Shortcuts';
import type { AppAtlObject } from './AtlObject';
import BaseItem from './BaseItem';

/* ***************************
  CLASS
*************************** */
export const TriggeredItemConditions = {
  Added: 'Added',
  Discovered: 'Discovered',
  FirstReach: 'FirstReach',
  Reached: 'Reached',
  Launched: 'Launched',
  Unlocked: 'Unlocked',
  Failed: 'Failed',
  Completed: 'Completed',
  Removed: 'Removed',
};

export type TriggeredItemConditionsType = $Values<typeof TriggeredItemConditions>;

export type AppTriggeredItem = AppAtlObject & {
  condition: TriggeredItemConditionsType,
  conditionValue: ?number,
  timer: ?number,
  newTriggeredCondition: ?TriggeredItemConditionsType,
  newTriggeredConditionValue: ?number,
  disableNotif: ?boolean,
};

export default class TriggeredItem extends AtlGraphNode<AppTriggeredItem> {
  condition: ?TriggeredItemConditionsType;

  conditionValue: ?number;

  timer: ?number;

  newTriggeredCondition: ?TriggeredItemConditionsType;

  newTriggeredConditionValue: ?number;

  disableNotif: ?boolean;

  constructor(json: any) {
    super(json, json instanceof TriggeredItem);
    this.condition = json.condition;
    if (json.conditionValue && typeof json.conditionValue === 'string') {
      this.conditionValue = parseInt(json.conditionValue, 10);
    } else if (typeof json.conditionValue === 'number') {
      this.conditionValue = json.conditionValue;
    } else {
      this.conditionValue = undefined;
    }
    if (json.timer && typeof json.timer === 'string') {
      this.timer = parseInt(json.timer, 10);
    } else if (typeof json.timer === 'number') {
      this.timer = json.timer;
    } else {
      this.timer = undefined;
    }
    this.newTriggeredCondition = json.newTriggeredCondition || TriggeredItemConditions.Added;
    if (json.newTriggeredConditionValue && typeof json.newTriggeredConditionValue === 'string') {
      this.newTriggeredConditionValue = parseInt(json.newTriggeredConditionValue, 10);
    } else if (typeof json.newTriggeredConditionValue === 'number') {
      this.newTriggeredConditionValue = json.newTriggeredConditionValue;
    } else {
      this.newTriggeredConditionValue = undefined;
    }
    if (json.disableNotif) {
      this.disableNotif = true;
    }
  }

  serializeInheritedFieldsForApp() {
    const res = {
      ...super.serializeInheritedFieldsForApp(),
      condition: this.condition ? this.condition : undefined,
      conditionValue: undefined,
      newTriggeredCondition: this.newTriggeredCondition ? this.newTriggeredCondition : undefined,
      newTriggeredConditionValue: undefined,
    };
    if (this.timer) {
      res.timer = this.timer;
    }
    if (this.disableNotif) {
      res.disableNotif = this.disableNotif;
    }
    if (this.condition === 'Unlocked') {
      res.conditionValue = this.conditionValue;
    }

    if (this.newTriggeredCondition === 'Unlocked') {
      res.newTriggeredConditionValue = this.newTriggeredConditionValue;
    }
    return res;
  }

  serializeInheritedFields() {
    const res: any = this.serializeInheritedFieldsForApp();
    res.nodeId = this.nodeId;
    res.pos = { ...this.pos };
    return res;
  }

  checkRelease(sourceItem: BaseItem, items: ObjectMap<BaseItem>) {
    const res = [];
    if (!this.id) {
      res.push({
        level: 'error',
        item: this.id,
        message: 'E_NO_TRIGGER_ID',
      });
    } else if (!items[this.id]) {
      res.push({
        level: 'error',
        item: this.id,
        message: 'E_TRIGGER_UNEXISTING_ITEM',
        details: { parent: sourceItem.id, child: this.id },
      });
    }
    // TODO : Check triggered state valid
    return res;
  }
}
