/* @flow */
import {
  LinkModel, LinkModelListener, BaseEvent, DiagramEngine, LabelModel, PortModel,
} from 'storm-react-diagrams';
import * as _ from 'lodash';
import ItemPortModel from './ItemPortModel';
import type { ItemNodeModel } from './ItemNodeModel';

/* eslint-disable no-use-before-define */
export interface ItemLinkModelListener extends LinkModelListener {
  colorChanged?: (event: BaseEvent<ItemLinkModel> & { color: null | string }) => void;

  widthChanged?: (event: BaseEvent<ItemLinkModel> & { width: 0 | number }) => void;
}
/* eslint-enable no-use-before-define */

const defaultColor = 'blue';

export default class ItemLinkModel extends LinkModel<ItemLinkModelListener> {
  width: number;

  color: string;

  curvyness: number;

  deleteLocked: boolean;

  constructor(type: string = 'discussion', deleteLocked: boolean = false) {
    super(type);
    this.color = defaultColor;
    this.width = 3;
    this.curvyness = 50;
    this.deleteLocked = deleteLocked;
    this.addListener({
      sourcePortChanged: (evt) => {
        this.updateColor(evt.entity);
      },
      targetPortChanged: (evt) => {
        this.updateColor(evt.entity);
      },
    });
  }

  updateColor(data: any) {
    const { targetPort, sourcePort } = data;
    const inPort = sourcePort && sourcePort.in ? sourcePort : targetPort;
    let color = defaultColor;
    if (inPort) {
      const inNode = inPort.parent;
      color = inNode.color;
      this.setColor(inNode.color);
    } else {
      this.setColor(color);
    }
  }

  getInPort(newSourcePort: ?ItemPortModel = undefined, newTargetPort: ?ItemPortModel = undefined) {
    const { targetPort, sourcePort } = this;
    const target = newTargetPort || targetPort;
    const source = newSourcePort || sourcePort;
    return source && source.in ? source : target;
  }

  getOutPort(newSourcePort: ?ItemPortModel = undefined, newTargetPort: ?ItemPortModel = undefined) {
    const { targetPort, sourcePort } = this;
    const target = newTargetPort || targetPort;
    const source = newSourcePort || sourcePort;
    return source && source.in ? target : source;
  }

  serialize() {
    return _.merge(super.serialize(), {
      width: this.width,
      color: this.color,
      curvyness: this.curvyness,
    });
  }

  deSerialize(ob: any, engine: DiagramEngine) {
    super.deSerialize(ob, engine);
    this.color = ob.color;
    this.width = ob.width;
    this.curvyness = ob.curvyness;
  }

  addLabel(label: LabelModel | string) {
    if (label instanceof LabelModel) {
      return super.addLabel(label);
    }
    const labelOb = new LabelModel();
    labelOb.setLabel(label);
    return super.addLabel(labelOb);
  }

  setSourcePort(port: PortModel) {
    if (port !== null) {
      if (port.canLinkToPort(undefined, true)) {
        super.setSourcePort(port);
        this.notifyPort();
      }
    }
  }

  setTargetPort(port: PortModel) {
    if (port !== null) {
      if (port.canLinkToPort(this.getSourcePort(), true)) {
        super.setTargetPort(port);
        this.notifyPort();
      }
    }
  }

  getNodes(): ItemNodeModel<any>[] {
    const res = [];
    const inPort = this.getInPort();
    const outPort = this.getOutPort();
    if (outPort) {
      res.push(outPort.parent);
    }
    if (inPort) {
      res.push(inPort.parent);
    }
    return res;
  }

  getNodesIds() {
    return this.getNodes().map(node => node.id);
  }

  notifyPort() {
    const outPort = this.getOutPort();
    const outNode = outPort && outPort.parent;
    const inPort = this.getInPort();
    const inNode = inPort && inPort.parent;
    if (outNode && inNode && inNode.inLinkAdded) {
      inNode.inLinkAdded(outNode.id, outNode.getModelId());
    }
  }

  setWidth(width: number) {
    this.width = width;
    this.iterateListeners((listener: ItemLinkModelListener, event: BaseEvent) => {
      if (listener.widthChanged) {
        listener.widthChanged({ ...event, width });
      }
    });
  }

  setColor(color: string) {
    this.color = color;
    this.iterateListeners((listener: ItemLinkModelListener, event: BaseEvent) => {
      if (listener.colorChanged) {
        listener.colorChanged({ ...event, color });
      }
    });
  }

  remove() {
    if (!this.deleteLocked) {
      super.remove();
    }
  }
}
