/* @flow */
import type { ReduxDispatch } from 'redux';

import { PreferencesServiceHelper } from 'src/store/preferences';
import Firebase, { FirebaseSingleton, FirebaseHelper } from 'src/services/Firebase';
import { asyncForEach } from 'src/utils';
import { Scenario } from 'src/data';
import * as Globals from 'src/constants/globals';
import { EventsServiceHelper, NotificationTypes } from 'src/store/events';
import type { HeaderReducerState } from './HeaderReducer';
import type { ItemsReducerState } from '../items/ItemsReducer';
import * as actions from './actions';
import type { TranslationObjType } from '../ScenarioServiceHelper';
import * as ScenarioServiceHelper from '../ScenarioServiceHelper';

const logHelperCall = (title, args) => {
  if (Globals.__DEV__) {
    console.log(`################# HeaderServiceHelper / ${title}`, args);
  }
};

export type createScenarioType = (id: string) => ReduxDispatch => void;
export const createScenario: createScenarioType = id => (dispatch) => {
  logHelperCall('createScenario');
  dispatch(actions.createHeader(id));
};

const saveHeaderInFirebase = async (firebase, scenarioId, header, items, dispatch) => {
  const firebaseHeader = ScenarioServiceHelper.serialiazeHeaderForFirebase(new Scenario(header), items, false);
  try {
    await firebase
      .scenario(scenarioId)
      .child('header')
      .set(firebaseHeader);
    EventsServiceHelper.addNotif(NotificationTypes.SUCCESS, 'S_SCENARIO_HEADER_SAVED_IN_DB', scenarioId)(dispatch);
  } catch (error) {
    EventsServiceHelper.addNotif(NotificationTypes.ERROR, 'E_SCNEARIO_HEADER_SAVE_FAILED', error.message)(dispatch);
  }
};

export type updateHeaderType = (
  value: any,
  firebase: Firebase,
  items: ItemsReducerState,
) => ReduxDispatch => Promise<void>;
export const updateHeader: updateHeaderType = (value, firebase, items) => async (dispatch) => {
  logHelperCall('updateHeader', { value });
  const scenarioId = value.id;
  const shouldUploadFile = value instanceof Scenario && value.hasFileToUpload();
  if (shouldUploadFile) {
    const version = await FirebaseHelper.getScenarioNextVersionAsync(scenarioId, firebase);
    const localizedFiles = value.getLocalizedFiles();
    if (localizedFiles && firebase) {
      await asyncForEach(localizedFiles, async (img) => {
        await asyncForEach(Object.keys(img.files), async (locale) => {
          const file = img.files[locale];
          if (file.contentToUpload) {
            const ext = file.contentToUpload.name.split('.').pop();
            const url = await FirebaseHelper.pushScenarioEditorAssetAsync(
              scenarioId,
              img.getStorageFileName(locale, version, ext),
              file.contentToUpload,
              firebase,
            );
            // eslint-disable-next-line no-param-reassign
            img.ext = ext;
            file.name = img.getStorageFileName(locale, version, ext);
            file.version = version;
            file.url = url;
            file.ext = ext;
            delete file.contentToUpload;
          }
        });
      });
    }
  }
  dispatch(actions.updateHeader(value));
  EventsServiceHelper.addNotif(NotificationTypes.SUCCESS, 'S_HEADER_PERSISTED')(dispatch);
};

type applyTranslationsType = (
  header: Scenario,
  translations: TranslationObjType,
  items: ItemsReducerState,
) => (dispatch: ReduxDispatch) => Promise<void>;
export const applyTranslations: applyTranslationsType = (header, translations, items) => async (dispatch) => {
  header.applyTranslations(translations);
  await updateHeader(header, FirebaseSingleton, items)(dispatch);
};

export type updateHeaderVersionType = (scenarioId: string, firebase: Firebase) => ReduxDispatch => Promise<void>;
export const updateHeaderVersion: updateHeaderVersionType = (scenarioId, firebase) => async (dispatch) => {
  logHelperCall('updateHeaderVersion');
  const version = await FirebaseHelper.getScenarioNextVersionAsync(scenarioId, firebase);
  dispatch(actions.updateLastVersion(version));
};

// IMPORT
// *********************
export type importHeaderType = (json: any) => any;
export const importFromContent: importHeaderType = json => (dispatch) => {
  logHelperCall('importFromContent', json);
  dispatch(actions.loadFromContent(json.id));
};

export type loadHeaderType = (json: any, items: ItemsReducerState, saveInFirebase?: boolean) => any;
export const loadHeader: loadHeaderType = (json, items, saveInFirebase = false) => async (dispatch) => {
  logHelperCall('loadHeaderType', json);
  if (json && json.startPoint) {
    PreferencesServiceHelper.setLastCoordinate(json.startPoint.latitude, json.startPoint.longitude, 15)(dispatch);
  }
  dispatch(actions.load(json));
  if (saveInFirebase) {
    await saveHeaderInFirebase(FirebaseSingleton, json.id, json, items, dispatch);
  }
};

// EXPORT
// *********************
export type exportHeaderType = (header: HeaderReducerState, items: any) => () => any;
export const exportForApp: exportHeaderType = (header, items) => () => {
  logHelperCall('exportForApp', { header, items });
  return header.serializeForApp(items);
};

export const exportForEditor: exportHeaderType = (header, items) => {
  logHelperCall('exportForEditor', { header, items });
  return header.serialize(items);
};

export type exportTranslationsType = (header: HeaderReducerState) => any;
export const exportTranslations: exportTranslationsType = (state) => {
  logHelperCall('exportTranslations');
  const res = state.getLocalizedStringToTranslateWithPath();
  return res;
};

// CLEANUP
// *********************
export type cleanupType = () => ReduxDispatch => void;
export const cleanup: cleanupType = () => (dispatch) => {
  logHelperCall('cleanup');
  dispatch(actions.cleanup());
};
