/* @flow */
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';

import { App } from 'src/pages';
import { FirebaseContext, FirebaseSingleton } from './services/Firebase';

import registerServiceWorker from './registerServiceWorker';
import { configureStore } from './store';

require('dotenv').config();

// check in the localstorage if a scenar already exist
const { store, persistor } = configureStore();

// send to app a bool if scenario exist or not
const root: ?HTMLElement = document && document.getElementById('root');
if (root) {
  ReactDOM.render(
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <FirebaseContext.Provider value={FirebaseSingleton}>
          <App />
        </FirebaseContext.Provider>
      </PersistGate>
    </Provider>,
    root,
  );
}
registerServiceWorker();
