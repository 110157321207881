/* @flow */
import * as React from 'react';

import { connect } from 'react-redux';
import type { AtlEditorState } from 'src/store';
import type { Notification } from 'src/store/events/EventsReducer';
import 'bootstrap/dist/css/bootstrap.min.css';

import NotificationView from './NotificationView';

export type NavBarProps = {
  notifications: Notification[],
};

export type state = {};

class NotificationsView extends React.Component<NavBarProps, state> {
  render() {
    const { notifications } = this.props;
    return (
      <div className="notifPane">
        {notifications.map(it => (
          <NotificationView key={it.id} notification={it} />
        ))}
      </div>
    );
  }
}

const mapStateToProps = (state: AtlEditorState) => ({
  notifications: state.events.notifications,
});

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(NotificationsView);
