/* @flow */
import type { ReduxDispatch } from 'redux';
import { saveAs } from 'file-saver';
import * as JSZip from 'jszip';

import { Scenario } from 'src/data';
import type { ObjectMap } from 'src/data';
import type { ScenarioEngineVersion, ScenarioVersion, ScenarioVendingInfo } from 'src/data/Scenario';
import type { CatalogType } from 'src/services/Firebase';
import Firebase, { FirebaseHelper, CatalogTypes } from 'src/services/Firebase';
import * as Globals from 'src/constants/globals';
import { EventsServiceHelper, NotificationTypes } from 'src/store/events';
import { asyncForEach, uniq } from 'src/utils';
import { HeaderServiceHelper } from './header';
import { NPCServiceHelper } from './npcs';
import { ItemsServiceHelper } from './items';
import type { ScenarioReducerState } from './ScenarioReducer';
import type { ItemsReducerState } from './items/ItemsReducer';
import BaseItem from '../../data/BaseItem';
import NPC from '../../data/NPC';

const logHelperCall = (title, args) => {
  if (Globals.__DEV__) {
    console.log(`################# ScenarioServiceHelper / ${title}`, args);
  }
};

const serializeItemsForFirebase = (items, forApp = false) => {
  const { __detachedNodes, ...others } = items;
  const res = {};
  Object.keys(others).forEach((key) => {
    const cur = others[key];
    if (cur.id) {
      const value = cur.serializeForFirebase(forApp);
      res[key] = value;
    }
  });
  if (__detachedNodes && __detachedNodes.items.length) {
    res.__detachedNodes = {
      items: __detachedNodes.items.map(it => it.serializeForFirebase(forApp)),
    };
  }
  return res;
};

const serialiazeNpcsForFirebase = (npcs: NPC[]) => {
  const res = {};
  npcs.forEach((npc) => {
    const serialized = npc.serialize();
    Object.keys(serialized).forEach(key => serialized[key] === undefined && delete serialized[key]);
    res[npc.id] = serialized;
  });
  return res;
};
export const serialiazeHeaderForFirebase = (scenario: Scenario, items: ItemsReducerState, forApp: boolean = false) => {
  const res = forApp ? scenario.serializeForApp(items) : scenario.serialize(items);
  Object.keys(res).forEach(key => res[key] === undefined && delete res[key]);
  // $FlowFixMe
  Object.keys(res.vendingInfo).forEach(key => res.vendingInfo[key] === undefined && delete res.vendingInfo[key]);
  return res;
};

const saveScenarioInFirebase = async (
  scenarioId: string,
  header: Scenario,
  items: ItemsReducerState,
  npcs: NPC[],
  firebase: Firebase,
) => {
  const serialized = {
    header: serialiazeHeaderForFirebase(header, items),
    itemsData: serializeItemsForFirebase(items),
    npcs: undefined,
  };
  if (npcs && npcs.length) {
    serialized.npcs = serialiazeNpcsForFirebase(npcs);
  } else {
    delete serialized.npcs;
  }
  await firebase.scenario(scenarioId).set(serialized);
};

export type saveScenarioInFirebaseType = (
  scenarioId: string,
  state: any,
  firebase: Firebase,
) => ReduxDispatch => Promise<void>;
export const exportScenarioInFirebase: saveScenarioInFirebaseType = (scenarioId, state, firebase) => async (dispatch) => {
  logHelperCall('exportScenarioInFirebase', { scenarioId, state });
  if (scenarioId && scenarioId.length) {
    try {
      await saveScenarioInFirebase(scenarioId, state.header, state.items, state.npcs.npcs, firebase);
      EventsServiceHelper.addNotif(NotificationTypes.SUCCESS, 'S_SCENARIO_SAVED_IN_DB')(dispatch);
    } catch (error) {
      EventsServiceHelper.addNotif(NotificationTypes.ERROR, 'E_SCENARIO_SAVE_FAILED', error.message)(dispatch);
    }
  } else {
    EventsServiceHelper.addNotif(NotificationTypes.ERROR, 'E_SAVE_NO_ID')(dispatch);
    throw new Error('Scenario id is required');
  }
};

export type createScenarioType = (scenarioId: string, firebase: Firebase) => ReduxDispatch => Promise<void>;
export const createScenario: createScenarioType = (scenarioId, firebase) => async (dispatch) => {
  logHelperCall('createScenario');
  const existReq = await firebase.scenario(scenarioId).once('value');
  if (existReq.exists()) {
    EventsServiceHelper.addNotif(NotificationTypes.ERROR, 'E_ALREADY_EXIST')(dispatch);
    throw new Error('Scenario already exists');
  } else {
    const scenario = new Scenario({ id: scenarioId, lastVersion: 'v1' });
    await saveScenarioInFirebase(scenarioId, scenario, {}, [], firebase);
  }
  HeaderServiceHelper.createScenario(scenarioId)(dispatch);
  ItemsServiceHelper.createScenario()(dispatch);
};
export type TranslationObjType = { string: { [locale: string]: string } };
export type TranslationItemsType = { [itemId: string]: TranslationObjType };
export type applyTranslationsType = (
  scenario: ScenarioReducerState,
  translations: { items: TranslationItemsType, npcs: TranslationItemsType, header: TranslationObjType },
) => ReduxDispatch => Promise<void>;
export const applyTranslations: applyTranslationsType = (scenario, translations) => async (dispatch) => {
  await ItemsServiceHelper.applyTranslations(scenario.items, translations.items)(dispatch);
  await HeaderServiceHelper.applyTranslations(scenario.header, translations.header[scenario.header.id], scenario.items)(
    dispatch,
  );
  await NPCServiceHelper.applyTranslations(scenario.header.id, scenario.npcs, translations.npcs)(dispatch);
  EventsServiceHelper.addNotif(NotificationTypes.SUCCESS, 'S_SCENARIO_TRANSLATION_APPLIED', scenario.header.id)(
    dispatch,
  );
};

export const formatTranslation = (translationJson: any[]) => {
  const translationObj = { npcs: {}, items: {}, header: {} };
  if (translationJson) {
    translationJson.forEach((line) => {
      const { path, ...trads } = line;
      const pathSteps = path.split('.');
      const scenarioPart = pathSteps.shift();
      let current = translationObj[scenarioPart];
      const id = pathSteps.shift();
      if (!current[id]) {
        current[id] = {};
      }
      current = current[id];
      const internalPath = pathSteps.join('.');
      current[internalPath] = trads;
    });
  }
  return translationObj;
};

// IMPORT
// *********************

export type importScenarioDataType = (
  content: any,
  header?: any,
  saveInFirebase?: boolean,
  editorEngineVersion: number,
) => ReduxDispatch => Promise<string>;
export const importScenarioData: importScenarioDataType = (
  content,
  header,
  saveInFirebase = false,
  editorEngineVersion,
) => async (dispatch) => {
  logHelperCall('importScenarioData', { content, header });
  // First check that the scenario is compatible
  let maxVersion;
  try {
    maxVersion = header && header.versionPerEngine && header.versionPerEngine.length;
  } catch (error) {
    maxVersion = Math.max(Object.keys(...header.versionPerEngine));
  }
  if (maxVersion && maxVersion > editorEngineVersion + 1) {
    EventsServiceHelper.addNotif(NotificationTypes.ERROR, 'E_SCENARIO_REQUIRE_NEW_EDITOR')(dispatch);
    throw new Error('E_SCENARIO_REQUIRE_NEW_EDITOR');
  } else {
    // Then setup
    const scenarioId = header ? header.id : content.id;
    await NPCServiceHelper.importNPCS(scenarioId, content.npcs, saveInFirebase)(dispatch);
    await ItemsServiceHelper.importItems(content.items)(dispatch);
    if (header && Object.values(header).length) {
      await HeaderServiceHelper.loadHeader(header, content.items, saveInFirebase)(dispatch);
    } else {
      HeaderServiceHelper.importFromContent(content)(dispatch);
    }
    return header ? header.id : 'unknwonScenario';
  }
};

export type loadScenarioFromFirebaseType = (
  scenarioId: string,
  firebase: Firebase,
  engineVersion: number,
) => ReduxDispatch => Promise<string>;
export const loadScenarioAsync: loadScenarioFromFirebaseType = (
  scenarioId,
  firebase,
  engineVersion,
) => async (dispatch) => {
  logHelperCall('loadScenarioAsync', scenarioId);
  const header = await firebase.scenarioEditorHeader(scenarioId).once('value');
  const itemsData = await firebase.scenarioEditorItemsData(scenarioId).once('value');
  const npcs = await firebase.scenarioEditorNPCs(scenarioId).once('value');

  const headerVal = header.val();
  const itemsDataVal = itemsData.val();
  const npcsVal = npcs.val();
  const content = { npcs: npcsVal, items: itemsDataVal };
  const version = await FirebaseHelper.getScenarioNextVersionAsync(scenarioId, firebase);
  headerVal.lastVersion = version;
  const res = await importScenarioData(content, headerVal, false, engineVersion)(dispatch);
  console.log('Working on scenario version:', version);
  return scenarioId;
};

// EXPORT
// *********************
export type exportScenarioType = (state: ScenarioReducerState) => ReduxDispatch => any;
export const exportScenarioForApp: exportScenarioType = state => () => {
  const npcs = NPCServiceHelper.exportForApp(state.npcs);
  const items = ItemsServiceHelper.exportForApp(state.items);
  return {
    npcs,
    items,
  };
};

export const exportScenarioTranlations: exportScenarioType = state => () => {
  const npcs = NPCServiceHelper.exportTranslations(state.npcs);
  const items = ItemsServiceHelper.exportTranslations(state.items);
  const head = HeaderServiceHelper.exportTranslations(state.header);
  const lines = [...npcs, ...items, ...head];
  const availableLocales = state.header.managedLocales;
  const headers = [{ key: 'path', label: 'technicalPath' }];
  availableLocales.forEach((locale) => {
    headers.push({ key: locale, label: locale });
  });
  return { headers, lines };
};

export const exportScenarioForEditor: exportScenarioType = state => () => {
  try {
    const npcs = NPCServiceHelper.exportForEditor(state.npcs);
    const items = ItemsServiceHelper.exportForEditor(state.items);
    return {
      npcs,
      items,
    };
  } catch (error) {
    console.warn('Could not export scenario, it may have not been imported correctly', error);
    return state;
  }
};

export const exportScenarioHeader: exportScenarioType = state => () => HeaderServiceHelper.exportForApp(state.header, state.items)();

export const exportToZip: exportScenarioType = state => () => {
  const zip = new JSZip();
  const id = state.header.id || 'unnamedScenario';
  zip.file(`${id}.json`, JSON.stringify(exportScenarioForApp(state)(), undefined, 4));
  zip.file(`${id}_editor.json`, JSON.stringify(exportScenarioForEditor(state)(), undefined, 4));
  zip.file(`${id}_header.json`, JSON.stringify(exportScenarioHeader(state)(), undefined, 4));

  ItemsServiceHelper.insertGpxsFolderToZip(state.items, zip, state.header);
  const now = new Date();

  const year = now.getFullYear().toString(10);
  const month = (now.getMonth() + 1).toLocaleString('en', {
    minimumIntegerDigits: 2,
    useGrouping: false,
  });
  const dayOfMonth = now.getDate().toLocaleString('en', { minimumIntegerDigits: 2, useGrouping: false });
  const hour = now.getHours().toLocaleString('en', { minimumIntegerDigits: 2, useGrouping: false });
  const mins = now.getMinutes().toLocaleString('en', { minimumIntegerDigits: 2, useGrouping: false });
  const secs = now.getSeconds().toLocaleString('en', { minimumIntegerDigits: 2, useGrouping: false });
  const zipName = `${id}_${year}${month}${dayOfMonth}_${hour}${mins}${secs}.zip`;
  zip.generateAsync({ type: 'blob' }).then((content) => {
    saveAs(content, zipName);
  });
};

// RELEASING
// **********************
export const listChangesAsync = async (scenarioId: string, versions: string[], firebase: Firebase) => {
  const versionNumbers = versions.map(it => Number.parseInt(it.substr(1), 10));
  let changes = [];
  if (versionNumbers.length) {
    let min = Math.min(...versionNumbers);
    const max = Math.max(...versionNumbers);
    if (min !== max) {
      min += 1;
    }
    let cpt = min;
    while (cpt <= max) {
      const changesRef = firebase.scenarioEditorChanges(scenarioId, `v${cpt}`);
      const changesSnap = await changesRef.once('value');
      const currentChanges = changesSnap.exists() ? changesSnap.val() : {};
      changes = [...changes, ...Object.values(currentChanges)];
      cpt += 1;
    }
  }
  const editors = [];
  const itemIds = [];
  const sections = [];
  changes.forEach((change) => {
    if (!editors.includes(change.editor)) {
      editors.push(change.editor);
    }
    if (!sections.includes(change.section)) {
      sections.push(change.section);
    }
    if (change.section === 'items' && !itemIds.includes(change.itemId)) {
      itemIds.push(change.itemId);
    }
  });
  return {
    changes,
    editors,
    itemIds,
    sections,
  };
};

const updateReleasedSchoolScenariosList = async (
  scenarioId: string,
  isSchool: boolean,
  isVisible: boolean,
  catalog: CatalogType,
  firebase: Firebase,
  dryRun: boolean,
) => {
  const schoolScenariosSnapshot = await firebase.schoolScenarios(catalog).once('value');
  let schoolScenarios = [];
  if (schoolScenariosSnapshot.exists()) {
    schoolScenarios = schoolScenariosSnapshot.val();
  }

  const shouldBeShoolListed = isSchool && isVisible;
  if (schoolScenarios.includes(scenarioId) !== shouldBeShoolListed) {
    // Update required
    if (shouldBeShoolListed) {
      schoolScenarios.push(scenarioId);
      console.log(`${scenarioId} was not listed as school scenario and should be, it would be added`);
    } else {
      schoolScenarios = schoolScenarios.filter(it => it !== scenarioId);
      console.log(
        `${scenarioId} was listed as school scenario and should not, it would be removed. For information : isSchool is ${
          isSchool ? 'true' : 'false'
        } and isVisible is ${isVisible ? 'true' : 'false'}`,
      );
    }
    if (!dryRun) {
      await firebase.schoolScenarios(catalog).set(schoolScenarios);
    }
  } else {
    console.log(
      `No change required to school scenarios list for ${scenarioId}, it is ${
        shouldBeShoolListed ? '' : 'not '
      } listed as school scenario. For information : isSchool is ${isSchool ? 'true' : 'false'} and isVisible is ${
        isVisible ? 'true' : 'false'
      }`,
    );
  }
};

const ensureLastReleaseFilesAvailable = async (
  scenarioId: string,
  filesIndex: ObjectMap<string[]>,
  firebase: Firebase,
  dryRun: boolean,
) => {
  if (!dryRun) {
    const missingFiles = [];
    const locales = Object.keys(filesIndex);
    await asyncForEach(locales, async (locale: string) => {
      await asyncForEach(filesIndex[locale], async (filename: string) => {
        try {
          await firebase
            .scenarioStorage(scenarioId)
            .child(`assets/${filename}`)
            .getDownloadURL();
        } catch (error) {
          missingFiles.push({ filename, error });
        }
      });
    });
    if (console.group) {
      console.group(
        `%cRelease check ${missingFiles.length ? 'failed' : 'succeeded'} for %c${scenarioId}`,
        `color: ${missingFiles.length ? 'red' : 'green'}`,
        'color: black',
      );
    } else {
      console.log(`%Release check process for %c${scenarioId}`, 'color: grey', 'color: black');
    }
    if (!missingFiles.length) {
      console.log(`%cHoura! All the assets required by ${scenarioId} version are available.`, 'color: green');
      if (console.group) {
        console.groupEnd();
      }
    } else {
      if (console.group) {
        console.group('Errors');
      }
      missingFiles.forEach((it) => {
        console.log(`%c${it.filename} is missing`, 'color: red', it.error);
      });
      if (console.group) {
        console.groupEnd();
      }
      console.log(`%cD'oh! Il manque ${missingFiles.length} assets...`, 'color: red');
      if (console.group) {
        console.groupEnd();
      }
      throw new Error(`${scenarioId} release failed cause some assets were missing, please check the logs`);
    }
  }
};

const cleanupUnusedData = (
  scenarioId: string,
  engineVersions: ScenarioEngineVersion[],
  firebase: Firebase,
  dryRun: boolean,
) => async (dispatch) => {
  let versionsTokeep: ScenarioVersion[] = [];
  engineVersions.forEach((it) => {
    // $FlowFixMe: Arrays are string arrays
    versionsTokeep = versionsTokeep.concat(Object.values(it));
  });

  let allFiles: string[] = [];
  let filesToKeep: string[] = [];

  const versionsTokeepNbrs = versionsTokeep.map(it => Number.parseInt(it.scenarioVersion.substr(1), 10));
  const max = Math.max(...versionsTokeepNbrs);
  console.log(versionsTokeepNbrs, max);
  const cleanRes = { files: [], indexVersions: [] };
  for (let i = 1; i <= max; i += 1) {
    const isVersionToKeep = versionsTokeepNbrs.includes(i);
    try {
      // eslint-disable-next-line no-await-in-loop
      const versionIndexSnapshot = await firebase
        .scenarioDataVersion(scenarioId, `v${i}`)
        .child('index')
        .once('value');
      if (versionIndexSnapshot.exists()) {
        const index = versionIndexSnapshot.val();
        // eslint-disable-next-line no-loop-func
        Object.keys(index).forEach((locale) => {
          allFiles = [...allFiles, ...index[locale]];
        });
        if (isVersionToKeep) {
          // eslint-disable-next-line no-loop-func
          Object.keys(index).forEach((locale) => {
            filesToKeep = [...filesToKeep, ...index[locale]];
          });
        }
      }
    } catch (error) {
      EventsServiceHelper.addNotif(NotificationTypes.WARN, 'W_SCENARIO_VERSIONS_CLEANING_FAILED', error.message)(
        dispatch,
      );
    }
    if (!isVersionToKeep) {
      cleanRes.indexVersions.push(`v${i}`);
      if (!dryRun) {
        try {
          // eslint-disable-next-line no-await-in-loop
          await firebase.scenarioDataVersion(scenarioId, `v${i}`).remove();
        } catch (error) {
          EventsServiceHelper.addNotif(NotificationTypes.WARN, 'W_SCENARIO_VERSIONS_CLEANING_FAILED', error.message)(
            dispatch,
          );
        }
      }
    }
  }

  // Remove files that are not targetted anymore
  filesToKeep = uniq(filesToKeep);
  allFiles = uniq(allFiles);

  const filesToRemove = allFiles.filter(file => !filesToKeep.includes(file));
  if (!dryRun) {
    asyncForEach(filesToRemove, async (filename) => {
      await firebase
        .scenarioStorage(scenarioId)
        .child(`assets/${filename}`)
        .delete();
    });
  }
  cleanRes.files = filesToRemove;
  return cleanRes;
};

export type generateReleaseType = (
  scenarioId: string,
  scenarioState: ScenarioReducerState,
  engineVersion: number,
  removeAllPreviousReleases: boolean,
  firebase: Firebase,
  dryRun: boolean,
) => ReduxDispatch => Promise<void>;
export const generateRelease: generateReleaseType = (
  scenarioId,
  scenarioState,
  engineVersion,
  removeAllPreviousReleases,
  firebase,
  dryRun,
) => async (dispatch) => {
  const newScenario = new Scenario(scenarioState.header);
  const successNotifType = dryRun ? NotificationTypes.DEBUG : NotificationTypes.SUCCESS;
  // Get the new version number
  console.log('Starting release process');
  const version = await FirebaseHelper.getScenarioNextVersionAsync(scenarioId, firebase);

  const vendingInfoSnapshot = await firebase.scenarioVendingInfo(scenarioId, CatalogTypes.dev).once('value');
  if (vendingInfoSnapshot.exists()) {
    newScenario.vendingInfo = vendingInfoSnapshot.val();
  } else {
    newScenario.vendingInfo = {
      comingSoon: false,
      visible: true,
      price: 0,
      isFree: true,
    };
  }

  console.log(`Releasing version ${version}`);
  // List scenario assets per locale
  const allFiles: {
    [locale: string]: { storageName: string, version: string }[],
  } = { default: [] };
  newScenario.managedLocales.forEach((locale) => {
    allFiles[locale] = [];
  });
  const items = Object.values(scenarioState.items).filter(
    item => item instanceof BaseItem && item.id && item.id !== '__detachedNodes',
  );

  const itemReleaseErrors = [];
  const locales = Object.keys(allFiles);
  await asyncForEach(items, async (item) => {
    // $FlowFixMe Object.values
    const itemErrors = item.checkRelease(scenarioState.items, locales);
    if (itemErrors.length) {
      itemReleaseErrors.push(...itemErrors);
    }
  });

  if (itemReleaseErrors.length) {
    const others = [...itemReleaseErrors].filter(it => it.level === 'warn');
    if (others.length) {
      EventsServiceHelper.addNotif(NotificationTypes.WARN, 'E_SCENARIO_RELEASE_WARNS', others, 0)(dispatch);
    }
    const erors = [...itemReleaseErrors].filter(it => it.level === 'error');
    if (erors.length) {
      EventsServiceHelper.addNotif(NotificationTypes.ERROR, 'E_SCENARIO_RELEASE_ERRORS', erors)(dispatch);
      throw new Error('E_SCENARIO_RELEASE_ERRORS');
    }
  }

  items.forEach((item) => {
    if (item instanceof BaseItem) {
      const itemFiles = item.getFilesPerLocale(locales);
      locales.forEach((locale) => {
        allFiles[locale] = [...allFiles[locale], ...itemFiles[locale]];
      });
    }
  });

  const scenarioFiles = newScenario.getFilesPerLocale(locales);
  locales.forEach((locale) => {
    allFiles[locale] = [...allFiles[locale], ...scenarioFiles[locale]];
  });

  const filesIndex = {};
  const filesTransferRes = {
    downloaded: [],
    pushed: [],
    failed: [],
    unchanged: [],
  };

  // Generate zips
  console.log('Start pushing assets to release storage.');
  let success = true;
  await asyncForEach(locales, async (locale) => {
    console.log(`--- Start pushing ${locale} assets.`);
    filesIndex[locale] = [];
    await asyncForEach(allFiles[locale], async (fileInfo: any) => {
      // Copy all new files to the release storage
      if (success && fileInfo.version === version) {
        console.log(`------ Coping ${fileInfo.storageName} to release for locale ${locale}`);
        try {
          const file: ?File = await FirebaseHelper.downloadScenarioAsset(scenarioId, fileInfo.storageName, firebase);
          filesTransferRes.downloaded.push(fileInfo.storageName);
          if (file && !dryRun) {
            await FirebaseHelper.pushScenarioReleaseAsset(
              scenarioId,
              version,
              'assets',
              fileInfo.storageName,
              file,
              undefined,
              fileInfo.public,
            );
            filesTransferRes.pushed.push(fileInfo.storageName);
          }
        } catch (error) {
          console.error(error);
          filesTransferRes.failed.push(fileInfo.storageName);
          success = false;
          EventsServiceHelper.addNotif(
            NotificationTypes.ERROR,
            'E_SCENARIO_RELEASE_FILE_COPY_ISSUE',
            `${fileInfo.storageName}: ${error.message}`,
          )(dispatch);
          EventsServiceHelper.addNotif(
            NotificationTypes.ERROR,
            'E_SCENARIO_RELEASE_FAILED',
            `${scenarioId}: ${version}`,
          )(dispatch);
          throw new Error('E_SCENARIO_RELEASED_FAILED');
        }
      } else {
        filesTransferRes.unchanged.push(fileInfo.storageName);
      }
      filesIndex[locale].push(fileInfo.storageName);
    });
  });
  console.log('File transfer res:', filesTransferRes);

  const newItemContent = {
    content: serializeItemsForFirebase(scenarioState.items, true),
    npcs: serialiazeNpcsForFirebase(scenarioState.npcs.npcs),
    index: filesIndex,
  };

  console.log('Updating scenario data');
  if (!dryRun) {
    try {
      await firebase.scenarioDataVersion(scenarioId, version).set(newItemContent);
      console.log('Scenario data pushed');
    } catch (error) {
      EventsServiceHelper.addNotif(NotificationTypes.ERROR, 'E_SCENARIO_RELEASE_DATA_UPLOAD_FAILED', error.message)(
        dispatch,
      );
      EventsServiceHelper.addNotif(NotificationTypes.ERROR, 'E_SCENARIO_RELEASE_FAILED', `${scenarioId}: ${version}`)(
        dispatch,
      );
      throw new Error('E_SCENARIO_RELEASED_FAILED');
    }
  }

  const oldEngineVersion = newScenario.versionPerEngine[engineVersion];
  if (removeAllPreviousReleases) {
    if (newScenario.versionPerEngine) {
      console.debug('Removing all previous releases: ', { ...newScenario.versionPerEngine });
    }
    if (!dryRun) {
      newScenario.versionPerEngine = {};
    }
  } else {
    console.debug('Previous engine was: ', oldEngineVersion);
  }
  newScenario.versionPerEngine[engineVersion] = {
    scenarioVersion: version,
  };

  newScenario.lastVersion = version;

  // TODO : Limit number of engine version managed here !
  console.debug('Previous engine was: ', oldEngineVersion);

  const newItemHeader = serialiazeHeaderForFirebase(newScenario, scenarioState.items, true);
  if (!dryRun) {
    try {
      firebase.scenarioHeader(scenarioId, CatalogTypes.dev).set(newItemHeader);
    } catch (error) {
      EventsServiceHelper.addNotif(NotificationTypes.ERROR, 'E_SCENARIO_RELEASE_HEADER_UPLOAD_FAILED', error.message)(
        dispatch,
      );
      EventsServiceHelper.addNotif(NotificationTypes.ERROR, 'E_SCENARIO_RELEASE_FAILED', `${scenarioId}: ${version}`)(
        dispatch,
      );
      throw new Error('E_SCENARIO_RELEASED_FAILED');
    }
  }
  let amss = [];
  try {
    amss = await FirebaseHelper.updateAmsForScenarioAsync(newItemHeader, version, CatalogTypes.dev, firebase, dryRun);
  } catch (error) {
    EventsServiceHelper.addNotif(NotificationTypes.WARN, 'E_SCENARIO_RELEASE_HEADER_AMS_FAILED', error.message)(
      dispatch,
    );
  }

  let siblings = [];
  try {
    const startPoint = items.find(it => it.id === newScenario.startItemId);
    if (startPoint && startPoint.coordinate) {
      siblings = await FirebaseHelper.updateScenarioSibling(
        newItemHeader.id,
        startPoint.coordinate,
        100,
        CatalogTypes.dev,
        firebase,
        dryRun,
      );
    }
  } catch (error) {
    EventsServiceHelper.addNotif(NotificationTypes.WARN, 'E_SCENARIO_RELEASE_HEADER_SIBLINGS_FAILED', error.message)(
      dispatch,
    );
  }
  try {
    await updateReleasedSchoolScenariosList(
      scenarioId,
      newScenario.isSchool,
      newScenario.vendingInfo.visible,
      'dev',
      firebase,
      dryRun,
    );
  } catch (error) {
    EventsServiceHelper.addNotif(NotificationTypes.WARN, 'E_SCENARIO_UPDATE_SCHOOL_LIST', error.message)(dispatch);
  }

  console.log('Dev scenario list updated');
  if (!dryRun) {
    try {
      await saveScenarioInFirebase(scenarioId, newScenario, scenarioState.items, scenarioState.npcs.npcs, firebase);
    } catch (error) {
      EventsServiceHelper.addNotif(NotificationTypes.WARN, 'E_SCENARIO_SAVE_FAILED', error.message)(dispatch);
    }
    delete newScenario.vendingInfo;
    await HeaderServiceHelper.updateHeader(newScenario, firebase, scenarioState.items)(dispatch);
  }
  const versions = [newScenario.versionPerEngine];
  try {
    const prodSnapshot = await firebase.scenarioHeader(scenarioId, CatalogTypes.prod).once('value');
    const currentProdVersion = prodSnapshot.exists() && prodSnapshot.val();
    if (currentProdVersion) {
      versions.push(currentProdVersion.versionPerEngine);
    }
    const cleanRes = await cleanupUnusedData(scenarioId, versions, firebase, dryRun)(dispatch);
    console.log('Scenario cleaning result:', cleanRes);
  } catch (error) {
    EventsServiceHelper.addNotif(NotificationTypes.ERROR, 'W_SCENARIO_VERSIONS_CLEANING_FAILED', error.message)(
      dispatch,
    );
  }

  try {
    await ensureLastReleaseFilesAvailable(scenarioId, filesIndex, firebase, dryRun);
  } catch (error) {
    EventsServiceHelper.addNotif(NotificationTypes.ERROR, 'E_SCENARIO_DEPLOYED_MISSING_FILES', error.message)(dispatch);
  }

  EventsServiceHelper.addNotif(
    successNotifType,
    'S_SCENARIO_RELEASED_DEV',
    `${scenarioId} version ${version}. Included folowing AMSs:  ${amss.join(
      ', ',
    )}. Nearest scenarios are ${siblings.join(', ')}`,
    0,
  )(dispatch);
};

const updateCityToInternalIfNeededAsync = async (
  cityId: string,
  wasVisible: boolean,
  isVisible: boolean,
  firebase: Firebase,
  dispatch: ReduxDispatch,
  dryRun: boolean,
) => {
  const cityDevSnapshot = await firebase.cityRelease(cityId, CatalogTypes.dev).once('value');
  const cityProdSnapshot = await firebase.cityRelease(cityId, CatalogTypes.prod).once('value');

  if (!cityProdSnapshot.exists()) {
    if (!cityDevSnapshot.exists()) {
      EventsServiceHelper.addNotif(NotificationTypes.ERROR, 'E_CITY_NOT_FOUND', cityId)(dispatch);
      throw new Error('E_CITY_NOT_FOUND');
    }

    const devCity = cityDevSnapshot.val();
    devCity.visibleScenariosCount = isVisible ? 1 : 0;
    if (!dryRun) {
      await firebase.cityRelease(cityId, CatalogTypes.prod).set(devCity);
    }
    EventsServiceHelper.addNotif(NotificationTypes.SUCCESS, 'S_CITY_DEPLOYED_PPR_PROD', cityId)(dispatch);
  } else if (wasVisible !== isVisible) {
    const increment = 0 - (wasVisible ? 1 : 0) + (isVisible ? 1 : 0);
    const oldCount = cityProdSnapshot.val().visibleScenariosCount;
    if (!dryRun) {
      console.log('Updated city scenario count');
      await firebase
        .cityRelease(cityId, CatalogTypes.prod)
        .child('visibleScenariosCount')
        .set(oldCount + increment);
    }
  }
};

export type deployToInternalAsyncType = (
  scenarioId: string,
  vendingInfo: ScenarioVendingInfo,
  removeOldReleasesAccess: boolean,
  deployPreviousVersions: boolean,
  firebase: Firebase,
  dryRun: boolean,
) => ReduxDispatch => Promise<void>;

export const deployToInternalAsync: deployToInternalAsyncType = (
  scenarioId,
  vendingInfo,
  removeOldReleasesAccess,
  deployPreviousVersions,
  firebase,
  dryRun,
) => async (dispatch) => {
  console.log('Starting deploy to prod :', scenarioId, firebase);

  const snapshot = await firebase.scenarioHeader(scenarioId, CatalogTypes.dev).once('value');
  const currentDevVersion = snapshot.exists() && snapshot.val();

  const prodSnapshot = await firebase.scenarioHeader(scenarioId, CatalogTypes.prod).once('value');
  const currentProdVersion = prodSnapshot.exists() && prodSnapshot.val();

  const version = currentDevVersion.lastVersion;
  let oldVendingInfo;
  let amss = [];
  let siblings = [];
  if (currentDevVersion && version) {
    if (currentProdVersion && currentProdVersion.lastVersion === version) {
      EventsServiceHelper.addNotif(NotificationTypes.ERROR, 'E_SCENARIO_RELEASE_ALREADY_DEPLOYED', version)(dispatch);
      throw new Error('E_SCENARIO_RELEASE_ALREADY_DEPLOYED');
    }
    // Dupplicate index from dev to prod
    try {
      const vendingInfoSnapshot = await firebase.scenarioVendingInfo(scenarioId, CatalogTypes.prod).once('value');
      if (vendingInfoSnapshot.exists) {
        oldVendingInfo = vendingInfoSnapshot.val();
      }
      currentDevVersion.vendingInfo = vendingInfo;
      const versionPerEngine = {};
      Object.keys(currentDevVersion.versionPerEngine).forEach((engine) => {
        const devVersion = currentDevVersion.versionPerEngine[engine];
        if (deployPreviousVersions || devVersion.scenarioVersion === version) {
          versionPerEngine[engine] = devVersion;
        }
      });
      if (currentProdVersion) {
        if (!removeOldReleasesAccess) {
          Object.keys(currentProdVersion.versionPerEngine).forEach((engine) => {
            const prodVersion = currentProdVersion.versionPerEngine[engine];
            if (!versionPerEngine[engine]) {
              versionPerEngine[engine] = prodVersion;
            }
          });
        }
      }
      currentDevVersion.versionPerEngine = versionPerEngine;
      if (!dryRun) {
        await firebase.scenarioHeader(scenarioId, CatalogTypes.prod).set(currentDevVersion);
      }
    } catch (error) {
      EventsServiceHelper.addNotif(NotificationTypes.ERROR, 'E_SCENARIO_DEPLOY_DATA_FAILED', error.message)(dispatch);
      EventsServiceHelper.addNotif(NotificationTypes.ERROR, 'E_SCENARIO_DEPLOY_PPR_PROD_FAILED', `${scenarioId}`)(
        dispatch,
      );
      throw new Error('E_SCENARIO_DEPLOY_DATA_FAILED');
    }
    // Update Production AMS for scenario
    try {
      amss = await FirebaseHelper.updateAmsForScenarioAsync(
        currentDevVersion,
        version,
        CatalogTypes.prod,
        firebase,
        dryRun,
      );
    } catch (error) {
      EventsServiceHelper.addNotif(NotificationTypes.WARN, 'E_SCENARIO_RELEASE_HEADER_AMS_FAILED', error.message)(
        dispatch,
      );
    }

    try {
      siblings = await FirebaseHelper.updateScenarioSibling(
        currentDevVersion.id,
        currentDevVersion.startPoint,
        100,
        CatalogTypes.prod,
        firebase,
        dryRun,
      );
    } catch (error) {
      EventsServiceHelper.addNotif(NotificationTypes.WARN, 'E_SCENARIO_RELEASE_HEADER_SIBLINGS_FAILED', error.message)(
        dispatch,
      );
    }

    await updateCityToInternalIfNeededAsync(
      currentDevVersion.cityId,
      !!oldVendingInfo && oldVendingInfo.visible,
      vendingInfo.visible,
      firebase,
      dispatch,
      dryRun,
    );

    await updateReleasedSchoolScenariosList(
      currentDevVersion.id,
      currentDevVersion.isSchool,
      vendingInfo.visible,
      'prod',
      firebase,
      dryRun,
    );

    // Cleanup unused data
    const cleanRes = await cleanupUnusedData(scenarioId, [currentDevVersion.versionPerEngine], firebase, dryRun)(
      dispatch,
    );
    console.log('Scenario cleaning result:', cleanRes);
  } else {
    EventsServiceHelper.addNotif(NotificationTypes.ERROR, 'E_NOTHING_TO_DEPLOY', scenarioId)(dispatch);
    throw new Error('E_NOTHING_TO_DEPLOY');
  }

  EventsServiceHelper.addNotif(
    dryRun ? NotificationTypes.DEBUG : NotificationTypes.SUCCESS,
    'S_SCENARIO_RELEASED_PROD',
    `${scenarioId} version ${version}. Included folowing Ams: ${amss.join(', ')}. Nearest scenarios are ${siblings.join(
      ', ',
    )}`,
    0,
  )(dispatch);
};

// CLEANUP
// *********************
export type cleanupType = () => ReduxDispatch => void;
export const cleanup: cleanupType = () => (dispatch) => {
  logHelperCall('cleanup');
  HeaderServiceHelper.cleanup()(dispatch);
  NPCServiceHelper.cleanup()(dispatch);
  ItemsServiceHelper.cleanup()(dispatch);
};
