/* @flow */
import * as React from 'react';
import { connect } from 'react-redux';
import * as _ from 'lodash';
import { BaseWidgetProps } from 'storm-react-diagrams';
import { DiscussionServiceHelper } from 'src/store/scenario/items';
import { Message, LocalizedString } from 'src/data';
import ItemNodeWidget from '../ItemNodeWidget';
import '../nodeStyle.css';
import LocalizedFile from '../../../../../data/LocalizedFile';

export interface MessageNodeProps extends BaseWidgetProps {
  discussionId: string;
  messageId: string;
  nodeId: string;
  item: any;
  deleteMessage: DiscussionServiceHelper.deleteMessageType;
}

export interface ItemNodeState {}

class MessageNodeWidget extends ItemNodeWidget<MessageNodeProps, ItemNodeState> {
  entityRemoved() {
    const {
      scenarioId, deleteMessage, discussionId, message, nodeId, messageId,
    } = this.props;
    if (deleteMessage) {
      const node = this.props.node;
      node.removeListener(this);
      deleteMessage(scenarioId, discussionId, message, nodeId, messageId);
    }
  }

  reachableChanged() {
    this.forceUpdate();
  }

  render() {
    return (
      <div
        {...this.getProps()}
        style={{
          backgroundColor: this.props.backgroundColor,
          color: '#494949',
          borderColor: this.props.borderColor,
        }}
      >
        <div className={`message_graph_title ${this.bem('__title')}`}>
          <div className={this.bem('__name')}>
            <span className="nodeName">{this.props.title}</span>
          </div>
        </div>
        <div className={this.bem('__ports')}>
          <div className={this.bem('__in')}>{_.map(this.props.node.getInPorts(), this.generatePort.bind(this))}</div>
          <div className={this.bem('__out')}>{_.map(this.props.node.getOutPorts(), this.generatePort.bind(this))}</div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { discussionId, message } = ownProps.node;
  const messageId = message && message.id;
  const nodeId = message && message.nodeId;
  const discussion = discussionId && state.scenario.items[discussionId];
  let messageRedux: ?Message;
  if (discussion) {
    if (!messageId) {
      messageRedux = discussion.__detachedNodes.messages.find(mess => mess.nodeId === nodeId);
      if (!messageRedux) {
        // $FlowFixMe Object.values
        messageRedux = Object.values(discussion.messages).find(mess => mess.nodeId === nodeId);
      }
      if (message && messageRedux && messageId !== messageRedux.id) {
        // eslint-disable-next-line no-param-reassign
        ownProps.node.message.id = messageRedux.id;
      }
    } else {
      messageRedux = discussion.messages[messageId];
    }
  }
  const content: ?LocalizedString = messageRedux && messageRedux.content;
  const contentImage: ?LocalizedFile = messageRedux && messageRedux.contentImage;
  const imageName = contentImage && contentImage.getCompressedName();
  const contentItemId = messageRedux && messageRedux.contentItemId;
  let contentText;
  const locale = state.preferences.editionLocale;
  if (messageRedux && messageRedux.contentType) {
    switch (messageRedux.contentType) {
      case 'Text':
        contentText = content && content.valueForLocale(locale, true);
        break;
      case 'Image':
        contentText = imageName;
        break;
      case 'Item':
        contentText = contentItemId;
        break;
      default:
        break;
    }
  }
  const isRoot = false;
  let backgroundColor = '#E8EDF3';
  if (messageRedux && messageRedux.nextMode) {
    switch (messageRedux.nextMode) {
      case 'Complete':
        backgroundColor = 'yellow';
        break;
      case 'Exit':
        backgroundColor = 'orange';
        break;
      default:
        break;
    }
  }
  return {
    scenarioId: state.scenario.header.id,
    message: messageRedux,
    isRoot,
    backgroundColor,
    borderColor: isRoot ? 'yellow' : ownProps.node.borderColor,
    nodeId,
    messageId,
    discussionId,
    title: contentText,
  };
};

const mapDispatchToProps = {
  deleteMessage: DiscussionServiceHelper.deleteMessageAsync,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(MessageNodeWidget);
