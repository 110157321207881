/* @flow */
import * as React from 'react';
import { connect } from 'react-redux';
import { City } from 'src/data';
import { Loader } from 'src/pages/components';
import { ConfigurationServiceHelper } from 'src/store/configuration';
import Firebase, { withFirebase } from 'src/services/Firebase';

const WithCitiesContext: Object = React.createContext(null);

export default WithCitiesContext;
const mapStateToProps = state => ({
  cities: state.configuration.availableCities,
});

const mapDispatchToProps = {
  loadCities: ConfigurationServiceHelper.loadCitiesFromFirebase,
};

export type WithCitiesProps = {
  cities: City[],
  loadCities: ConfigurationServiceHelper.loadCitiesFromFirebaseType,
  match: { params: { [key: string]: string } },
  firebase: Firebase,
};

export type WithCitiesState = {
  isLoading: boolean,
};

const withCities = (Component: React.AbstractComponent<any>, refreshIfNotEmpty: boolean = false) => {
  class WithCities extends React.Component<WithCitiesProps, WithCitiesState> {
    state = {
      isLoading: false,
    };

    componentWillMount() {
      const { loadCities, firebase, cities } = this.props;
      if (!cities || !cities.length || refreshIfNotEmpty) {
        this.setState({ isLoading: true });
        loadCities(firebase).then(() => {
          this.setState({ isLoading: false });
        });
      }
    }

    render() {
      return (
        <WithCitiesContext.Provider value={this.props.match}>
          <Component {...this.props} />
          {this.state.isLoading && <Loader />}
        </WithCitiesContext.Provider>
      );
    }
  }

  return withFirebase(
    connect(
      mapStateToProps,
      mapDispatchToProps,
    )(WithCities),
  );
};

export { withCities };
