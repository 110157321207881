/* eslint-disable no-alert */
/* @flow */
import React from 'react';

import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { compose } from 'redux';
import { Loader, InputBoolean, RoomMembersView } from 'src/pages/components';
import { FirebaseSingleton as firebase, FirebaseHelper } from 'src/services/Firebase';
import packageJson from 'src/../package.json';

import { TabContent } from '../components';

type Props = {
  migrateAllUsersAsync: FirebaseHelper.migrateAllUsersAsyncType,
  currentEditorVersion: string,
};

type State = {
  dryRun: boolean,
  removeOldData: boolean,
  isLoading: boolean,
  connectedUsers: any[],
  isMaintaining: boolean,
  serverEditorVersion: string,
  serverNextDeploymentVersion: string,
  endDeploy: number,
  loadingMessage: string,
};

class ToolsTab extends React.PureComponent<Props, State> {
  static defaultProps = {};

  state = {
    isLoading: false,
    dryRun: true,
    removeOldData: false,
    connectedUsers: [],
    isMaintaining: false,
    serverEditorVersion: undefined,
    serverNextDeploymentVersion: undefined,
    endDeploy: 0,
    loadingMessage: '',
  };

  componentWillMount = () => {
    this.trackConnectedUsers();
    this.refreshMaintaining();
    this.getDeployedVersion();
  };

  componentWilUnmount = () => {
    this.stoptrackingConnectedUsers();
  };

  stoptrackingConnectedUsers = () => {
    firebase.connectedDevices().off();
  };

  cleanConnectedUser = async (id: string, email: string) => {
    console.log(id);
    await firebase
      .connectedDevices()
      .child(id)
      .remove();
    const roomsSnap = await firebase.rooms().once('value');
    const rooms = await roomsSnap.val();
    Object.entries(rooms).forEach(([roomId, value]) => {
      Object.entries(value).forEach(([userRoomDataId, userRoomData]) => {
        if (userRoomData.email === email) {
          console.log(userRoomData);
          firebase
            .room(roomId)
            .child(userRoomDataId)
            .remove();
        }
      });
    });
  };

  trackConnectedUsers = async () => {
    this.roomListener = firebase.connectedDevices().on('value', (snapshot) => {
      const value = snapshot.val();
      const userIds = [];
      if (value) {
        const keys = Object.keys(value);
        keys.forEach((key) => {
          const content = value[key];
          let userId;
          let email;
          let roomStartDate;
          let sessionStartDate;
          let sessionRefreshDate;
          if (typeof content === 'string') {
            userId = content;
            email = content;
          } else {
            email = content.email;
            userId = key;
            roomStartDate = content.roomStartDate;
            sessionStartDate = content.sessionStartDate;
            sessionRefreshDate = content.sessionRefreshDate;
          }
          userIds.push({
            userId,
            email,
            roomStartDate,
            sessionStartDate,
            sessionRefreshDate,
          });
        });
      }
      this.setState({ connectedUsers: userIds });
    });
  };

  handleChange = (event) => {
    const value = event.target.value;
    const fieldName = event.target.id;
    const newState: $Shape<State> = { [fieldName]: value };
    this.setState(newState);
  };

  refreshMaintaining = async () => {
    const snapshot = await firebase.editorMaintenance().once('value');
    this.setState({ isMaintaining: snapshot.exists() });
    return snapshot;
  };

  toogleMaintenance = async () => {
    const snapshot = await this.refreshMaintaining();
    if (snapshot.exists()) {
      if (snapshot.val().maintainers.includes(firebase.email)) {
        this.stopMaintenance();
      } else {
        // Error
        throw new Error('Maintenance en cours par un autre utilisateur');
      }
    } else {
      this.startMaintenance();
    }
  };

  startMaintenance = () => {
    firebase.editorMaintenance().set({
      maintainers: [firebase.email],
      startTime: new Date().getTime(),
    });
    this.setState({ isMaintaining: true });
  };

  stopMaintenance = () => {
    firebase.editorMaintenance().remove();
    this.setState({ isMaintaining: false });
  };

  getDeployedVersion = async () => {
    const snapshot = await firebase.editorDeployedVersion().once('value');
    if (snapshot.exists()) {
      const val = snapshot.val();
      this.setState({
        serverEditorVersion: val && val.required,
        serverNextDeploymentVersion: val && val.nextDeploymentVersion,
      });
    }
  };

  deployEditorVersion = () => {
    const now = new Date().getTime();
    const { t } = this.props;
    const timeout = now + 60000;
    this.setState({
      isLoading: true,
      endDeploy: timeout,
      loadingMessage: t(['screens.admin.tools.deployingVersion', '']),
    });
    firebase.editorDeployedVersion().set({
      required: this.state.serverEditorVersion || '1.0',
      nextDeploymentVersion: this.props.currentEditorVersion,
      nextDeploymentTime: timeout,
    });

    setTimeout(() => {
      firebase.editorDeployedVersion().set({
        required: this.props.currentEditorVersion,
        nextDeploymentTime: 0,
      });
      this.setState({ isLoading: false, endDeploy: 0, loadingMessage: '' });
      this.getDeployedVersion();
    }, 60000);
  };

  migrate = async () => {
    if (
      window.confirm(
        'Êtes-vous sûr de vouloir lancer cette migration des données ? Cette dernière peut prendre pas mal de temps :)',
      )
    ) {
      const { dryRun, removeOldData } = this.state;
      try {
        this.setState({ isLoading: true });

        // TODO Migrate

        this.setState({ isLoading: false });
      } catch (error) {
        console.error('Cannot migrate data', error);
        this.setState({ isLoading: false });
      }
    }
  };

  // eslint-disable-next-line class-methods-use-this
  render() {
    const {
      isLoading,
      dryRun,
      removeOldData,
      isMaintaining,
      serverEditorVersion,
      endDeploy,
      loadingMessage,
    } = this.state;
    const { currentEditorVersion, t, locale } = this.props;
    return (
      <TabContent name="tools">
        <div className="card-header">
          <h3>{t(['screens.admin.tools.sectionTitle', ''])}</h3>
        </div>

        <div className="card-body p-2 pl-4">
          <div className="list-group pb-10">
            <h4>{t(['screens.admin.tools.loggedUsers', ''])}</h4>
            <RoomMembersView
              roomMembers={this.state.connectedUsers}
              alignLeft={true}
              noTitle
              detailedMode={true}
              withCleanButton={true}
              askClean={this.cleanConnectedUser}
            />
            <button
              className="btn btn-outline-secondary mb-3"
              type="button"
              id="button-addon2"
              onClick={this.toogleMaintenance}
            >
              {isMaintaining ? t(['screens.admin.tools.stopWorking', '']) : t(['screens.admin.tools.startWorking', ''])}
            </button>
          </div>

          <div className="list-group pb-10">
            <h4>{t(['screens.admin.tools.editorVerion', ''])}</h4>
            <p>
              {`${t(['screens.admin.tools.currentEditorVersion', ''])}: ${currentEditorVersion}`}
              <br />
              {`${t(['screens.admin.tools.serverEditorVersion', ''])}: ${serverEditorVersion}`}
            </p>
            <button
              className="btn btn-outline-secondary mb-3"
              type="button"
              id="button-addon2"
              onClick={this.deployEditorVersion}
              disabled={serverEditorVersion === currentEditorVersion}
            >
              {t(['screens.admin.tools.deployEditorVersion', ''])}
            </button>
          </div>

          <div className="list-group col-4 pb-10">
            <h4>{t(['screens.admin.tools.dataMigration', ''])}</h4>
            <InputBoolean
              fieldName="dryRun"
              value={dryRun}
              label={t(['screens.admin.tools.dryRun', ''])}
              help={t(['screens.admin.tools.dryRunHelp', ''])}
              handleChange={this.handleChange}
            />
            <InputBoolean
              fieldName="removeOldData"
              value={removeOldData}
              label={t(['screens.admin.tools.removeOldData', ''])}
              help={t(['screens.admin.tools.removeOldDataHelp', ''])}
              handleChange={this.handleChange}
            />
            <button
              className="btn btn-outline-secondary mb-3"
              type="button"
              id="button-addon2"
              onClick={this.migrate}
              disabled
            >
              {t(['screens.admin.tools.migrate', ''])}
            </button>
          </div>
        </div>

        {isLoading && <Loader endTime={endDeploy} message={loadingMessage} />}
      </TabContent>
    );
  }
}

const mapStateToProps = state => ({
  locale: state.preferences.editionLocale,
  currentEditorVersion: packageJson.version,
});

const mapDispatchToProps = {
  migrateAllUsersAsync: FirebaseHelper.migrateAllUsersAsync,
};

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  withTranslation('default'),
)(ToolsTab);
