/* @flow */
import * as types from './types';

export const createHeader = (id: string, isImport: boolean = false) => ({
  type: types.HEADER_CREATE,
  payload: { id, isImport },
});

export const loadFromContent = (id: string) => ({
  type: types.HEADER_LOAD_CONTENT,
  payload: { id },
});

export const load = (json: any) => ({
  type: types.HEADER_LOAD,
  payload: { content: json },
});

export const updateHeader = (json: any, isImport: boolean = false) => ({
  type: types.HEADER_UPDATE,
  payload: { content: json, isImport },
});

export const updateLastVersion = (version: any) => ({
  type: types.HEADER_UPDATE_LAST_VERSION,
  payload: { version },
});

export const cleanup = () => ({
  type: types.HEADER_CLEAN,
  payload: {},
});
