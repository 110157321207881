/* @flow */
import * as React from 'react';
import { connect } from 'react-redux';

import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { compose } from 'redux';
import * as ROUTES from 'src/constants/routes';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

type Props = {
  scenarioId?: string,
  ams?: string,
  cities?: string,
  discussionId?: string,
  t: (key: string[]) => string,
};

class Breadcrumb extends React.Component<Props> {
  render() {
    const {
      scenarioId, discussionId, ams, cities, t,
    } = this.props;
    return (
      <nav aria-label="breadcrumb">
        <ol className="breadcrumb mt-auto mb-auto atlBreadcrumbBg">
          <FontAwesomeIcon icon={['far', 'folder-tree']} id="starter-icon" />
          <li>{t(['navbar.missions', ''])} /</li>
          {scenarioId && (
            <li className="breadcrumb-item atlBreadcrumbItem">
              <Link to={ROUTES.SCENARIO_EDITION.replace(':scenarioId', scenarioId)}>{scenarioId}</Link>
            </li>
          )}
          {scenarioId && discussionId && (
            <li className="breadcrumb-item atlBreadcrumbItem">
              <Link
                to={ROUTES.SCENARIO_DISCUSSION_EDITION.replace(':scenarioId', scenarioId).replace(
                  ':discussionId',
                  discussionId,
                )}
              >
                {discussionId}
              </Link>
            </li>
          )}
          {ams && (
            <li className="breadcrumb-item atlBreadcrumbItem">
              <Link to={ROUTES.AMS_EDITION}>{t(['sideBar.ams', ''])}</Link>
            </li>
          )}
          {cities && (
            <li className="breadcrumb-item atlBreadcrumbItem">
              <Link to={ROUTES.CITIES_EDITION}>{t(['sideBar.cities', ''])}</Link>
            </li>
          )}
        </ol>
      </nav>
    );
  }
}

const mapStateToProps = state => ({
  scenarioId: state.preferences.urlProperties.scenarioId,
  ams: state.preferences.urlProperties.ams,
  cities: state.preferences.urlProperties.cities,
  discussionId: state.preferences.urlProperties.discussionId,
});

export default compose(
  connect(mapStateToProps),
  withTranslation('default'),
)(Breadcrumb);
