/* @flow */
import * as React from 'react';
import { connect } from 'react-redux';
import * as _ from 'lodash';
import { BaseWidgetProps, DiagramEngine } from 'storm-react-diagrams';
import { DiscussionServiceHelper } from 'src/store/scenario/items';
import ItemNodeWidget from '../ItemNodeWidget';

export interface AnswerNodeProps extends BaseWidgetProps {
  discussionId: string;
  messageId: string;
  answerId: string;
  nodeId: string;
  outOfRedux: boolean;
  item: any;
  deleteAnswer: DiscussionServiceHelper.deleteAnswerType;
  diagramEngine: DiagramEngine;
}

export interface ItemNodeState {}

class AnswerNodeWidget extends ItemNodeWidget<AnswerNodeProps, ItemNodeState> {
  state = {};

  entityRemoved() {
    const {
      deleteAnswer, discussionId, messageId, nodeId,
    } = this.props;
    if (deleteAnswer) {
      deleteAnswer(discussionId, messageId, nodeId);
    }
  }

  parentChanged() {
    this.forceUpdate();
  }

  reachableChanged() {
    this.forceUpdate();
  }

  componentDidUpdate(oldProps) {
    const hasParentId = this.props.hasParentId;
    const hasParent = this.props.hasParent;
    const hadParent = oldProps.hasParent;
    const { nodeId, diagramEngine } = oldProps;
    if (hasParentId && hadParent && !hasParent) {
      diagramEngine.diagramModel.removeNode(nodeId);
    }
  }

  render() {
    return (
      <div
        {...this.getProps()}
        style={{
          background: this.props.bgColor,
          borderColor: this.props.node.borderColor,
        }}
      >
        <div className={`answer_graph_title ${this.bem('__title')}${this.props.nextCustomClass}`}>
          <div className={this.bem('__name')}>{this.props.title}</div>
        </div>
        <div className={this.bem('__ports')}>
          <div className={this.bem('__in')}>{_.map(this.props.node.getInPorts(), this.generatePort.bind(this))}</div>
          <div className={this.bem('__out')}>{_.map(this.props.node.getOutPorts(), this.generatePort.bind(this))}</div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { node, answer } = ownProps;
  const { discussionId } = node;
  const nodeId = answer && answer.nodeId;
  const messageId = answer && answer.parentMessageId;
  const discussion = discussionId && state.scenario.items[discussionId];
  let answerRedux;
  let messageRedux;
  if (discussion) {
    if (!messageId) {
      answerRedux = discussion.__detachedNodes.answers.find(ans => ans.nodeId === nodeId);
      if (answer && answerRedux && ownProps.node.answer.parentMessageId !== answerRedux.parentMessageId) {
        // eslint-disable-next-line no-param-reassign
        ownProps.node.answer.parentMessageId = answerRedux.parentMessageId;
        messageRedux = discussion.messages[answerRedux.parentMessageId];
      }
      if (answerRedux.id) {
        // eslint-disable-next-line no-param-reassign
        ownProps.node.answer.id = answerRedux.id;
      }
    } else {
      messageRedux = messageId && discussion.messages[messageId];
      answerRedux = messageRedux && messageRedux.answers.find(ans => ans.nodeId === nodeId);
    }
    if (!answerRedux) {
      answerRedux = discussion.__detachedNodes.answers.find(ans => ans.nodeId === nodeId);
      if (answerRedux) {
        // eslint-disable-next-line no-param-reassign
        delete ownProps.node.answer.parentMessageId;
      }
    }
  }
  const content = answerRedux && answerRedux.content;
  const hasNextCustom = answerRedux && answerRedux.nextCustom;
  const locale = state.preferences.editionLocale;
  let color;
  if (messageRedux) {
    color = messageRedux.answerMode === 'System' ? '#00E0CC' : '#001B48';
  } else {
    color = '#F5A623';
  }
  return {
    nodeId,
    messageId,
    discussionId,
    answer: answerRedux,
    title: content && content.valueForLocale(locale, true),
    nextCustomClass: hasNextCustom ? ' nextCustomAnswer' : '',
    hasParentId: !!messageId,
    hasParent: !!messageId && !!discussion.messages[messageId],
    bgColor: color,
  };
};

const mapDispatchToProps = {
  deleteAnswer: DiscussionServiceHelper.deleteAnswerAsync,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AnswerNodeWidget);
