/* @flow */
import React from 'react';

import { connect } from 'react-redux';
import { City } from 'src/data';
import { ConfigurationServiceHelper } from 'src/store/configuration';

import { withTranslation } from 'react-i18next';
import { compose } from 'redux';
import Firebase, { withFirebase, FirebaseHelper } from 'src/services/Firebase';
import Loader from 'src/pages/components/loader';
import * as Globals from 'src/constants/globals';
import { InputString, InputNumber, InputLocalizedFile } from 'src/pages/components/inputs';
import LocalizedFile from '../../data/LocalizedFile';

type State = {
  id?: string,
  isValid: boolean,
  clues?: string,
  cover?: LocalizedFile,
  hasChanges: boolean,
  isIdValid: boolean,
  isLoading: boolean,
  idName: string,
  name: string,
  centerCoordinate?: string,
  displayOrder: string,
  radius: string,
  filesToRemove: any[],
};

export type CitInputProps = {
  cityId?: string,
  city: City,
  locale: string,
  isCreate: boolean,
  createCity: ConfigurationServiceHelper.createCityType,
  updateCity: ConfigurationServiceHelper.updateCityType,
  deleteCity: ConfigurationServiceHelper.removeCityType,
  fieldsDescription: any,
  marker: number[],
  firebase: Firebase,
  existingCityIds: string[],
  t: (key: string[]) => string,
};

class CityInput extends React.PureComponent<CitInputProps, State> {
  static defaultProps = {
    idPrefix: undefined,
    idSuffix: '',
    canChangeId: true,
  };

  state = {
    id: undefined,
    idName: '',
    isValid: true,
    hasChanges: false,
    isIdValid: false,
    isLoading: false,
    name: '',
    centerCoordinate: undefined,
    radius: '0',
    displayOrder: '0',
    filesToRemove: [],
    cover: undefined,
  };

  componentDidMount() {
    this.setItemData(this.props);
  }

  componentDidUpdate(oldProps: CitInputProps) {
    const { locale, city, marker } = this.props;
    const { locale: oldLocale, city: oldCity, marker: oldMarker } = oldProps;
    if (locale !== oldLocale || city !== oldCity || marker !== oldMarker) {
      this.setItemData(this.props);
    }
  }

  setItemData = (props: CitInputProps) => {
    const { locale, city, marker } = props;
    const id = city && city.id;
    const newState: any = {
      idName: id || '',
      hasChanges: false,
      filesToRemove: [],
    };
    if (city) {
      const value = city.name;
      const translated = value && value.valueForLocale(locale);
      newState.name = translated;

      let textValue;
      if (marker) {
        textValue = JSON.stringify(marker);
      } else {
        const value = city.centerCoordinate;
        textValue = value && `[${value.latitude}, ${value.longitude}]`;
      }
      newState.cover = city.cover;
      newState.centerCoordinate = textValue;
      newState.radius = `${city.radius}`;
      if (city.radius === undefined) {
        newState.radius = '';
      }
      newState.displayOrder = `${city.displayOrder}`;
      if (city.displayOrder === undefined) {
        newState.displayOrder = '';
      }
    }

    this.setState(newState);
  };

  handleChange = (event) => {
    const value = event.target.value;
    const fieldName = event.target.id;
    this.setState({ [fieldName]: value, hasChanges: true });
    const newVal = { ...this.state };
    newVal[fieldName] = value;
    this.updateValidity(newVal);
  };

  updateValidity = (newVal) => {
    const { existingCityIds } = this.props;
    const { idName } = newVal;
    const cityId = idName;
    const isIdValid = !!cityId.match(Globals.idRegex) && !existingCityIds.includes(cityId);
    const isValid = !!this.props.city.id || isIdValid;
    this.setState({ isIdValid, isValid });
  };

  deleteItem = async () => {
    const { deleteCity, city, firebase } = this.props;
    if (city) {
      deleteCity(city.id, firebase);
    }
  };

  updateItem = async () => {
    const {
      locale, city, updateCity, isCreate, createCity, firebase,
    } = this.props;
    const state = this.state;
    this.setState({ isLoading: true });

    if (city) {
      const newItem = new City(city);
      newItem.id = state.idName;
      newItem.name.setValueForLocale(state.name, locale);

      const json = state.centerCoordinate && JSON.parse(state.centerCoordinate);
      if (json) {
        newItem.centerCoordinate = {
          latitude: json[0],
          longitude: json[1],
        };
      } else {
        delete newItem.centerCoordinate;
      }
      newItem.radius = state.radius.length ? parseInt(state.radius, 10) : 0;
      newItem.displayOrder = state.displayOrder.length ? parseInt(state.displayOrder, 10) : 0;

      if (state.cover) {
        newItem.cover = state.cover;
      }

      const { filesToRemove } = this.state;

      if (filesToRemove && filesToRemove.length) {
        await FirebaseHelper.removeEditorFilesAsync(newItem.id, filesToRemove, 'cities', firebase);
      }

      if (updateCity && createCity) {
        if (isCreate) {
          await createCity(newItem, true, firebase);
        } else {
          await updateCity(newItem.id, newItem, firebase);
        }
        this.setState({ hasChanges: false, isLoading: false });
      } else {
        this.setState({ isLoading: false });
      }
    }
  };

  handleFileSelected = (fieldName, locale, file) => {
    const field = this.state[fieldName];
    const oldFile = field;
    const newFile = new LocalizedFile(this.props.city.id, fieldName, oldFile);
    const itemFilesToRemove = oldFile.listStorageFiles(locale);
    if (!newFile.hasLocale(locale)) {
      newFile.addLocale(locale);
    }
    newFile.files[locale].contentToUpload = file;

    newFile.files[locale].name = file.name;
    const newField = newFile;
    this.setState({ [fieldName]: newField, hasChanges: true });
    if (itemFilesToRemove.length) {
      let { filesToRemove } = this.state;
      if (filesToRemove) {
        filesToRemove = [...filesToRemove, ...itemFilesToRemove];
      } else {
        filesToRemove = itemFilesToRemove;
      }
      this.setState({ filesToRemove });
    }
  };

  handleContentChange = (fieldName: string, value: string) => {
    const { locale } = this.props;
    const field = this.state[fieldName];
    const oldFile = field;
    const newFile = new LocalizedFile(this.props.city.id, fieldName, oldFile);
    newFile.content.values[locale] = value;
    const newField = newFile;
    this.setState({ [fieldName]: newField, hasChanges: true });
  };

  addFileLocale = (fieldName: string, locale: string) => {
    const field = this.state[fieldName];
    const oldFile = field;
    const newFile = new LocalizedFile(this.props.city.id, fieldName, oldFile);
    newFile.addLocale(locale);
    const newField = newFile;
    this.setState({ [fieldName]: newField, hasChanges: true });
  };

  removeFileLocale = (fieldName: string, locale: string) => {
    const field = this.state[fieldName];
    const oldFile = field;
    const newFile = new LocalizedFile(this.props.city.id, fieldName, oldFile);
    const itemFilesToRemove = newFile.listStorageFiles(locale);
    newFile.removeLocale(locale);
    const newField = newFile;

    this.setState({ [fieldName]: newField, hasChanges: true });
    if (itemFilesToRemove.length) {
      let { filesToRemove } = this.state;
      if (filesToRemove) {
        filesToRemove = [...filesToRemove, ...itemFilesToRemove];
      } else {
        filesToRemove = itemFilesToRemove;
      }
      this.setState({ filesToRemove });
    }
  };

  render() {
    const {
      city, locale, deleteCity, t,
    } = this.props;
    const {
      idName, isValid, hasChanges, isIdValid, isLoading, cover,
    } = this.state;

    let saveBtnClass = hasChanges ? 'btn-warning' : 'btn-outline-secondary';
    if (!isValid) {
      saveBtnClass = 'btn-danger';
    }
    return (
      <div className="card bg-light screenBlock" style={{ height: '100%', overflow: 'hidden' }}>
        <div className="card-header">
          <h3>{t(['screens.cities.edition.title', ''])}</h3>
        </div>
        {city && (
          <div className="pr-3 ml-3 mt-3" style={{ height: '100%', overflow: 'scroll' }}>
            <InputString
              fieldName="idName"
              value={idName}
              label={t(['screens.cities.edition.idLabel', ''])}
              help={t(['screens.cities.edition.idPlaceholder', ''])}
              handleChange={this.handleChange}
              disabled={!!city && !!city.id}
            />
            {(!city || !city.id) && idName && !isIdValid && (
              <label style={{ color: 'red', fontSize: 12 }}>{t(['general.idAlreadyUsed', ''])}</label>
            )}
            <InputString
              key={'name'}
              separatorBefore
              fieldName={'name'}
              value={this.state.name}
              label={t(['screens.cities.edition.nameLabel', ''])}
              handleChange={this.handleChange}
            />
            <InputString
              key={'centerCoordinate'}
              separatorBefore
              fieldName={'centerCoordinate'}
              value={this.state.centerCoordinate}
              label={t(['screens.cities.edition.centerCoordinateLabel', ''])}
              handleChange={this.handleChange}
            />
            <InputNumber
              key={'radius'}
              fieldName={'radius'}
              value={this.state.radius}
              label={t(['screens.cities.edition.radiusLabel', ''])}
              help={t(['screens.cities.edition.radiusHelp', ''])}
              handleChange={this.handleChange}
            />
            <InputNumber
              key={'displayOrder'}
              fieldName={'displayOrder'}
              value={this.state.displayOrder}
              label={t(['screens.cities.edition.displayOrderLabel', ''])}
              help={t(['screens.cities.edition.displayOrderHelp', ''])}
              handleChange={this.handleChange}
            />
            <InputLocalizedFile
              fieldName="cover"
              value={cover}
              label={t(['screens.cities.edition.coverLabel', ''])}
              handleChange={this.handleChange}
              handleFileSelected={this.handleFileSelected}
              handleContentChange={this.handleContentChange}
              addFileLocale={this.addFileLocale}
              removeFileLocale={this.removeFileLocale}
              contentLocale={locale}
            />
            <button
              className={`btn mb-3 ${saveBtnClass}`}
              type="button"
              id="updateitem"
              onClick={this.updateItem}
              disabled={!isValid}
            >
              {t(['general.save', ''])}
            </button>
            {deleteCity && (
              <button
                className={'btn mb-3 ml-2 btn-outline-danger'}
                type="button"
                id="updateitem"
                onClick={this.deleteItem}
                disabled={!isValid}
              >
                {t(['general.delete', ''])}
              </button>
            )}
          </div>
        )}
        {isLoading && <Loader />}
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { cityId } = ownProps;
  const cities = state.configuration.availableCities;
  let itemRedux = cities.find(it => it.id === cityId);
  let isCreate = false;
  if (!itemRedux && !!ownProps.marker) {
    itemRedux = new City({});
    isCreate = true;
  }
  const cityIds = cities.map(it => it.id);
  return {
    isCreate,
    city: itemRedux,
    locale: state.preferences.editionLocale,
    existingCityIds: cityIds,
    hideBaseItemFields: true,
  };
};

const mapDispatchToProps = {
  createCity: ConfigurationServiceHelper.createCity,
  updateCity: ConfigurationServiceHelper.updateCity,
  deleteCity: ConfigurationServiceHelper.removeCity,
};

export default compose(
  withFirebase,
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  withTranslation('default'),
)(CityInput);
