/* @flow */
import Message from './Message';
import Answer from './Answer';
import MessageTriggeredItem from './MessageTriggeredItem';
import NPC from '../NPC';
import BaseItem, { ItemTypes } from '../BaseItem';
import DiscussionEntryPoint from './DiscussionEntryPoint';
import AnswerOpened from './AnswerOpened';
import type { ObjectMap } from '../Shortcuts';

/* ***************************
  CLASS
*************************** */
export type AppDiscussion = {
  npc?: NPC,
  messages?: ObjectMap<Message>,
  firstMessagesIds?: ObjectMap<string>,
};

export default class Discussion extends BaseItem {
  npcId: string;

  messages: ObjectMap<Message>;

  entryPoints: ObjectMap<DiscussionEntryPoint>;

  __detachedNodes: {
    messages: Message[],
    triggers: MessageTriggeredItem[],
    // $FlowFixMe Stupid flow is stupid
    answers: Answer[],
    answersOpened: AnswerOpened[],
  };

  // Contains all the data the editor does not manage yet
  meta: any;

  constructor(json: any) {
    super(json, json instanceof Discussion);
    const {
      npcId, firstMessagesIds, messages, __detachedNodes, entryPoints, type, meta, ...newMeta
    } = json;
    this.type = ItemTypes.Discussion;
    this.npcId = npcId;
    if (json instanceof Discussion) {
      this.messages = { ...json.messages };
      this.__detachedNodes = {
        messages: [...__detachedNodes.messages],
        answers: [...__detachedNodes.answers],
        triggers: [...__detachedNodes.triggers],
        answersOpened: [...__detachedNodes.answersOpened],
      };
      this.entryPoints = { ...entryPoints };
      this.setMeta({ ...json.meta });
    } else {
      this.entryPoints = this.deserizalizeFirstMessagesIds(firstMessagesIds, entryPoints);
      this.messages = this.deserizalizeMessages(messages);
      this.__detachedNodes = this.deserializeDetachedNodes(__detachedNodes);
      this.setMeta({ ...json.meta, ...newMeta });
    }
  }

  getMessageTriggeredItems = () => {
    let items = [];
    Object.values(this.messages).forEach((mess) => {
      if (mess instanceof Message && mess.triggeredItems && mess.triggeredItems.length) {
        items = items.concat(mess.triggeredItems);
      }
    });
    return items;
  };

  deserializeDetachedNodes = (json: any) => {
    const res = {};
    res.messages = [];
    res.answers = [];
    res.triggers = [];
    res.answersOpened = [];
    if (json) {
      if (json.messages) {
        Object.values(json.messages).forEach((value) => {
          res.messages.push(new Message(value));
        });
      }
      if (json.answers) {
        Object.values(json.answers).forEach((value) => {
          res.answers.push(new Answer(value));
        });
      }
      if (json.triggers) {
        Object.values(json.triggers).forEach((value) => {
          res.triggers.push(new MessageTriggeredItem(value));
        });
      }
      if (json.answersOpened) {
        Object.values(json.answersOpened).forEach((value) => {
          res.answersOpened.push(new AnswerOpened(value));
        });
      }
    }
    return res;
  };

  deserizalizeFirstMessagesIds = (jsonIds: any, jsonEntryPoints: any) => {
    const res = {};
    if (jsonEntryPoints) {
      Object.keys(jsonEntryPoints).forEach((id) => {
        const value = { id, ...jsonEntryPoints[id] };
        res[id] = new DiscussionEntryPoint(value);
      });
    }
    if (jsonIds) {
      Object.keys(jsonIds).forEach((id) => {
        if (!res[id]) {
          const value = { id, name: id, messageId: jsonIds[id] };
          res[id] = new DiscussionEntryPoint(value);
        }
      });
    }
    return res;
  };

  deserizalizeMessages = (json: any) => {
    const res = {};
    if (json) {
      Object.keys(json).forEach((messageId) => {
        const value = { id: messageId, ...json[messageId] };
        res[messageId] = new Message(value);
      });
    }
    return res;
  };

  serializeFirstMessageIds = () => {
    const res = {};
    Object.keys(this.entryPoints).forEach((id) => {
      res[id] = this.entryPoints[id].messageId;
    });
    return res;
  };

  serializeEntryPoints = () => {
    const res = {};
    Object.keys(this.entryPoints).forEach((id) => {
      res[id] = this.entryPoints[id].serialize();
    });
    return res;
  };

  serializeInheritedFieldsForApp() {
    const res = super.serializeInheritedFieldsForApp();
    res.npcId = this.npcId;
    res.firstMessagesIds = this.serializeFirstMessageIds();
    if (Object.values(this.messages).length) {
      res.messages = {};
      Object.values(this.messages).forEach((mess) => {
        if (mess instanceof Message) {
          res.messages[mess.id] = mess.serializeForApp();
        }
      });
    } else {
      delete res.messages;
    }
    return res;
  }

  serializeInheritedFields() {
    const res = super.serializeInheritedFields();
    res.firstMessagesIds = this.serializeFirstMessageIds();
    res.entryPoints = this.serializeEntryPoints();
    res.npcId = this.npcId;
    res.messages = {};
    Object.values(this.messages).forEach((mess) => {
      if (mess instanceof Message) {
        res.messages[mess.id] = mess.serialize();
      }
    });
    res.__detachedNodes = {
      messages: this.__detachedNodes.messages.map(mess => mess.serialize()),
      answers: this.__detachedNodes.answers.map(ans => ans.serialize()),
      answersOpened: this.__detachedNodes.answersOpened.map(ans => ans.serialize()),
      triggers: this.__detachedNodes.triggers.map(trig => trig.serialize()),
    };
    return res;
  }

  checkRelease(items: ObjectMap<BaseItem>, locales: string[]) {
    const res = super.checkRelease(items, locales);
    if (!this.npcId) {
      res.push({
        level: 'error',
        item: this.id,
        message: 'E_DISCUSSION_NO_NPC',
      });
    }
    if (!this.entryPoints.Default) {
      res.push({
        level: 'error',
        item: this.id,
        message: 'E_DISCUSSION_NO_DEFAULT_ENTRY',
      });
    }

    Object.entries(this.entryPoints).forEach((ep) => {
      // $FlowFixMe Object.Entries
      const epErrors = ep[1].checkRelease(this.id);
      if (epErrors.length) {
        res.push(...epErrors);
      }
    });
    return res;
  }

  hasDetachedNodes = () => this.__detachedNodes
    && (this.__detachedNodes.messages.length
      || this.__detachedNodes.answers.length
      || this.__detachedNodes.answersOpened.length
      || this.__detachedNodes.triggers.length);

  cleanSubObjectsForFirebase(ser: any, forApp: boolean = false) {
    const res = super.cleanSubObjectsForFirebase(ser, forApp);
    res.firstMessagesIds = this.serializeFirstMessageIds();
    res.entryPoints = this.serializeEntryPoints();
    res.npcId = this.npcId;
    if (this.hasDetachedNodes() && !forApp) {
      res.__detachedNodes = {};
      if (this.__detachedNodes.messages.length) {
        res.__detachedNodes.messages = this.__detachedNodes.messages.map(it => it.serializeForFirebase());
      } else {
        delete res.__detachedNodes.messages;
      }
      if (this.__detachedNodes.answers.length) {
        res.__detachedNodes.answers = this.__detachedNodes.answers.map(it => it.serializeForFirebase());
      } else {
        delete res.__detachedNodes.answers;
      }
      if (this.__detachedNodes.answersOpened.length) {
        res.__detachedNodes.answersOpened = this.__detachedNodes.answersOpened.map(it => it.serializeForFirebase());
      } else {
        delete res.__detachedNodes.answersOpened;
      }
      if (this.__detachedNodes.triggers.length) {
        res.__detachedNodes.triggers = this.__detachedNodes.triggers.map(it => it.serializeForFirebase());
      } else {
        delete res.__detachedNodes.triggers;
      }
    } else {
      delete res.__detachedNodes;
    }
    if (Object.values(this.messages).length) {
      res.messages = {};
      Object.values(this.messages).forEach((mess) => {
        if (mess instanceof Message) {
          res.messages[mess.id] = mess.serializeForFirebase(forApp);
        }
      });
    } else {
      delete res.messages;
    }
    return res;
  }

  getLocalizedStringsWithPath() {
    const res = super.getLocalizedStringsWithPath();
    Object.keys(this.messages).forEach((messId) => {
      const mess = this.messages[messId];
      const messStrings = mess.getLocalizedStringsWithPath();
      Object.keys(messStrings).forEach((strPath) => {
        const newPath = `messages.${messId}.${strPath}`;
        res[newPath] = messStrings[strPath];
      });
    });
    return res;
  }

  getLocalizedFilesWithPath() {
    const res = super.getLocalizedFilesWithPath();
    Object.keys(this.messages).forEach((messId) => {
      const mess = this.messages[messId];
      const messAssets = mess.getLocalizedFilesWithPath();
      Object.keys(messAssets).forEach((assetPath) => {
        const newPath = `messages.${messId}.${assetPath}`;
        res[newPath] = messAssets[assetPath];
      });
    });
    return res;
  }

  getDefaultNextMode() {
    return this.npcId === 'lucas' ? undefined : 'Continue';
  }
}
