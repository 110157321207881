export default {
  message_answer_default_missing: {
    key: 'message_answer_default_missing',
    text: 'Missing default answer',
    class: 'alert-danger',
  },
  message_answer_default_duplicated: {
    key: 'answer_default_duplicated',
    text: 'Only one default answer per message is accepted',
    class: 'alert-danger',
  },
  message_answer_default_order: {
    key: 'messagee_answer_default_order',
    text: 'Some answers are not accessible because they are placed after the default answer',
    class: 'alert-danger',
  },
  answer_default_order: {
    key: 'answer_default_order',
    text: 'Some answers are not accessible because they are placed after the default answer',
    class: 'alert-danger',
  },
  node_unreachable: {
    key: 'node_unreachable',
    text: 'There is no path to access this node',
    class: 'alert-warning',
  },
  item_not_saved: {
    key: 'item_not_saved',
    text: 'Unsaved changes detected',
    class: 'alert-info',
  },
  id_missing: {
    key: 'id_missing',
    text: 'Node id is required',
    class: 'alert-warning',
  },
};
