/* @flow */

import * as _ from 'lodash';
import { DiagramEngine } from 'storm-react-diagrams';

import BaseItem from 'src/data/BaseItem';
import TriggeredItem from 'src/data/TriggeredItem';
import { ItemNodeModel } from '../ItemNodeModel';
import ItemPortModel from '../ItemPortModel';
import type { ItemNodeModelListener } from '../ItemNodeModel';

/* eslint-disable no-use-before-define */
export interface TriggeredItemNodeModelListener extends ItemNodeModelListener<TriggeredItemNodeModel> {}
/* eslint-enable no-use-before-define */

const generateId = () => Math.random()
  .toString(36)
  .slice(-8);

export default class TriggeredItemNodeModel extends ItemNodeModel<TriggeredItemNodeModelListener> {
  item: $Shape<TriggeredItem>;

  constructor(reachable: boolean = false, parent: BaseItem, item: TriggeredItem, isRoot: boolean = false) {
    super('TriggeredItem', item.nodeId || generateId(), reachable, isRoot);
    this.item = { ...item };
    if (!item.nodeId) {
      this.item.nodeId = this.id;
    }
    this.parent = parent;
  }

  getModelId() {
    return this.item.id || this.item.nodeId;
  }

  getId() {
    return this.item.id || this.item.nodeId;
  }

  // eslint-disable-next-line class-methods-use-this
  acceptInLinks(outNode: any, port: ItemPortModel, isBeforeAdd: boolean) {
    const max = isBeforeAdd ? 2 : 1;
    return Object.values(port.links).length < max;
  }

  // eslint-disable-next-line class-methods-use-this
  acceptOutLinks(inNode: any, port: any, isBeforeAdd: boolean) {
    const max = isBeforeAdd ? 2 : 1;
    return Object.values(port.links).length < max;
  }

  initPorts() {
    this.addPort(new ItemPortModel(true, `${this.id}_in`, 'In'));
    this.addPort(new ItemPortModel(false, `${this.id}_out`, 'Out'));
  }

  deSerialize(object: any, engine: DiagramEngine) {
    super.deSerialize(object, engine);
  }

  serialize() {
    return _.merge(super.serialize(), {});
  }
}
