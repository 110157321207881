/* @flow */
import BaseItem, { ItemTypes } from './BaseItem';
import LocalizedString from './LocalizedString';
import LocalizedStringArray from './LocalizedStringArray';
import LocalizedFile from './LocalizedFile';

/* ***************************
  CLASS
*************************** */
export interface AppArchive {}

export default class ArchiveItem extends BaseItem implements AppArchive {
  images: LocalizedFile[];

  name: LocalizedString;

  subtitle: LocalizedString;

  contentText: LocalizedString;

  annotations: LocalizedString;

  constructor(json: any) {
    if (json instanceof ArchiveItem) {
      super(json, true);
    } else {
      super(json, false);
    }
    const {
      type, meta, imageNames, images, name, subtitle, contentText, annotations, ...newMeta
    } = json;
    this.images = [];
    if (images) {
      this.images = images.map(img => new LocalizedFile(this.id, undefined, img));
    }
    this.name = new LocalizedString(`${this.id}_name`, name);
    this.subtitle = new LocalizedString(`${this.id}_subtitle`, subtitle, false);
    this.contentText = new LocalizedString(`${this.id}_contentText`, contentText);
    this.annotations = new LocalizedString(`${this.id}_annotations`, annotations, false);
    this.type = ItemTypes.Archive;
    this.setMeta({ ...json.meta, ...newMeta });
  }

  serializeInheritedFieldsForApp() {
    const res = super.serializeInheritedFieldsForApp();
    res.images = this.images.map(it => it.serializeForApp());
    res.name = this.name && this.name.serializeForApp();
    if (this.subtitle && !this.subtitle.isEmpty()) {
      res.subtitle = this.subtitle.serializeForApp();
    }
    res.contentText = this.contentText && this.contentText.serializeForApp();
    if (this.annotations && !this.annotations.isEmpty()) {
      res.annotations = this.annotations.serializeForApp();
    }
    return res;
  }

  serializeInheritedFields() {
    const res = super.serializeInheritedFields();
    res.images = this.images.map(it => it.serialize());
    res.name = this.name && this.name.serialize();
    res.subtitle = this.subtitle && this.subtitle.serialize();
    res.contentText = this.contentText && this.contentText.serialize();
    res.annotations = this.annotations && this.annotations.serialize();
    return res;
  }

  getLocalizedStringsWithPath() {
    const res: { [path: string]: LocalizedString | LocalizedStringArray } = super.getLocalizedStringsWithPath();
    res.name = this.name;
    res.subtitle = this.subtitle;
    res.contentText = this.contentText;
    res.annotations = this.annotations;
    return res;
  }

  getLocalizedFilesWithPath() {
    const res = super.getLocalizedFilesWithPath();
    this.images.forEach((img) => {
      res[`images.${img.index}`] = img;
    });
    return res;
  }
}
