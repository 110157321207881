/* @flow */
import * as types from './types';

export const setUrlProperties = (urlProperties: { [key: string]: string }) => ({
  type: types.URL_PROPERTIES_CHANGED,
  payload: { urlProperties },
});
export const setLastCoordinate = (lat: number, lon: number, zoom: number) => ({
  type: types.PREFERENCES_SET_LAST_COORDINATE,
  payload: { lat, lon, zoom },
});
export const updateGraphOffset = (graphId: string, x: number, y: number) => ({
  type: types.PREFERENCES_SET_GRAPH_OFFSET,
  payload: { graphId, x, y },
});
export const updateGraphZoom = (graphId: string, zoom: number) => ({
  type: types.PREFERENCES_SET_GRAPH_ZOOM,
  payload: { graphId, zoom },
});
export const setEditionLocale = (locale: string) => ({
  type: types.PREFERENCES_SET_EDITION_LOCALE,
  payload: { locale },
});
