/* @flow */
import type { ReduxDispatch } from 'redux';
import Discussion from 'src/data/discussions/Discussion';
import DiscussionEntryPoint from 'src/data/discussions/DiscussionEntryPoint';
import * as Globals from 'src/constants/globals';
import Message from 'src/data/discussions/Message';
import Firebase, { FirebaseSingleton, FirebaseHelper } from 'src/services/Firebase';
import { EventsServiceHelper, NotificationTypes } from 'src/store/events';
import { asyncForEach } from 'src/utils';
import * as actions from './actions';

const logHelperCall = (title, args) => {
  if (Globals.__DEV__) {
    console.log(`################# DiscussionServiceHelper / ${title}`, args);
  }
};

// DISCUSSIONS
// **********************
export type createDiscussionType = (discussionId: string, npcId: string) => ReduxDispatch => void;
export type updateDiscussionType = (discussionId: string, discussion: any) => ReduxDispatch => void;
export const createDiscussionAsync: createDiscussionType = (discussionId, npcId) => (dispatch) => {
  logHelperCall('addDiscussionAsync', { discussionId, npcId });
  const firstMessageId = `${discussionId}_message_start`;
  const discussion = new Discussion({
    npcId,
    id: discussionId,
    firstMessagesIds: {
      Default: firstMessageId,
    },
    messages: {
      [firstMessageId]: {
        id: firstMessageId,
      },
    },
  });
  dispatch(actions.addDiscussion(discussionId, discussion));
};

export const updateDiscussionAsync: updateDiscussionType = (discussionId, discussion) => (dispatch) => {
  logHelperCall('updateDiscussionAsync', { discussionId, discussion });
  dispatch(actions.updateDiscussion(discussionId, discussion, false));
};

export type updateFirstMessageIdType = (
  discussionId: string,
  entryPoint: DiscussionEntryPoint,
) => ReduxDispatch => void;
export type deleteFirstMessageIdType = (discussionId: string, name: string) => ReduxDispatch => void;
export const addFirstMessageId: updateFirstMessageIdType = (discussionId, entryPoint) => (dispatch) => {
  logHelperCall('addFirstMessageId', { discussionId, entryPoint });
  dispatch(actions.updateEntryPoint(discussionId, entryPoint));
};
export const updateFirstMessageId: updateFirstMessageIdType = (discussionId, entryPoint) => (dispatch) => {
  logHelperCall('addFirstMessageId', { discussionId, entryPoint });
  dispatch(actions.updateEntryPoint(discussionId, entryPoint));
};
export const deleteFirstMessageId: deleteFirstMessageIdType = (discussionId, name) => (dispatch) => {
  logHelperCall('deleteFirstMessageId', { discussionId, name });
  dispatch(actions.removeEntryPoint(discussionId, name));
};

export type entryPointMovedType = (
  discussionId: string,
  entryPointName: string,
  pos: { x: number, y: number },
) => ReduxDispatch => void;
export const entryPointMoved: entryPointMovedType = (discussionId, entryPointName, pos) => (dispatch) => {
  logHelperCall('entryPointMoved', { discussionId, entryPointName, pos });
  dispatch(actions.moveEntryPoint(discussionId, entryPointName, pos));
};

// Messages
// **********************
export type updateMessageType = (
  discussionId: string,
  messageId?: string,
  message: any,
  scenarioId?: string,
  firebase?: Firebase,
) => ReduxDispatch => Promise<void>;
export const addMessageAsync: updateMessageType = (discussionId, messageId, message) => async (dispatch) => {
  logHelperCall('addMessageAsync', { discussionId, messageId, message });
  dispatch(actions.addMessage(discussionId, messageId, message));
};

export const updateMessageAsync: updateMessageType = (
  discussionId,
  messageId,
  message,
  scenarioId,
  firebase,
) => async (dispatch) => {
  logHelperCall('updateMessageAsync', { discussionId, messageId, message });
  const shouldUploadFile = message instanceof Message && message.hasFileToUpload();
  if (shouldUploadFile && scenarioId && firebase) {
    const version = await FirebaseHelper.getScenarioNextVersionAsync(scenarioId, firebase);
    const localizedFiles = message.getLocalizedFiles();
    if (localizedFiles) {
      await asyncForEach(localizedFiles, async (img) => {
        await asyncForEach(Object.keys(img.files), async (locale) => {
          const file = img.files[locale];
          if (file.contentToUpload) {
            const ext = file.contentToUpload.name.split('.').pop();
            const url = await FirebaseHelper.pushScenarioEditorAssetAsync(
              scenarioId,
              img.getStorageFileName(locale, version, ext),
              file.contentToUpload,
              firebase,
            );
            // eslint-disable-next-line no-param-reassign
            img.ext = ext;
            file.url = url;
            file.ext = ext;
            file.version = version;
            file.name = img.getStorageFileName(locale, version, ext);
            delete file.contentToUpload;
          }
        });
      });
    }
  }
  if (message.contentType !== 'Image') {
    // TODO : Delete all images from storage
  }
  dispatch(actions.updateMessage(discussionId, messageId, message, shouldUploadFile));
  EventsServiceHelper.addNotif(NotificationTypes.SUCCESS, 'S_ITEM_PERSISTED')(dispatch);
};

export type nodeMovedType = (
  discussionId: string,
  messageId?: string,
  nodeId: string,
  pos: { x: number, y: number },
) => ReduxDispatch => void;
export const messageNodeMoved: nodeMovedType = (discussionId, messageId, nodeId, pos) => (dispatch) => {
  logHelperCall('messageNodeMoved', {
    discussionId,
    messageId,
    nodeId,
    pos,
  });
  dispatch(actions.messageNodeMoved(discussionId, messageId, nodeId, pos));
};

export type deleteMessageType = (
  scenarioId: string,
  discussionId: string,
  message: Message,
  nodeId: string,
  messageId: string,
) => ReduxDispatch => Promise<void>;
export const deleteMessageAsync: deleteMessageType = (
  scenarioId,
  discussionId,
  message,
  nodeId,
  messageId,
) => async (dispatch) => {
  logHelperCall('deleteMessageAsync', {
    scenarioId,
    discussionId,
    message,
    nodeId,
  });
  let filesToRemove = [];
  if (message) {
    message.getLocalizedFiles().forEach((it) => {
      filesToRemove = [...filesToRemove, ...it.listStorageFiles()];
    });
  }
  if (filesToRemove && filesToRemove.length) {
    await FirebaseHelper.removeEditorFilesAsync(scenarioId, filesToRemove, 'scenario', FirebaseSingleton);
  }
  if (message) {
    dispatch(actions.removeMessage(discussionId, message.id, nodeId, !!filesToRemove && !!filesToRemove.length));
  } else if (messageId) {
    dispatch(actions.removeMessage(discussionId, messageId, nodeId, !!filesToRemove && !!filesToRemove.length));
  } else {
    console.error({
      scenarioId, discussionId, message, nodeId, messageId,
    });
  }
};

// ANSWERS
// **********************

export type updateAnswerType = (discussionId: string, messageId?: string, answer: any) => ReduxDispatch => void;
export const addAnswerAsync: updateAnswerType = (discussionId, messageId, answer) => (dispatch) => {
  logHelperCall('addAnswerAsync', { discussionId, messageId, answer });
  dispatch(actions.addAnswer(discussionId, messageId, answer));
};

export const updateAnswerAsync: updateAnswerType = (discussionId, messageId, answer) => (dispatch) => {
  logHelperCall('updateAnswerAsync', { discussionId, messageId, answer });
  dispatch(actions.updateAnswer(discussionId, messageId, answer));
  EventsServiceHelper.addNotif(NotificationTypes.SUCCESS, 'S_ITEM_PERSISTED')(dispatch);
};

export const answerNodeMoved: nodeMovedType = (discussionId, messageId, nodeId, pos) => (dispatch) => {
  logHelperCall('answerNodeMoved', {
    discussionId,
    messageId,
    nodeId,
    pos,
  });
  dispatch(actions.answerNodeMoved(discussionId, messageId, nodeId, pos));
};

export type deleteAnswerType = (discussionId: string, messageId?: string, nodeId: string) => ReduxDispatch => void;
export const deleteAnswerAsync: deleteAnswerType = (discussionId, messageId, nodeId) => (dispatch) => {
  logHelperCall('deleteAnswerAsync', { discussionId, messageId, nodeId });
  dispatch(actions.removeAnswer(discussionId, messageId, nodeId));
};

// ANSWER OPENED
// **********************

export const addAnswerOpenedAsync: updateAnswerType = (discussionId, messageId, answer) => (dispatch) => {
  logHelperCall('addAnswerOpenedAsync', { discussionId, messageId, answer });
  dispatch(actions.addAnswerOpened(discussionId, messageId, answer));
};

export const updateAnswerOpenedAsync: updateAnswerType = (discussionId, messageId, answer) => (dispatch) => {
  logHelperCall('updateAnswerOpenedAsync', { discussionId, messageId, answer });
  dispatch(actions.updateAnswerOpened(discussionId, messageId, answer));
  EventsServiceHelper.addNotif(NotificationTypes.SUCCESS, 'S_ITEM_PERSISTED')(dispatch);
};

export const answerOpenedNodeMoved: nodeMovedType = (discussionId, messageId, nodeId, pos) => (dispatch) => {
  logHelperCall('answerOpenedNodeMoved', {
    discussionId,
    messageId,
    nodeId,
    pos,
  });
  dispatch(actions.answerOpenedNodeMoved(discussionId, messageId, nodeId, pos));
};

export const deleteAnswerOpenedAsync: deleteAnswerType = (discussionId, messageId, nodeId) => (dispatch) => {
  logHelperCall('deleteAnswerOpenedAsync', { discussionId, messageId, nodeId });
  dispatch(actions.removeAnswerOpened(discussionId, messageId, nodeId));
};

// TRIGGERS
// **********************
export type updateTriggerType = (discussionId: string, messageId?: string, trigger: any) => ReduxDispatch => void;
export const addTriggerAsync: updateTriggerType = (discussionId, messageId, trigger) => (dispatch) => {
  logHelperCall('addTriggerAsync', { discussionId, messageId, trigger });
  dispatch(actions.addMessageTriggeredItem(discussionId, messageId, trigger));
};

export const updateTriggerAsync: updateTriggerType = (discussionId, messageId, trigger) => (dispatch) => {
  logHelperCall('updateTriggerAsync', {
    discussionId,
    messageId,
    trigger,
  });
  dispatch(actions.updateMessageTriggeredItem(discussionId, messageId, trigger));
  EventsServiceHelper.addNotif(NotificationTypes.SUCCESS, 'S_ITEM_PERSISTED')(dispatch);
};

export const triggerNodeMoved: nodeMovedType = (discussionId, messageId, nodeId, pos) => (dispatch) => {
  logHelperCall('triggerNodeMoved', {
    discussionId,
    messageId,
    nodeId,
    pos,
  });
  dispatch(actions.triggerNodeMoved(discussionId, messageId, nodeId, pos));
};

export type deleteTriggerType = (discussionId: string, messageId?: string, nodeId: string) => ReduxDispatch => void;
export const deleteTriggerAsync: deleteTriggerType = (discussionId, messageId, nodeId) => (dispatch) => {
  logHelperCall('deleteTriggerAsync', { discussionId, messageId, nodeId });
  dispatch(actions.removeMessageTriggeredItem(discussionId, messageId, nodeId));
};

// LINKS
// **********************
export type linkNextMessageType = (
  discussionId: string,
  messageId: string,
  answerId?: string,
  nextMessageId: string,
  isOpened?: boolean,
) => ReduxDispatch => void;
export const linkNextMessageAsync: linkNextMessageType = (
  discussionId,
  messageId,
  answerId,
  nextMessageId,
  isOpened = false,
) => (dispatch) => {
  logHelperCall('linkNextMessageAsync', {
    discussionId,
    messageId,
    answerId,
    nextMessageId,
    isOpened,
  });
  dispatch(actions.linkNextMessage(discussionId, messageId, answerId, nextMessageId, isOpened));
};

export type linkAnswerType = (discussionId: string, messageId: string, answerNodeId: string) => ReduxDispatch => void;
export const linkAnswerAsync: linkAnswerType = (discussionId, messageId, answerNodeId) => (dispatch) => {
  logHelperCall('linkAnswerAsync', { discussionId, messageId, answerNodeId });
  dispatch(actions.linkAnswer(discussionId, messageId, answerNodeId));
};
export const linkAnswerOpenedAsync: linkAnswerType = (discussionId, messageId, answerNodeId) => (dispatch) => {
  logHelperCall('linkAnswerOpenedAsync', {
    discussionId,
    messageId,
    answerNodeId,
  });
  dispatch(actions.linkAnswerOpened(discussionId, messageId, answerNodeId));
};

export type linkTriggerType = (discussionId: string, messageId: string, triggerNodeId: string) => ReduxDispatch => void;
export const linkTriggerAsync: linkTriggerType = (discussionId, messageId, triggerNodeId) => (dispatch) => {
  logHelperCall('linkTriggerAsync', { discussionId, messageId, triggerNodeId });
  dispatch(actions.linkMessageTriggeredItem(discussionId, messageId, triggerNodeId));
};

export const unlinkNextMessageAsync: linkNextMessageType = (
  discussionId,
  messageId,
  answerId,
  nextMessageId,
  isOpened = false,
) => (dispatch) => {
  logHelperCall('unlinkNextMessageAsync', {
    discussionId,
    messageId,
    answerId,
    nextMessageId,
    isOpened,
  });
  dispatch(actions.unlinkNextMessage(discussionId, messageId, answerId, nextMessageId, isOpened));
};

export const unlinkAnswerAsync: linkAnswerType = (discussionId, messageId, answerNodeId) => (dispatch) => {
  logHelperCall('unlinkAnswerAsync', { discussionId, messageId, answerNodeId });
  dispatch(actions.unlinkAnswer(discussionId, messageId, answerNodeId));
};

export const unlinkAnswerOpenedAsync: linkAnswerType = (discussionId, messageId, answerNodeId) => (dispatch) => {
  logHelperCall('unlinkAnswerOpenedAsync', {
    discussionId,
    messageId,
    answerNodeId,
  });
  dispatch(actions.unlinkAnswerOpened(discussionId, messageId, answerNodeId));
};

export const unlinkTriggerAsync: linkTriggerType = (discussionId, messageId, triggerNodeId) => (dispatch) => {
  logHelperCall('unlinkTriggerAsync', {
    discussionId,
    messageId,
    triggerNodeId,
  });
  dispatch(actions.unlinkMessageTriggeredItem(discussionId, messageId, triggerNodeId));
};

// IMPORT
// *********************
export type importDiscussionType = (data: any) => ReduxDispatch => void;
export const importDiscussion: importDiscussionType = data => (dispatch) => {
  logHelperCall('updateDiscussionAsync', { data });
  const discussion = new Discussion(data);
  dispatch(actions.updateDiscussion(discussion.id, discussion, true));
};
