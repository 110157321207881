/* @flow */
import * as React from 'react';
import { connect } from 'react-redux';

import 'bootstrap/dist/css/bootstrap.min.css';

import { withAuthorization, AuthenticatedCondition } from 'src/services/Session';
import { Claims } from 'src/constants/roles';
import { withTranslation } from 'react-i18next';
import { compose } from 'redux';
import { Loader } from 'src/pages/components';
import StatisticsTab from './statistics/StatisticsTab';

export type AdminScreenProps = {
  editionLocale: string,
};

type State = {
  isLoading: boolean,
};

class StatisticsScreen extends React.PureComponent<AdminScreenProps, State> {
  static defaultProps = {};

  reader: ?FileReader = undefined;

  state = {
    isLoading: false,
  };

  render() {
    const { isLoading } = this.state;
    return (
      <div className="pageContainer">
        <div className="container-fluid fill component-controller align-content-stretch" style={{ overflow: 'hidden' }}>
          <div className="tab-content d-flex" id="myTabContent" style={{ overflow: 'hidden', height: '100%' }}>
            <StatisticsTab />
          </div>
        </div>
        {isLoading && <Loader />}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  editionLocale: state.preferences.editionLocale,
});

const mapDispatchToProps = {};

export default compose(
  withAuthorization(AuthenticatedCondition, [Claims.Admin]),
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  withTranslation('default'),
)(StatisticsScreen);
