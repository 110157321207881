/* @flow */
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { withAuthorization, AuthenticatedCondition } from 'src/services/Session';
import { Claims } from 'src/constants/roles';
import { withUrlProperties } from 'src/pages/components/WithUrlProperties';
import Firebase, { withFirebase, FirebaseHelper } from 'src/services/Firebase';
import '../style.css';

import 'bootstrap/dist/css/bootstrap.min.css';
import type { ItemTypesType } from 'src/data';
import { RoomServiceHelper } from 'src/store/room';
import { ItemTypes, BaseItem } from 'src/data';
import { withTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { compose } from 'redux';
import {
  MapEditionWidget, DefaultItemInput, InputSelect, RoomMembersView,
} from 'src/pages/components';
import { TriggeredItemInput } from './components';
import ScenarioGraphView from './ScenarioGraphView';
import { withCities } from '../../cities/WithCities';
import { ItemNodeModel } from '../components/graph';

export type ScenarioItemsEditionScreenProps = {
  items: { [id: string]: BaseItem },
  roomMembers: any[],
  setRoomItem: RoomServiceHelper.dispatchSetRoomItemType,
  scenarioId: string,
};

type State = {
  editionMode?: ItemTypesType,
  currentItemId?: string,
  currentNodeId?: string,
  parentId?: string,
  childId?: string,
  currentMarker?: number[],
  currentPolygon?: any,
  leftColumnClass: string,
  collapseButtonText: string,
  editingItem: boolean,
  forcedItemToFocus?: ItemNodeModel<any>,
};

const ScenarioItemsEditionScreen = (props: ScenarioItemsEditionScreenProps) => {
  const [editionMode, setEditionMode] = useState(undefined);
  const [currentItemId, setCurrentItemId] = useState(undefined);
  const [currentNodeId, setCurrentNodeId] = useState(undefined);
  const [currentMarker, setCurrentMarker] = useState(undefined);
  const [currentPolygon, setCurrentPolygon] = useState(undefined);
  const [leftColumnClass, setLeftColumnClass] = useState('col-12');
  const [collapseButtonText, setCcollapseButtonText] = useState('chevron-left');
  const [parentId, setParentId] = useState(undefined);
  const [childId, setChildId] = useState(undefined);
  const [editingItem, setEditingItem] = useState(undefined);
  const [forcedItemToFocus, setForcedItemToFocus] = useState(undefined);
  const [trackedUser, setTrackedUser] = useState(undefined);

  let userTracker;
  useEffect(
    () => {
      props.setRoomItem(currentItemId, currentNodeId);
    },
    [currentItemId, currentNodeId],
  );
  useEffect(
    () => {
      if (trackedUser) {
        userTracker = props.firebase
          .room(props.scenarioId)
          .child(trackedUser)
          .on('value', (snapshot) => {
            const data = snapshot.val();
            console.log(data);
            if (data && data.currentItemId) {
              const item = props.items[data.currentItemId];
              setForcedItemToFocus(item);
            }
          });
      }
    },
    [trackedUser],
  );
  useEffect(
    () => function cleanup() {
      if (userTracker) {
        userTracker.off('value', this.roomListener);
      }
    },
    [],
  );

  const handleSearchtItem = (event) => {
    const value = event.target.value;
    const fieldName = event.target.id;
    const item = props.items[value];
    setForcedItemToFocus(item);
  };

  const itemSelected = (id?: string, nodeId: string, itemType: ItemTypesType) => {
    setEditingItem(false);
    setEditionMode(itemType);
    setCurrentItemId(id);
    setCurrentNodeId(nodeId);
    setCurrentMarker(undefined);
    setCurrentPolygon(undefined);
    setParentId(undefined);
    setChildId(undefined);

    forceShowRight();
  };

  // eslint-disable-next-line no-unused-vars
  const itemDeleted = (id?: string, nodeId: string) => {
    setEditingItem(false);
    setEditionMode(undefined);
    setCurrentItemId(undefined);
    setCurrentNodeId(undefined);
  };

  const triggerSelected = (parentId: string, childId: string, nodeId: string) => {
    setEditingItem(false);
    setEditionMode('TriggeredItem');
    setCurrentItemId(undefined);
    setCurrentNodeId(nodeId);
    setParentId(parentId);
    setChildId(childId);

    forceShowRight();
  };

  const triggerDeleted = () => {
    setEditingItem(false);
    setEditionMode('TriggeredItem');
    setCurrentItemId(undefined);
    setParentId(undefined);
    setChildId(undefined);
  };

  const onCloseItem = () => {
    setEditingItem(false);
    setEditionMode(undefined);
    setCurrentItemId(undefined);
    setCurrentNodeId(undefined);
    setParentId(undefined);
    setChildId(undefined);
    closeRight();
  };

  const polygonChanged = (id, polygon) => {
    setCurrentPolygon(polygon);
  };

  const markerChanged = (id, point) => {
    setCurrentMarker(point);
  };

  const onMarkerDragged = (id: string, lat: number, lng: number, alt?: number) => {
    const pos: number[] = [lat, lng];
    if (alt !== undefined) {
      pos.push(alt);
    }
    const item = props.items[id];
    setCurrentItemId(id);
    setEditionMode(item.type);
    setCurrentNodeId(item.nodeId);
    setCurrentMarker(pos);
  };

  const renderItem = () => {
    if (editionMode === 'TriggeredItem') {
      return (
        <TriggeredItemInput
          parentId={parentId}
          childId={childId}
          nodeId={currentNodeId}
          startEditing={onStartEditingItem}
          isEditingItem={editingItem}
          disabled={!!trackedUser}
        />
      );
    }

    if (editionMode) {
      return (
        <DefaultItemInput
          itemId={currentItemId}
          nodeId={currentNodeId}
          marker={currentMarker}
          polygon={currentPolygon}
          startEditing={onStartEditingItem}
          isEditingItem={editingItem}
          disabled={!!trackedUser}
        />
      );
    }

    if (!editionMode) {
      return (
        <p className="text-center" id="no-item-selected">
          Selectionnez un élement pour l'éditer
        </p>
      );
    }
  };

  const renderClose = () => (
    editionMode && (
        <div className="interactive mr-auto ml-2 mb-2" id="close-item" onClick={onCloseItem}>
          <FontAwesomeIcon icon={['fad', 'times']} />
        </div>
    )
  );

  const renderEditionMode = () => (
      <InputSelect
        fieldName="selectedItem"
        value={currentItemId}
        values={Object.values(props.items)}
        itemToId={it => it.id}
        itemToTitle={it => it.id}
        label={''}
        handleChange={handleSearchtItem}
        disabled={!!trackedUser}
      />
  );

  const onStartEditingItem = () => {
    setEditingItem(true);
  };

  const onCollapseClick = () => {
    if (collapseButtonText === 'chevron-right') {
      closeRight();
    } else {
      forceShowRight();
    }
  };

  const forceShowRight = () => {
    setCcollapseButtonText('chevron-right');
    setLeftColumnClass('col-6 col-sm-8');
  };

  const closeRight = () => {
    setCcollapseButtonText('chevron-left');
    setLeftColumnClass('col-12');
  };

  const onMemberClic = (data) => {
    if (data.meta) {
      const itemId = data.meta.currentItemId;
      const sessionId = data.sessionId;
      if (sessionId === trackedUser) {
        setTrackedUser(undefined);
      } else {
        if (itemId) {
          const item = props.items[itemId];
          setForcedItemToFocus(item);
        }
        setTrackedUser(sessionId);
      }
    }
  };

  const { items, t } = props;
  return (
    <div className="pageContainer" id="scenario-edition">
      <RoomMembersView noTitle roomMembers={props.roomMembers} onMemberClick={onMemberClic} trackedUser={trackedUser} />
      <div className="row">
        <div className="col">
          <ul className="nav nav-tabs" id="myTab" role="tablist">
            <li className="nav-item">
              <a
                className="nav-link active  btn"
                id="home-tab"
                data-toggle="tab"
                data-target="#graph"
                href={'#graph'}
                role="tab"
                aria-controls="home"
                aria-selected="true"
              >
                <FontAwesomeIcon icon={['far', 'sitemap']} />
                {t(['screens.scenarioEdition.structure.graph', ''])}
              </a>
            </li>
            <li className="nav-item">
              <a
                className="nav-link btn"
                id="profile-tab"
                data-toggle="tab"
                href="#map"
                data-target="#map"
                role="tab"
                aria-controls="profile"
                aria-selected="false"
              >
                <FontAwesomeIcon icon={['fad', 'map-marked']} />
                {t(['screens.scenarioEdition.structure.map', ''])}
              </a>
            </li>

            <div className="right-side-bar">{renderEditionMode()}</div>
          </ul>
        </div>
      </div>
      <div className="row fill mr-0">
        <div className={leftColumnClass} id="leftColumn">
          <div className="container-fluid fill background-primary" id="graphContainer">
            <div className="tab-content" id="myTabContent">
              <div className="tab-pane fade show active" id="graph" role="tabpanel" aria-labelledby="home-tab">
                <ScenarioGraphView
                  itemSelected={itemSelected}
                  itemDeleted={itemDeleted}
                  forcedItemToFocus={forcedItemToFocus}
                  triggerSelected={triggerSelected}
                  isEditingItem={editingItem}
                />
              </div>
              <div className="tab-pane fade" id="map" role="tabpanel" aria-labelledby="profile-tab">
                <MapEditionWidget
                  currentItemId={currentItemId}
                  itemClicked={itemSelected}
                  markerChanged={markerChanged}
                  onMarkerDragged={onMarkerDragged}
                  polygonChanged={polygonChanged}
                  itemsMap={items}
                  markerFilter={it => it instanceof BaseItem && it.type === ItemTypes.POI}
                  polygonFilter={it => it instanceof BaseItem && it.type === ItemTypes.GameArea}
                />
              </div>
            </div>
          </div>
          {
            <div className={'sideButton'}>
              <div className="ml-auto mr-2 mb-2 interactive text-main" onClick={onCollapseClick}>
                <FontAwesomeIcon icon={['fas', collapseButtonText]} />
              </div>
            </div>
          }
        </div>
        <div className="col" id="rightColumn">
          {renderClose()}
          <div className="container-fluid no-margin" id="editionContainer">
            {renderItem()}
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  items: state.scenario.items,
  scenarioId: state.scenario.header.id,
});

const mapDispatchToProps = {
  setRoomItem: RoomServiceHelper.dispatchSetRoomItem,
};

export default compose(
  withCities,
  withFirebase,
  withUrlProperties,
  withAuthorization(AuthenticatedCondition, [Claims.Editor]),
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  withTranslation('default'),
)(ScenarioItemsEditionScreen);
