import React from 'react';
import { connect } from 'react-redux';
import 'bootstrap/dist/css/bootstrap.min.css';

import { withUrlProperties } from 'src/pages/components/WithUrlProperties';
import { withAuthorization, AuthenticatedCondition } from 'src/services/Session';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { RoomMembersView } from 'src/pages/components';
import { Claims } from 'src/constants/roles';
import { withTranslation } from 'react-i18next';
import { compose } from 'redux';

import './DiscussionEditionScreen.css';
import {
  AnswerInput,
  DiscussionContextView,
  DiscussionGraphView,
  DiscussionMetaInput,
  MessageInput,
  MessageTriggerInput,
  DiscussionInitWidget,
  AnswerOpenedInput,
} from './components';

export type DiscussionEditionScreenProps = {
  discussion: any,
  discussionId?: string,
  roomMembers: any[],
};

type State = {
  editionMode: 'message' | 'answer' | 'meta' | 'answerOpened',
  currentAnswerId: string,
  currentMessageId: string,
  currentTriggerId: string,
  currentNodeId: string,
  editingItem: boolean,
  graphHandler?: any => any,
};

class DiscussionEditionScreen extends React.PureComponent<DiscussionEditionScreenProps, State> {
  static defaultProps = {
    rootMessageId: 'message_1',
  };

  state = {
    editionMode: 'discussion',
  };

  showDiscussion = () => {
    console.log('Showing discussion meta editor');
    if (this.state.editionMode === 'discussion') this.setState({ editionMode: '' });
    else this.setState({ editionMode: 'discussion' });
  };

  messageSelected = (id?: string, nodeId: string) => {
    console.log(`Selected message for edition with id ${id}`);
    this.setState({
      editionMode: 'message',
      editingItem: false,
      currentMessageId: id,
      currentNodeId: nodeId,
    });
  };

  answerSelected = (id?: string, messageId?: string, nodeId: string) => {
    console.log(`Selected answer for edition with id ${id}`);
    this.setState({
      editionMode: 'answer',
      editingItem: false,
      currentAnswerId: id,
      currentMessageId: messageId,
      currentNodeId: nodeId,
    });
  };

  answerOpenedSelected = (id?: string, messageId?: string, nodeId: string) => {
    console.log(`Selected answer opened for edition with id ${id}`);
    this.setState({
      editionMode: 'answerOpened',
      editingItem: false,
      currentAnswerId: id,
      currentMessageId: messageId,
      currentNodeId: nodeId,
    });
  };

  itemSelected = (id?: string, messageId?: string, nodeId: string) => {
    console.log(`Selected trigger for edition with id ${id}`);
    this.setState({
      editionMode: 'trigger',
      editingItem: false,
      currentTriggerId: id,
      currentMessageId: messageId,
      currentNodeId: nodeId,
    });
  };

  addMessage = (nodeId: string) => {
    this.setState({
      editionMode: 'message',
      editingItem: false,
      currentMessageId: undefined,
      currentNodeId: nodeId,
    });
  };

  addTrigger = (nodeId: string) => {
    this.setState({
      editionMode: 'trigger',
      editingItem: false,
      currentMessageId: undefined,
      currentNodeId: nodeId,
    });
  };

  addAnswer = (nodeId: string) => {
    this.setState({
      editionMode: 'answer',
      currentMessageId: undefined,
      currentNodeId: nodeId,
    });
  };

  addAnswerOpened = (nodeId: string) => {
    this.setState({
      editionMode: 'answerOpened',
      editingItem: false,
      currentMessageId: undefined,
      currentNodeId: nodeId,
    });
  };

  removeItem = (itemId: string) => {
    console.log('Item removed for id', itemId);
    this.setState({
      editionMode: 'discussion',
      editingItem: false,
      currentMessageId: undefined,
      currentNodeId: undefined,
    });
  };

  onStartEditingItem = () => {
    this.setState({ editingItem: true });
  };

  onAddBellow = (data, type) => {
    if (this.state.graphHandler) {
      this.state.graphHandler({ type: 'addItem', data: { item: data, type } });
    }
  };

  renderEditionMode = () => {
    const { editionMode, currentNodeId, currentMessageId } = this.state;
    const { discussionId } = this.props;
    let res;
    switch (editionMode) {
      case 'message':
        res = (
          <MessageInput
            messageId={currentMessageId}
            discussionId={discussionId}
            nodeId={currentNodeId}
            startEditing={this.onStartEditingItem}
            isEditingItem={this.state.editingItem}
            onAddBellow={this.onAddBellow}
          />
        );
        break;
      case 'answer':
        res = (
          <AnswerInput
            messageId={currentMessageId}
            discussionId={discussionId}
            nodeId={currentNodeId}
            startEditing={this.onStartEditingItem}
            isEditingItem={this.state.editingItem}
            onAddBellow={this.onAddBellow}
          />
        );
        break;
      case 'answerOpened':
        res = (
          <AnswerOpenedInput
            messageId={currentMessageId}
            discussionId={discussionId}
            nodeId={currentNodeId}
            startEditing={this.onStartEditingItem}
            isEditingItem={this.state.editingItem}
            onAddBellow={this.onAddBellow}
          />
        );
        break;
      case 'trigger':
        res = (
          <MessageTriggerInput
            messageId={currentMessageId}
            discussionId={discussionId}
            nodeId={currentNodeId}
            startEditing={this.onStartEditingItem}
            isEditingItem={this.state.editingItem}
            onAddBellow={this.onAddBellow}
          />
        );
        break;
      case 'discussion':
        res = null; /* (
          <DiscussionMetaInput
            discussionId={discussionId}
            startEditing={this.onStartEditingItem}
            isEditingItem={this.state.editingItem}
          />
        ); */
        break;
      default:
        break;
    }
    return res;
  };

  renderNoDiscussion = () => (
    <div className="pageContainer">
      <div className="container-fluid fill component-controller">
        <DiscussionInitWidget startEditing={this.onStartEditingItem} isEditingItem={this.state.editingItem} />
      </div>
    </div>
  );

  attachGraphEventHandler = (handler) => {
    this.setState({
      graphHandler: handler || null,
    });
  };

  showRightPane = () => this.state.editionMode && this.state.editionMode.length && this.state.currentNodeId;

  onCloseItem = () => {
    this.setState({
      currentNodeId: undefined,
      currentAnswerId: undefined,
      currentMessageId: undefined,
      currentTriggerId: undefined,
    });
  };

  renderClose = () => this.state.editionMode && (
      <div className="interactive mr-auto ml-2 mb-2" id="close-item" onClick={this.onCloseItem}>
        <FontAwesomeIcon icon={['fad', 'times']} />
      </div>
  );

  renderDiscussionExist = () => {
    const { discussion, discussionId, t } = this.props;
    const hasRightPane = this.showRightPane();
    return (
      <div className="pageContainer" id="discution-edition">
        <RoomMembersView roomMembers={this.props.roomMembers} />
        <div className="row hidden">
          <div className="col">
            <button
              type="button"
              id="discussionMetaButton"
              className="btn btn-primary"
              onClick={() => {
                this.showDiscussion();
              }}
            >
              {t(['screens.discussionEdition.structure.metaEditionBtn', ''])}
            </button>
          </div>
        </div>
        {(true || this.state.editionMode === 'discussion') && (
          <div className="row">
            <div className="col-xs-11 col-md-4 col-lg-3">
              <DiscussionMetaInput
                discussionId={discussionId}
                startEditing={this.onStartEditingItem}
                isEditingItem={this.state.editingItem}
              />
            </div>
          </div>
        )}

        <div className="row fill mr-0 noScroll">
          <div className="col" id="leftColumn">
            <div className="container-fluid fill background-primary" id="graphContainer">
              <DiscussionGraphView
                setExternalEventHandler={this.attachGraphEventHandler}
                discussionId={discussionId}
                messageSelected={this.messageSelected}
                answerSelected={this.answerSelected}
                answerOpenedSelected={this.answerOpenedSelected}
                itemSelected={this.itemSelected}
                addItem={this.addItem}
                removeItem={this.removeItem}
                messageAdded={this.addMessage}
                answerAdded={this.addAnswer}
                answerOpenedAdded={this.addAnswerOpened}
                triggerAdded={this.addTrigger}
                isEditingItem={this.state.editingItem}
              />
            </div>
          </div>
          {hasRightPane && (
            <div className="col-4" id="rightColumn">
              {this.renderClose()}
              {/* <DiscussionContextView discussion={discussion} /> */}
              <div className="container-fluid no-margin" id="editionContainer">
                {this.renderEditionMode()}
              </div>
            </div>
          )}
        </div>
      </div>
    );
  };

  render() {
    const { discussion } = this.props;
    if (discussion) {
      return this.renderDiscussionExist();
    }
    return this.renderNoDiscussion();
  }
}

const mapStateToProps = (state, ownProps) => {
  const discussionId = ownProps.match.params.discussionId;
  return {
    discussion: discussionId && state.scenario.items[discussionId],
    discussionId,
  };
};

const mapDispatchToProps = {};

export default compose(
  withUrlProperties,
  withAuthorization(AuthenticatedCondition, [Claims.Editor]),
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  withTranslation('default'),
)(DiscussionEditionScreen);
