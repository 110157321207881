/* @flow */
import { createNamedReducer } from 'redux-named-reducers';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage/session';

import * as types from './types';

export type GraphPreferenceType = { x: number, y: number, zoom: number };

export type RoomReducerState = {
  x: number,
  y: number,
  zoom: number,
  roomId?: string,
  itemId?: string,
  nodeId?: string,
};

export const RoomReducerDefaultState = {
  x: 0,
  y: 0,
  zoom: 1,
  roomId: undefined,
  itemId: undefined,
  nodeId: undefined,
};

const reducer = (state: RoomReducerState = RoomReducerDefaultState, action: { type: string, payload: any }) => {
  let newState;
  switch (action.type) {
    case types.ROOM_SET_ROOM: {
      newState = {
        ...state,
        roomId: action.payload.roomId,
      };
      // console.log('ROOM UPDATED', newState);
      break;
    }
    case types.ROOM_SET_GRAPH_OFFSET: {
      const { x, y } = action.payload;
      newState = {
        ...state,
        x,
        y,
      };
      // console.log('ROOM UPDATED', newState);
      break;
    }
    case types.ROOM_SET_GRAPH_ZOOM: {
      const { zoom } = action.payload;
      newState = {
        ...state,
        zoom,
      };
      // console.log('ROOM UPDATED', newState);
      break;
    }
    case types.ROOM_SET_ITEM: {
      const { itemId, nodeId } = action.payload;
      newState = { ...state };
      newState.itemId = itemId;
      newState.nodeId = nodeId;
      // console.log('ROOM UPDATED', newState);
      break;
    }
    default:
      newState = state;
      break;
  }
  return newState;
};

export const RoomReducerKey = 'room';

export default reducer;
