/* @flow */
import React from 'react';

import { connect } from 'react-redux';
import { SortableContainer, SortableElement, arrayMove } from 'react-sortable-hoc';

import { DiscussionServiceHelper } from 'src/store/scenario/items';
import { Message, Answer, AnswerOpened } from 'src/data/discussions';
import LocalizedString from 'src/data/LocalizedString';
import errors from 'src/assets/errors';
import { withTranslation } from 'react-i18next';
import { compose } from 'redux';
import Firebase, { withFirebase, FirebaseHelper } from 'src/services/Firebase';
import { EventsServiceHelper, NotificationTypes } from 'src/store/events';

import Loader from 'src/pages/components/loader';
import InputString from 'src/pages/components/inputs/InputString';
import './DiscussionGraphView.css';
import InputLocalizedFile from 'src/pages/components/inputs/InputLocalizedFile';
import LocalizedFile from 'src/data/LocalizedFile';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AddItemWidget from '../../../components/graph/AddItemWidget';
import { InputSelect } from '../../../../components/inputs';
import { ItemNodeTypes } from '../../../components/graph';
import { ItemTypes } from '../../../../../data/BaseItem';

export type MessageInputProps = {
  nodeId: string,
  messageId: string,
  discussionId: string,
  prefix: string,
  message: Message,
  canChangeId: boolean,
  contentTypes: string[],
  answerModes: string[],
  nextModes: string[],
  items: string[],
  updateMessage: DiscussionServiceHelper.updateMessageType,
  locale: string,
  firebase: Firebase,
  scenarioId: string,
  addNotif: EventsServiceHelper.addNotifType,
  startEditing: () => any,
  isEditingItem: boolean,
  onAddBellow: (data: any, type: string) => any,
};

type State = {
  isValid?: boolean,
  idSuffix: string,
  content: ?string,
  answers: Answer[],
  answersOpened: AnswerOpened[],
  contentType: string,
  answerMode?: string,
  hasChanges: boolean,
  isSaved?: boolean,
  nextMode?: string,
  contentImage: ?LocalizedFile,
  contentItemId: ?string,
  filesToRemove: any[],
  isLoading: boolean,
};

const SortableItem = SortableElement(({
  value, locale, contentKey, transform,
}) => {
  const displayValue = transform ? transform(value) : value[contentKey].valueForLocale(locale, true);

  return (
    <div className="d-flex justify-content-between d-flex list-group-item list-group-item-action">
      <label className="mb-1">{displayValue}</label>
    </div>
  );
});

const SortableList = SortableContainer(({
  items, locale, contentKey, transform,
}) => (
  <div className="list-group d-flex container-fluid">
    {items.map((answer, index) => (
      <SortableItem
        key={`item-${index}`}
        index={index}
        value={answer}
        contentKey={contentKey}
        transform={transform}
        locale={locale}
      />
    ))}
  </div>
));

class MessageInput extends React.PureComponent<MessageInputProps, State> {
  static defaultProps = {
    canChangeId: true,
    contentTypes: [],
    message: {},
  };

  state = {
    isValid: false,
    isSaved: true,
    idSuffix: '',
    content: '',
    contentImage: undefined,
    contentItemId: undefined,
    answers: [],
    answersOpened: [],
    contentType: 'Text',
    answerMode: undefined,
    hasChanges: false,
    filesToRemove: [],
    isLoading: false,
    nextMode: undefined,
  };

  componentDidMount() {
    this.setItemData(this.props);
  }

  componentDidUpdate(oldProps, oldState) {
    if (oldProps.nodeId !== this.props.nodeId) {
      this.warnSaveIfNeeded(oldProps, oldState);
    }
    try {
      if (oldProps.messageId !== this.props.messageId || oldProps.nodeId !== this.props.nodeId) {
        this.setItemData(this.props);
      }
    } catch (error) {
      console.debug(error);
    }
  }

  componentWillUnmount() {
    this.warnSaveIfNeeded();
  }

  warnSaveIfNeeded = (oldProps?: MessageInputProps = this.props, state: State = this.state) => {
    if (this.state.hasChanges && this.props.scenarioId) {
      const updateData = this.getDataToSave(oldProps, state);
      const { isValid } = state;
      if (isValid) {
        this.props.addNotif(NotificationTypes.WARN, 'W_UNSAVED_ITEM', undefined, 0, {
          title: this.props.t(['general.save', '']),
          callback: async () => {
            if (oldProps.scenarioId) {
              this.updateWithData(updateData, false);
            } else {
              this.props.addNotif(NotificationTypes.ERROR, 'E_UNSAVED_ITEM', undefined, 0);
            }
          },
          closeOnCallback: true,
        });
      } else {
        this.props.addNotif(NotificationTypes.ERROR, 'E_UNSAVED_ITEM_INVALID', undefined, 0);
      }
    }
  };

  setItemData = (props: MessageInputProps) => {
    const {
      message, prefix, locale, contentTypes,
    } = props;
    if (message) {
      const {
        id,
        contentType,
        content,
        contentImage,
        contentItemId,
        answerMode,
        answers,
        nextMode,
        answersOpened,
      } = message;
      const idSuffix = id ? id.slice(prefix.length) : '';
      const newState: State = {
        idSuffix: idSuffix || '',
        contentType: contentType || (contentTypes && contentTypes[0]) || 'Text',
        content: content && content.valueForLocale(locale),
        contentImage,
        contentItemId: contentItemId || '',
        answerMode: answerMode || '',
        answers: answers || [],
        answersOpened: answersOpened || [],
        nextMode: nextMode || '',
        hasChanges: false,
        filesToRemove: [],
        isLoading: false,
      };
      this.setState(newState);
      this.updateValidity(newState);
    }
  };

  onSortAnswersOpened = ({ oldIndex, newIndex }) => {
    this.setState({
      answersOpened: arrayMove(this.state.answersOpened, oldIndex, newIndex),
    });
  };

  onSortAnswers = ({ oldIndex, newIndex }) => {
    this.setState({
      answers: arrayMove(this.state.answers, oldIndex, newIndex),
    });
  };

  onFieldFocus = () => {
    if (!this.props.isEditingItem) {
      this.props.startEditing();
    }
  };

  handleChange = (event) => {
    if (!this.props.isEditingItem) {
      this.props.startEditing();
    }
    const value = event.target.value;
    const fieldName = event.target.id;
    this.setState({ [fieldName]: value, hasChanges: true });
    const newVal = { ...this.state };
    newVal[fieldName] = value;
    this.updateValidity(newVal);
  };

  updateValidity = (newVal: State) => {
    let isValid: boolean = true;
    const {
      idSuffix, contentType, content, nextMode,
    } = newVal;
    isValid = !!idSuffix && !!idSuffix.length && !!contentType && !!content;

    const { nextMessageId } = this.props.message;

    switch (nextMode) {
      case 'Continue':
      case undefined:
      case 'Retry':
        isValid = isValid && !!nextMessageId;
        break;
      case 'Exit':
      case 'Complete':
        isValid = isValid && !nextMessageId;
        break;
      default:
        break;
    }
    this.setState({ isValid });
  };

  getDataToSave = (props?: MessageInputProps = this.props, state?: State = this.state) => {
    const {
      idSuffix,
      contentType,
      content,
      contentImage,
      contentItemId,
      answerMode,
      answers,
      answersOpened,
      nextMode,
    } = state;

    const {
      updateMessage, message, prefix, discussionId, messageId, locale, scenarioId, firebase,
    } = props;
    const id = prefix + idSuffix;
    const contentTranslated = message.content && new LocalizedString(message.content.id, message.content);
    if (contentTranslated && content) {
      contentTranslated.setValueForLocale(content, locale);
    }
    const newMessage = new Message(message);
    newMessage.id = id;
    newMessage.contentType = contentType;
    newMessage.content = contentTranslated;
    if (newMessage.content && contentType !== 'Text') {
      newMessage.content.flush();
      this.setState({ content: '' });
    }
    newMessage.contentImage = contentImage;
    newMessage.contentItemId = contentType === 'Item' ? contentItemId : undefined;
    newMessage.answerMode = answerMode;
    newMessage.answers = answers;
    newMessage.nextMode = nextMode;
    newMessage.answersOpened = answersOpened;

    let { filesToRemove } = state;

    if (contentType !== 'Image' && newMessage.contentImage) {
      filesToRemove = [...filesToRemove, ...newMessage.contentImage.listStorageFiles()];
      newMessage.contentImage.flush();
    }
    return {
      newMessage,
      updateMessage,
      discussionId,
      messageId,
      scenarioId,
      id,
      firebase,
      filesToRemove,
    };
  };

  updateWithData = async (updateData, notifyUi: boolean = false) => {
    const {
      updateMessage, discussionId, messageId, scenarioId, id, firebase, newMessage, filesToRemove,
    } = updateData;
    if (updateMessage) {
      if (filesToRemove && filesToRemove.length) {
        await FirebaseHelper.removeEditorFilesAsync(scenarioId, filesToRemove, 'scenario', firebase);
      }
      if (notifyUi) {
        this.setState({ isLoading: true });
      }
      await updateMessage(discussionId, messageId || id, newMessage, scenarioId, firebase);
      if (notifyUi) {
        this.setState({ hasChanges: false, isLoading: false });
      }
    }
  };

  updateMessage = () => {
    const updateData = this.getDataToSave();
    this.updateWithData(updateData, true);
  };

  getErrors = () => {
    const { answersOpened, isSaved } = this.state;
    const res = [];
    if (answersOpened.length) {
      let defaultCount = 0;
      answersOpened.forEach((ans) => {
        if (defaultCount > 0) {
          res.push(errors.message_answer_default_order);
        }
        if (ans.isDefault) {
          defaultCount += 1;
        }
      });
      if (defaultCount === 0) {
        res.push(errors.message_answer_default_missing);
      } else if (defaultCount > 1) {
        res.push(errors.message_answer_default_duplicated);
      }
    }
    if (!isSaved) {
      // res.push(errors.item_not_saved);
    }
    return res;
  };

  handleFileSelected = (fieldName, locale, file) => {
    const field = this.state[fieldName];
    const oldFile = field;
    const newFile = new LocalizedFile(this.props.message.id, undefined, oldFile);
    const itemFilesToRemove = oldFile.listStorageFiles(locale);
    if (!newFile.hasLocale(locale)) {
      newFile.addLocale(locale);
    }
    newFile.files[locale].contentToUpload = file;

    newFile.files[locale].name = file.name;
    const newField = newFile;
    this.setState({ [fieldName]: newField, hasChanges: true });
    if (itemFilesToRemove.length) {
      let { filesToRemove } = this.state;
      if (filesToRemove) {
        filesToRemove = [...filesToRemove, ...itemFilesToRemove];
      } else {
        filesToRemove = itemFilesToRemove;
      }
      this.setState({ filesToRemove });
    }
  };

  handleContentChange = (fieldName: string, value: string) => {
    const { locale } = this.props;
    const field = this.state[fieldName];
    const oldFile = field;
    const newFile = new LocalizedFile(this.props.message.id, undefined, oldFile);
    newFile.content.values[locale] = value;
    const newField = newFile;
    this.setState({ [fieldName]: newField, hasChanges: true });
  };

  addFileLocale = (fieldName, locale) => {
    const field = this.state[fieldName];
    const oldFile = field;
    const newFile = new LocalizedFile(this.props.message.id, undefined, oldFile);
    newFile.addLocale(locale);
    const newField = newFile;
    this.setState({ [fieldName]: newField, hasChanges: true });
  };

  removeFileLocale = (fieldName, locale) => {
    const field = this.state[fieldName];
    const oldFile = field;
    const newFile = new LocalizedFile(this.props.message.id, undefined, oldFile);
    const itemFilesToRemove = newFile.listStorageFiles(locale);
    newFile.removeLocale(locale);
    const newField = newFile;

    this.setState({ [fieldName]: newField, hasChanges: true });
    if (itemFilesToRemove.length) {
      let { filesToRemove } = this.state;
      if (filesToRemove) {
        filesToRemove = [...filesToRemove, ...itemFilesToRemove];
      } else {
        filesToRemove = itemFilesToRemove;
      }
      this.setState({ filesToRemove });
    }
  };

  addBellow = (dataStr: string) => {
    const data = JSON.parse(dataStr);
    this.props.onAddBellow(this.props.message, data.type);
  };

  render() {
    const {
      contentTypes, answerModes, message, locale, items, nextModes, t,
    } = this.props;
    const {
      idSuffix,
      contentType,
      content,
      contentImage,
      contentItemId,
      answerMode,
      isValid,
      answers,
      answersOpened,
      nextMode,
      hasChanges,
      isLoading,
    } = this.state;
    const errorMessages = this.getErrors();

    const saveBtnClass = hasChanges ? 'btn-warning' : 'btn-outline-secondary';
    return (
      <div className="card bg-light screenBlock" style={{ height: '100%', overflow: 'hidden' }}>
        <div className="card-header">
          <h5 style={{ marginBottom: 0 }}>{t(['screens.discussionEdition.messageEdition.sectionTitle', ''])}</h5>
        </div>
        {errorMessages.length ? (
          <div>
            {errorMessages.map(mess => (
              <div className={`alert mb-0 ${mess.class}`} key={mess.key} role="alert">
                {mess.text}
              </div>
            ))}
          </div>
        ) : (
          undefined
        )}
        {message && message instanceof Message && (
          <div className="pr-3 ml-3 mt-3 message-edit">
            <InputString
              fieldName="idSuffix"
              onFocus={this.onFieldFocus}
              value={idSuffix}
              label={t(['screens.discussionEdition.messageEdition.idLabel', ''])}
              help={t(['screens.discussionEdition.messageEdition.idPlaceholder', ''])}
              handleChange={this.handleChange}
              disabled={!!message.id}
            />

            <InputSelect
              fieldName="contentType"
              onFocus={this.onFieldFocus}
              value={contentType}
              values={contentTypes}
              itemToId={it => it}
              itemToTitle={it => it}
              label={t(['screens.discussionEdition.messageEdition.contentTypeLabel', ''])}
              handleChange={this.handleChange}
            />
            <InputString
              fieldName="content"
              onFocus={this.onFieldFocus}
              value={content || ''}
              label={t(['screens.discussionEdition.messageEdition.contentLabel', ''])}
              handleChange={this.handleChange}
              multiline={true}
              hidden={contentType !== 'Text'}
            />
            <InputLocalizedFile
              fieldName="contentImage"
              onFocus={this.onFieldFocus}
              value={contentImage}
              label={t(['screens.discussionEdition.messageEdition.contentLabel', ''])}
              handleChange={this.handleChange}
              hidden={contentType !== 'Image'}
              handleFileSelected={this.handleFileSelected}
              handleContentChange={this.handleContentChange}
              addFileLocale={this.addFileLocale}
              removeFileLocale={this.removeFileLocale}
              contentLocale={locale}
            />
            <InputSelect
              fieldName="contentItemId"
              onFocus={this.onFieldFocus}
              value={contentItemId}
              values={items}
              itemToId={it => it}
              itemToTitle={it => it}
              label={t(['screens.discussionEdition.messageEdition.nextCustomLabel', ''])}
              handleChange={this.handleChange}
              hidden={contentType !== 'Item'}
            />
            <InputSelect
              fieldName="answerMode"
              onFocus={this.onFieldFocus}
              value={answerMode}
              values={answerModes}
              itemToId={it => it}
              itemToTitle={it => it}
              label={t(['screens.discussionEdition.messageEdition.answerModeLabel', ''])}
              handleChange={this.handleChange}
              hidden={!message || message.nextMessageId}
            />
            <InputSelect
              fieldName="nextMode"
              onFocus={this.onFieldFocus}
              value={nextMode}
              values={nextModes}
              itemToId={it => it}
              itemToTitle={it => it}
              label={t(['screens.discussionEdition.messageEdition.nextModeLabel', ''])}
              handleChange={this.handleChange}
              help={t(['screens.discussionEdition.messageEdition.nextModeHelp', ''])}
              hidden={answersOpened.length !== 0 || answers.length !== 0}
              helpInfos={[
                {
                  content: t(['helpStrings:scenario.discussion.input.messageNextModes', ''], { returnObjects: true }),
                  title: 'nextMode',
                },
              ]}
            />

            <div className="mb-3" hidden={answers.length === 0}>
              <h5 className="mb-0">{t(['screens.discussionEdition.messageEdition.answers', ''])}</h5>
              <span className="blockquote-footer mb1">
                {t(['screens.discussionEdition.messageEdition.dragToOrder', ''])}
              </span>
              <SortableList items={answers} locale={locale} onSortEnd={this.onSortAnswers} contentKey={'content'} />
            </div>
            <div className="mb-3" hidden={answersOpened.length === 0}>
              <h5 className="mb-0">{t(['screens.discussionEdition.messageEdition.answersOpened', ''])}</h5>
              <span className="blockquote-footer mb1">
                {t(['screens.discussionEdition.messageEdition.dragToOrderOpened', ''])}
              </span>
              <SortableList
                items={answersOpened}
                locale={locale}
                onSortEnd={this.onSortAnswersOpened}
                contentKey={'contentsRecognized'}
                transform={(item) => {
                  if (item.isDefault) {
                    return ['default', 'helpStrings'];
                  }
                  const val = item.contentsRecognized.valueForLocale(locale, true);
                  return val ? val.join(', ') : 'Undefined';
                }}
              />
            </div>
            <div
              className={`${saveBtnClass} save-btn interactive`}
              id="button-addon2"
              onClick={this.updateMessage}
              disabled={!isValid}
            >
              <FontAwesomeIcon icon={['fad', 'save']} />
            </div>
            <div className="form-group">
              <label className="strong">{t(['screens.discussionEdition.messageEdition.fastNext', ''])}</label>
              <span className="fast-action">
                <AddItemWidget type={ItemNodeTypes.Message} draggable={false} onClick={this.addBellow} />
              </span>
              <span className="fast-action">
                <AddItemWidget type={ItemNodeTypes.Answer} draggable={false} onClick={this.addBellow} />
              </span>
              <span className="fast-action">
                <AddItemWidget type={ItemNodeTypes.AnswerOpened} draggable={false} onClick={this.addBellow} />
              </span>
              <span className="fast-action">
                <AddItemWidget type={ItemNodeTypes.Trigger} draggable={false} onClick={this.addBellow} />
              </span>
            </div>
          </div>
        )}
        {isLoading && <Loader />}
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { discussionId, messageId, nodeId } = ownProps;
  const discussion = state.scenario.items[discussionId];
  let message;
  if (discussion) {
    if (!messageId) {
      message = discussion.__detachedNodes.messages.find(mess => mess.nodeId === nodeId);
      if (!message) {
        // $FlowFixMe Object.values
        message = Object.values(discussion.messages).find(it => it.nodeId === nodeId);
      }
    } else {
      message = discussion.messages[messageId];
    }
  }
  const validItemTypeKeys = Object.values(state.scenario.items)
    .filter(val => val.id && val.type === ItemTypes.Document)
    .map(it => it.id);
  return {
    scenarioId: state.scenario.header.id,
    locale: state.preferences.editionLocale,
    contentTypes: state.configuration.contentTypes,
    answerModes: state.configuration.answerModes,
    nextModes: state.configuration.nextModes,
    message,
    items: validItemTypeKeys,
    prefix: `${discussionId}_`,
  };
};

const mapDispatchToProps = {
  updateMessage: DiscussionServiceHelper.updateMessageAsync,
  addNotif: EventsServiceHelper.addNotif,
};

export default compose(
  withFirebase,
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  withTranslation(['default', 'helpStrings']),
)(MessageInput);
