/* @flow */
import React from 'react';

import { connect } from 'react-redux';
import update from 'immutability-helper';

import * as Globals from 'src/constants/globals';
import {
  InputString,
  InputBoolean,
  Loader,
  InputNumber,
  InputDate,
  InputSelect,
  withConfirm,
} from 'src/pages/components';
import { LocalizedString, City } from 'src/data';
import type { ObjectMap, ScenarioVendingInfo } from 'src/data';
import { withTranslation } from 'react-i18next';
import { compose } from 'redux';
import Firebase, { withFirebase, FirebaseHelper } from 'src/services/Firebase';
import { ScenarioServiceHelper } from 'src/store/scenario';
import { withCities } from 'src/pages/cities/WithCities';

import { locale } from 'moment';
import { TabContent } from '../components';

type Props = {
  firebase: Firebase,
  deployScenarioAsync: FirebaseHelper.deployScenarioAsyncType,
  checkScenarioVersionAsync: FirebaseHelper.checkScenarioVersionAsyncType,
  deployToInternalAsync: ScenarioServiceHelper.deployToInternalAsyncType,
  locale: string,
  cities: City[],
};

type ScenarioInfo = {
  id: string,
  cityId: string,
  name: LocalizedString,
  subtitle: LocalizedString,
  vendingInfo: ScenarioVendingInfo,
  lastVersion: string,
  currentVersion?: string,
  alert: any,
};

type ProductItem = {
  active: boolean,
  price: number,
  id: string,
  name: string,
  definition: string,
};

type State = {
  newScenarios?: ObjectMap<ScenarioInfo>,
  currentScenarios?: ObjectMap<ScenarioInfo>,
  matchingScenarios?: ObjectMap<ScenarioInfo>,
  newScenarioId?: string,
  scenarioId?: string,
  currentVersion?: string,
  lastVersion?: string,
  comingSoon: boolean,
  externalSeller: boolean,
  visible: boolean,
  wasVisible: boolean,
  isFree: boolean,
  price: ?number,
  iapSku: ?string,
  promoExpirationDate: ?number,
  hasMultipleEngines: boolean,
  removeOldReleasesAccess: boolean,
  expendable: boolean,

  isNewDeploy: boolean,
  isLoading: boolean,
  isDeploying: boolean,
  isValid: boolean,

  changes: any[],
  editors: string[],
  itemIds: string[],
  sections: string[],
  preconfiguredProducts: ProductItem[],
  preconfiguredProductId?: string,

  // filters
  cityId?: string,
  searchString?: string,
};

class ReleasesTab extends React.PureComponent<Props, State> {
  static defaultProps = {};

  state = {
    newScenarios: {},
    currentScenarios: {},
    matchingScenarios: {},
    newScenarioId: undefined,
    scenarioId: undefined,
    lastVersion: undefined,
    currentVersion: undefined,
    comingSoon: false,
    externalSeller: false,
    visible: false,
    wasVisible: false,
    isFree: false,
    price: null,
    iapSku: null,
    promoExpirationDate: null,
    hasMultipleEngines: false,
    removeOldReleasesAccess: false,
    expendable: false,

    isNewDeploy: false,
    isLoading: false,
    isDeploying: false,
    isValid: false,

    changes: [],
    editors: [],
    itemIds: [],
    sections: [],
    preconfiguredProducts: [],
    preconfiguredProductId: null,

    cityId: undefined,
    searchString: '',
  };

  componentWillMount() {
    this.reloadScenariosAsync().then(() => {
      this.reloadSkusAsync();
    });
  }

  handleCityChange = (event) => {
    const value = event.target.value;
    // $FlowFixMe Boolean is only used for bool fields
    this.setState({ cityId: value }, () => {
      this.updateMatchingScenarios();
    });
  };

  handleChange = (event) => {
    const value = event.target.value;
    const fieldName = event.target.id;
    if (
      event.target.id === 'isFree'
      && !value
      && this.state.scenarioId
      && (!this.state.iapSku || !this.state.iapSku.length)
    ) {
      this.setState({ iapSku: `com.magnitudelabs.atlantide.${this.state.scenarioId}` });
    }
    if (fieldName === 'preconfiguredProductId') {
      const product = value && this.state.preconfiguredProducts.find(it => it.id === value);
      if (product) {
        const { id, price } = product;
        this.setState({ iapSku: decodeURI(id), price });
      } else {
        this.setState({ iapSku: `com.magnitudelabs.atlantide.${this.state.scenarioId}`, price: 0 });
      }
    }
    this.setState({ [fieldName]: value }, () => this.updateValidity(this.state));
  };

  selectScenario = (data: ScenarioInfo) => {
    const { vendingInfo } = data;
    let nonNullEngineCount;
    try {
      nonNullEngineCount = data.versionPerEngine ? data.versionPerEngine.filter(it => it !== null).length : 0;
    } catch (error) {
      nonNullEngineCount = Object.keys(data.versionPerEngine).length;
    }
    const isFree = !vendingInfo || vendingInfo.isFree || (!vendingInfo.price && !vendingInfo.iapSku);
    this.setState(
      {
        newScenarioId: undefined,
        scenarioId: data ? data.id : undefined,
        lastVersion: data.lastVersion,
        currentVersion: data.currentVersion,
        comingSoon: (vendingInfo && vendingInfo.comingSoon) || false,
        externalSeller: (vendingInfo && vendingInfo.externalSeller) || false,
        visible: (vendingInfo && vendingInfo.visible) || false,
        isFree,
        price: vendingInfo && vendingInfo.price,
        iapSku: vendingInfo && vendingInfo.iapSku,
        promoExpirationDate: vendingInfo && vendingInfo.promoExpirationDate,
        isNewDeploy: false,
        wasVisible: (vendingInfo && vendingInfo.visible) || false,
        hasMultipleEngines: nonNullEngineCount > 1,
        removeOldReleasesAccess: false,
        expendable: (vendingInfo && vendingInfo.expendable) || false,
        preconfiguredProductId:
          !isFree && vendingInfo && vendingInfo.expendable
            ? this.state.preconfiguredProducts.find(it => decodeURI(it.id) === vendingInfo.iapSku).id
            : '',
      },
      () => {
        this.updateValidity(this.state);
        this.refreshChanges();
      },
    );
  };

  selectScenarioId = () => {
    const scenarioId = this.state.newScenarioId;
    this.setState({
      scenarioId,
      comingSoon: true,
      externalSeller: false,
      visible: false,
      isFree: true,
      price: 0,
      iapSku: undefined,
      promoExpirationDate: undefined,
      isNewDeploy: true,
      wasVisible: false,
      hasMultipleEngines: false,
      removeOldReleasesAccess: false,
    });
  };

  refreshChanges = async () => {
    const { firebase } = this.props;
    const { scenarioId, lastVersion, currentVersion } = this.state;
    const versions = [];
    if (lastVersion) {
      versions.push(lastVersion);
    }
    if (currentVersion) {
      versions.push(currentVersion);
    }
    if (scenarioId && lastVersion) {
      try {
        const {
          changes, editors, itemIds, sections,
        } = await ScenarioServiceHelper.listChangesAsync(
          scenarioId,
          versions,
          firebase,
        );
        this.setState({
          changes,
          editors,
          itemIds,
          sections,
        });
      } catch (error) {
        console.error('Could not load changelist', error);
      }
    }
  };

  addScenarioAsync = async () => {
    await this.reloadScenariosAsync();
    this.setState(
      {
        scenarioId: undefined,
        newScenarioId: undefined,
      },
      () => {
        this.updateValidity(this.state);
      },
    );
  };

  prepareVendingInfoToDeploy = () => {
    const {
      comingSoon, visible, isFree, price, iapSku, promoExpirationDate, externalSeller, expendable,
    } = this.state;
    const newVendingInfo: any = {
      comingSoon,
      externalSeller,
      expendable: !isFree && expendable,
      visible,
      price: isFree || !price ? 0 : parseInt(price, 10),
    };
    if (!isFree && iapSku) {
      newVendingInfo.iapSku = iapSku;
    }
    if (promoExpirationDate) {
      newVendingInfo.promoExpirationDate = promoExpirationDate;
    }
    return newVendingInfo;
  };

  reloadScenariosAsync = async () => {
    try {
      this.setState({ isLoading: true });
      const { newScenarios, currentScenarios } = await this.props.firebase.getScenariosToDeployAsync();
      this.setState({ newScenarios, currentScenarios, isLoading: false }, () => {
        this.updateMatchingScenarios();
      });
    } catch (error) {
      console.warn('Cannot load scenarios', error);
      this.setState({ isLoading: false });
    }
  };

  updateMatchingScenarios = () => {
    const cityId = this.state.cityId;
    const matchingScenarios = {};
    if (this.state.currentScenarios) {
      Object.keys(this.state.currentScenarios).forEach((it: string) => {
        if (cityId) {
          // $FlowFixMe indexer
          if (this.state.currentScenarios[it].cityId === cityId) {
            matchingScenarios[it] = this.state.currentScenarios[it];
          }
        } else {
          // $FlowFixMe indexer
          matchingScenarios[it] = this.state.currentScenarios[it];
        }
      });
    }
    this.setState({ matchingScenarios });
  };

  reloadSkusAsync = async () => {
    try {
      this.setState({ isLoading: true });
      const preconfiguredProducts = await this.props.firebase.getPreconfiguredProducts();
      this.setState({ preconfiguredProducts, isLoading: false });
    } catch (error) {
      console.warn('Cannot load availableSkus', error);
      this.setState({ isLoading: false });
    }
  };

  dryRunDeployLatestReleaseAsync = async () => {
    if (Globals.hasEditor) {
      await this._deployToInternalAsync(true);
    } else {
      await this._deployLatestReleaseAsync(true);
    }
  };

  deployLatestReleaseAsync = async () => {
    if (Globals.hasEditor) {
      await this._deployToInternalAsync(false);
    } else {
      await this._deployLatestReleaseAsync(false);
    }
  };

  _checkMultipleEngines = (yesCallback: () => void | Promise<void>, noCallback: () => void) => {
    const { hasMultipleEngines, wasVisible, visible } = this.state;
    const { t } = this.props;
    if (visible && !wasVisible && hasMultipleEngines) {
      this.props.alert(t(['screens.admin.releaseInfo.confirmSetVisibleMultipleVersions', '']), [
        { text: t(['general.confirm', '']), onPress: yesCallback },
        { text: t(['general.cancel', '']), onPress: noCallback },
      ]);
    } else {
      yesCallback();
    }
  };

  _deployLatestReleaseAsync = async (dryRun: boolean = false) => {
    const { scenarioId, removeOldReleasesAccess } = this.state;
    const { deployScenarioAsync } = this.props;
    if (scenarioId) {
      const generate = async () => {
        try {
          this.setState({ isDeploying: true });
          const newVendingInfo: ScenarioVendingInfo = this.prepareVendingInfoToDeploy();
          await deployScenarioAsync(scenarioId, newVendingInfo, removeOldReleasesAccess, dryRun);
          this.setState({ isDeploying: false });
          if (!dryRun) {
            this.setState({ isNewDeploy: false });
          }
        } catch (error) {
          this.setState({ isDeploying: false });
        }
        await this.reloadScenariosAsync();
      };
      this._checkMultipleEngines(generate, () => {});
    }
  };

  _deployToInternalAsync = async (dryRun: boolean) => {
    const { scenarioId, removeOldReleasesAccess } = this.state;
    const { deployToInternalAsync, firebase } = this.props;
    if (scenarioId && deployToInternalAsync) {
      const generate = async () => {
        try {
          this.setState({ isDeploying: true });
          const newVendingInfo: ScenarioVendingInfo = this.prepareVendingInfoToDeploy();
          await deployToInternalAsync(scenarioId, newVendingInfo, removeOldReleasesAccess, false, firebase, dryRun);
          this.setState({ isDeploying: false, newScenarioId: undefined });
          if (!dryRun) {
            this.setState({ isNewDeploy: false });
          }
        } catch (error) {
          this.setState({ isDeploying: false });
        }
        await this.reloadScenariosAsync();
      };
      this._checkMultipleEngines(generate, () => {});
    }
  };

  _checkVersion = async () => {
    const { checkScenarioVersionAsync } = this.props;
    const { scenarioId } = this.state;
    try {
      this.setState({ isLoading: true });
      await checkScenarioVersionAsync(scenarioId);
      this.setState({ isLoading: false });
    } catch (error) {
      this.setState({ isLoading: false });
    }
  };

  saveVendingInfo = () => {
    this._checkMultipleEngines(this.saveVendingInfoAsync, () => {});
  };

  saveVendingInfoAsync = async () => {
    const { scenarioId } = this.state;
    this.setState({ isLoading: true });
    if (scenarioId) {
      try {
        const newVendingInfo: ScenarioVendingInfo = this.prepareVendingInfoToDeploy();
        await this.props.firebase.updateVendingInfoAsync(scenarioId, newVendingInfo);
        this.setState(
          prevState => update(prevState, {
            currentScenarios: {
              [scenarioId]: {
                vendingInfo: { $set: newVendingInfo },
              },
            },
          }),
          () => {
            this.updateMatchingScenarios();
          },
        );
      } catch (error) {
        console.warn(`Cannot update scenario '${scenarioId}'`, error);
      }
    }
    this.setState({ isLoading: false });
  };

  // eslint-disable-next-line no-unused-vars
  updateValidity = (newVal: State) => {
    const isValid: boolean = newVal.isFree || (newVal.price !== 0 && !!newVal.iapSku);
    this.setState({ isValid });
  };

  renderListButton = (element: ScenarioInfo) => {
    const { locale } = this.props;
    const cityId = element.cityId || '??';
    const scenarioId = this.state.scenarioId || '??';
    let isActive = false;
    let buttonClass = 'list-group-item list-group-item mb-3 list-group-item-action align-items-start';
    if (scenarioId !== '??' && element.id === scenarioId) {
      buttonClass += ' active';
      isActive = true;
    }

    const {
      id, name, subtitle, currentVersion, lastVersion,
    } = element;

    let versionString;
    if (currentVersion && currentVersion !== lastVersion) {
      versionString = `${currentVersion} => ${lastVersion}`;
      if (!isActive) {
        buttonClass += ' list-group-item-warning';
      }
    } else {
      versionString = `Latest (${lastVersion})`;
    }
    return (
      <div className="" key={id}>
        <button id={id} className={buttonClass} onClick={() => this.selectScenario(element)}>
          <div className="d-flex justify-content-between">
            <h5 className="mb-1">{name.valueForLocale(locale)}</h5>
            <small className="text-muted">{`${cityId}/${id}`}</small>
          </div>
          <div className="d-flex justify-content-between">
            <p className="mb-1">{subtitle.valueForLocale(locale)}</p>
            <small className="text-muted">{versionString}</small>
          </div>
        </button>
      </div>
    );
  };

  render() {
    const {
      newScenarioId,
      newScenarios,
      matchingScenarios,
      scenarioId,
      comingSoon,
      externalSeller,
      visible,
      isFree,
      price,
      iapSku,
      promoExpirationDate,
      removeOldReleasesAccess,
      expendable,
      preconfiguredProductId,
      preconfiguredProducts,

      isLoading,
      isDeploying,
      isValid,
    } = this.state;
    const { locale, t } = this.props;
    return (
      <TabContent name="releaseInfo">
        <div className="card bg-light  screenBlock fill" style={{ overflow: 'scroll' }}>
          <div className="card-header">
            <h3>
              {t(['screens.admin.releaseInfo.sectionTitle', ''])} /{' '}
              <small>{`${scenarioId || ''}${
                newScenarioId ? t(['screens.admin.releaseInfo.firstDeploy', '']) : ''
              }`}</small>
            </h3>
          </div>
          <div className="card-body d-flex p-2 pl-4 fill">
            <div className="row fill" style={{ height: '100%', overflow: 'scroll' }}>
              <div className="list-group fill col-4 pb-2" style={{ overflow: 'scroll' }}>
                <div className="addButton">
                  <button
                    id="addButton"
                    className="list-group-item list-group-item mb-3 list-group-item-action align-items-start"
                    onClick={this.addScenarioAsync}
                  >
                    <div className="d-flex justify-content-between">
                      <h5 className="mb-1">{t(['screens.admin.releaseInfo.addScenario', ''])}</h5>
                    </div>
                  </button>
                </div>
                <InputSelect
                  fieldName={'cityId'}
                  value={this.state.cityId}
                  values={this.props.cities}
                  itemToId={it => it.id}
                  itemToTitle={it => it.name.valueForLocale(locale)}
                  label={'Ville'}
                  handleChange={this.handleCityChange}
                />
                {matchingScenarios
                  /* $FlowFixMe: Object.values */
                  && Object.values(matchingScenarios).map((element: ScenarioInfo) => this.renderListButton(element))}
              </div>
              {scenarioId && (
                <div
                  className="list-group col-8 d-flex pb-4"
                  style={{ overflow: 'scroll', height: 'calc(100% - 10px)', paddingLeft: 5 }}
                >
                  <div className="card p-2 mb-2" style={{ display: 'block' }}>
                    <InputBoolean
                      fieldName="comingSoon"
                      value={comingSoon}
                      label={t(['screens.admin.releaseInfo.comingSoonLabel', ''])}
                      help={t(['screens.admin.releaseInfo.comingSoonHelp', ''])}
                      handleChange={this.handleChange}
                    />
                    <InputBoolean
                      fieldName="externalSeller"
                      value={externalSeller}
                      label={t(['screens.admin.releaseInfo.externalSellerLabel', ''])}
                      help={t(['screens.admin.releaseInfo.externalSellerHelp', ''])}
                      handleChange={this.handleChange}
                    />
                    <InputBoolean
                      fieldName="visible"
                      value={visible}
                      label={t(['screens.admin.releaseInfo.visibleLabel', ''])}
                      help={t(['screens.admin.releaseInfo.visibleHelp', ''])}
                      handleChange={this.handleChange}
                    />
                  </div>
                  <div className="card p-2 mb-2 flex-fill" style={{ display: 'block' }}>
                    <InputBoolean
                      fieldName="isFree"
                      value={isFree}
                      label={t(['screens.admin.releaseInfo.isFreeLabel', ''])}
                      help={t(['screens.admin.releaseInfo.isFreeHelp', ''])}
                      handleChange={this.handleChange}
                    />
                    <InputBoolean
                      fieldName="expendable"
                      value={expendable}
                      label={t(['screens.admin.releaseInfo.expendableLabel', ''])}
                      help={t(['screens.admin.releaseInfo.expendableHelp', ''])}
                      handleChange={this.handleChange}
                      hidden={isFree}
                    />
                    <InputSelect
                      fieldName="preconfiguredProductId"
                      value={preconfiguredProductId}
                      values={preconfiguredProducts}
                      itemToId={it => it.id}
                      itemToTitle={it => it.name}
                      label={t(['screens.admin.releaseInfo.preconfiguredProductIdLabel', ''])}
                      handleChange={this.handleChange}
                      hidden={isFree || !expendable}
                    />
                    <InputString
                      fieldName="iapSku"
                      value={isFree ? '' : iapSku || `com.magnitudelabs.atlantide.${scenarioId}`}
                      label={t(['screens.admin.releaseInfo.iapSkuLabel', ''])}
                      help={expendable ? undefined : t(['screens.admin.releaseInfo.iapSkuHelp', ''])}
                      multiline={false}
                      disabled={expendable}
                      handleChange={this.handleChange}
                      hidden={isFree}
                    />
                    <InputNumber
                      fieldName="price"
                      value={isFree || !price ? '' : price}
                      label={t(['screens.admin.releaseInfo.priceLabel', ''])}
                      help={t(['screens.admin.releaseInfo.priceHelp', ''])}
                      disabled={expendable}
                      handleChange={this.handleChange}
                    />

                    <InputDate
                      fieldName="promoExpirationDate"
                      label={t(['screens.admin.releaseInfo.promoExpirationDateLabel', ''])}
                      help={t(['screens.admin.releaseInfo.promoExpirationDateHelp', ''])}
                      value={isFree || !promoExpirationDate ? '' : new Date(promoExpirationDate)}
                      handleChangeTime={this.handleChange}
                      disabled={isFree}
                    />
                  </div>
                  <div>
                    <h5>{t(['screens.admin.releaseInfo.changeList', ''])}</h5>
                    <h6>
                      {t(['screens.admin.releaseInfo.editors', ''])} <small>{this.state.editors.join(', ')}</small>
                    </h6>
                    <h6>
                      {t(['screens.admin.releaseInfo.sections', ''])} <small>{this.state.sections.join(', ')}</small>
                    </h6>
                    <h6>
                      {t(['screens.admin.releaseInfo.items', ''])} <small>{this.state.itemIds.join(', ')}</small>
                    </h6>
                  </div>
                  <div style={{ minHeight: 30 }}>
                    <InputBoolean
                      fieldName="removeOldReleasesAccess"
                      value={removeOldReleasesAccess}
                      label={t(['screens.admin.releaseInfo.removeOldReleasesAccessLabel', ''])}
                      help={t(['screens.admin.releaseInfo.removeOldReleasesAccessHelp', ''])}
                      handleChange={this.handleChange}
                    />
                    <button
                      className="btn btn-outline-secondary"
                      type="button"
                      id="button-save"
                      onClick={this.saveVendingInfo}
                      disabled={!isValid || !!newScenarioId}
                    >
                      {t(['general.save', ''])}
                    </button>
                    <button
                      className="btn btn-outline-warning ml-2"
                      type="button"
                      id="button-deploy"
                      onClick={this.deployLatestReleaseAsync}
                    >
                      {Globals.hasEditor
                        ? t(['screens.admin.releaseInfo.deploy', ''])
                        : t(['screens.admin.releaseInfo.deployLive', ''])}
                    </button>
                    <button
                      className="btn btn-outline-warning ml-2"
                      type="button"
                      id="button-deploy"
                      onClick={this.dryRunDeployLatestReleaseAsync}
                    >
                      {t(['screens.admin.releaseInfo.deployDry', ''])}
                    </button>
                    <button
                      className="btn btn-outline-secondary ml-2"
                      type="button"
                      id="button-check"
                      onClick={this._checkVersion}
                    >
                      {t(['screens.admin.releaseInfo.checkIntegrity', ''])}
                    </button>
                  </div>
                </div>
              )}
              {!scenarioId && (
                <div className="list-group col-8">
                  <div className="card p-2 mb-2">
                    <InputSelect
                      fieldName="newScenarioId"
                      value={newScenarioId}
                      values={Object.values(newScenarios)}
                      itemToId={it => it.id}
                      itemToTitle={it => `${it.id} - ${it.name.valueForLocale(locale)}`}
                      label={t(['screens.admin.releaseInfo.newScenarioLabel', ''])}
                      handleChange={this.handleChange}
                      help={
                        Globals.hasEditor
                          ? t(['screens.admin.releaseInfo.newScenarioHelp', ''])
                          : t(['screens.admin.releaseInfo.newScenarioHelpLive', ''])
                      }
                    />
                  </div>
                  <button
                    className="btn btn-outline-secondary mb-3"
                    type="button"
                    id="button-addon2"
                    onClick={this.selectScenarioId}
                    disabled={!newScenarioId}
                  >
                    {t(['screens.admin.releaseInfo.startReleaseProcess', ''])}
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
        {(isDeploying || isLoading) && <Loader />}
      </TabContent>
    );
  }
}

const sortCities = (a: City, b: City) => a.id.localeCompare(b.id, 'fr');

const mapStateToProps = state => ({
  locale: state.preferences.editionLocale,
  cities: Object.values(state.configuration.availableCities).sort(sortCities),
});

const mapDispatchToProps = {
  deployScenarioAsync: FirebaseHelper.deployScenarioAsync,
  checkScenarioVersionAsync: FirebaseHelper.checkScenarioVersionAsync,
  deployToInternalAsync: ScenarioServiceHelper.deployToInternalAsync,
};

export default compose(
  withCities,
  withConfirm,
  withFirebase,
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  withTranslation('default'),
)(ReleasesTab);
