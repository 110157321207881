/* @flow */
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';

import { withTranslation } from 'react-i18next';
import Firebase, { withFirebase } from '../services/Firebase';
import * as ROUTES from '../constants/routes';
import { UserServiceHelper } from '../store/user';

// import 'bootstrap/dist/css/bootstrap.min.css';
// import '../assets/styles/app.scss';

export type LoginScreenProps = {
  login: UserServiceHelper.loginType,
  lastEmail: string,
  firebase: Firebase,
  history: any,
};

type State = {
  email: string,
  password: string,
  error?: { message: string },
  isValid: boolean,
  hasChanges: boolean,
};

class LoginScreen extends React.PureComponent<LoginScreenProps, State> {
  static defaultProps = {};

  reader = undefined;

  state = {
    email: '',
    password: '',
    error: undefined,
    isValid: false,
    hasChanges: false,
  };

  componentDidMount() {
    try {
      this.initData(this.props);
    } catch (error) {
      console.debug(error);
    }
  }

  componentDidUpdate(oldProps: LoginScreenProps) {
    try {
      if (oldProps.lastEmail !== this.props.lastEmail) {
        this.initData(this.props);
      }
    } catch (error) {
      console.debug(error);
    }
  }

  initData = (props: LoginScreenProps) => {
    const { lastEmail } = props;
    this.setState({
      email: lastEmail,
    });
    this.updateValidity({
      email: lastEmail,
    });
  };

  login = (event) => {
    const { email, password } = this.state;
    const { login, firebase } = this.props;
    if (login) {
      login(email, password, firebase)
        .then(() => {
          this.setState({ password: '', error: undefined });
          this.props.history.push(ROUTES.EDITOR_DASHBOARD);
        })
        .catch((error) => {
          this.setState({ error });
        });
    }
    event.preventDefault();
  };

  updateValidity = (state) => {
    const stateToUse = state || this.state;
    const isValid = !!stateToUse.email && stateToUse.email.length > 6 && !!stateToUse.password && stateToUse.password.length > 6;
    this.setState({
      isValid,
    });
  };

  handleChange = (event: any) => {
    const value = event.target.value;
    const fieldName = event.target.id;
    this.setState({ [fieldName]: value, hasChanges: true });
    const newVal = { ...this.state };
    newVal[fieldName] = value;
    this.updateValidity(newVal);
  };

  render() {
    const {
      isValid, email, password, error,
    } = this.state;
    const { t } = this.props;
    return (
      <div id="login">
        <div className="popup col-md-4 col-12 bg-grey">
          <img src={require('../assets/images/atlantide_logo_c.svg')} id="logo" />
          <h3 id="title" className="titleFont">
            {process.env.REACT_APP_ATL_EDITOR_NAME}
          </h3>

          {error && <p className="error text-center">{error.message}</p>}

          <form>
            <div className="form-group">
              <label htmlFor="email">{t(['screens.login.email', ''])}</label>
              <input
                type="email"
                className="form-control"
                id="email"
                aria-describedby="emailHelp"
                placeholder={t(['screens.login.emailPlaceholder', ''])}
                onChange={this.handleChange}
                value={email}
              />
              <small id="emailHelp" className="form-text text-muted">
                {t(['screens.login.emailHelp', ''])}
              </small>
            </div>
            <div className="form-group">
              <label htmlFor="password">{t(['screens.login.password', ''])}</label>
              <input
                type="password"
                className="form-control"
                id="password"
                placeholder={t(['screens.login.passwordPlaceholder', ''])}
                onChange={this.handleChange}
                value={password}
              />
            </div>
            <button type="button" className="btn btn-primary" disabled={!isValid} onClick={this.login}>
              {t(['screens.login.login', ''])}
            </button>
          </form>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  lastEmail: state.user.lastEmail,
});

const mapDispatchToProps = {
  login: UserServiceHelper.loginAsync,
};

export default compose(
  withRouter,
  withFirebase,
  withTranslation('default'),
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
)(LoginScreen);
