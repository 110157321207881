/* @flow */
import * as React from 'react';
import $ from 'jquery';
import { withTranslation } from 'react-i18next';
import { compose } from 'redux';
import Moment from 'moment';

import './components.css';

export interface RoomMembersProps {
  roomMembers: {
    userId: string,
    sessionId: string,
    email: string,
    sessionStartDate: number,
    roomStartDate: number,
    sessionRefreshDate: number,
  }[];
  onMemberClick?: any => any;
  detailedMode?: boolean;
  noTitle?: boolean;
  alignLeft?: boolean;
  trackedUser?: string;
  withCleanButton?: boolean;
  askClean: (id: string, email: string) => any;
  title?: string;
  t: (key: string[]) => string;
}

type MemberProps = {
  id: string,
  askClean: (id: string, email: string) => any,
  onMemberClick?: any => any,
  withCleanButton?: boolean,
  email: string,
  initialsStr: string,
  detailedMode?: boolean,
  sessionStartDate: number,
  sessionRefreshDate: number,
  isTracked: boolean,
  roomStartDate: number,
};

class RoomMemberView extends React.Component<MemberProps> {
  onClick = () => {
    const { onMemberClick, item } = this.props;
    if (onMemberClick) {
      onMemberClick(item);
    }
  };

  render = () => (
    <div
      className={`${this.props.isTracked ? 'badge-danger' : 'badge-primary'} ${
        this.props.detailedMode ? '' : 'badge badge-pill '
      } mb-1 mr-1 ml-1 roomTooltip`}
      onClick={this.onClick}
    >
      {this.props.detailedMode || (
        <span className="tooltiptext">{`${this.props.email} since ${Moment.duration(
          new Date() - this.props.roomStartDate,
        )
          .locale('fr')
          .humanize()}`}</span>
      )}
      {this.props.initialsStr}
      {this.props.detailedMode
        && `(${this.props.email}, last update ${
          this.props.sessionRefreshDate
            ? Moment(this.props.sessionRefreshDate).fromNow()
            : Moment(this.props.sessionStartDate).fromNow()
        })`}
      {this.props.withCleanButton && (
        <button className="inline-block" onClick={() => this.props.askClean(this.props.id, this.props.email)}>
          X
        </button>
      )}
    </div>
  );
}

class RoomMembersView extends React.Component<RoomMembersProps> {
  // eslint-disable-next-line class-methods-use-this
  render() {
    const {
      roomMembers,
      detailedMode,
      alignLeft,
      title,
      noTitle,
      t,
      withCleanButton,
      askClean,
      onMemberClick,
      trackedUser,
    } = this.props;
    return (
      <div className={`d-flex flex-fill ${alignLeft ? '' : 'flex-row-reverse'} roommember`}>
        <div className="d-flex">
          {noTitle || title || t(['general.modifyingUsers', ''])}
          <div className={`${detailedMode ? 'detailedMemberView' : 'd-flex flex-fill'}`}>
            {roomMembers.map((member) => {
              const {
                userId, email, sessionStartDate, roomStartDate, sessionRefreshDate, sessionId,
              } = member;
              const firstPart = email.split('@')[0];
              const names = firstPart ? firstPart.split('.') : [];
              const initials = names.map(name => name.toUpperCase().substr(0, 1));
              return (
                <RoomMemberView
                  key={`${userId}-${sessionStartDate}`}
                  email={email}
                  item={member}
                  id={userId}
                  initialsStr={initials.join('.')}
                  detailedMode={detailedMode}
                  sessionStartDate={sessionStartDate}
                  sessionRefreshDate={sessionRefreshDate}
                  roomStartDate={roomStartDate}
                  isTracked={sessionId === trackedUser}
                  withCleanButton={withCleanButton}
                  askClean={askClean}
                  onMemberClick={onMemberClick}
                />
              );
            })}
          </div>
        </div>
      </div>
    );
  }
}

export default compose(withTranslation('default'))(RoomMembersView);
