/* @flow */
import * as types from './types';

export const createNpc = (values: any, isImport: boolean = false) => ({
  type: types.NPC_CREATE,
  payload: { npc: values, isImport },
});
export const updateNPCAsync = (npcId: string, values: any) => ({
  type: types.NPC_UPDATE,
  payload: { id: npcId, npc: values },
});
export const removeNPC = (npcId: string) => ({
  type: types.NPC_REMOVE,
  payload: { id: npcId },
});

export const cleanup = () => ({
  type: types.NPC_CLEANUP,
  payload: {},
});
