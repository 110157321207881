/* @flow */
import * as React from 'react';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap';

export interface InputBooleanProps {
  fieldName: string;
  value: boolean;
  label: string;
  help?: ?string;
  disabled?: boolean;
  handleChange: (event: { target: { value: boolean, id: string } }) => any;
  hidden?: boolean;
  separatorBefore?: boolean;
  onFocus?: () => any;
}

export default class InputBoolean extends React.Component<InputBooleanProps> {
  static defaultProps = {
    disabled: false,
    hidden: false,
    separatorBefore: false,
  };

  handleBooleanChange = (event: any) => {
    const { handleChange } = this.props;
    if (handleChange) {
      handleChange({
        target: { value: event.target.checked, id: event.target.id },
      });
    }
  };

  render() {
    const {
      fieldName, value, label, help, disabled, hidden, separatorBefore, onFocus,
    } = this.props;

    return (
      <div className="form-group form-check mb-0" key={fieldName} hidden={hidden}>
        {separatorBefore && <hr />}
        <input
          type="checkbox"
          className="form-check-input"
          id={fieldName}
          onChange={this.handleBooleanChange}
          checked={value}
          disabled={disabled}
          aria-describedby={`${fieldName}Help`}
          placeholder={''}
          onFocus={onFocus}
        />
        <label htmlFor={fieldName} className="form-check-label">
          {label}
        </label>
        {help && help.length && (
          <small id={`${fieldName}Help`} className="form-text text-muted">
            {help}
          </small>
        )}
      </div>
    );
  }
}
